(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.teacher.report.curriculum', {
                    url: '/curriculum/:id',
                    controller: 'teacherReportCurriculumCtrl',
                    templateUrl: '/assets/ng1/teacher/curriculum/curriculum_reports.html',
                    deepStateRedirect: {
                        default: { state: 'app.teacher.report.curriculum.classreports' },
                        params: true
                    }
                })
                .state('app.teacher.report.curriculum.classreports', {
                    url: '/classreports',
                    controller: 'teacherReportCurriculumClassCtrl',
                    templateUrl: '/assets/ng1/teacher/curriculum/curriculum_directive.html'
                })
                .state('app.teacher.report.curriculum.userreports', {
                    url: '/userreports',
                    controller: 'teacherReportCurriculumUserCtrl',
                    templateUrl: '/assets/ng1/teacher/curriculum/userreport/userreport_directive.html'
                })
        }])

        .controller('teacherReportCurriculumCtrl', function ($scope, $state, credentials, user, classes, courses) {
            $scope.$emit('activeTabLeft', 'curriculum');
            $scope.$emit('teacherModeChange', 'report');
            $scope.$on('teacherReportsTabChange', function (event, data) {
                $scope.activeTab = data;
            });

            $scope.mode = 'teacher';
            $scope.credentials = credentials;
            $scope.user = user;
            $scope.classes = classes;
            $scope.courses = courses;
            $scope.list = angular.copy($scope.classes);
            $scope.list.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            });
            
            function vmChangeTab(page) {
                switch (page) {
                    case 'classreports': {
                        $state.go('app.teacher.report.curriculum.classreports');
                        return;
                    }
                    case 'userreports': {
                        $state.go('app.teacher.report.curriculum.userreports');
                        return;
                    }
                }
            }
    
            $scope.changeTab = vmChangeTab;
        })
        .controller('teacherReportCurriculumClassCtrl', function ($scope) {
            $scope.$emit('teacherReportsTabChange', 'classreports');

            $scope.usage = 'class';
        })
        .controller('teacherReportCurriculumUserCtrl', function ($scope) {
            $scope.$emit('teacherReportsTabChange', 'userreports');
        })
})();