(function () {
    'use strict';
    
    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider       
        .state('app.admin.school.tasks.tupdate12.detailreport', {
            params: {
                token: null
            },
            url: '/detailreport',
            controller: 'teacherDetailReportTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/detailreport/detailreport.html'
        })     
        .state('app.admin.school.tasks.dublicate.detailreport', {
            params: {
                token: null
            },
            url: '/detailreport',
            controller: 'teacherDetailReportTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/detailreport/detailreport.html'
        })
        .state('app.admin.school.tasks.bookresults', {

            url: '/bookresults?:idnumber?:token?:state?:title',
            params: {
                extendedParams: null
            },
            controller: 'teacherReportBookResultsCtrl',
            templateUrl: '/assets/ng1/teacher/report/book/results.html'
        })
    }])
})();