(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.teacher.levels', {

            url: '/assignments/:course',
            controller: 'teacherLevelsCtrl',
            resolve: {
                students: function (FRAPI, classes, credentials) {            
                    if (classes.length > 0) {
                        // get list of available classes id's
                        var classIds = classes.map((item) => item.idnumber).join();
      
                        return FRAPI.getUsersFromCohortTeacherMode(classIds, JSON.stringify(['student']))
                            .then(function (response) {
      
                                let uniqStudents = response.unique(x => x.token);
                                return uniqStudents.map((student) => {
      
                                  student.classes = response.filter((x) => x.token == student.token)
                                                            .map((y) => y.idnumber);
      
                                  return student;
                                });
                            })
                    } else {
                        return [];
                    }
                },
            },
            templateUrl: '/assets/ng1/teacher/courses/assignments/assign.html'
        })
        .state('app.admin.school.levels', {

            url: '/assignments/:course',
            controller: 'teacherLevelsCtrl',
            resolve: {
                students: function (FRAPI, classes, credentials, $rootScope, commonService) {     

                    let _date = new Date($rootScope.date);
                    let today = commonService.convertLocalDateToUTCUnix(_date);
                    let $classes = classes.filter(function (item) {
                        return item.date_end >= today;
                    });

                    if ($classes.length > 0) {

                        // get list of available classes id's
                        var classIds = $classes.map((item) => item.idnumber).join();
      
                        return FRAPI.getUsersFromCohortTeacherMode(classIds, JSON.stringify(['student']))
                            .then(function (response) {
      
                                let uniqStudents = response.unique(x => x.token);
                                return uniqStudents.map((student) => {
      
                                  student.classes = response.filter((x) => x.token == student.token)
                                                            .map((y) => y.idnumber);
      
                                  return student;
                                });
                            })
                    } else {
                        return [];
                    }
                },
            },
            templateUrl: '/assets/ng1/teacher/courses/assignments/assign.html'
        })

    }])
    .controller('teacherLevelsCtrl', function ($scope, $q, $state, FRAPI, school, commonService, $rootScope, students, credentials, LMAPI, $stateParams, courses, classes) {

        $scope.$emit('sideTabChange', 'Curriculums');
        $scope.$emit('teacherModeChange', 'modules');
      
        var _date = new Date($rootScope.date);
        let currentDate = commonService.convertLocalDateToUTCUnix(_date);
        
        $scope.classes = classes.filter((item) => currentDate <= (item.date_end || 1924905600));

        $scope.school = school;

        let course = courses.find((course) => course.id == $stateParams.course);
        $scope.name_curriculum = course.name;

        let params = course ? JSON.parse(course.params) : null;

        if (params.p_levels) {
            LMAPI.levels(credentials.session_token, course.curriculumtype, JSON.stringify(params.p_levels)).then(function (response) {

                params.p_levels.forEach((x, i) => {
                    response.forEach((elem) => {

                        if (elem.idnumber === x) {

                            elem.order = i + 1;
                        }
                    });
                });
                response.sort(function (a, b) {
                    if (a.order > b.order) {
                        return 1;
                    }
                    if (a.order < b.order) {
                        return -1;
                    }
                    return 0;
                });
                $scope.levels = response;

                if ($scope.levels.length > 0) {
                    vmOpenLevel($scope.levels[0].idnumber);
                }
            }).catch(function (error) {
                console.log(error);
            })
        }

        // open level
        function vmOpenLevel(levelId) {

            $scope.selectedLevel = levelId;
            $scope.load = false;

            $scope.allStudent = angular.copy(students);

            let _date = new Date($rootScope.date);
            let today = commonService.convertLocalDateToUTCUnix(_date);
            let $classes = classes.filter(function (item) {
                return item.date_end >= today;
            }).map((item) => item.idnumber);

  
            FRAPI.levelStudents(levelId, $classes).then((response) => {
           
                $scope.assignStudents = [];
                
                response.data.forEach(function (item, i) {

                    $scope.allStudent.forEach((elem, index) => {
                        if (elem.username === item) {
                            $scope.assignStudents.push(elem);
                            $scope.allStudent.splice(index, 1);
                        }

                    });
                })

                $scope.load = true;
                $('table.demo').floatThead('reflow');

          })
        }
        // assign student to selected level        
        function vmCohortAddUser(student) {                        

            $rootScope.pageLoading = true;

            FRAPI.addStudentsToLevel($scope.selectedLevel, [student.username])
                       .then(() => {

                           $scope.assignStudents.push(student);

                           $scope.allStudent = $scope.allStudent.filter(function (elem) {
                               return elem.token != student.token;
                           });
                           $rootScope.pageLoading = false;
                           $('table.demo').floatThead('reflow');
                       });            
        }
        // delete student with assign student on this level
        function vmCohortRemoveUser(student) {   
            
            let isArray = Array.isArray(student);               
            let username = isArray ? student.map((x) => x.username) : [student.username];

            $rootScope.pageLoading = true;

            FRAPI.removeStudentsToLevel($scope.selectedLevel, username)
                       .then(() => {

                            if (!isArray) {

                                $scope.allStudent.push(student);

                                $scope.assignStudents = $scope.assignStudents.filter(function (elem) {
                                    return elem.token != student.token;
                                });
                            } else {

                                $scope.assignStudents = $scope.assignStudents.filter((x) => !student.some(y => x.username === y.username));
                                
                                student.forEach((item) => {                               
                                    $scope.allStudent.push(item);
                                });   
                            }
                            $rootScope.pageLoading = false;

                           $('table.demo').floatThead('reflow');
                       });            
        }
        // assign all students
        function vmAssignAllToClass(list) {

            if (list.length > 0) {

                let usernames = list.map((x) => x.username);

                $rootScope.pageLoading = true;

                FRAPI.addStudentsToLevel($scope.selectedLevel, usernames).then(() => {

                    list.forEach((student) => {

                        $scope.assignStudents.push(student);

                        $scope.allStudent = $scope.allStudent.filter(function (elem) {
                            return elem.token != student.token;
                        });
                        
                    });          
                    $rootScope.pageLoading = false;  
                    $('table.demo').floatThead('reflow');
                });  
            }
          
        }
        function vmRemoveStudents(students) {

            if (students.length === 0) { return; }   

            vmCohortRemoveUser(students);
        }
        function vmRemoveFromLevelsStudents(students){

            if (students.length === 0) { return; }   

            $rootScope.pageLoading = true;

            let promise = [];
            let $students = students.map((y) => y.username);

            $scope.levels.forEach((x) => {
                promise.push(FRAPI.removeStudentsToLevel(x.idnumber, $students))
            });

            $q.all(promise).then((response) => {

                $scope.assignStudents = $scope.assignStudents.filter((x) => !students.some(y => x.username === y.username));
                                
                students.forEach((item) => {                               
                    $scope.allStudent.push(item);
                });
                $rootScope.pageLoading = false;
                $('table.demo').floatThead('reflow');
            });
        }
        function vmAssignFromLevelsStudents(students) {

            if (students.length === 0) { return; }
            
            $rootScope.pageLoading = true;

            let promise = [];
            let $students = students.map((y) => y.username);

            $scope.levels.forEach((x) => {
                promise.push(FRAPI.addStudentsToLevel(x.idnumber, $students))
            });

            $q.all(promise).then((response) => {

                $scope.allStudent = $scope.allStudent.filter((x) => !students.some(y => x.username === y.username));
                                
                students.forEach((item) => {                               
                    $scope.assignStudents.push(item);
                });   
                $rootScope.pageLoading = false;
                $('table.demo').floatThead('reflow');
            });
        }

        $scope.limitAssign = 30;
        $scope.limitAvailable = 30;

        $scope.loadMoreAssign = function () {
            $scope.limitAssign += 20;
        };
        $scope.loadMoreAvailable = function () {
            $scope.limitAvailable += 20;
        };
        function vmBack() {
            window.history.back();
        }

        $scope.vmBack = vmBack;
        $scope.openLevel = vmOpenLevel;
        $scope.cohortremoveuser = vmCohortRemoveUser;
        $scope.cohortadduser = vmCohortAddUser;
        $scope.assignAllToClass = vmAssignAllToClass;
        $scope.vmRemoveStudents = vmRemoveStudents;
        $scope.vmRemoveFromLevelsStudents = vmRemoveFromLevelsStudents;
        $scope.vmAssignFromLevelsStudents = vmAssignFromLevelsStudents;
    })

})();