(function () {

    'use strict';

    angular.module('LMLanguaCards')

    .directive('vmsSpellWord', function (LMConfig, languacardsService, $q, $http, $translate) {

        function vmController($scope) {

            $scope.courseErrorMsg = $translate.instant('library.shelve.shelve.cards.noBook');
            $scope.playScannerSound = true;

            var scanSuccessSoundConfig = {
                url: 'resourses/audio/beep-07.wav'
            };

            $scope.$on('$destroy', function () {
                audioContext.close();
                $scope.data = null;

                $scope.playScannerSound = true;

                $scope.dataLoadedCallBack({
                    data: {
                        timeout: 5000,
                        //playScannerSound: true
                    }
                })
            })

            var audioContext = new (window.AudioContext || webkitAudioContext)();

            $scope.course = null;

            $scope.spellWord = false;
            $scope.rCardLetters = [{
                letter: null,
                visible: false,
            }];

            $scope.firstScanned = false;
            $scope.spellWordError = false;
            $scope.spellWordFinish = false;
            $scope.spellWordLettersArray = [];
            $scope.spellWordPosition = 0;
            $scope.spellWordNext = false;
            $scope.spellWordReset = false;
            $scope.courseLoaded = false;
            $scope.showLoadingIndicator = false;

            $scope.nextCard = vmNextCard;
            $scope.playAudio = vmPlayAudio;

            $scope.missedSoundAudioProperties = {
                url: vmGetSoundEffectsUrl('6.Drag-fly%20back-final')
            };

            $scope.finishSoundAudioProperties = {               
                url: vmGetSoundEffectsUrl('4.Match-all%20matched-claps-final')
            };

            $scope.$watch('data', function () {
                if ($scope.data) {

                    if ($scope.playScannerSound)
                        vmPlayAudio(scanSuccessSoundConfig, true);

                    $scope.firstScanned = true;

                    if ($scope.rCardInfo && !$scope.spellWord) {

                        if ($scope.rCardInfo.img) {
                            $scope.rCardInfo.img = null;
                        }
                    }

                    if ($scope.spellWord == true && !$scope.spellWordFinish) {
                        vmCheckLetter($scope.data.letter);
                    } else {

                        vmNextCard();
                        $scope.showLoadingIndicator = true;

                        $http({
                            method: 'get',
                            url: LMConfig.storage + '/dictionary/en/{0}.json'.format($scope.data.rCard)
                        }).then(function (response) {

                            var data = response.data;
                            //load audio for rCard key(like dictionary)
                            if (data.audio) {

                                if (data.audio.length > 0) {
                                    $scope.rCardAudioBufferLoad = languacardsService.loadAudio({
                                        url: '{0}/dictionary/{1}/{2}'.format(LMConfig.storage, 'en', data.audio[0].value)
                                    })
                                }
                            }

                            //then init search and playing random audio
                            vmProcessSpellWord();                            
                        });
                    }                    
                }
            });

            function vmGetSoundEffectsUrl(fileName) {

                return 'resourses/audio/{0}.ogg'.format(fileName);
            }

            function vmProcessSpellWord() {

                languacardsService.searchCourse($scope.token, $scope.data.course).then(function (courses) {

                    $scope.spellWordReset = false;                    

                    if (courses.length > 0) {                       

                        //api returns list (usually 1 item, but if more then 1, take first)
                        $scope.course = courses[0];
                        var course = courses[0];

                        if ($scope.course.enrol == '1') {
                            languacardsService.getScosList($scope.token, course).then(function (scos) {
                                if (scos) {
                                    if (scos.length > 0) {
                                        var randomizedSco = languacardsService.getRandomSco(scos);
                                        if (randomizedSco.params) {

                                            var randomizedContentKey = JSON.parse(randomizedSco.params).title_id;

                                            languacardsService.getRandomScoWordInfo(randomizedContentKey).then(function (data) {

                                                if (data) {
                                                    $scope.rCardWordLoaded = true;
                                                    $scope.rCardInfo = data;

                                                    var word = Array.from($scope.rCardInfo.words);

                                                    $scope.spellWordLettersArray = word;

                                                    var arrayLetters = [];

                                                    // Push it to array like object with properties 'letter' & 'visible'
                                                    word.forEach(x => arrayLetters.push({ letter: x, visible: false }));

                                                    //Looking if first letter of card equal first letter of word
                                                    if (arrayLetters[0].letter.toLowerCase() == $scope.data.letter.toLowerCase()) {
                                                        arrayLetters[0].visible = true;
                                                        $scope.spellWordPosition = $scope.spellWordPosition + 1;
                                                    }

                                                    $scope.rCardLetters = arrayLetters;

                                                    $scope.rCardInfo.img = '{0}/Library/{1}/{1}_Image.png'.format(LMConfig.storage, randomizedContentKey);

                                                }

                                            })

                                            $scope.randomAudioLoad = languacardsService.loadAudio({
                                                url: languacardsService.getAudioUrl(randomizedContentKey)
                                            });

                                            $q.all([$scope.rCardAudioBufferLoad, $scope.randomAudioLoad]).then(function (responses) {

                                                var rCardBuffer = responses[0];
                                                var randomAudioBuffer = responses[1];

                                                $scope.wordAudioURL = languacardsService.getAudioUrl(randomizedContentKey);

                                                languacardsService.processAudio(audioContext, rCardBuffer).then(function () {
                                                    languacardsService.processAudio(audioContext, randomAudioBuffer);
                                                });
                                                $scope.courseLoaded = true;
                                                $scope.courseLoadError = false;

                                                $scope.showLoadingIndicator = false;
                                                $scope.spellWord = true;

                                                //callBack for outer behavior handling

                                                $scope.playScannerSound = false;

                                                $scope.dataLoadedCallBack({
                                                    data: {
                                                        timeout: 2000,
                                                        //playScannerSound: false
                                                    }
                                                });
                                            });

                                        };
                                    };
                                };
                            });
                        } else {
                            vmProcessCourseError();
                        }

                    } else {                       
                        vmProcessCourseError();
                    }
                }).catch(function () {
                    vmProcessCourseError();
                });
            }

            function vmProcessCourseError() {

                $scope.courseErrorMsg = $scope.courseErrorMsg.format($scope.data.course);

                $scope.courseLoaded = false;
                $scope.courseLoadError = true;
                $scope.showLoadingIndicator = false;

                $scope.playScannerSound = true;

                $scope.dataLoadedCallBack({
                    data: {
                        timeout: 5000,
                        //playScannerSound: true,                        
                    }
                });
            }

            function vmCheckLetter(letter) {

                $scope.playScannerSound = false;

                $scope.dataLoadedCallBack({
                    data: {
                        timeout: 2000,
                        //playScannerSound: false
                    }
                });

                var position = $scope.spellWordPosition;

                // If card letter not equal , so error
                if ($scope.spellWordLettersArray[position] != letter) {
                    $scope.spellWordError = true;

                    vmPlayAudio($scope.missedSoundAudioProperties, true);
                }
                else {                   

                    // generate dictionary key
                    // based on current letter
                    let letterSound = letter + '_letter';

                    // If card letter equal
                    if ($scope.rCardLetters[position].letter == letter) {

                        if (position < $scope.rCardLetters.length - 1)
                            vmPlaySuccessRecognizedSound(letterSound);

                        $scope.rCardLetters[position].visible = true;
                        $scope.spellWordError = false;
                        $scope.spellWordPosition = $scope.spellWordPosition + 1;

                    }

                    var arrayVisibleValue = [];

                    for (var i = 0; i < $scope.rCardLetters.length; i++) {
                        arrayVisibleValue.push($scope.rCardLetters[i].visible)
                    }

                    // If in array there no false, so we finish spell word
                    if (arrayVisibleValue.indexOf(false) > -1) {
                        $scope.spellWordFinish = false;
                    }
                    else {

                        $scope.playScannerSound = true;

                        $scope.dataLoadedCallBack({
                            data: {
                                timeout: 5000,
                                //playScannerSound: true
                            }
                        });

                        //vmPlayAudio($scope.finishSoundAudioProperties, true);
                        vmPlaySuccessRecognizedSound(letterSound, vmProcessFinishSpellWord);
                        
                    }

                }
            }

            function vmNextCard() {
                $scope.spellWord = false;
                $scope.spellWordError = false;
                $scope.spellWordFinish = false;
                $scope.spellWordReset = true;

                if ($scope.rCardInfo) {
                    if ($scope.rCardInfo.img)
                        $scope.rCardInfo.img = null;
                }
                
                $scope.rCardLetters = null;
                $scope.spellWordPosition = 0;
            }

            function vmProcessFinishSpellWord() {

                $scope.spellWordError = false;
                $scope.spellWordFinish = true;
                $scope.spellWordNext = true;

                setTimeout(function () {
                    vmPlayAudio({ 'url': $scope.wordAudioURL }).then(function () {

                        setTimeout(function () {
                            vmPlayAudio($scope.finishSoundAudioProperties, true);
                        }, 1000)

                        
                    });
                }, 1000);
                
            }

            function vmPlaySuccessRecognizedSound(letter, callBack) {

                $http({
                    method: 'get',
                    url: LMConfig.storage + '/dictionary/en/{0}.json'.format(letter)
                }).then(function (response) {

                    var data = response.data;

                    if (data.audio) {

                        if (data.audio.length > 0) {

                            var soundUrl = '{0}/dictionary/{1}/{2}'.format(LMConfig.storage, 'en', data.audio[0].value);

                            var playing = vmPlayAudio({
                                url: soundUrl
                            }, true);

                            playing.then(function () {

                                if (callBack)
                                    callBack();
                            });
                        }
                    }
                });
            }

            function vmPlayAudio(audioConfig, preventPlayButtonActiveMode) {
               
                var playing = languacardsService.playAudio(audioContext, audioConfig);

                if (playing) {

                    if (!preventPlayButtonActiveMode)
                        $scope.play = true;

                    playing.then(function () {

                        if (!preventPlayButtonActiveMode)
                            $scope.play = false;

                    });
                }

                return playing;
            }
        }

        return {
            scope: {
                data: '=vmsData',
                token: '=vmsToken',
                dataLoadedCallBack: '&vmsDataLoadedCallback'
            },
            controller: vmController,
            templateUrl: '/assets/ng1/languacards/spellword.html'
        }
    })

})();