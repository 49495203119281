
(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.admin.school.hiringimport', {
                    url: '/hiring/import',
                    params: {
                       // flagFromUpdate: null 
                    },
                    controller: 'adminHiringImportCtrl',
                    templateUrl: '/assets/ng1/admin/hiring/importHiring/importHiring.html',
                })
        }])
        .controller('adminHiringImportCtrl', function ($q, $scope,  $state, LMConfig, LMWordpress, $stateParams, $rootScope, $translate) {
        
            $scope.$emit('adminTabChange', 'hiring');

            $scope.status = [
                { key: 1, value: $translate.instant('admin.hiring.dropListStatus.hired'), sort: 3},
                { key: -1, value: $translate.instant('admin.hiring.dropListStatus.nothired'), sort: 4},
                { key: 0, value: $translate.instant('admin.hiring.dropListStatus.dismissed'), sort: 5},
                { key: 'all', value: $translate.instant('admin.hiring.dropListStatus.all'), sort: 1},
                { key: null, value: "", sort: 2}
            ];

            const keysExsel = [{ key: 'cscid', value: $translate.instant('admin.hiring.importPage.excelColumn.cscid')},
                                { key: 'status', value: $translate.instant('admin.hiring.importPage.excelColumn.status')},
                                { key: 'login', value: $translate.instant('admin.hiring.importPage.excelColumn.login')},
                                { key: 'test_result', value: $translate.instant('admin.hiring.importPage.excelColumn.test_result') },
                                { key: 'date', value: $translate.instant('admin.hiring.importPage.excelColumn.date')},
                                { key: 'name', value: $translate.instant('admin.hiring.importPage.excelColumn.name')}];

            function renameKey ( obj, oldKey, newKey ) {

                if (oldKey !== newKey) {
                    obj[newKey] = obj[oldKey];

                    delete obj[oldKey];
                }              
            }

            function vmImport(data, fileData) {

                $rootScope.pageLoading = true;

                $scope.attachment = {
                    data: fileData.data,
                    name: fileData.name,
                    type: fileData.type
                };
        
                // load workbook
                // and first sheet from it
                var workbook = XLSX.read(data, { type: 'binary' });
                var sheet = workbook.Sheets[workbook.SheetNames[0]];
        
                // convert sheet to json list
                $scope.importList = XLSX.utils.sheet_to_json(sheet).map(function (item) {
                    // trim all fields from excel
                    for (var property in item) {

                        if (item.hasOwnProperty(property) && typeof item[property] === 'number') {

                            let nameColmn = keysExsel.find((x) => x.value.toLowerCase() === property.toLowerCase());

                            if (nameColmn) {
                                
                                renameKey(item, property, nameColmn.key);
                               // item.error = 'Error! Fields must be string';
                            }
                         }


                        if (item.hasOwnProperty(property) && typeof item[property] === 'string') {                                
                            item[property] = item[property].trim();
                            let nameColmn = keysExsel.find((x) => x.value.toLowerCase() === property.toLowerCase());

                            if (nameColmn) {
                                
                                renameKey(item, property, nameColmn.key);
                            }
                        }
                           
                    }

                    if (!item.cscid) {
                        item.cscid = null;
                    } else {
                        item.cscid = vmCheckCSCID(item);
                    }

                    if (!item.login) {
                        item.error = $translate.instant('admin.hiring.importPage.loginError');
                    }                   

                    if (item.status) {
                        item.status = vmCheckStatus(item);
                    } else {
                        item.status = "";
                    }

                    return {
                        name: item.name,
                        login: item.login,
                        status: item.status,
                        cscid: item.cscid,
                        error: item.error
                    };

                });

                // check user names
                $q.all($scope.importList.map((x) => x.def)).then(function () {

                    let isError = false;

                    let logins = $scope.importList.map((item) => { return item.login });
                    let csc_codes = $scope.importList.map((item) => { return item.cscid });

                    let _importList = $scope.importList.filter((x) => x.cscid);
                    let _csc_codes = _importList.map((item) => { return item.cscid });
              
                    let isUnique = onCheckUniqueUser(logins, $scope.importList, 'login');
                    let isUniqueCSCID = onCheckUniqueUser(_csc_codes, _importList, 'cscid');

                    isError = $scope.importList.some((item) => { return item.error });
                    // check or username and csc id exist only 
                    // if no errors anymore
                    if (!isUniqueCSCID && !isUnique && !isError) {
    
                        LMWordpress.checkusersHired($stateParams.school, logins, csc_codes).then(function (response) {
                         
                            $scope.importList.forEach((y) => {
                                let user = response.data.find((x) => x.username === y.login);

                                if (!user.csc_check) {

                                    y.error = $translate.instant('admin.hiring.importPage.userErrorCSC');
                                }

                                if (!user.tested) {
                                    y.error = $translate.instant('admin.hiring.importPage.userNotTest');
                                } 

                                if (!user.school_check) {
                                    y.error = $translate.instant('admin.hiring.importPage.userNotSchool');
                                }

                                if (!user.exists) {
                                    y.error = $translate.instant('admin.hiring.importPage.userNotExist');
                                }
                              

                            })
                            vmOnChecksFinish();
    
                        }).catch(function (error) {
                            console.log(error);
                            vmOnChecksFinish();
                        });
                    } else {
                        vmOnChecksFinish();
                    }

                });
           
            }

            function vmOnChecksFinish() {
                $scope.IsErrors =  $scope.importList.some((x) => !!x.error);
      
                $rootScope.pageLoading = false;
                if (!$scope.$$phase) {
                  $scope.$apply();
                }
                // restructure header table to the correct position
                $('table.demo').floatThead('reflow');
            }

            function onCheckUniqueUser(valueArr, datas, field) {
          
                let _isInArray = false;          
         
                valueArr.forEach(function(item, idx){ 
          
                  let isInArray = valueArr.indexOf(item) != idx;
          
                  if (isInArray) {
          
                    let _array = datas.filter((x) => x[field] === item);
          
                    _array.forEach((y) => {
                        if (field === 'login') {
                            y.error = $translate.instant('admin.hiring.importPage.loginDuplicated');
                        } else {
                            y.error = $translate.instant('admin.hiring.importPage.cscidDuplicated');
                        }                     
                    });
                    _isInArray = true;
                  }
                });
                    
                return _isInArray;
            }

            function vmCheckCSCID(item) {

                if (item.cscid && (/\s/g.test(item.cscid.toString().trim()) || !(/^[ A-Za-z0-9]*$/.test(item.cscid.toString())))){
                    
                    item.error = $translate.instant('admin.hiring.importPage.cscIdError'); 
        
                };
                
                return item.cscid.toString();
            }

            function vmCheckStatus(item) {

                let isStatus = $scope.status.find((x) => x.value.toLowerCase() === item.status.toString().toLowerCase());

                if (!isStatus) {
                    item.error = $translate.instant('admin.hiring.importPage.statusError');
                } 

                return item.status.toString();
            }

            function vmSaveFromImport() {

                $rootScope.pageLoading = true;

                let people = $scope.importList.map((item) => {
                    return {
                        username: item.login,
                        hired_status: $scope.status.find((x) => x.value.toLowerCase() === item.status.toString().toLowerCase()).key,
                        ext_id: item.cscid                  
                    }
                })

                LMWordpress.importHiringPeople(people).then((response) => {
                    
                    // for again disabled button 'Save'
                    $scope.IsErrors = true;

                    let arrayWrong = response.data.filter((elem) => elem.error !== null);

                    $scope.importList.forEach((x) => {
 
                        let userWrong = arrayWrong.find((y) => y.username === x.login);
                        if (userWrong) {
                            x.error = $translate.instant('admin.hiring.importPage.error');
                        } else {
                            x.success = $translate.instant('admin.hiring.importPage.success');
                        }
                    })
                    if (!$scope.$$phase) {
                        $scope.$apply();
                    }
                    $rootScope.pageLoading = false;

                
                }).catch(function (error) {

                    $scope.importList.forEach((x) => {
                     
                        x.error = $translate.instant('admin.hiring.importPage.error');                   
                    })
                });;
            }

            function vmDownloadTemplate() {
 
 
                let arGlobal = [];
                let firstLine =  [ $translate.instant('admin.hiring.importPage.excelColumn.name'), 
                                    $translate.instant('admin.hiring.importPage.excelColumn.login'), 
                                    $translate.instant('admin.hiring.importPage.excelColumn.status'), 
                                    $translate.instant('admin.hiring.importPage.excelColumn.cscid')];

                arGlobal.unshift(firstLine);

                var ws = XLSX.utils.aoa_to_sheet(arGlobal);

                var wscols = [
                    {wch:20},
                    {wch:20},
                    {wch:20},
                    {wch:20}
                ];
                
                ws['!cols'] = wscols;
        
                // A workbook is the name given to an Excel file
                var wb = XLSX.utils.book_new();
        
                // add Worksheet to Workbook
                // Workbook contains one or more worksheets
                XLSX.utils.book_append_sheet(wb, ws);
        
                let namefile = $translate.instant('Template.xlsx');
        
                // export Excel file
                XLSX.writeFile(wb, namefile);
            }

            function vmBack() {
                if ($stateParams.page === 'teachers') {
                    $state.go('app.teacher.report.hiring', { school: $stateParams.school, flagFromUpdate: true});
                } else {
                    $state.go('app.admin.school.hiring', { flagFromUpdate: true}); 
                }
              
            }


            $scope.loadExcel = vmImport;
            $scope.vmSaveFromImport = vmSaveFromImport;  
            $scope.back = vmBack; 
            $scope.downloadTemplate = vmDownloadTemplate;
        })
    })();