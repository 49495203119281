(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.admin.school.tasks.list', {
            url: '/list?:state',
            controller: 'adminListTasksCtrl',
            templateUrl: '/assets/ng1/admin/tasks/listtasks/list.html'
        })
    }])
    .controller('adminListTasksCtrl', function ($scope, $state, credentials, uuid, commonService, LMAPI, tasks, $stateParams, school) {

        $scope.$emit('sideTabChange', $stateParams.state);
        $scope.school = school;
        // cache for new angular in library tab
        window.localStorage.removeItem('cacheQuest');
        
        var _date = new Date();
        let currentDate = commonService.convertLocalDateToUTCUnix(_date);

        $scope.showAllQuest = false;     
       
        //restructure header table to the correct position
        $('table.demo').floatThead('reflow');
        vmSeparate();
        function vmSeparate(){

            if ($stateParams.state == 'current') {

                $scope.listTasks = tasks.filter(function (item) {
                    return currentDate <= item.enddate;
                })
              
                vmConvertDate();
            } else if ($stateParams.state == 'completed') {
    
                $scope.listTasks = tasks.filter(function (item) {
                    return item.enddate < currentDate;
                });
                vmConvertDate();
            }
        }
        function vmConvertDate() {
            $scope.listTasks.forEach(function (item) {
                item.dBegin = item.startdate == 0 || item.startdate == null ? 0 : commonService.getLocaleDate(item.startdate);
                item.dEnd = item.enddate == 0 || item.enddate == null ? 0 : commonService.getLocaleDate(item.enddate);
            })
        }
        function vmFilterTaskByDate(date) {
            if (date) {         

                var unixDate = commonService.convertLocalDateToUTCUnix(date);

                $scope.listTasks = tasks.filter(function (item) {
                    if ((item.startdate <= unixDate && item.enddate >= unixDate) && item.enddate < currentDate) {

                        return item;
                    };
                });
            } else {
                $scope.listTasks = tasks.filter(function (item) {
                    return item.enddate < currentDate;
                });
            }
            //restructure header table to the correct position
            $('table.demo').floatThead('reflow');
        }
        function vmAddTask() {
            $state.go('app.admin.school.tasks.tadd', { state: $stateParams.state });
        }
        function vmSearchTasks(o, i, a) {

            if (!$scope.taskselect) return true;

            var filter = $scope.taskselect.toLowerCase().split(' ');
            var keys = ['title'];

            return filter.every(function (f) {

                return keys.some(function (key) {

                    return this[key].toLowerCase().indexOf(f) > -1;
                }, o);
            });
        };
        function vmOpenPopupTask(task) {

            $scope.task = angular.copy(task);
            $scope.task.$idnumber = uuid.newguid();
            $scope.popupTask = true;

        }
        function vmClosePopup() {

            $scope.popupTask = false;
        }
        function vmCheckDates(begin, end){

            let $begin = begin ? commonService.convertLocalDateToUTCUnix(begin) : null;
            let $end = end ? commonService.convertLocalDateToUTCUnix(end) : null;

            if ($end < $begin) {
                $scope.errorDates = true;
            } else {
                $scope.errorDates = false;
            }
        }
        function vmDelete(task) {

            if (confirm('Are you sure?')) {

                LMAPI.deletetask(credentials.session_token, task.idnumber).then(function (response) {
                    tasks.forEach(function (item, i) {
                        if (item.idnumber == task.idnumber) {
                            tasks.splice(i, 1);
                        }
                    })
                    vmSeparate();
                    if (!$scope.$$phase) {
                        $scope.$apply();
                    }
                })
            }
        }

        function vmChangeTitle(taskselect) {
            $scope.taskselect = taskselect;
        }

        function onChangeTeacherInQuest(teacher) {

            let mark = localStorage.getItem('schooladmin_myquest_checkmark');
            if (mark == '1') {
                $scope.searchteach22 =  credentials.username;
            } else {
                $scope.searchteach22 = teacher ? teacher.username : null;
            }
            let taskselect = localStorage.getItem('taskselect');
            if (taskselect == 'undefined' || taskselect == 'null') {
                taskselect = null;
            }
            vmChangeTitle(taskselect);
        }
        function getStatusTask(status) {

            switch (status) {
                case 'missing_books_students':                       
                    return 'Missing books and students';      
                case 'missing_books':                       
                    return 'Missing books';
                case 'missing_students':
                    return 'Missing students';
                case 'ready':                       
                    return 'Ready';
            }
        }

        $scope.getStatusTask = getStatusTask;
        $scope.addtask = vmAddTask;
        $scope.searchtask = vmSearchTasks;
        $scope.state = $stateParams.state;
        $scope.filterByDate;
        $scope.filterTaskByDate = vmFilterTaskByDate;
        $scope.checkDates = vmCheckDates;
        $scope.OpenPopupTask = vmOpenPopupTask;
        $scope.closePopup = vmClosePopup;
        $scope.deleteTask = vmDelete;
        $scope.vmChangeTitle = vmChangeTitle;
        $scope.onChangeTeacherInQuest = onChangeTeacherInQuest;
    })
})();