(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.teacher.tasks.tupdate12.progressreport', {

            url: '/progressreport',
            controller: 'teacherProgressReportTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/progressreport/progressreport.html'
        })
        .state('app.teacher.tasks.dublicate.progressreport', {

            url: '/progressreport',
            controller: 'teacherProgressReportTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/progressreport/progressreport.html'
        })
    }])
    .controller('teacherProgressReportTaskCtrl', function ($scope, tasks, $stateParams, commonService, LMAPI, FRAPI, credentials, user, school, $rootScope, $q, $state) {
        $scope.$emit('sideTabChange', $stateParams.state);
        $scope.$emit('editTaskTab', 'progressreport');

        let progressReport = FRAPI.taskProgressReport($stateParams.task);
        let students = LMAPI.taskStudents.allstudents(credentials.session_token, $stateParams.task);
        let books = LMAPI.taskCourses.allcourses(credentials.session_token, $stateParams.task);

        $rootScope.pageLoading = true;
        $q.all({
            progressReport: progressReport,
            students: students,
            books: books
        }).then((response) => {
            let results = response.progressReport;

            results.forEach(function (item) {
                item.$raw_read = parseFloat(item.raw_read).toFixed(1);
                item.$raw_first_quiz = item.raw_first_quiz == null ? 'N/A' : parseFloat(item.raw_first_quiz).toFixed(1);
                let Lmin = item.time_listening ? Math.trunc(parseFloat(item.time_listening) / 60) : 0;
                let Rmin = item.time_reading ? Math.trunc(parseFloat(item.time_reading) / 60) : 0;
                let Lsec; let Rsec;
                if (Lmin * 60 < parseInt(item.time_listening)) {
                    Lsec = item.time_listening - Lmin * 60;
                } else {
                    Lsec = 0;
                }
                if (Rmin * 60 < parseInt(item.time_reading)) {
                    Rsec = item.time_reading - Rmin * 60;
                } else {
                    Rsec = 0;
                }

                item.listening_minutes = commonService.vmAddZero(Lmin) + ":" + commonService.vmAddZero(Lsec);
                item.reading_minutes = commonService.vmAddZero(Rmin) + ":" + commonService.vmAddZero(Rsec);
            });
            
            let students = response.students.data;
            students.unshift({
                firstname: ' All',
                lastname: 'Students',
                token: null
            });

            let books = response.books.data;

            $scope.results = results;
            $scope.students = students;
            $scope.selectStudent = $scope.students.find((x) => x.token === null);
            $scope.books = books;

            $rootScope.pageLoading = false;
        }).catch((error) => {
            $rootScope.pageLoading = false;
        });

        function vmGetDetailReport(user) {
            let mode = localStorage.getItem('mode');
            let statename = (mode === 'schooladmin') ? 'app.admin.school.tasks.tupdate12.detailreport' : 'app.teacher.tasks.tupdate12.detailreport';

            $state.go(statename, { page: 'btnDetailReport', token: user.token });
        };

        $scope.getDetailReport = vmGetDetailReport;
    })
})();