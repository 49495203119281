(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.parent', {
                url: '/parent',
                controller: 'parentCtrl',
                templateUrl: '/assets/ng1/parent/index.html',
                resolve: {
                    children: function (LMWordpress, token) {
                        return LMWordpress.getchildren(token).then(function (response) {
                            return response.data;
                        })
                    }
                },
                deepStateRedirect: {
                    default: { state: 'app.parent.manage' },
                    params: ['locale']
                },
              
            })
    }])

    .controller('parentCtrl', function ($scope, LMConfig, user, LMWordpress, token, children, $state, commonService) {
      
        // watch for library tab changed
        $scope.$on('activeTabTop', function (event, data) {
            $scope.activeTab = data;
        });
        // configuration
        $scope.config = LMConfig;
        // read user information
        $scope.user = user;
        $scope.locale = user.lang;
        $scope.children = children;

        var logoObj = commonService.logo(user.project, 'parent');
        $scope.logo = logoObj.logo;        
        $scope.tm = logoObj.tm;
    })
})();