(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', 'LMConfig', function ($stateProvider, LMConfig) {

        $stateProvider
            .state('app.library.shelve.search', {
                url: '/search',                
                controller: 'searchCtrl',
                templateUrl: '/assets/ng1/library/search/search.html',
            })
    }])

    .controller('searchCtrl', function ($scope, token, user) {
        $scope.token = token;
        $scope.project = user.project;
        $scope.user = user;
        $scope.onBooks = onBooks;

        function onBooks(books) {
            $scope.courses = books;
            $scope.flagcloseinfobook = true;
        }
        // throw main tab change
        $scope.$emit('libraryTabChange', 'library');
        $scope.$emit('libraryActiveTab', 'search'); 
        $scope.$on('change_quest', function(event, data) {
               
            let books = localStorage.getItem('current_books');
            if ($scope.courses && $scope.courses.length > 0){
                $scope.courses.forEach((item) => {
                    let _b = JSON.parse(books).find((y) => y === item.id);
                    item.isAssign = _b ? true : false;
                })
            }
            if (!$scope.$$phase) {
                $scope.$apply();
            } 
        });     
    })  

})();