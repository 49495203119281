(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.library.bot', {
                    url: '/bot',
                    controller: 'botCtrl',
                    templateUrl: '/assets/ng1/library/bot/bot.html'
                })
        }])

        .controller('botCtrl', function ( $scope, $state, $rootScope, LMConfig, user, commonService, $compile, LMAPI, uuid, $window, credentials, coursesService, token) {

            $scope.$emit('libraryTabChange', 'bot');

            const JSON_SIMPLE_VALUE_KINDS = new Set([
                'numberValue',
                'stringValue',
                'boolValue',
              ]);


            $scope.loadingBot = false;

            let botHistory = sessionStorage.getItem('botHistory');   
                        
            botHistory = botHistory ? vmShowHistory(botHistory) : send('start');
            console.log(botHistory);

            var sessionId = uuid.newguid();
            var accessToken = commonService.agent_key(user.project);

            var lang;
            $rootScope.recognition;
            var text = [];
            var basicCard;
            var simpleResponse;
            var displayText;
            var textToSpeech;

            window.utterances = [];
            var synth = window.speechSynthesis;

            // all the added notes will be saved
            $scope.allNotes = [];

            $scope.activeNotes = false;

            // states for microphone/speaker turn On/Off
            $scope.state = {
                record: false,
                listen: false,
                volume: true
            };
            $scope.textNotes = {
                id: null,
                text: null
            };



            $scope.notSupport = ('webkitSpeechRecognition' in window);
            // const for notes
            var constNotes = 'notes';
            var constNote = 'note';
            var constSend = 'send';
            var constFinish = 'finish';
            var constOpen = 'open';
            var constPlay = 'play';

            $scope.username = credentials.username;
            $scope.token = credentials.token;
            $scope.session = credentials.session_token;

            if (user.lang == 'en') {

                lang = { code: 'en-US', lang: 'en' };

            } else if (user.lang == 'ru') {

                lang = { code: 'ru-RU', lang: 'ru' };

            } else {
                lang = { code: 'uk-UA', lang: 'uk' };
            }
            

            function vmShowHistory(botHistory) {

                $scope.flagHistory = true;

                let _botHistory =  JSON.parse(botHistory);
           
                _botHistory.forEach((item) => {
                 
                    addQuestion(item.send);
                    
                    vmShowMsg(item.body);
        
                })              
                           
                let valuescrollHeight = document.getElementById("chatbot_areaChat").scrollHeight;
                
                $('#chatbot_areaChat').animate({ scrollTop: valuescrollHeight }, "slow");
              
            }
            
            // turn OFF microphone and speacker when go to other tab
            $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

                stopRecognition();
                stopSpeacker();
            });

            // Turn on and turn Off speacker
            function vmTurnOnSpeacker() {

                if ($scope.state.volume === true) {

                    $scope.state.volume = false;

                    stopSpeacker();

                } else {

                    $scope.state.volume = true;

                }
            }

            function stopSpeacker() {

                $scope.state.volume = false;

                if (synth) {
                    synth.cancel();
                }
            }

            function playSpeacker(fulfillment) {

                if (!!fulfillment.data && !!fulfillment.data.google.richResponse) {

                    var ssml = fulfillment.data.google.richResponse.items[0].simpleResponse.ssml;
                    var xml = $.parseXML('<?xml version="1.0"?>' + ssml);
                    xml.childNodes[0].setAttribute('version', '1.0');
                    text = (new XMLSerializer()).serializeToString(xml);
                }

                if ($rootScope.recognition) {

                    $rootScope.recognition.continuous = false;

                    $rootScope.recognition.stop();
                    $rootScope.recognition = null;
                }

                var utterThis = new SpeechSynthesisUtterance(fulfillment);

                // if turn off volume speacker - the record must be continuous
                // if turn On volume speacker - the voice recording continues until the speaker speaks
                utterThis.onend = function (event) {

                    if ($scope.state.record === true) {
                        startRecognition();
                    }
                };

                speechUtteranceChunker(utterThis, {
                    chunkLength: 200
                }, function () {

                    if ($scope.state.record === true) {
                        startRecognition();
                    }
                });
            }

            // method for speech
            // read first 200 symbols then next 200 symbols
            // because SpeechSynthesisUtterance read from 200 to 300 symbols
            // if not native voice
            function speechUtteranceChunker(utt, settings, callback) {

                $scope.state.listen = false;

                settings = settings || {};
                var newUtt;

                var txt = (settings && settings.offset !== undefined ? utt.text.substring(settings.offset) : utt.text);

                var chunkLength = (settings && settings.chunkLength) || 160;
                var pattRegex = new RegExp('^[\\s\\S]{' + Math.floor(chunkLength / 2) + ',' + chunkLength + '}[.!?,]{1}|^[\\s\\S]{1,' + chunkLength + '}$|^[\\s\\S]{1,' + chunkLength + '} ');
                var chunkArr = txt.match(pattRegex);

                if (chunkArr[0] === undefined || chunkArr[0].length <= 2) {
                    //call once all text has been spoken...
                    if (callback !== undefined) {
                        callback();
                    }
                    return;
                }
                var chunk = chunkArr[0];
                newUtt = new SpeechSynthesisUtterance(chunk);
                var x;
                for (x in utt) {
                    if (utt.hasOwnProperty(x) && x !== 'text') {
                        newUtt[x] = utt[x];
                    }
                }
                newUtt.addEventListener('end', function () {
                    if (speechUtteranceChunker.cancel) {
                        speechUtteranceChunker.cancel = false;

                        return;
                    }
                    settings.offset = settings.offset || 0;
                    settings.offset += chunk.length - 1;
                    speechUtteranceChunker(utt, settings, callback);
                });


                if (settings.modifier) {
                    settings.modifier(newUtt);
                }
                newUtt.voice = synth.getVoices().filter((voice) => voice.lang == lang.code).find((voice) => !voice.localService);
                newUtt.lang = lang.code;

                setTimeout(function () {

                    newUtt.text = newUtt.text.replace(/(\r?\n|\r)/gmi, ' ');
                    newUtt.text = newUtt.text.replace(/^\.|\,/, '');
                    newUtt.text = newUtt.text.trim();

                    speechSynthesis.speak(newUtt);
                }, 0);
            };

            // Switch for recognition
            function vmSwitchRecognition() {

                if ($scope.state.record === true) {

                    stopRecognition();

                } else {

                    startRecognition();
                }
            }

            function startRecognition() {

                if (!('webkitSpeechRecognition' in window)) {
                    alert("not support");
                    return;
                }

                if (synth) {
                    synth.cancel();
                }

                $rootScope.recognition = new webkitSpeechRecognition();
                $rootScope.recognition.continuous = true;
                $rootScope.recognition.interimResults = false;
                $rootScope.recognition.maxAlternatives = 1;
                let interimResults;
                // If recongnition gave the result
                // send result on response and add text
                $rootScope.recognition.onresult = function (event) {

                    var text = "";
                    let array = [];

                    for (var i = 0; i < event.results.length; i++) {
                        array.push(event.results[i][0]);
                    }
                    let results = array.reduce(function (prev, cur) {

                        return cur.confidence > prev.confidence ? cur : prev;

                    }, { confidence: 0 });


                    if (!interimResults || interimResults.transcript !== results.transcript) {

                        interimResults = results;

                        text = interimResults.transcript;

                        $scope.text = text;
                        vmSend(text);
                        // dont work without it
                        vmApply();
                    }


                };
                // if turn off volume speacker - the record must be continuous
                // if turn On volume speacker - the voice recording continues until the speaker speaks
                $rootScope.recognition.onend = function () {

                    if ($scope.state.volume !== true && $scope.state.record && $state.current.name === 'app.library.bot') {
                        startRecognition();
                    }

                };

                $rootScope.recognition.onstart = function () {

                    $scope.state.record = true;
                    $scope.state.listen = true;

                    vmApply();
                };

                $rootScope.recognition.onerror = function (event) {

                    if ($rootScope.recognition) {

                        $rootScope.recognition.stop();
                        $rootScope.recognition = null;

                    }
                    startRecognition();
                };

                $rootScope.recognition.lang = lang.code;
                $rootScope.recognition.start();
            }

            function stopRecognition() {

                $scope.state.record = false;
                $scope.state.listen = false;

                vmApply();

                if ($rootScope.recognition) {

                    $rootScope.recognition.continuous = false;

                    $rootScope.recognition.stop();
                    $rootScope.recognition = null;
                }
            }
            function vmApply() {

                if (!$scope.$$phase || !$rootScope.$$phase) {
                    $scope.$apply();
                }
            }
         

            function addQuestion(text) {
                               
                $scope.question = angular.copy(text);
         
                // Add new question block with text in chat
                var template = $('<bot-question asr="{{question}}" ></bot-question>');
                var linkFn = $compile(template);
           
                var content = linkFn($scope);

                $('#chatbot_areaChat').append(content);

                autoscroll();             

            }

            function vmAddEditingNote() {

                var flagEdit = $scope.allNotes.some((x) => x.id == $scope.textNotes.id);

                if (flagEdit) {

                    $scope.allNotes.forEach(function (x) {

                        if (x.id === $scope.textNotes.id) {

                            x.text = $scope.text;
                        }
                    });
                }
                $scope.edit = false;
            }

            function vmCheckOpen(sendText) {

                stopRecognition();
                if ($scope.languametrics) {

                    if ($scope.languametrics.data.url_type === 'book#page') {

                        coursesService.runTest(token, $scope.languametrics.data.book_id, $scope.languametrics.data.activity_id);

                    } else if ($scope.languametrics.data.url_type === 'book' && basicCard && basicCard.url) {

                        $window.open(basicCard.url);

                    } else {

                        if (basicCard && basicCard.url) {
                            $window.open(basicCard.url, '_blank');

                        } else {

                            addResponse("Last message doesn't have a link to open.");

                            $scope.textToSpeech = "Last message doesn't have a link to open.";

                            if ($scope.state.volume && sendText !== 'start') {
                                playSpeacker($scope.textToSpeech);
                            }
                        }
                    }
                }
            }


            function valueProtoToJson(proto) {
                if (!proto || !proto.kind) {
                  return null;
                }
              
                if (JSON_SIMPLE_VALUE_KINDS.has(proto.kind)) {
                  return proto[proto.kind];
                } else if (proto.kind === 'nullValue') {
                  return null;
                } else if (proto.kind === 'listValue') {
                  if (!proto.listValue || !proto.listValue.values) {
                    console.warn('Invalid JSON list value proto: ', JSON.stringify(proto));
                  }
                  return proto.listValue.values.map(valueProtoToJson);
                } else if (proto.kind === 'structValue') {
                  return structProtoToJson(proto.structValue);
                } else {
                  console.warn('Unsupported JSON value proto kind: ', proto.kind);
                  return null;
                }
            }
              
              
            function structProtoToJson(proto) {
                if (!proto || !proto.fields) {
                  return {};
                }
                const json = {};
                for (const k in proto.fields) {
                  json[k] = valueProtoToJson(proto.fields[k]);
                }
                return json;
            }

            // Send text with textarea
            function send(sendText) {

                $scope.loadingBot = true;

                LMAPI.botQueryFR(sendText).then(function (response) {

                    let body =  JSON.parse(response.data.body);

                    $rootScope.botHistoryLocalAr.push({send: sendText, body: body});

                    sessionStorage.setItem('botHistory', JSON.stringify($rootScope.botHistoryLocalAr));

                    $scope.loadingBot = false;

                    basicCard = null;
                                        
                    vmShowMsg(body);                

                }).catch(function (error) {

                    addResponse("Internal Server Error");
                });
            }

            function vmShowMsg(body) {

                console.log(body);

                if (false) { // data.result.contexts && data.result.contexts.length === 0

                    $("#chatbot_areaChat").empty();

                    playSpeacker("Your last session has expired, I have started a new one.");
                    addResponse("Your last session has expired, I have started a new one.");

                    send('Hello');

                } else {

                    
                    let $simpleResponses = body.queryResult.fulfillmentMessages.find((x) => x.message === "simpleResponses");
                    let $basicCard = body.queryResult.fulfillmentMessages.find((x) => x.message === "basicCard");

                    let $payload = body.queryResult.fulfillmentMessages.find((x) => x.message === "payload");
                    let languametricsJSON = null;

                    if ($payload) {
                        languametricsJSON =  valueProtoToJson($payload.payload.fields.languametrics);
                    }
                  //  languametricsJSON = valueProtoToJson(body.queryResult.fulfillmentMessages.find((x) => x.message === "payload").payload.fields.languametrics);
                   
                    var languametrics = languametricsJSON;

                    $scope.languametrics = languametrics;

                    if (!languametrics) {
                        
                        addResponse(body.queryResult.fulfillmentText);
                       // ResponseBot(google, sendText);

                    } else if (languametrics) {

                   

                      //  ResponseBot(google, sendText);
                        let _basicCard = { url: null, image: null };
                        _basicCard.url = $basicCard.basicCard.buttons[0] ? $basicCard.basicCard.buttons[0].openUriAction.uri : null;
                        _basicCard.image = $basicCard.basicCard.image ? $basicCard.basicCard.image.imageUri : null;

                        let textToSpeech = $simpleResponses.simpleResponses.simpleResponses[0].textToSpeech;
                        $scope.textToSpeech = textToSpeech;

                        if (languametrics.data.url_type === "book") {

                            LinkPage(_basicCard, textToSpeech, languametrics.data.book_id);
                        }
                        if (languametrics.data.url_type === "pdf" || languametrics.data.url_type === "html") {

                            LinkPdf(_basicCard, textToSpeech);
                        }
                        if (languametrics.data.url_type === "youtube") {

                            LinkYoutube(_basicCard, textToSpeech);
                        }
                        if (languametrics.data.url_type === "book#page") {

                            LinkPage(_basicCard, textToSpeech, languametrics.data.book_id, languametrics.data.activity_id);
                        }

                    } else {

                        addResponse(body.queryResult.fulfillmentText);
                        /*text = data.result.fulfillment.messages.filter(function (messages) {

                            if (messages.type === 0) {

                                return messages.speech;
                            }
                        });

                        if (text.length > 0) {

                            // If speaker turn on
                            // change links on word 'link' and play this text
                            if ($scope.state.volume === true) {

                                var urlRegex = /(https?:\/\/[^\s]+)/g;
                                var textWithWordLink = text[0].speech.replace(urlRegex, function (url) {
                                    return 'link';
                                });
                                $scope.textToSpeech = textWithWordLink;

                            }
                        }

                        if (text.length > 0) {

                            $scope.textToSpeech = text[0].speech;

                            if ($scope.state.volume && sendText !== 'start') {
                                playSpeacker($scope.textToSpeech);
                            }

                            addResponse(text[0].speech);

                            var video = data.result.fulfillment.messages.filter(function (messages) {
                                if (messages.type === 4) {
                                    return messages.payload.languabooks.video;
                                }
                            });
                            if (video.length > 0) {
                                addResponseVideo(video[0].payload.languabooks.video);
                            }
                            if (basicCard) {
                                addResponseButton(basicCard);
                            }
                        }*/
                    }
                }
            }

            function ResponseBot(google, sendText) {

                // return object with text response
                simpleResponse = google.richResponse.items.filter((x) => x['simpleResponse'])[0].simpleResponse;

                // return object with url image and url url link
                basicCard = google.richResponse.items.filter((x) => x['basicCard'])[0].basicCard;

                if (simpleResponse) {

                    textToSpeech = simpleResponse['textToSpeech'] ? simpleResponse['textToSpeech'] : null;
                    displayText = simpleResponse['displayText'] ? simpleResponse['displayText'] : null;

                }

    /* if (basicCard.buttons.length > 0) {

                    basicCard = basicCard.buttons[0];
                    basicCard.title = basicCard.buttons[0].title;

                }*/
                if (displayText && textToSpeech) {

                    $scope.textToSpeech = textToSpeech;

                } else if (displayText && !textToSpeech) {

                    $scope.textToSpeech = displayText;

                } else {

                    $scope.textToSpeech = textToSpeech;
                }
                if ($scope.state.volume && sendText !== 'start') {
                    playSpeacker($scope.textToSpeech);
                }

            }

            function LinkPdf(basicCard, textSpeech) {

                $scope.url = basicCard.url;
                $scope.textSpeech = textSpeech;

                // Add new answer block with text in chat
                var template = $('<bot-response-pdf as-url="url" as-text="textSpeech" as-stop-recording="stopRecognition"></bot-response-pdf>');

                var linkFn = $compile(template);
                var content = linkFn($scope);
                $('#chatbot_areaChat').append(content);

                autoscroll();

            }

            function LinkYoutube(basicCard, textSpeech) {

                $scope.url = basicCard.url;
                $scope.textSpeech = textSpeech;

                // Add new answer block with text in chat
                var template = $('<bot-response-youtube as-url="url" as-text="textSpeech" as-stop-recording="stopRecognition"></bot-response-youtube>');

                var linkFn = $compile(template);
                var content = linkFn($scope);
                $('#chatbot_areaChat').append(content);

                autoscroll();
            }

            function LinkPage(basicCard, textSpeech, book, activity) {

                $scope.image = basicCard.image;
                $scope.textSpeech = textSpeech;
                $scope.book = book;
                $scope.activity = activity;
                $scope.titleBtn = basicCard.buttons ? basicCard.buttons[0].title : null;

                // Add new answer block with text in chat
                var template = $('<bot-response-book as-image="image" as-book="book" as-activity="activity" as-text="textSpeech" as-title="titleBtn" as-stop-recording="stopRecognition"></bot-response-book>');

                var linkFn = $compile(template);
                var content = linkFn($scope);
                $('#chatbot_areaChat').append(content);

                autoscroll();
            }

            function addResponse(text) {
                // Parse for links
                var urlRegex = /(https?:\/\/[^\s]+)/g;

                $scope.textWithLinks = text.replace(urlRegex, function (url) {

                    return '<a target="_blank" href="' + url + '">' + 'link' + '</a>';
                });

                // Add new answer block with text in chat
                var template = $('<bot-response ng-model="textWithLinks"></bot-response>');
                var linkFn = $compile(template);
                var content = linkFn($scope);
                $('#chatbot_areaChat').append(content);

                autoscroll();
            }

            function autoscroll() {

                if ($scope.flagHistory) return;

                var lenghtDialogBlock = $('.chatbot_answer').length;
                if (lenghtDialogBlock > 0) {
                    var scrollValue = $('.chatbot_answer')[lenghtDialogBlock - 1].offsetTop;
                    $('#chatbot_areaChat').animate({ scrollTop: scrollValue }, "slow");
                }

            }

            // Add new answer block with link on video in chat
            function addResponseVideo(url) {

                $scope.url = url;

                var template = $('<div class="chatbot_answer"><a target="_blank" href="{{url}}">video</a></div>');
                var linkFn = $compile(template);
                var content = linkFn($scope);
                $('#chatbot_areaChat').append(content);

                autoscroll();
            }
            // Add new answer block with button in chat
            function addResponseButton(basicCard) {

                $scope.basicCard = basicCard;

                var template = $('<div class="chatbot_answer"><a target="_blank" href="{{basicCard.url}}"><input type="button" value="{{basicCard.title}}" /></a></div>');
                var linkFn = $compile(template);
                var content = linkFn($scope);
                $('#chatbot_areaChat').append(content);

                autoscroll();
            }

            function vmAutoSendEmail() {

                var note = $scope.allNotes && $scope.allNotes.length > 0 ? $scope.allNotes[$scope.allNotes.length - 1].text : null;
                if (note) {

                    LMAPI.sentToEmail(user.email, 'Notes from Kleo assistant', note, $scope.session).then(function (response) {

                        vmAudioSend();

                    }).catch(function (reason) {
                        console.error(reason);
                    });
                }
            }

            function vmSend(text) {

                text = text ? text.toLowerCase().trim() : null;

                switch (text) {

                   /* case constNote:
                    case constNotes:

                        vmNotes();

                        break;*/
                    case constSend:

                        vmAutoSendEmail(text);
                        break;

                   /* case constPlay:
                    case constOpen:

                        vmCheckOpen(text);
                        break;*/

                    default:
                        if (text || $scope.textNotes.text) {

                            if ($scope.edit) {

                                vmAddEditingNote();

                            } else if ($scope.activeNotes) {

                                let txt = text || $scope.textNotes.text;

                                addNotes(txt);

                                vmNotes();
                            } else {

                                addQuestion(text);
                                send(text);
                            }
                        }
                }

                $scope.text = null;
                $scope.textNotes = {
                    text: null,
                    id: null
                };
            }

            function vmNotes() {

                $scope.activeNotes = !$scope.activeNotes;
                $scope.textNotes = {
                    text: null,
                    id: null
                };
                $scope.text = null;
            }

            function vmPressEnter(event, text) {

                if (event.keyCode === 13) {
                  
                 // for to extend the session
                  const link = document.createElement('a');
                  document.body.append(link);
                  link.click();

                  vmSend(text);
                }
            }

            function addNotes(text) {

                $scope.textNotes.id = uuid.newguid();

                $scope.note = {
                    id: uuid.newguid(),
                    text: text
                };
                $scope.allNotes.push($scope.note);

                // for sent messages to user
                $scope.mail = user.email;

                // Add new question block with text in chat
                var template = $('<notes as-note="note" as-all-notes="allNotes" as-text-area="textNotes" as-session="session" as-mail="mail" audio-send = "audioSend" close-reminder="closeReminder" edit-note = "editNote"></notes>');
                var linkFn = $compile(template);
                var content = linkFn($scope);
                $('#chatbot_areaChat').append(content);

                autoscroll();

            }

            function vmAudioSend() {

                var snd = new Audio("library/bot/audio/send_mail.wav");

                snd.play();
            }

            function vmEditNote(note) {

                $scope.edit = true;

                $scope.text = note.text;
                $scope.textNotes = note;
            }

            // appears/disappears dialog window for enter dates and time
            function vmCloseReminder() {

                $scope.dialogReminder = !$scope.dialogReminder;

                return $scope.dialogReminder;
            }

            $scope.blurred = function () {

                if (!$scope.dialogReminder) {
                    $scope.activeNotes ? $("#chatbot_textArea2").focus() : $("#chatbot_textArea").focus();
                }
            }

            $scope.turnSpeaker = vmTurnOnSpeacker;
            $scope.switchRecognition = vmSwitchRecognition;

            $scope.notes = vmNotes;
            $scope.audioSend = vmAudioSend;
            $scope.closeReminder = vmCloseReminder;
            $scope.stopRecognition = stopRecognition;
            $scope.editNote = vmEditNote;

            $scope.pressEnter = vmPressEnter;
            $scope.send = vmSend;

        })

})();