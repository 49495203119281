(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', 'LMConfig', function ($stateProvider, LMConfig) {

        $stateProvider
            .state('app.profile', {
                url: '/profile',
                controller: 'rootProfileCtrl',
                templateUrl: '/assets/ng1/profile/index.html',
                resolve: {
                    username: function (LMWordpress) {

                        return LMWordpress.getcredentials()
                                        .then(x => x.username);
                    }
                },
                deepStateRedirect: {
                    default: { state: 'app.profile.edit' }
                },
            })
            .state('app.profile.edit', {
                url: '/edit',
                controller: 'profileCtrl',
                templateUrl: '/assets/ng1/profile/profile.html',
            })
    }])

    .controller('rootProfileCtrl', function ($scope, $state, LMConfig, user) {
        // configuration
        $scope.config = LMConfig;
        // read user information
        $scope.user = user;     
    })

    .controller('profileCtrl', function ($scope, $state, user) {

        // watch for side tab changed
        $scope.$on('sideTabChange', function (event, data) {
            $scope.activeTab = data;
        });
        // check is tester role
        $scope.isTester = user.roles.some(x => x == 'tester');
        // throw main tab change
        $scope.$emit('libraryTabChange', '');
      
    })
})();