(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.admin.school.licenses', {
                    url: '/licenses',
                    controller: 'adminLicensesCtrl',
                    templateUrl: '/assets/ng1/admin/licenses/index.html',
                    deepStateRedirect: {
                        default: { state: 'app.admin.school.licenses.licensestudents', },
                        params: true
                    },
                })

        }])
        .controller('adminLicensesCtrl', function ($scope, $state, $window, LMConfig, user, commonService, schools) {
          
            $scope.$emit('adminTopTabChange', 'licenses');
        })

})();