angular.module('LMAPI')

.service('LMAPImarketCache', ['$q', 'LMAPI', 'LMWebStorage', "LMWordpress", function ($q, LMAPI, LMWebStorage, LMWordpress) {

    // 60 minutes
    var cachedTime = 60 * 60;

    this.subscriptions = function () {

        var currentTime = new Date().getTime() / 1000;

        // try to get data about subscriptions from cache
        return LMWebStorage.get('MarketSubscriptionsCache', null, null)
                    .then(function (cached) {
                        // if no cached data found
                        // or cache already expired
                        if (!cached || cached.length == 0 || cached.updated + cachedTime < currentTime) {
                          
                            // read fresh courses data from API
                            return LMWordpress.subscriptions()
                                        .then(function (subscriptions) {

                                            vmUpdateSubscriptions(subscriptions, currentTime);

                                            return subscriptions;
                                        })
                        } else {

                            // read all data from subscriptions cache
                            return LMWebStorage.get('MarketSubscriptionsCache', null, null)
                                        .then(cached);                          

                        }
                    })
    };
    this.getordersstatus = function () {

        var currentTime = new Date().getTime() / 1000;
        
        return LMWebStorage.get('MarketOrderStatusCache', null, null)
                        .then(function (cachedStatus) {

                            if (!cachedStatus || cachedStatus.length == 0 || cachedStatus.updated + cachedTime < currentTime) {

                                // read fresh orders status data from API
                                return LMWordpress.getordersstatus()
                                            .then(function (orders) {

                                                vmUpdateOrderStatus(orders, currentTime);

                                                return orders;
                                            })

                            } else {
                                // read all data from subscriptions cache
                                return LMWebStorage.get('MarketOrderStatusCache', null, null)
                                            .then(cachedStatus);
                            }

                        })
    }
    this.getorders = function () {

        var currentTime = new Date().getTime() / 1000;

        return LMWebStorage.get('MarketOrderCache', null, null)
                        .then(function (cached) {

                            if (!cached || cached.length == 0 || cached.updated + cachedTime < currentTime) {

                                // read fresh orders data from API
                                return LMWordpress.getorders()
                                            .then(function (orders) {

                                                vmUpdateOrder(orders, currentTime);

                                                return orders;
                                            })

                            } else {
                                // read all data from subscriptions cache
                                return LMWebStorage.get('MarketOrderCache', null, null)
                                            .then(cached);
                            }

                        })
    }
    this.getlicensetypes = function () {

        var currentTime = new Date().getTime() / 1000;

        return LMWebStorage.get('MarketLicenseCache', null, null)
            .then(function (cached) {
            // if no cached data found
            // or cache already expired
            if (!cached || cached.length == 0 || cached.updated + cachedTime < currentTime) {

                // read fresh license data from API
                return LMWordpress.getlicensetypes()
                            .then(function (licensetypes) {

                                vmUpdateLicense(licensetypes, currentTime);

                                return licensetypes;
                            })
            } else {

                // read all data from license cache
                return LMWebStorage.get('MarketLicenseCache', null, null)
                            .then(cached);

            }
        })
    };
    this.collectionmarket = function () {

        var currentTime = new Date().getTime() / 1000;

        return LMWebStorage.get('MarketCollectionsCache', null, null)
            .then(function (cached) {
                // if no cached data found
                // or cache already expired
                if (!cached || cached.length == 0 || cached.updated + cachedTime < currentTime) {

                    // read fresh collections data from API
                    return LMWordpress.collectionmarket()
                                .then(function (collections) {

                                    vmUpdateCollections(collections, currentTime);

                                    return collections;
                                })
                } else {

                    // read all data from collections cache
                    return LMWebStorage.get('MarketCollectionsCache', null, null)
                                .then(cached);

                }
            })
    };
    this.booksmarket = function () {

        var currentTime = new Date().getTime() / 1000;

        return LMWebStorage.get('MarketBooksCache', null, null)
            .then(function (cached) {
                // if no cached data found
                // or cache already expired
                if (!cached || cached.length == 0 || cached.updated + cachedTime < currentTime) {

                    // read fresh books data from API
                    return LMWordpress.booksmarket()
                                .then(function (books) {

                                    vmUpdateBooks(books, currentTime);

                                    return books;
                                })
                } else {

                    // read all data from books cache
                    return LMWebStorage.get('MarketBooksCache', null, null)
                                .then(cached);

                }
            })
    };
    this.testsmarket = function () {

        var currentTime = new Date().getTime() / 1000;

        return LMWebStorage.get('MarketTestsCache', null, null)
            .then(function (cached) {
                // if no cached data found
                // or cache already expired
                if (!cached || cached.length == 0 || cached.updated + cachedTime < currentTime) {

                    // read fresh tests data from API
                    return LMWordpress.testsmarket()
                                .then(function (tests) {

                                    vmUpdateTests(tests, currentTime);

                                    return tests;
                                })
                } else {

                    // read all data from tests cache
                    return LMWebStorage.get('MarketTestsCache', null, null)
                                .then(cached);
                }
            })
    };
    this.othermarket = function () {

      var currentTime = new Date().getTime() / 1000;

      return LMWebStorage.get('MarketOtherCache', null, null)
          .then(function (cached) {
            // if no cached data found
            // or cache already expired
            if (!cached || cached.length == 0 || cached.updated + cachedTime < currentTime) {

              // read fresh tests data from API
              return LMWordpress.othermarket()
                          .then(function (otherproducts) {

                            vmUpdateOther(otherproducts, currentTime);

                            return otherproducts;
                          })
            } else {

              // read all data from tests cache
              return LMWebStorage.get('MarketOtherCache', null, null)
                          .then(cached);
            }
          })
    };
    
    // reset API cache
    this.reset = function () {
        LMWebStorage.delete('MarketSubscriptionsCache');
        LMWebStorage.delete('MarketOrderStatusCache');
        LMWebStorage.delete('MarketOrderCache');
        LMWebStorage.delete('MarketLicenseCache');
        LMWebStorage.delete('MarketCollectionsCache');
        LMWebStorage.delete('MarketBooksCache');
        LMWebStorage.delete('MarketTestsCache');
        LMWebStorage.delete('MarketOtherCache');
    };

    // update cache
    function vmUpdateSubscriptions(response, time) {

        // for each course read
        // set updated date as current
        // and update in database
        response.forEach(function (item) {

            item.updated = time;
            LMWebStorage.update('MarketSubscriptionsCache', null, item.license_id, item);
        });
    };
    function vmUpdateOrderStatus(response, time) {

        // for each course read
        // set updated date as current
        // and update in database
        response.forEach(function (item) {

            item.updated = time;
            LMWebStorage.update('MarketOrderStatusCache', null, item.option_id, item);
        });
    };
    function vmUpdateOrder(response, time) {

        // for each course read
        // set updated date as current
        // and update in database
        response.forEach(function (item) {

            item.updated = time;
            LMWebStorage.update('MarketOrderCache', null, item.idnumber, item);
        });
    };
    function vmUpdateLicense(response, time) {

        // for each course read
        // set updated date as current
        // and update in database
        response.forEach(function (item) {

            item.updated = time;
            LMWebStorage.update('MarketLicenseCache', null, item.idnumber, item);
        });
    };
    function vmUpdateCollections(response, time) {

        // for each course read
        // set updated date as current
        // and update in database
        response.forEach(function (item) {

            item.updated = time;
            LMWebStorage.update('MarketCollectionsCache', null, item.product_id, item);
        });
    };
    function vmUpdateBooks(response, time) {

        // for each course read
        // set updated date as current
        // and update in database
        response.forEach(function (item) {

            item.updated = time;
            LMWebStorage.update('MarketBooksCache', null, item.product_id, item);
        });
    };
    function vmUpdateTests(response, time) {

        // for each course read
        // set updated date as current
        // and update in database
        response.forEach(function (item) {

            item.updated = time;
            LMWebStorage.update('MarketTestsCache', null, item.product_id, item);
        });
    };
    function vmUpdateOther(response, time) {

      // for each course read
      // set updated date as current
      // and update in database
      response.forEach(function (item) {

        item.updated = time;
        LMWebStorage.update('MarketOtherCache', null, item.product_id, item);
      });
    };
}])