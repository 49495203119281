(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.library.report.book.bookresults', {

                url: '/bookresults/:token/:course',
                controller: 'libraryReportBookResultsCtrl',
                templateUrl: '/assets/ng1/library/report/results.html'
            })
    }])

    .controller('libraryReportBookResultsCtrl', function ($scope, $state, $stateParams, LMAPI, LMAPICache, LMConfig, LMWordpress, $q, $translate) {

        $scope.back = vmBack;
        $scope.showActivityReport = vmShowActivityReport;
        $scope.loadReportActivity = vmLoadReportActivity;
        $scope.currentActivity = null;

        $scope.course = $stateParams.course;

        function vmBack() {

            $state.go('app.library.report.book', {}, {reload: true});
        };

        function vmShowActivityReport(sco) {

            $scope.currentActivity = sco;

            var params = ['cmi.vms_absolute_score', 'cmi.core.lesson_status', 'cmi.core.total_time', 
            'cmi.core.score_coefficient', 'cmi.vms_quiz_xml', 'cmi.vms_audio_path', 'cmi.vms_first_score', 'cmi.vms_text', 'cmi.vms_first_quiz_xml'];

            $q.all([LMAPICache.scos($stateParams.token, $stateParams.course), LMAPI.track($stateParams.token, sco.id, params.join(','))]).then(function (responses) {
                var scos = responses[0].scos;
                var tracks = responses[1].data;
                var reportData = {};

                var activityParams = scos.find(x => x.id == sco.id).params;
                tracks = tracks.filter(x => x.attempt == sco.attempt);
                if (tracks.length > 0) {

                    reportData = tracks.reduce(function (prev, next) {

                        prev[next.key] = next.value;
                        return prev;

                    }, {});
                };

                vmLoadReportActivity(activityParams, reportData)
            });
        }

        function vmLoadReportActivity(activityParams, reportData) {

            var defaultStorage = {
                type: 's3',
                audio: 'speech-userfiles-dev'
            };

            var reportConfig = {

                application_id: "contenttool_report_page",
                bucket_root_url: LMConfig.storage,
                bucket_script_url: LMConfig.sco_defaults.bucket_script_url,
                idevice_mode: "report",
                lang: "en",
                speech_service: LMConfig.sco_defaults.speech_service,
                web_worker: LMConfig.sco_defaults.web_worker,
                storage: LMConfig.sco_defaults.storage || defaultStorage,
                mode: 'report'
            };

            reportConfig = $.extend(reportConfig, JSON.parse(activityParams));

            var preload = vimas.loader.preload({
                bucket: reportConfig.bucket_script_url || reportConfig.bucket_root_url,
                skin: {
                    action: "lb_report",
                    html: "lb_report",
                    skin: "lb_report",
                    localization: "lb"
                },
                scos: [{ container: '#sco_container', type: reportConfig.idevice_type }],
                lang: reportConfig.lang,
                mode: reportConfig.mode
            });

            preload.done(function () {

                Idevice.postinit('sco_container', reportConfig, reportData);
            });
        };

        LMAPI.activitiesprogress($stateParams.token, $stateParams.course).then(function (response) {

            $scope.results = response.data.filter(x => x.type !== 'NSCO' && x.type !== 'FC');
            //restructure header table to the correct position
            $('table.demo').floatThead('reflow');

            $scope.results.forEach(x => x.activityName = $translate.instant('teacher.report.book.results.activityTypes.{0}'.format(x.type)));
            $scope.results;
        });
    })
})();