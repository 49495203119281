(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.teacher.report', {

            url: '/report?clas&student',
            controller: 'teacherReportCtrl',
            templateUrl: '/assets/ng1/teacher/report/index.html',
            resolve: {
                newNotifications: function(FRAPI, credentials){
                          
                    return FRAPI.getNewNotifications().then((newNotifications) => {
                       
                       return newNotifications;
                    })
                }, 
            }, 
            deepStateRedirect: {
                default: { state: 'app.teacher.report.creport' },
                params: true
            }
        })
        .state('app.teacher.report.notifications', {
            url: '/notifications',
            controller: 'districtNotificationsCtrl',
            templateUrl: '/assets/ng1/districtadmin/notifications/notifications.html'
        })

    }])

    .controller('teacherReportCtrl', function ($scope, $stateParams, FRAPI, $state, school, notifications, newNotifications, commonService, user) {

        $scope.$emit('teacherModeChange', 'report');
        $scope.clas = $stateParams.clas;
        $scope.student = $stateParams.student;
        $scope.school = school;
        $scope.reports = commonService.reports(user.project);
        $scope.certificatesTab = commonService.certificatesTab(user.project);
        $scope.teacherReports = commonService.teacherReports(user.project);
        $scope.unreadNotifiCount = newNotifications.length;
        $scope.notifications = notifications;

        $scope.$on('unreadNotifiCount', function (event, data) {
            $scope.unreadNotifiCount = data;
            FRAPI.clearNewNotifications();
            
            if (!$scope.$$phase) {
                $scope.$apply();
            }
        });
        if ($scope.unreadNotifiCount && $scope.unreadNotifiCount > 0){
            $state.go('app.teacher.report.notifications');
        } 

        // watch for side tab changed
        $scope.$on('activeTabLeft', function (event, data) {
            $scope.selected = data;
        });
    })

})();