(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.teacher.class.liststudent', {

                    url: '/:school/:currentClass/:teacher',
                    controller: 'assignStudentToClass',
                    templateUrl: '/assets/ng1/teacher/class/class.html'
                })
                .state('app.teacher.class.email', {
                    url: '/email',
                    params: {
                        recipients: null,
                        people: null
                    },
                    controller: 'sendEmailCtrl',
                    templateUrl: '/assets/ng1/admin/email/email.html',
                })

        }])

        .controller('assignStudentToClass', function ($rootScope, FRAPI, gemstones, $scope, $translate, LMWordpress, $state, $stateParams, user, classes, LMConfig, commonService, schools) {

            $scope.$emit('adminTabChange', $stateParams.type);
            $scope.$emit('sideTabChange', $stateParams.currentClass);

            $scope.load = false;
            $scope.arraySelectedAssigned = [];
            $scope.arraySelectedAvailable = [];
            $scope.clas = $stateParams.currentClass;
            $scope.gemstones = gemstones;
            $scope.gemstones_filter = angular.copy(gemstones);
            $scope.gemstones_filter.unshift({
                idnumber: null,
                name: 'All'
            })
            $scope.selectedGem = $scope.gemstones_filter.find((x) => !x.idnumber).idnumber;

            $scope.type = $stateParams.type;
            let school = sessionStorage.getItem('current_school_idnumber');
            $scope.schoolType = schools.find((x) => x.id === school).school_type;
            
            let clas = classes.find((x) => x.idnumber === $stateParams.currentClass);
            $scope.classType = clas ? JSON.parse(clas.properties).class_type : null;

            $scope.ext_user = LMConfig.rights.profile_ext_user(user.project);
            $scope.btnHide = commonService.btnHide(user.project);

            vmCalculateStudentsList();

            // update the students list
            function vmCalculateStudentsList() {
                
                FRAPI.getUsersFromCohortTeacherMode($stateParams.currentClass, JSON.stringify(['student'])).then(function (response) {
                    
                    $scope.usersInClassList = angular.copy(response);
                    
                    $scope.usersInClassList.forEach((x) => { 
                        x.gemstone_level = x.properties ? x.properties.gemstone_level : null;
                        x.name_gemstone_level = x.properties ? vmGetGemLevel(x.properties.gemstone_level) : null;
                    })
                   
                    // restructure header table to the correct position
                    $('table.demo').floatThead('reflow');

                })
            }
            function vmStudentInSchool() {

                FRAPI.getUsersFromCohortTeacherMode(school, JSON.stringify(['student']), commonService.checkIndividualLicense(user.project), 1).then((response) => {
                  
                    $scope.usersList = angular.copy(response).filter((x)=> !$scope.usersInClassList.some((y) => x.username === y.username));
                    $scope.usersList.forEach((x) => { 
                        x.gemstone_level = x.properties ? x.properties.gemstone_level : null;
                        x.name_gemstone_level = x.properties ? vmGetGemLevel(x.properties.gemstone_level) : null;
                    });
                    vmFlagAllAssignedSelected(false);
                })
            }

            // add student to current class
            function vmAssignStudentToClass(studentArr) {
                if (commonService.checkIndividualLicense(user.project)) {
                    var studentsWithoutLic = studentArr.filter(function (u) { return !u.has_license; });
                    if (studentsWithoutLic.length) {
                        var msg = $translate.instant('admin.class.edit.userWithoutLicCantBeAdded',
                            { users: studentsWithoutLic.slice(0, 3).map(function (st) { return "'" + st.username + "'" }).join(',') });
                        alert(msg);
                        return false;
                    }
                }

                $rootScope.pageLoading = true;

                FRAPI.cohortaddusers($stateParams.currentClass,
                    studentArr.map(function (s) {
                        return s.username
                    }))
                    .then(function () {
                        $rootScope.pageLoading = false;

                        $scope.error = false;

                        studentArr.forEach(function (student) {

                            $scope.usersList = $scope.usersList.filter((x) => x.username != student.username);
                            $scope.usersInClassList.push(student);

                            student.select = false;
                        });
                        FRAPI.cleargetUsersFromCohortTeacherMode($stateParams.currentClass, JSON.stringify(['student']));
                        let classIds = classes.map((item) => item.idnumber).join();
                        FRAPI.cleargetUsersFromCohortTeacherMode(classIds, JSON.stringify(['student']));
                        //restructure header table to the correct position
                        $('table.demo').floatThead('reflow');

                    }).catch(function () {
                        $rootScope.pageLoading = false;
                        $scope.error = true;
                    });
                return true;
            };

            //remove student from current class
            function vmRemoveStudentFromClass(studentArr) {
                $rootScope.pageLoading = true;

                FRAPI.cohortremoveusers($stateParams.currentClass, studentArr.map(s => s.username)).then(function (response) {
                    $rootScope.pageLoading = false;

                    $scope.error = false;
                    studentArr.forEach(function (student) {
                        student.select = false;

                        $scope.usersInClassList = $scope.usersInClassList.filter((x) => x.username != student.username);
                        if ($scope.usersList) {
                            $scope.usersList.push(student);
                        }                        
                    });
                    FRAPI.cleargetUsersFromCohortTeacherMode($stateParams.currentClass, JSON.stringify(['student']));
                    FRAPI.cleargetUsersFromCohortTeacherMode(school, JSON.stringify(['student']), commonService.checkIndividualLicense(user.project), 1);
                    let classIds = classes.map((item) => item.idnumber).join();
                    FRAPI.cleargetUsersFromCohortTeacherMode(classIds, JSON.stringify(['student']));
                    //restructure header table to the correct position
                    $('table.demo').floatThead('reflow');
                }).catch(function () {
                    $rootScope.pageLoading = false;
                    $scope.error = true;
                });
            }

            //assign all student to class
            function vmAssignAllStudentToClass(list) {

                if (list.length > 0) {
                    vmAssignStudentToClass(list);
                }
            }

            function vmOpenSendEmail() {

                let recipients = $scope.usersInClassList.filter((y) => y.select);

                $state.go('app.teacher.class.email', { recipients: recipients, people: $scope.usersInClassList });
            }

            function vmSelectAllAvailable(select) {

                if (select) {
                    $scope.usersList.forEach((x) => x.select = true);
                } else {
                    $scope.usersList.forEach((x) => x.select = false);
                }
            }
            function vmRemoveSelected() {

                let selectedStudents = $scope.usersInClassList.filter((x) => x.select === true);

                if (selectedStudents.length > 0) {
                    vmRemoveStudentFromClass(selectedStudents);
                    vmFlagAllRemoveSelected(false, selectedStudents);

                }
            }

            function vmAssignSelected() {

                $scope.selectavailable = false;

                let selectedStudents = $scope.usersList.filter((x) => x.select === true);

                if (selectedStudents.length > 0) {
                    if (vmAssignStudentToClass(selectedStudents))
                        selectedStudents.forEach(function (item) {
                            vmFlagAssignedSelected(false, item.username);
                        });
                }
            }

            function vmFlagAllRemoveSelected(select, listUsersClass) {
               
                $scope.isSeleted = select;
                $scope.selectassigned = select;

                listUsersClass.forEach((x) => {                   
                   
                    let item = $scope.usersInClassList.find((y) => y.username === x.username);
                    item.select = select;
                })
            }
            function vmFlagItemSelected(select) {
                $scope.isSeleted = $scope.usersInClassList.some((x) => x.select);
            }

            function vmFlagAssignedSelected(select, username) {

                if (select) {
                    $scope.arraySelectedAvailable.push({ select: select, username: username });
                } else {
                    $scope.arraySelectedAvailable = $scope.arraySelectedAvailable.filter((x) => x.username !== username);
                    if ($scope.arraySelectedAvailable.length === 0) {
                        $scope.selectavailable = false;
                    }
                }

            }
            function vmFlagAllAssignedSelected(selectavailable) {

                if (selectavailable) {
                    $scope.usersList.forEach((x) => {
                        $scope.arraySelectedAvailable.push({ select: true, username: x.username });
                    })
                } else {
                    $scope.arraySelectedAvailable = [];
                }

            }
            function vmGetGemLevel(gemstone_level) {

                if (!gemstone_level) return;
        
                let gemstone =  gemstones.find((x) => x.idnumber === gemstone_level);
        
                return gemstone ? gemstone.name : null;                
            }
            function vmOpenPopup() {

                $scope.popup = !$scope.popup;
    
            }
            function vmAssignGemLevel(level) {
              
                let _students1 = $scope.usersInClassList.filter((x) => x.select === true);
                let _students =_students1.map((y) => y.username);

                FRAPI.setGemstoneLevel(level, _students).then((response) => {
                                    
                    $scope.isSeleted = false;

                    vmOpenPopup();
                    FRAPI.cleargetUsersFromCohortTeacherMode($stateParams.currentClass, JSON.stringify(['student']));
                    let classIds = classes.map((item) => item.idnumber).join();
                    FRAPI.cleargetUsersFromCohortTeacherMode(classIds, JSON.stringify(['student']));
                    vmCalculateStudentsList();
                   // vmFlagAllRemoveSelected(false, _students1);       
                    //restructure header table to the correct position
                     $('table.demo').floatThead('reflow');
                    if (!$scope.$$phase) {
                        $scope.$apply();
                    }
                })
            }

            $scope.limitAssign = 30;
            $scope.limitAvailable = 30;

            $scope.loadMoreAssign = function () {
                $scope.limitAssign += 20;
            };
            $scope.loadMoreAvailable = function () {
                $scope.limitAvailable += 20;
            };

            $scope.assignGemLevel = vmAssignGemLevel;
            $scope.OpenPopup = vmOpenPopup;
            $scope.assignStudentToClass = vmAssignStudentToClass;
            $scope.removeStudentFromClass = vmRemoveStudentFromClass;
            $scope.assignAllStudentToClass = vmAssignAllStudentToClass;
            $scope.teacher = $stateParams.teacher;
            $scope.school = $stateParams.school;
            $scope.type = $stateParams.type;
            $scope.openSendEmail = vmOpenSendEmail;
            $scope.removeSelected = vmRemoveSelected;
            $scope.selectAllAvailable = vmSelectAllAvailable;
            $scope.vmFlagItemSelected = vmFlagItemSelected;
            $scope.assignselected = vmAssignSelected;
            $scope.vmFlagAllRemoveSelected = vmFlagAllRemoveSelected;
            $scope.vmFlagAssignedSelected = vmFlagAssignedSelected;
            $scope.vmFlagAllAssignedSelected = vmFlagAllAssignedSelected;
            $scope.vmStudentInSchool = vmStudentInSchool;
        })

})();