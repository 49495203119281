(function () {

    'use strict';

    angular.module('LMLanguaCards')

    .service('languacardsService', function (LMConfig, LMAPICache, LMAPI, $http, $q) {

        this.searchCourse = function (token, courseId) {

            var search = {
                id: courseId,
                name: undefined,
                p_author: undefined,
                p_series: undefined,
                p_atopic: undefined,
                p_genre: undefined
            };

            return LMAPICache.searchCourses(token, null, search);
        };

        this.getScosList = function (token, course) {

            return LMAPICache.scos(token, course.id).then(function (response) {

                var scos = response.scos;

                if (scos) {

                    //leave only speaking activities
                    scos = scos.filter(function (item) {
                        return ['SRS', 'SAS', 'FCR', 'FCA'].contains(item.type);
                    })

                    return scos;
                }
                else return null;
                
            });
        }

        this.getRandomSco = function (scos) {

            var randomIndex = Math.random() * (scos.length - 1);
            randomIndex = Math.round(randomIndex);

            var randomizedSco = scos[randomIndex];

            return randomizedSco;
        }

        this.getRandomScoWordInfo = function(contentFolder) {           

            var rCardInfo = {
                img: null,
                words: null
            }

            return $http.get('{0}/Library/{1}/{1}.xml'.format(LMConfig.storage, contentFolder), { dataType: "xml" }).then(function (response) {
                
                var textNode = $(response.data).find('w').text();
                rCardInfo.words = textNode;
                rCardInfo.img = '{0}/Library/{1}/{1}_Image.png'.format(LMConfig.storage, contentFolder);

                return rCardInfo;
            })
        }

        this.getAudioUrl = function(contentFolder) {
            return LMConfig.storage + '/Library/' + contentFolder + '/' + contentFolder + '_Audio.ogg';
        };

        this.loadAudio = function (audioProperties) {

            return $http.get(audioProperties.url, { responseType: "arraybuffer" })
                                 .then(function (response) {
                                     return response.data;

                                 });
        };

        this.playAudio = function (audioContext, audioProperties) {

            var defer = $q.defer();

            var self = this;

            $q.when(self.loadAudio(audioProperties))
              .then(function (audioBuffer) {

                  self.processAudio(audioContext, audioBuffer);
                  defer.resolve();
              });

            return defer.promise;
            
        };

        this.processAudio = function(audioContext, audioBuffer) {

            var defer = $q.defer();
            
            audioContext.decodeAudioData(audioBuffer, function (audioBuffer) {

                var buffer = audioBuffer;
                var sourse = audioContext.createBufferSource();
                sourse.buffer = buffer;
                sourse.connect(audioContext.destination);
                sourse.start(audioContext.currentTime, 0);
                sourse.onended = function () {
                   
                    defer.resolve();
                };
            });

            return defer.promise;
        };

    });

})();