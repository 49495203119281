(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', 'LMConfig', function ($stateProvider, LMConfig) {

        $stateProvider
            .state('app.course.audio', {
                url: '/audio',
                controller: 'courseAudioCtrl',
                templateUrl: '/assets/ng1/course/audio.html'
            })
    }])

    .controller('courseAudioCtrl', function ($scope, $state, $q, LMAPI, LMAPICache, LMConfig, LMWebStorage, $stateParams, $location, token, user, $window) {

        $scope.config = LMConfig;

        // is playing
        $scope.play = true;

        //for hide footer when play course
        $(".mainWindow").addClass("blackZone");

        $scope.select = vmSelect;
        $scope.playPause = vmPlayPause;
        $scope.next = vmNext;
        $scope.previous = vmPrevious;
        $scope.setPlayBackSpeed = vmSetPlayBackSpeed;

        var courseId = $stateParams.course;

        var player = new vimas.sco.audiocourse({
            application: window.application_id,
            token: token,
            courseid: courseId,
            api: LMAPI,            
            webstorage: LMWebStorage,
            config: LMConfig,
            lang: user.lang
        });

        var course = LMAPICache.scos(token, courseId);
        var progress = LMAPI.activitiesprogress(token, courseId);

        $q.all({ course: course, progress: progress })
          .then(function (response) {

              player.load(response.course, response.progress.data);
          });

        $(player).on('prepared', function (e, data) {

            $scope.scos = data.scos;
        })

        .on('current', function (e, sco) {

            $scope.current = sco.id;
            $scope.sco = sco;            

            //removes event handlers in order that don't show dictionary 
            //when play audio course
            $(sco.activity).off("text_ready");

            $scope.activityIndex = $scope.scos.indexOf($scope.sco);

            // this is anti-pattern
            // but method is called both from Angular and JS
            // so phase check and apply both are required
            if (!$scope.$$phase)
                $scope.$apply();

            // set state pause
            // when user click on button 'pause'
            var pause = false;
            $(sco.activity.vpPlayer).on('pause', function () {
                pause = true
            });

            // when audio play is finished
            // and state not is 'pause'
            $(sco.activity.vpPlayer).on('finish', function () {
                if (!pause) {
                    vmNext();
                    $scope.$apply();
                }
                pause = false;
            });

        })

        .on('loaded', function (e, sco) {

            $scope.sco = sco;
            $scope.current = sco.id;

            // if playing flag is on
            // start autolpay on activity loaded
            if ($scope.play) {

                if ($scope.sco.type == 'NSCO' && $scope.sco.params.passage_type == 'ImageAudio') {
                } else {
                    sco.activity.autoplay();
                }
            };

            sco.audioState = 'loaded';

        })

        .on('no_actitity_already', function (e) {

            //save all events before exit
            vimas.sco.lms.vmCommitEvents();
            player.exit();
        })

        .on('audiocourse.exit', function (e, dest) {

            $state.go('app.course.exit', { back: $stateParams.back });

            // trigger angular update cycle
            $scope.$apply();
        });

        function vmNext() {

            //close current activity 
            //and automatically open the next activity
            //with special delay (delay is different for different customers)
            player.stop();
            setTimeout(player.next, player.timeout);
        };

        function vmPrevious() {

            //close current activity 
            //and automatically open the next activity
            player.stop();
            player.previous();
        };

        function vmSelect(id) {

            player.stop();

            // find sco by index
            // select it as current
            $scope.sco = $scope.scos[id];
            $scope.current = $scope.sco.id;

            // open current sco by id
            player.open($scope.sco.id);
        };

        function vmPlayPause() {

            var sco = $scope.sco;

            // toogle playing flag
            $scope.play = !$scope.play;

            if (!$scope.play) {

                sco.activity.vpPlayer.vmPause();
                sco.audioState = 'paused';

            } else {

                // remove audio player sunscription
                $(sco.activity.vpPlayer).off('start');

                if (sco.audioState != 'paused') {

                    sco.activity.autoplay();
                }
                else {

                    sco.activity.vpPlayer.vmResume();
                };
            };
        };

        function vmSetPlayBackSpeed() {

            // get playback speed
            // and for each activity
            var rate = parseFloat($scope.playBackSpeed);
            $scope.scos.forEach(function (sco) {

                // update params
                sco.params.playback_speed = rate;

                // update value if already created
                if (sco.activity)
                    sco.activity.vpPlayer.vmSetPlayRate(rate);
            });
        };

        $scope.swipeLeft = vmNext;
        $scope.swipeRight = vmPrevious;
    })

})();