(function () {

    'use strict';

    angular.module('LMLanguaCards')

    .directive('vmsSayWord', function (LMConfig, languacardsService, $q, $http, $translate) {

        function vmController($scope) {
            
            $scope.courseErrorMsg = $translate.instant('library.shelve.shelve.cards.noBook');
            $scope.playScannerSound = true;

            var scanSuccessSoundConfig = {
                url: 'resourses/audio/beep-07.wav'
            };

            $scope.$on('$destroy', function () {
                audioContext.close();
                $scope.data = null;

                $scope.playScannerSound = true;

                $scope.dataLoadedCallBack({
                    data: {
                        timeout: 5000,
                        //playScannerSound: true
                    }
                })
            })

            var audioContext = new (window.AudioContext || webkitAudioContext)();

            var timerId;
            $scope.timer = 0;

            $scope.course = null;

            $scope.sayWord = false;

            $scope.firstScanned = false;
            $scope.courseLoaded = false;
            $scope.showLoadingIndicator = false;

            $scope.nextCard = vmNextCard;
            $scope.playAudio = vmPlayAudio;

            $scope.missedSoundAudioProperties = {
                url: vmGetSoundEffectsUrl('6.Drag-fly%20back-final')
            };

            $scope.finishSoundAudioProperties = {
                url: vmGetSoundEffectsUrl('4.Match-all%20matched-claps-final')
            };

            $scope.$watch('data', function () {
                if ($scope.data) {

                    if ($scope.playScannerSound)
                        vmPlayAudio(scanSuccessSoundConfig, true);

                    $scope.firstScanned = true;

                    if ($scope.rCardInfo && !$scope.spellWord) {

                        if ($scope.rCardInfo.img) {
                            $scope.rCardInfo.img = null;
                        }
                    }
                    $scope.startRecognition = false;
                    clearInterval(timerId);
                    $scope.timer = 0;
                    $scope.time = '00:00';
                    vmNextCard();
                    $scope.showLoadingIndicator = true;
                    
                    vmProcessWord();

                }
            });

            function vmGetSoundEffectsUrl(fileName) {

                return 'resourses/audio/{0}.ogg'.format(fileName);
            }

            function vmProcessWord() {

                languacardsService.searchCourse($scope.token, $scope.data.course).then(function (courses) {

                    $scope.spellWordReset = false;

                    if (courses.length > 0) {

                        //api returns list (usually 1 item, but if more then 1, take first)
                        $scope.course = courses[0];
                        var course = courses[0];

                        if ($scope.course.enrol == '1') {
                            languacardsService.getScosList($scope.token, course).then(function (scos) {
                                if (scos) {
                                    if (scos.length > 0) {

                                        var randomizedSco = languacardsService.getRandomSco(scos);
                                        if (randomizedSco.params) {

                                            var randomizedContentKey = JSON.parse(randomizedSco.params).title_id;

                                            languacardsService.getRandomScoWordInfo(randomizedContentKey).then(function (data) {

                                                if (data) {

                                                    $scope.rCardWordLoaded = true;
                                                    $scope.rCardInfo = data;
                                                    $scope.sayWord = true;

                                                    var word = $scope.rCardInfo.words.toLowerCase().trim();

                                                    // create local recognizer
                                                    var SpeechRecognition = SpeechRecognition || webkitSpeechRecognition;
                                                    var SpeechRecognitionEvent = SpeechRecognitionEvent || webkitSpeechRecognitionEvent;
                                                    var recognition = new SpeechRecognition();
                                                    recognition.lang = 'en-US';
                                                    recognition.maxAlternatives = 10;
                                                    recognition.continuous = true;
                                                    recognition.interimResults = true;
                                                   

                                                    // attach to recognizer result event
                                                    // top handle the responses
                                                    recognition.onresult = function (event) {

                                                        // get the latest results
                                                        let result = event.results[event.results.length - 1];
                                                        
                                                        // take only final results
                                                        if (result.isFinal) {

                                                            // check all the alternatives returned
                                                            // if any of them has confidence above 0.7
                                                            // and transript text contains target word
                                                            // than we have a match
                                                            let match = false;
                                                            for (var i = 0; i < result.length; i++) {
                                                                let transcript = result[i].transcript;
                                                                let confidence = result[i].confidence;

                                                                // take only variants with confidence over 0.7
                                                                // split the transcript by space
                                                                // and match each piece in case something is added
                                                                if (confidence > 0.7 && transcript.toLowerCase().trim().split().some((x) => x === word)) {

                                                                    match = true;
                                                                    break;
                                                                }
                                                            }

                                                            if (match) {

                                                                // stop the recognition                                                                
                                                                recognition.stop();
                                                                $scope.startRecognition = false;
                                                                clearInterval(timerId);
                                                                $scope.timer = 0;
                                                                // set state flags
                                                                $scope.sayWordFinish = true;
                                                                $scope.sayWordError = false;

                                                                // play success sound
                                                                vmPlayAudio($scope.finishSoundAudioProperties, true);

                                                            } else {

                                                                vmPlayAudio($scope.missedSoundAudioProperties, true);
                                                                $scope.sayWordError = true;
                                                            };

                                                        };
                                                    };

                                                    // start recognition
                                                    recognition.start();
                                                    $scope.startRecognition = true;
                                                    timerId = setInterval(function () {
                                                        $scope.timer = $scope.timer + 1;
                                                        var minutes = $scope.timer / 60;
                                                        var seconds = $scope.timer % 60;
                                                        minutes = minutes <= 9 ? '0' + parseInt(minutes) : parseInt(minutes);
                                                        seconds = seconds <= 9 ? '0' + seconds : seconds;
                                                        $scope.time = minutes + ':' + seconds;
                                                        $scope.$apply();
                                                    }, 1000);

                                                    $scope.rCardInfo.img = '{0}/Library/{1}/{1}_Image.png'.format(LMConfig.storage, randomizedContentKey);

                                                    $scope.courseLoaded = true;
                                                    $scope.courseLoadError = false;

                                                    $scope.showLoadingIndicator = false;                                                    

                                                    $scope.playScannerSound = false;

                                                    $scope.dataLoadedCallBack({
                                                        data: {
                                                            timeout: 2000,
                                                            // playScannerSound: false
                                                        }
                                                    });
                                                }
                                            });

                                        };
                                    };
                                };
                            });
                        } else {
                            vmProcessCourseError();
                        }

                    } else {
                        vmProcessCourseError();
                    }
                }).catch(function () {
                    vmProcessCourseError();
                });
            }

            function vmProcessCourseError() {

                $scope.courseErrorMsg = $scope.courseErrorMsg.format($scope.data.course);

                $scope.courseLoaded = false;
                $scope.courseLoadError = true;
                $scope.showLoadingIndicator = false;

                $scope.playScannerSound = true;

                $scope.dataLoadedCallBack({
                    data: {
                        timeout: 5000,
                        //playScannerSound: true,                        
                    }
                });
            }

            function vmNextCard() {

                $scope.sayWord = false;
                $scope.sayWordError = false;
                $scope.sayWordFinish = false;
            }

            function vmPlayAudio(audioConfig, preventPlayButtonActiveMode) {

                var playing = languacardsService.playAudio(audioContext, audioConfig);

                if (playing) {

                    if (!preventPlayButtonActiveMode)
                        $scope.play = true;

                    playing.then(function () {

                        if (!preventPlayButtonActiveMode)
                            $scope.play = false;

                    });
                }

                return playing;
            }
        }

        return {
            scope: {
                data: '=vmsData',
                token: '=vmsToken',
                dataLoadedCallBack: '&vmsDataLoadedCallback'
            },
            controller: vmController,
            templateUrl: '/assets/ng1/languacards/sayword.html'
        }
    })

})();