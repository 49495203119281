(function () {
    'use strict';

    angular.module('languabooksApp', ['LMConfig', 'LMAPI', 'LMWordpress', 'LMLanguaCards', 'ui.router', 'ct.ui.router.extras', 'ngAnimate', 'chart.js', 'pascalprecht.translate', 'ngSanitize', 'angular.filter', 'ui.select', 'textAngular'])

        .config(function ($stateProvider, $urlRouterProvider, $translateProvider, LMConfig) {

            $stateProvider
                .state('app', {
                    abstract: true,
                    controller: 'appCtrl',
                    template: '<ui-view>',
                    resolve: {                        
                        credentials: function (LMWordpress, LMAPI, FRAPI) {
                            return LMWordpress.getcredentials().then((x) => {
                                LMAPI.setSession(x.session_token);
                                FRAPI.setSession(x.session_token);                               
                                return x;
                            });
                        },
                        cdn: function (LMAPI, LMConfig, credentials) {

                            // set withCredentials true for all jquery requests                            
                            $.ajaxPrefilter((options) => {
                            // turn on for content requests except idevice folder
                                if (options.url.startsWith(LMConfig.storage) && !options.url.startsWith(LMConfig.storage + '/js'))
                                    options.xhrFields = { withCredentials: true }; 
                            });

                            // set CDN access cookies
                            return LMAPI.getCDNAccess(credentials.session_token);
                        },
                        token: function (credentials) {

                            return credentials.token;
                        },
                        user: function (FRAPI, commonService, token) {
                            return FRAPI.userinfo(token)
                                .then(function (info) {

                                    var user = info.data;

                                    if (user.project == null) {
                                        user.project = 'fr';
                                    }

                                    commonService.$project = user.project;
                                    // set the localization language
                                    // and return user when ready
                                    return commonService.setLanguage($translateProvider, user.project, user.lang)
                                        .then(function () {

                                            return user;
                                        });
                                })
                        }
                    }
                });

            $urlRouterProvider.otherwise('/library');
        })

        .config(function ($sceDelegateProvider) {

            $sceDelegateProvider.resourceUrlWhitelist([
                'self',
                'http://localhost/build/languabooks/**',
                'http://localhost:8000/**',
                'https://s3.amazonaws.com/speech-content-devel/**',
                'https://dev-cdn.languametrics.com/**',
                'https://s3.amazonaws.com/speech-content-test/**',
                'https://s3.amazonaws.com/speech-content-prod/**',
                'https://s3.eu-central-1.amazonaws.com/speech-content-lb-eu/**',
                'https://s3.ap-southeast-1.amazonaws.com/speech-content-apac/**',
                'https://**.globalgatewaye4.firstdata.com/payment'
            ]);
        })

        /* .run(function(Session) {})
         .factory('Session', ['$http', function($http) {
 
         }])*/
        //for show warning message when press back history button in browse on $state - read book 
        .run(function ($rootScope, $window, LMConfig, authService) {

            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

                if (toState.status != 'active' && toState.status != 'entered') {
                    $rootScope.pageLoading = true;
                }

                if ($rootScope.recognition) {

                    $rootScope.recognition.continuous = false;

                    $rootScope.recognition.stop();
                    $rootScope.recognition = null;
                }
            });

            $rootScope.$on('$stateChangeSuccess',
                function (event, toState, toParams, fromState, fromParams) {
                    $rootScope.fromState = fromState;
                    $rootScope.pageLoading = false;
                });

            $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
                console.error(error);
                $rootScope.pageLoading = false;
            });
        })

        .config(['$httpProvider', function ($httpProvider) {
            $httpProvider.interceptors.push('dateInterceptor');
            $httpProvider.interceptors.push('401Interceptor');
        }])

        .controller('initCtrl', function ($scope, $state, authService, LMAPICache, LMAPImarketCache) {

            // reset app cache
            // on app refresh
            LMAPICache.reset();
            LMAPImarketCache.reset();
            window.localStorage.removeItem('gemstones');
            window.localStorage.removeItem('gemstonesStudents');
            window.localStorage.removeItem('vmLoadSchoolStudents');
    //        window.sessionStorage.removeItem('current_district_idnumber');
//            window.sessionStorage.removeItem('current_school_idnumber');

        })

        .controller('appCtrl', function ($rootScope, $translate, user, LMWordpress, credentials, sessionService, LMProjectConfig) {

            // set interface language
            // based on user preferences
            $translate.use(user.lang || 'en');

            $rootScope.botHistoryLocalAr = [];

            sessionService.setCheckSession(credentials.session_token);


            // prevent navigating to Edit Profile when user has no rights
            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                if (toState.name == 'app.profile.edit.edit' && LMProjectConfig.properties[user.project].editProfileRoles) {
                    var userInRole = LMProjectConfig.properties[user.project].editProfileRoles.some(prflRole => user.roles.some(usrRole => usrRole == prflRole));
                    if (!userInRole) {
                        event.preventDefault();
                        $rootScope.pageLoading = false;
                    }
                }
            });
        })
        .factory('sessionService', ['$window', 'LMConfig', 'FRAPI', function ($window, LMConfig, FRAPI) {
            function checkSessionAfter(interval, session) {
                setTimeout(function () {
                    FRAPI.checksession()
                        .then(function (response) {
                            let userActive = sessionStorage.getItem('usrActiv_' + session);
                            console.log('checkSession(): TTL= ' + response.data.TTL + ' active=' + userActive);
                            // if session is expiring
                            if (response.data.TTL <= LMConfig.session.checkInterval) {
                                if (userActive) {
                                    // if session is almost expired and user active - prolong session
                                    prolongSession(session);
                                } else {
                                    // if user not active - check after session is expired to show warning
                                    checkSessionAfter(parseInt(response.data.TTL) + 1000 /* 1 sec*/, session);
                                }
                            } else {
                                // check user activity before session is expired
                                checkSessionAfter(LMConfig.session.checkInterval, session); // should return 401 and redirect to /logout
                            }
                        })
                        .catch(function (error) {
                            console.error(error);
                        });

                }, interval);
            }

            function prolongSession(session) {
                sessionStorage.removeItem('usrActiv_' + session);
               
                FRAPI.prolongsession().then(function () {
                    checkSessionAfter(LMConfig.session.checkInterval, session);
                }).catch(function (error) {
                    console.error(error);
                });
            }

            function onBodyClick(session) {
                let userActive = sessionStorage.getItem('usrActiv_' + session) || 0;
                userActive++;
                sessionStorage.setItem('usrActiv_' + session, userActive);
            }

            return {
                setCheckSession: function (session) {
                    $("body").click(function () { onBodyClick(session); });
                    checkSessionAfter(LMConfig.session.checkInterval, session);
                }
            };
        }])
        .service('authService', ['$window', 'LMConfig', function ($window, LMConfig) {

            this.setToken = function (token) {

                window.sessionStorage.setItem('languabooks.com/token', token);
            };

            this.getToken = function () {

                return window.sessionStorage.getItem('languabooks.com/token');
            };

            this.logout = function (keepServSession) {
                $window.sessionStorage.clear();
                $window.location.href = LMConfig.wpapi.user_logout + (keepServSession ? '?clnt=1' : '');
                
            }

        }])

        .factory('dateInterceptor', function ($rootScope) {
            return {
                response: function (response) {

                    var dateHeader = response.headers().date;
                    if (dateHeader) {
                        // get string like 'Wed, 09 Oct 2019 07:43:52 GMT'. Sometimes can come 2 dates - 'Wed, 09 Oct 2019 07:43:52 +0000, Wed, 09 Oct 2019 07:43:52 GMT'
                        var match = /[a-zA-Z]{3},\s\d{2}\s[a-zA-Z]{3}\s\d{4}\s\d{2}:\d{2}:\d{2}\sGMT/.exec(dateHeader);
                        if (match) {
                            $rootScope.date = match[match.length - 1];
                        } else {
                            $rootScope.date = dateHeader;
                        }

                    }

                    return response;
                },
            };
        })

        .factory('401Interceptor', ['$q', '$translate', 'authService', function ($q, $translate, authService) {
            var logOutInProgress = false;
            return {
                responseError: function (rejection) {

                    if (rejection.status === 401 && !logOutInProgress) {
                        logOutInProgress = true;
                        var msg = 'Your session is expired. You will be redirected to login page.';
                        if ($translate.proposedLanguage()) {
                            msg = $translate.instant('common.session.expired');
                        }
                        alert(msg);
                        authService.logout(true);
                    }
                    return $q.reject(rejection);
                }
            }
        }])

        .factory("uuid", [function () {
            function e() {
                return Math.floor((1 + Math.random()) * 65536).toString(16).substring(1);
            }
            return {
                newuuid:
                    function () {
                        var e = [];
                        var t = "0123456789abcdef";
                        for (var n = 0; n < 36; n++) {
                            e[n] = t.substr(Math.floor(Math.random() * 16), 1)
                        }
                        e[14] = "4";
                        e[19] = t.substr(e[19] & 3 | 8, 1);
                        e[8] = e[13] = e[18] = e[23] = "-";
                        return e.join("")
                    },
                newguid: function () {
                    return e() + e() + "-" + e() + "-" + e() + "-" + e() + "-" + e() + e() + e();
                }
            }
        }])
        // store element value in local storage
        .directive('ngLocalStorage', [function () {

            return {
                restrict: 'A',
                link: function (scope, el, attrs) {

                    // watch for model changes
                    // and update local storage
                    scope.$watch('ngModel', function (value) {

                        if (value instanceof Object && !(value instanceof Date))
                            value = angular.toJson(value);

                        localStorage.setItem(attrs.ngModel, value);

                    }, true);

                    // load initial value from local storage
                    var value = localStorage.getItem(attrs.ngModel);

                    // convert type if required
                    if (scope.ngLocalStorageType == 'integer')
                        value = parseInt(value);

                    if (scope.ngLocalStorageType == 'object' && value != "undefined")
                        value = JSON.parse(value);

                    if (scope.ngLocalStorageType == 'date' && value && value != 'undefined' && value != 'null') {
                        value = new Date(value);
                    }
                    // set initial model value
                    if (value && value != 'null' && value != 'undefined') {
                        scope.ngModel = value;
                    }

                },
                scope: {
                    ngModel: '=',
                    ngLocalStorageType: '@'
                }
            }
        }])
    /*    .directive('vmDateHours', [function () {
    
            function link($scope, element, attrs) {
    
                // watch for model changes
                // and update local storage
                $scope.$watch('ngModel', function (value) {
                    if (!$scope.ngForm && value) {
    
                        value = hours(value, $scope);
                        localStorage.setItem(attrs.ngModel, value);
    
                    } else if ($scope.ngForm && value) {
    
                        $scope.ngModel = hours(value, $scope);
                    }
    
                }, true);
    
                if (!$scope.ngForm) {
                    // load initial value from local storage
                    var value = localStorage.getItem(attrs.ngModel);
    
                    if (value && value != 'undefined' && value != 'null') {
                        value = new Date(value);
                        $scope.ngModel = value;
                    }
                }
    
            }
    
            function hours(value, $scope) {
    
                if ($scope.ngDateType === 'begin') {
                    value.setHours(0, 0, 0, 0);
                };
    
                if ($scope.ngDateType === 'end') {
                    value.setHours(23, 59, 59, 999);
                };
                return value;
            }
    
            return {
                restrict: 'A',
                scope: {
                    ngModel: "=",
                    ngDateType: '@',
                    ngForm: "@"
                },
                link: link
            }
        }])*/
})();