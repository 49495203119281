(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', 'LMConfig', function ($stateProvider, LMConfig) {

            $stateProvider
                .state('app.library.shelve.cards', {
                    url: '/cards',
                    controller: 'libraryShelveCardsCtrl',
                    templateUrl: '/assets/ng1/library/shelve/cards.html',
                })
        }])

      .controller('libraryShelveCardsCtrl', function ($scope, $state, $rootScope, LMAPI, LMAPICache, LMConfig, token, $interval, user, $http, $q, cohorts, LMWordpress, FRAPI, LMWebStorage, coursesService) {

            $scope.$emit('sideTabChange', 'cards');

            $scope.mode = 'flashcard';
            $scope.token = token;

            $scope.config = LMConfig;
            $scope.user = user;
            $scope.course = null;
            $scope.qrData = null;
            $scope.languaCardDataLoaded = null;

            var languacards = 'languacards';

            // languacards shod be available
            // only for users which bought the product
            // so check if "languacards" cohort is available
            $scope.languacards = cohorts.some(x => x.id === languacards);

            $scope.showQrCallBack = vmShowQrCallBack;
            $scope.showTab = vmShowTab;

            $scope.onWordDataLoaded = vmOnWordDataLoaded;
            $scope.goToCourse = vmGoToCourse;

            $scope.activateCards = vmActivateCards;

            function vmShowQrCallBack(data) {

                $scope.qrData = data;
                $scope.languaCardDataLoaded = null;
                $scope.$apply();
            }

            function vmActivateCards() {

                LMWordpress.getcredentials().then(function (user) {
                    FRAPI.cohortaddusers(languacards, [user.username]).then(function (response) {

                        $scope.languacards = true;

                        cohorts.push({
                            id: 'languacards',
                            title: 'LanguaCards',
                            type: null
                        });
                        LMWebStorage.delete('CohortsCache');
                    })
                });
            }

            function vmShowTab(mode) {

                $scope.qrData = null;
                $scope.mode = mode;

            }

            function vmOnWordDataLoaded(data) {

                $scope.languaCardDataLoaded = data;
            }

            function vmGoToCourse(data) {

                var course = data.course;

                switch (course.curriculumtype) {
                    case 'categories':

                        // open categories curriculum
                        $state.go('app.library.categories', { id: course.id, back: $state.href($state.current.name, $state.current.params) });
                        break;

                    case 'cohorts':

                        // open cohort curriculum
                        $state.go('app.library.cohorts', { id: course.id, back: $state.href($state.current.name, $state.current.params) });
                        break;

                    default:
                        coursesService.runTest(token, course.id, null, $state.href($state.current.name, $state.current.params));
                }
            }

        })

})();