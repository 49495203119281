(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.admin.school.users.teachers', {
                url: '/teachers',
                controller: 'adminTeachersCtrl',
                templateUrl: '/assets/ng1/admin/users/teacher/list.html',
            })
            .state('app.admin.school.users.tedit', {
                url: '/tedit/:teacher',
                params: {
                    school: null,
                },
                controller: 'teacherEditCtrl',
                templateUrl: '/assets/ng1/admin/users/teacher/editteacher.html',
            })
            .state('app.admin.school.users.tadd', {
                url: '/tadd',
                params:{
                    school: null
                },
                controller: 'teacherEditCtrl',
                templateUrl: '/assets/ng1/admin/users/teacher/editteacher.html',
            })
            .state('app.admin.school.users.requestteach', {
                url: '/request/:page',
                params: {
                    currentClass: null,
                    school: null
                },
                controller: 'parentSearchChildCtrl',
                templateUrl: '/assets/ng1/parent/manage/request.html'
            })

    }])

    .controller('adminTeachersCtrl', function ($scope, $rootScope, commonService, LMConfig, LMWordpress, FRAPI, user, $state, $stateParams, schools, classes) {

        //restructure header table to the correct position
        $('table.demo').floatThead('reflow');
        // watch for side tab changed
        $scope.$emit('adminTabChange', 'teacher');

        FRAPI.getUsersFromCohortTeacherMode(commonService.getCurrentSchool(), JSON.stringify(['teacher'])).then(function (response) {
            // get list of school teachers
            $scope.teachers = response;
           /* $scope.teachers.forEach((x) => {
                let temp = teachers.find((y) => y.username === x.username);
                if(!temp) {
                    teachers.push(x);
                }
            })*/
        });
        $scope.school = schools.find((x) => x.id === commonService.getCurrentSchool());

        $scope.ext_user= LMConfig.rights.profile_ext_user(user.project);

        function vmRemoveTeacher(teacher) {

            if (confirm('Are you sure?')) {

                $rootScope.pageLoading = true;

                FRAPI.deleteTeacher(teacher.token, $scope.school.id).then(function (data) {

                    FRAPI.cleargetUsersFromCohortTeacherMode(commonService.getCurrentSchool(), JSON.stringify(['teacher']));
                    
                    $scope.teachers.forEach(function (item, i) {
                        if (item.username == teacher.username) {

                            $scope.teachers.splice(i, 1);
                           
                            let _clas = classes.filter((x) => x.teacher === item.token);
                            _clas.forEach((x) => {
                                x.teacherLastname = null;
                                x.teacherFirstname = null;
                            })
                            if ($scope.users && item.properties && 
                                (item.properties.ext_integration === 'classlink' || item.properties.ext_integration === 'clever' )) {
                                
                                $scope.users.push(item); 
                            }
                        }
                    });
                    localStorage.setItem('isCache', '1');
                    $rootScope.pageLoading = false;
                   
                     //restructure header table to the correct position
                     $('table.demo').floatThead('reflow');
                })
            }
        }
        function vmSelectAll(allselect) {

            if (allselect) {
                $scope.teachers.forEach((x) => x.select = true);
            } else {
                $scope.teachers.forEach((x) => x.select = false);
            }
        }
        function vmOpenSendEmail() {

            let recipients = $scope.teachers.filter((y) => y.select);
                                           // .map((x) => x.email);

            $state.go('app.admin.school.users.email', { recipients: recipients, people: $scope.teachers });
        }
        function vmGetUsers() {
   
            switch ($scope.school.district_type) {
                case 'Clever':                       
                    vmGetCleverUsersInSchool();                                           
                    break;
                case 'Classlink':                       
                    vmGetClasslinkUsersInSchool();                                                 
                    break;
                case 'Languametrics':
                    break;
            }
        }
        function vmGetCleverUsersInSchool() {

            $rootScope.pageLoading = true;

            if ($scope.school.school_type === 'Languametrics' && $scope.school.district_type !== 'Languametrics') {
             
                FRAPI.getTeachersCleverByDistrict($scope.school.district).then((response) => {
                    $scope.users = response.filter((x) => !$scope.teachers.some((y) => x.username === y.username));
                    $rootScope.pageLoading = false;
                })
            } else {
                FRAPI.getUsersClever(commonService.getCurrentSchool(), $scope.school.district, 'teacher').then((response) => {
                    $scope.users = response.filter((x) => !$scope.teachers.some((y) => x.username === y.username)); 
                    $rootScope.pageLoading = false;                    
                })
            }   
            //restructure header table to the correct position
            $('table.demo').floatThead('reflow');          
        }
        function vmGetClasslinkUsersInSchool() {

            $rootScope.pageLoading = true;

            if ($scope.school.school_type === 'Languametrics' && $scope.school.district_type !== 'Languametrics') {

                FRAPI.getTeachersClasslinkByDistrict($scope.school.district).then((response) => {
                    $scope.users = response.filter((x) => !$scope.teachers.some((y) => x.username === y.username));   
                    $rootScope.pageLoading = false;              
                })
            } else {
                FRAPI.getUsersClasslink(commonService.getCurrentSchool(), 'teacher').then((response) => {
                    $scope.users =  response.filter((x) => !$scope.teachers.some((y) => x.username === y.username));    
                    $rootScope.pageLoading = false;            
                })
            } 
            //restructure header table to the correct position
            $('table.demo').floatThead('reflow');             
        }
        function vmAssignUser(user) {

            $rootScope.pageLoading = true;

            let isArray = Array.isArray(user);
            user = isArray ?  user.filter((y) => y.select) : user;

            if (isArray && user.length > 100) {
                $rootScope.pageLoading = false;
                alert("Can't add more than 100 users one time");
                return;
            }

            if ($scope.school.district_type === 'Classlink'){
               
               let sourcedId = isArray ? user.map((x) => x.sourcedId) : [user.sourcedId];

                FRAPI.assignUserClasslink(sourcedId, 'teacher', $scope.school.district, $scope.school.id, null).then((response) => {
                 
                    if (!isArray) {
                        $scope.users = $scope.users.filter((x) => x.username != user.username);
                        user.token = response.data.find((x) => x.id == user.username).token;
                        user.properties = { ext_integration: "classlink" };
                        $scope.teachers.push(user);
                       // teachers.push(user);
                        
                    }  else {
                        $scope.users = $scope.users.filter((x) => !user.some(y => x.username === y.username));

                        user.forEach((item) => {
                            item.token = response.data.find((x) => x.id == item.username).token;
                            item.properties = { ext_integration: "classlink" };
                            item.select = false;
                            $scope.teachers.push(item);
                        //   teachers.push(item);
                        })                      
                    }  
                    vmSelectAll2(false, null);
                    $rootScope.pageLoading = false;

                   // FRAPI.clearUsersClasslink();
                   // FRAPI.clearTeachersClasslinkByDistrict();

                })
            } else if ($scope.school.district_type === 'Clever') {

               let username = isArray ? user.filter((y) => y.select).map((x) => x.username) : [user.username];

                FRAPI.assignUserClever(username, 'teacher', $scope.school.district, 
                                        $scope.school.id, null).then((response) => {

                    if (!isArray) {
                        $scope.users = $scope.users.filter((x) => x.username != user.username);
                        user.token = response.data.find((x) => x.id == user.username).token; 
                        user.properties = { ext_integration: "clever" };
                        $scope.teachers.push(user);
                      //  teachers.push(user);

                    }  else {
                        $scope.users = $scope.users.filter((x) => !user.some(y => x.username === y.username));

                        user.forEach((item) => {
                            item.token = response.data.find((x) => x.id == item.username).token;
                            item.properties = { ext_integration: "clever" };
                            item.select = false;
                            $scope.teachers.push(item);
                          //  teachers.push(item);
                        })                      
                    }                  
                    vmSelectAll2(false, null);
                    $rootScope.pageLoading = false;

                   // FRAPI.clearUsersClever();
                   // FRAPI.clearTeachersCleverByDistrict();
                })
            } 

            //restructure header table to the correct position
            $('table.demo').floatThead('reflow');
        }
        function vmSelectAll2(select, _users) {
            if (_users) {
                $scope.users.forEach((x) => {
                    let user = _users.find((y) => y.username === x.username);
                    if (user) {
                        x.select = select;
                    }
                })
            } else {
                $scope.users.forEach((x) => {                   
                    x.select = select;                    
                })
            }
          
        }
        function isDisabledSelectedBtn() {
           return $scope.users.every((x) => !x.select);
        }
        function vmGetNameClassType(type) {
            switch (type) {
                case 'Clever':                       
                    return 'Clever';      
                case 'Classlink':                       
                    return 'ClassLink';
                case 'Languametrics':
                    return 'Languametrics';
            }
        }

        $scope.removeTeacher = vmRemoveTeacher;
        $scope.selectAll = vmSelectAll;
        $scope.openSendEmail = vmOpenSendEmail;
        $scope.vmGetUsers = vmGetUsers;
        $scope.vmAssignUser = vmAssignUser;
        $scope.vmSelectAll2 = vmSelectAll2;
        $scope.isDisabledSelectedBtn = isDisabledSelectedBtn;
        $scope.vmGetNameClassType = vmGetNameClassType;
    })

    .controller('teacherEditCtrl', function ($scope, LMWordpress, $q, LMAPI, FRAPI, user, $stateParams, commonService, $state, LMConfig, $rootScope) {
        // watch for side tab changed
        $scope.$emit('adminTabChange', 'teacher');

        $scope.idschool = sessionStorage.getItem('current_school_idnumber');
        $scope.username = $stateParams.teacher;
 
        //call method when successfully add/update teacher
        function vmBack() {
            FRAPI.cleargetUsersFromCohortTeacherMode($scope.idschool, JSON.stringify(['teacher']));
            $state.go('app.admin.school.users.teachers');
        }
        $scope.onBack = vmBack;      
    })
})();