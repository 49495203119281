(function () {

    'use strict';

    angular.module('LMLanguaCards')

    .directive('vmsQrScanner', function () {

        function vmController($scope) {

            $scope.$on('$destroy', function () {

                //audioContext.close();

                window.clearTimeout($scope.qrAnimationTimeout);
                window.cancelAnimationFrame($scope.qrAnimationFrame);

                //stop video streaming on exit
                if ($scope.stream)
                    $scope.stream.getTracks()[0].stop();

            });

            //var audioContext = new (window.AudioContext || webkitAudioContext)(),
            var gCtx = null, gCanvas = null, prev = null, blockScanner = false,
                sx, sy, sw, sh, timeout = 5000, playScannerSound = true;

            //use web api for access to webcam
            navigator.mediaDevices
                .getUserMedia({
                    audio: false, video: {
                        width: { max: 1280 },
                        height: { max: 720 }
                    }
                })
                .then(function (stream) {

                    var video = document.querySelector('video');
                    video.srcObject = stream;

                    $scope.video = video;
                    $scope.stream = stream;

                    video.onloadedmetadata = function (e) {

                        video.play();
                        vmInitCanvas(video.clientWidth, video.clientHeight);
                        vmCaptureToCanvas();
                    };

                })
                .catch(function (ex) {

                    alert('Failed to start recording');
                });

            function vmInitCanvas(w, h) {

                sx = 0; sy = 0; sw = w; sh = h;

                sx = (w - (w / 1.6)) / 2;
                sy = (h - (h / 1.2)) / 2;

                $scope.sx = sx;
                $scope.sy = sy;

                $scope.$apply();

                gCanvas = document.getElementById("qr-canvas");
                gCanvas.style.width = "400px";
                gCanvas.style.height = "400px";
                gCanvas.width = 400;
                gCanvas.height = 400;
                gCtx = gCanvas.getContext("2d");
                gCtx.clearRect(0, 0, 400, 400);
            };

            function vmCaptureToCanvas() {

                try {

                    var video = document.querySelector('video');

                    gCtx.drawImage(video, sx, sy, 400, 400, 0, 0, 400, 400);

                    try {
                        qrcode.decode();
                    }
                    catch (e) {

                    };
                }
                catch (e) {

                };

                $scope.qrAnimationTimeout = window.setTimeout(function () {

                    $scope.qrAnimationFrame = requestAnimationFrame(vmCaptureToCanvas);

                }, 500);
            };

            qrcode.callback = function (data) {


                if (prev != data)
                    prev = data
                else
                    return;

                if (blockScanner)
                    return;

                $scope.$watch('languaCardDataLoaded', function () {

                    if ($scope.languaCardDataLoaded) {                      

                        if (typeof $scope.languaCardDataLoaded == 'object') {

                            if ($scope.languaCardDataLoaded.timeout)
                                timeout = $scope.languaCardDataLoaded.timeout;

                        }

                        //make possibility use the same qr code again (after 5 sec delay and all data loading)
                        window.setTimeout(function () {
                            prev = null;
                            blockScanner = false;
                        }, timeout);
                    }
                });
                
                var course = vmParse(data, 'b', '[A-z-0-9]*');
                var rCard = vmParse(data, 'r', '[A-z-0-9]*');
                var letter = vmParse(data, 'l', '[A-z-0-9]*');
                var dictionary = vmParse(data, 'd', '[A-z-0-9]*');
                
                $scope.vmGetQRCallBackData({
                    data: {
                        course: course,
                        rCard: rCard,
                        letter: letter,
                        dictionary: dictionary
                    }
                });

                blockScanner = true;
            };

            function vmParse(data, param, pattern) {

                var parsed = data.match('{0}=({1})'.format(param, pattern));
                return parsed ? parsed[1] : null;

            };

        };

        return {
            scope: {
                
                languaCardDataLoaded: '=vmsLanguacardDataLoaded',
                vmGetQRCallBackData: '&onQrCallback'
            },
            controller: vmController,
            templateUrl: '/assets/ng1/languacards/qrscanner.html'
        }
    })

})();