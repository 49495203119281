(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', 'LMConfig', function ($stateProvider, LMConfig) {

            $stateProvider
                .state('app.profile.edit.subscriptions', {
                    url: '/subscriptions',
                    controller: 'profileScriptionCtrl',
                    templateUrl: '/assets/ng1/profile/subscriptions/subscriptions.html'
                })
        }])

      .controller('profileScriptionCtrl', function ($scope, $state, token, LMWordpress, user, $q, LMAPImarketCache, $rootScope, coursesService) {

            $scope.$emit('sideTabChange', 'subscriptions');
            // get server date
            $rootScope.$watch('date', function (date) {
                if (date) {
                    $scope.currentDateServer = new Date(date);
                    $scope.currentDateServerUnix = moment(new Date(date)).unix();
                }
            });

            $q.all([LMWordpress.subscriptions(), LMAPImarketCache.getlicensetypes()]).then(function (responses) {

                responses[0].forEach(function (item) {

                    item.type = item.vms_curriculum_type == 'categories' || item.vms_curriculum_type == 'cohorts' ? 'Collection' : 'Book';
                    item.license_type = responses[1].find(function (license) {
                        return license.option_code == item.license_type_code;
                    });
                    item.start = item.date_start ? new Date(item.date_start * 1000) : 0;
                    item.end = item.date_end ? new Date(item.date_end * 1000) : 0;
                    item.unixend = item.date_end ? moment((new Date(item.date_end * 1000)).setHours(23, 59, 59, 999)).unix() : 0;
                    item.status = item.date_end < $scope.currentDateServerUnix ? 'expired' : 'active';
                });

                // block for sorting
                // first subscriptions where date end === 0
                // then sorting newest first (12.11.2019, 09,02.2018 ....)
                let infinity = responses[0].filter((x) => x.unixend === 0)
                    .sort((a, b) => {
                        if (a.fullname > b.fullname) {
                            return 1;
                        }
                        if (a.fullname < b.fullname) {
                            return -1;
                        }
                        return 0;
                    });

                let expired = responses[0].filter((x) => x.unixend != 0)
                    .sort((a, b) => {
                        if (b.unixend === a.unixend) {

                            if (a.fullname > b.fullname) {
                                return 1;
                            }
                            if (a.fullname < b.fullname) {
                                return -1;
                            }
                            return 0;

                        } else {
                            return b.unixend - a.unixend;
                        }

                    });
                $scope.products = [...infinity, ...expired];

                $scope.licenseTypes = responses[1];
            })

            function vmGetDate(end) {

                var dateEnd = new Date(end * 1000);
                var quantity;

                var hours = (dateEnd - $scope.currentDateServer) / (1000 * 60 * 60);
                if (hours > 0 && hours < 24) {

                    quantity = parseInt(hours) + ' h';

                } else if (hours >= 24) {

                    quantity = parseInt(hours / 24) + ' days';
                }
                return quantity;
            }

            function vmGetPeriod(end, start) {

                if (end != null) {
                    var dateEnd = new Date(end * 1000);
                    var dateStart = new Date(start * 1000);

                    var days = (dateEnd - dateStart) / (1000 * 60 * 60 * 24);

                    return parseInt(days);
                } else {
                    return 0;
                }
            }
            function vmFilterProduct(id) {
                $scope.productKey = id;
            }
            function vmFilterLicense(id) {
                $scope.lincenseKey = id;
            }

            function vmFilterSubscription(id) {

                $scope.subKey = id;
            }
            function vmLicense(o, i, a) {

                if (!$scope.lincenseKey || $scope.lincenseKey == 'All') return true;

                var filter = $scope.lincenseKey.split(' ');
                var keys = ['license_type_code'];

                return filter.every(function (f) {

                    return keys.some(function (key) {

                        return this[key].indexOf(f) > -1;
                    }, o);
                });

            }

            function vmStatus(o, i, a) {

                if (!$scope.subKey || $scope.subKey == 'All') return true;

                var filter = $scope.subKey.split(' ');
                var keys = ['status'];

                return filter.every(function (f) {

                    return keys.some(function (key) {

                        return this[key].indexOf(f) > -1;
                    }, o);
                });
            }
            function vmProduct(o, i, a) {

                if (!$scope.productKey || $scope.productKey == 'All') return true;

                var filter = $scope.productKey.split(' ');
                var keys = ['type'];

                return filter.every(function (f) {

                    return keys.some(function (key) {

                        return this[key].indexOf(f) > -1;
                    }, o);
                });
            }
            // open cohorts curriculum
            function vmOpenCohorts(course) {

                switch (course.vms_curriculum_type) {
                    case 'categories':

                        // open categories curriculum
                        $state.go('app.library.categories', { id: course.idnumber, back: $state.href($state.current.name, $state.current.params) });
                        break;

                    case 'cohorts':

                        // open cohort curriculum
                        $state.go('app.library.cohorts', { id: course.idnumber, back: $state.href($state.current.name, $state.current.params) });
                        break;

                    default:
                        coursesService.runTest(token, course.idnumber, null, $state.href($state.current.name, $state.current.params));
                }
            }
            function vmSaveLicenseType(product) {

                var sendData = {
                    token: token,
                    id: product.license_id,
                    customer_id: product.customer_id,
                    license_type: product.license_type.option_code,
                    period: product.period_code,
                    date_start: product.date_start,
                    date_end: product.date_end,
                    order_item_id: product.order_item_id,
                    group_license_id: product.group_license_id || null,
                    course_id: product.course_id,
                    course_cohort_id: product.course_cohort_id || null
                };

                if (confirm("Are you sure?")) {
                    LMWordpress.editlicensetype(sendData).then(function (response) {

                        LMAPImarketCache.reset();

                        $state.reload();

                    }).catch(function (err) {
                        console.log(err);
                    })
                }
            }

            // disable field 'license type' 
            // when the end date is less than today's date or is equal to zero 
            // when several identical courses, to ban editing all but the last
            function vmIsDisabled(products, product) {

                var sameProduct = products.filter(function (x) {
                    return x.course_id === product.course_id;
                });
                if (sameProduct.length > 1) {

                    sameProduct = sameProduct.sort(function (a, b) {
                        return a.date_end - b.date_end;
                    });

                    sameProduct.forEach(function (x, i) {
                        if (i != sameProduct.length - 1) {
                            x.disabled = true;
                        } else {
                            x.disabled = false;
                        }

                    });
                }
                return product.date_end < $scope.currentDateServerUnix || product.date_end == null || product.disabled;
            }

            $scope.status = vmStatus;
            $scope.product = vmProduct;
            $scope.license = vmLicense;
            $scope.filterProduct = vmFilterProduct;
            $scope.filterLicense = vmFilterLicense;
            $scope.getDate = vmGetDate;
            $scope.openCourse = vmOpenCohorts;
            $scope.saveLicenseType = vmSaveLicenseType;
            $scope.getPeriod = vmGetPeriod;
            $scope.isDisabled = vmIsDisabled;
            $scope.filterSubscription = vmFilterSubscription;
        })

})();