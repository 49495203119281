(function () {
  'use strict';

  angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

      $stateProvider
        .state('app.admin.school.certificates', {
          url: '/certificates',
          controller: 'adminCertificatesCtrl',
          templateUrl: '/assets/ng1/admin/certificates/index.html'
        })
        .state('app.admin.school.cert_details', {
          url: '/certificate_details/:usertoken',
          controller: 'adminCertificateDetailsCtrl',
          templateUrl: '/assets/ng1/admin/certificates/details.html',
        });
    }])
    .controller('adminCertificatesCtrl', function ($scope, classes, $stateParams, commonService) {
      $scope.$emit('adminTabChange', 'certificates');

      $scope.schoolId = commonService.getCurrentSchool();
      $scope.classes = classes;
    })
    .controller('adminCertificateDetailsCtrl', function ($scope, $stateParams) {
      $scope.usertoken = $stateParams.usertoken;
    })
})();