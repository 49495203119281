(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.projectadmin.page.availabledistrictadmins', {
                    url: '/availabledistrictadmins',
                    controller: 'availableDistrictAdminsCtrl',
                    templateUrl: '/assets/ng1/projectadmin/page/districtadmins/districtadmins.html'
                })
        }])

        .controller('availableDistrictAdminsCtrl', function ($scope,  $state, $stateParams) {

            $scope.$emit('modeChange', 'page');
            $scope.$emit('leftTabs', 'availabledistrictadmins');

            $scope.onUpdateAdmin = onUpdateAdmin;
            $scope.onCreateAdmin = onCreateAdmin;
            $scope.onInviteDistrictAdmin = onInviteDistrictAdmin;

            function onUpdateAdmin(admin) {
                $state.go('app.projectadmin.page.createdisadmin', { username: admin.username });
            }
            function onCreateAdmin() {
                $state.go('app.projectadmin.page.createdisadmin');
            }
            function onInviteDistrictAdmin() {
                $state.go('app.projectadmin.page.invitedisadmin', { page: 'districtadmin' });
            }
        })
})();