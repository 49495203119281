(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', 'LMConfig', function ($stateProvider, LMConfig) {

            $stateProvider
                .state('app.library.report.certificates', {
                    url: '/certificates',
                    controller: 'libraryReportCertificatesCtrl',
                    templateUrl: '/assets/ng1/library/report/certificates.html'
                })
        }])

        .controller('libraryReportCertificatesCtrl', function ($scope, LMAPI, LMConfig, token, $filter, LMWordpress) {

            $scope.config = LMConfig;
            // throw side tab change
            $scope.$emit('sideTabChange', 'certificates');
            $scope.$emit('activeTabTop', token);

            if (token) {
                LMWordpress.certificates(token).then(function (response) {

                    $scope.certificates = response.data;

                    $scope.certificates.forEach(function (item) {

                        item.date = new Date(item.timemodified * 1000);

                        item.$certificate_raw = item.certificate_raw ? JSON.parse(item.certificate_raw) : {};

                    });

                })
            }
            function vmOpenCertificates(url) {

                let secureUrl = vmGetSignedURL(url); // secure url from field 'External Content'

                window.open(secureUrl, '_blank');
            }

            function vmGetSignedURL(url) {

                // find Amazon S3 bucket from URL
                // check two possible positions for it
                var buckets = url.match('https://([a-z\-]*).?s3[\.a-z0-9\-]*.amazonaws.com/([a-z\-]*)');
                var bucket = buckets[1] || buckets[2];

                // find key portion in Amazon S3 URL
                // exclude root and bucket
                var key = url.match('amazonaws.com(/' + bucket + ')?/([A-Za-z0-9\-\/\._ ]*)')[2];

                // get signed URL
                var s3 = new AWS.S3({ signatureVersion: "v4" });
                return s3.getSignedUrl('getObject', { Bucket: bucket, Key: key });
            };

            $scope.openCertificates = vmOpenCertificates;

        })

})();