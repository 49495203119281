angular.module('LMConfig')

    .constant('LMProjectConfig', {

        get properties() {

            return {
                fr: {
                    localization: [{ key: 'en', title: 'English', file: 'en' }],
                    languaCards: false,
                    agent_key: null,
                    logo: 'Rev Learning Suite',
                    notifications: true,
                    fingerprintMandatory: false,
                    checkIndividualLicense: false,
                    searchGroup: { topic: ['er', 'fr'], genre: 'school', level: 'cefr' }
                },
                tr: {
                    localization: [{ key: 'en', title: 'English', file: 'en' }],
                    languaCards: false,
                    agent_key: null,
                    logo: 'Rev Learning Suite',
                    notifications: true,
                    fingerprintMandatory: false,
                    checkIndividualLicense: false,
                    searchGroup: { topic: ['er', 'fr'], genre: 'school', level: 'cefr' }
                },
                uonline: {
                    localization: [{ key: 'en', title: 'English', file: 'en' }],
                    languaCards: false,
                    agent_key: null,
                    logo: 'Rev Learning Suite',
                    notifications: true,
                    fingerprintMandatory: false,
                    checkIndividualLicense: false,
                    searchGroup: { topic: ['er', 'fr'], genre: 'school', level: 'cefr' }
                }
            }
        },
    });