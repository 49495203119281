(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider

        .state('app.teacher.tasks.tadd', {
            url: '/tadd?:state',
            controller: 'teacherAddTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/addtask/add.html'
        })
    }])

    .controller('teacherAddTaskCtrl', function ($scope, commonService, token, LMAPI, $stateParams, user, $state, tasks, credentials, uuid, school) {

        $scope.$emit('sideTabChange', $stateParams.state);
        
        $scope.errors = {
            errorBegin: null,
            errorEnd: null
        };

        function vmSaveTask(title, dateBegin, dateEnd, description) {

            // create unique idnumber
            var idnumber = uuid.newguid();
            $scope.idnumber = idnumber;

            let end = dateEnd ? commonService.convertLocalDateToUTCUnix(dateEnd) : null;
            let start = dateBegin ? commonService.convertLocalDateToUTCUnix(dateBegin) : null;

            LMAPI.addtask(credentials.session_token, null, idnumber, title, user.project, 'task', start, end, description, school.id).then(function () {

                LMAPI.singletask(credentials.session_token, idnumber, school.id).then(function (res) {
              
                    tasks.push({
                    assignment_status: 'missing_books_students',
                    content_id: res.data.content_id,
                    description: res.data.description,
                    enddate: res.data.enddate,
                    id: res.data.id,
                    idnumber: res.data.idnumber,
                    project: res.data.project,
                    school: res.data.school,
                    startdate: res.data.startdate,
                    taskID: res.data.taskID,
                    title: res.data.title,
                    type: res.data.type,
                    userid: res.data.userid,
                    teach_firstname: user.firstname,
                    teach_lastname: user.lastname,
                    teach_username: credentials.username,
                    teacher: {id: res.data.userid, username: credentials.username, firstname: user.firstname, lastname: user.lastname}
                    });
                    window.localStorage.removeItem('cacheQuest');
                    
                    $scope.msg = 'Saved';

                    if (localStorage.getItem('mode') === 'schooladmin') {
                        $state.go('app.admin.school.tasks.tupdate12', { task: $scope.idnumber, page: 'fromCreateQuest', state: $stateParams.state});
                    } else {
                        $state.go('app.teacher.tasks.tupdate12', { task: $scope.idnumber, page: 'fromCreateQuest', state: $stateParams.state });
                    }                 

                }).catch(function (error) {

                    $scope.msg = 'Error';
                });
            }).catch(function (error) {

                $scope.msg = 'Error';

            })
        }

        function vmBack() {
            if (localStorage.getItem('mode') === 'schooladmin') {
                $state.go('app.admin.school.tasks.list', { state: $stateParams.state });
            } else {
                $state.go('app.teacher.tasks.list', { state: $stateParams.state });
            }           
        }

        function vmCheckDates(begin, end){

            let $begin = begin ? commonService.convertLocalDateToUTCUnix(begin) : null;
            let $end = end ? commonService.convertLocalDateToUTCUnix(end) : null;

            if ($end < $begin) {
                $scope.errorDates = true;
            } else {
                $scope.errorDates = false;
            }
        }

        $scope.saveTask = vmSaveTask;
        $scope.back = vmBack;
        $scope.checkDate = vmCheckDates;
    })
})();