(function () {
    'use strict';

angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.root.districtadmin.district', {
                url: '/district',
                controller: 'districtCtrl',
                templateUrl: '/assets/ng1/districtadmin/district/district.html',
                deepStateRedirect: {
                    default: { state: 'app.root.districtadmin.district.schools' },
                    params: true
                }
            })          
    }])

    .controller('districtCtrl', function ($scope, $state, notifications) {

        $scope.notifications = notifications;
        $scope.isDistrict = sessionStorage.getItem("current_district_idnumber");
        $scope.$on('leftTabs', function (event, data) {
            $scope.activeTab = data;
        });
  
        $scope.$on('newNotifications', function (event, data) {
            $scope.unreadNotifiCount = data;

            if ($scope.unreadNotifiCount && $scope.unreadNotifiCount > 0){
              
                $state.go('app.root.districtadmin.district.notifications');
            }
        });
       
    })
})();