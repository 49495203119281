(function () {
    'use strict';
  
    angular.module('languabooksApp')
  
      .config(['$stateProvider', function ($stateProvider) {
  
        $stateProvider
        .state('app.admin.school.users.importteachers', {
          url: '/import/page/:mode',
          controller: 'importCtrl',
          templateUrl: '/assets/ng1/admin/import/importTeacher/importTeacher.html',
          params: {
            mode: null
          },
          })
      }])
  
    .controller('importCtrl', function ($scope, $rootScope, $q, FRAPI, importservice, LMWordpress, user, $translate, $stateParams) {

      $scope.$emit('adminTabChange', 'teacher');
      
      $scope.disableBtn = true;
      $scope.disableAddStudents = false;
      $scope.isMessage = false;
      
        function vmAddTeachers(people) {

            FRAPI.addTeacherBulk(people).then(function (response) {
    
              var resObjArr = response.data;
    
              $scope.importList.forEach(function (person) {
    
                person.state = $translate.instant('teacher.import.saved');
    
                var studResObj = resObjArr.find(function (ro, i) {
                  return ro.username == person.username;
                });
    
                resObjArr = resObjArr.filter((x) => x.token !== studResObj.token);
    
                person.invalid = studResObj.result.addTeacher || studResObj.result.addToSchool || studResObj.result.addLicenses;
                if (studResObj.result.addTeacher) {
                  person.state = $translate.instant('common.forms.errorserverteacher');
                } else if (studResObj.result.addToSchool) {
                  person.state = $translate.instant('common.forms.errorserverschoolteacher');
                } else if (studResObj.result.addLicenses) {
                  person.state = $translate.instant('common.forms.errorserverlicenseteacher');
                }
    
                if (!person.invalid) {
                  FRAPI.cleargetUsersFromCohort();    
                  $scope.testMessageAboveTable = response.data.some((x) => x.result.error !== 0);            
                  $scope.isMessage = true;
                  $rootScope.pageLoading = false;
                  if (!$scope.$$phase) {
                    $scope.$apply();
                  }
                }
                localStorage.setItem('import_old_angular', 'import');
              });
    
    
              $rootScope.pageLoading = false;
              if (!$scope.$$phase) {
                $scope.$apply();
              }
            })
            .catch(function (error) {
              $scope.importList.forEach((teacher) => {
                teacher.invalid = true;
                teacher.state = $translate.instant('common.forms.errorserverschool');
              });
              $rootScope.pageLoading = false;
              if (!$scope.$$phase) {
                $scope.$apply();
              }
            });
        }
        function vmDownloadTemplate() {

          let list = [{
            username: null,
            password: null,
            firstname: null,
            lastname: null,
            voice_model: null,
            lang: null,
            email: null
          }];
          // export json to Worksheet of Excel
          let listWS = XLSX.utils.json_to_sheet(list);
    
          // A workbook is the name given to an Excel file
          var wb = XLSX.utils.book_new();
    
          // add Worksheet to Workbook
          // Workbook contains one or more worksheets
          XLSX.utils.book_append_sheet(wb, listWS);
    
          let namefile = $translate.instant('teacher.import.template');
    
          // export Excel file
          XLSX.writeFile(wb, namefile);
        }

        function vmExportToExsel() {

          $rootScope.pageLoading = true;
          if (!$scope.$$phase) {
            $scope.$apply();
          }
    
          let list = $scope.importList.map((x) => {
            return {
              username: x.username,
              password: x.password,
              firstname: x.firstname,
              lastname: x.lastname,
              voice_model: x.age || x.voice_model,
              lang: x.lang,
              email: x.email
            }
          });
          // export json to Worksheet of Excel
          let listWS = XLSX.utils.json_to_sheet(list);
    
          // A workbook is the name given to an Excel file
          var wb = XLSX.utils.book_new();
    
          // add Worksheet to Workbook
          // Workbook contains one or more worksheets
          XLSX.utils.book_append_sheet(wb, listWS);
    
          XLSX.writeFile(wb, 'teachers.xlsx');
            
          $rootScope.pageLoading = false;
          if (!$scope.$$phase) {
            $scope.$apply();
          }
        }

        function vmLoadExcel(data, fileData) {

          $scope.disableAddStudents = false;
          $scope.isMessage = false;
          $rootScope.pageLoading = true;
          if (!$scope.$$phase) {
            $scope.$apply();
          }
    
          $scope.attachment = {
            data: fileData.data,
            name: fileData.name,
            type: fileData.type
          };
    
          // load workbook
          // and first sheet from it
          var workbook = XLSX.read(data, { type: 'binary' });
          var sheet = workbook.Sheets[workbook.SheetNames[0]];
    
          // convert sheet to json list
          $scope.importList = XLSX.utils.sheet_to_json(sheet).map(function (item) {
            // trim all fields from excel
            for (var property in item) {
              if (item.hasOwnProperty(property) && typeof item[property] === 'string')
                item[property] = item[property].trim();
            }
    
            item.password ? importservice.vmCheckPassword(item) : importservice.vmGeneratePassword(item);
    
            item.lang = item.lang ? importservice.vmCheckLang(item, user) : 'en';
            item.age || item.voice_model ? importservice.vmCheckAge(item, user) : importservice.vmAgeEmpty(item);
    
            item.def = item.username ? importservice.vmCheckUsername(item) : vmErrorEmptyUsername(item);    
  
            // set payment status if change_password is set or default ('paid')
            importservice.vmCheckChangePassword(item);
    
            item.email ? importservice.vmCheckEmail(item) : importservice.vmCheckEmail(item);
            !item.firstname ? importservice.vmCheckName(item, 'firstname') : null;
            !item.lastname ? importservice.vmCheckName(item, 'lastname') : null;
    
            return item;
          });
    
          // check user names
          $q.all($scope.importList.map((x) => x.def))
            .then(() => {
    
              let usernames = $scope.importList.map((item) => { return item.username });
    
              let isUnique = importservice.onCheckUniqueUser(usernames, $scope.importList);
    
              if (!isUnique) {
    
                var usernamesToCheckOnServer = $scope.importList
                  .filter(function (i) { return i.checkUsernameOnServer; })
                  .map(function (i) { return i.username; });
    
                if (usernamesToCheckOnServer.length) {
                  FRAPI.userexistsBulk(usernamesToCheckOnServer)
                    .then(function (response) {
                      var existingUsers = response.data.filter(function (user) { return user.exists; })
                      existingUsers.forEach(function (eu) {
                        var item = $scope.importList.find(function (i) { return i.username === eu.login; });
                        item.invalid = true;
                        item.state = $translate.instant('common.forms.errorexists');
                      });
                      vmOnChecksFinish();
                    });
                } else {
                  vmOnChecksFinish();
                }
              } else {
                vmOnChecksFinish();
              }
    
            });
    
       
    
          function vmOnChecksFinish() {
            $scope.disableBtn = $scope.importList.some((x) => !!x.invalid);

            $rootScope.pageLoading = false;
            if (!$scope.$$phase) {
              $scope.$apply();
            }
            // restructure header table to the correct position
            $('table.demo').floatThead('reflow');
          }   
    
     
        };
        function vmAccept() {

          $scope.disableAddStudents = true;
          // download file if password or username was generated and didnt have mistakes
          let download = $scope.importList.some((x) => !x.invalid && x.download);
    
          if (download) {
            vmExportToExsel();
          }
    
          const people = {
            addToClass: $stateParams.currentClass || null,
            people: []
          };
    
          $rootScope.pageLoading = $scope.importList.length;
          $scope.importList.forEach((teacher) => {
           
            const person = {
              school: $stateParams.school,
              username: teacher.username,
              password: teacher.password,
              firstname: teacher.firstname,
              lastname: teacher.lastname,
              age: teacher.age || teacher.voice_model,
              lang: teacher.lang,
              email: teacher.email,
              payment_status: teacher.payment_status
            };
  
    
            people.people.push(person);
          });
    
          vmAddTeachers(people);  
    
        }

        function vmBack() {
            window.history.back();
        }
        $scope.exportToExsel = vmExportToExsel;
        $scope.loadExcel = vmLoadExcel;
        $scope.downloadTemplate = vmDownloadTemplate;
        $scope.vmAccept = vmAccept;
        $scope.back = vmBack;
    
    })
})();