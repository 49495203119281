(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.teacher.tasks.dublicate', {

            url: '/copy/:task?:page?:state',
            params:{
                dublicateBooks: null,
                dublicateStudents: null,
                originalIdnumber: null,
                taskEnd: null,
                taskBegin: null,
                description: null,
                title: null
            },
            controller: 'teacheDublicateTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/edit.html'
        })
    }])
    .controller('teacheDublicateTaskCtrl', function ($scope, $q, LMAPI, uuid, $stateParams, $state, tasks, credentials, user, school, commonService) {
        
        if (!$stateParams.originalIdnumber) { vmBack(); return; };

        var idnumber = $stateParams.task;
        $scope.originalIdnumber = $stateParams.originalIdnumber;    
        $scope.isDisabled = true;    
        $scope.globalValue_courses = [];   
        $scope.globalValue_users = [];   
       
        $scope.$on('editTaskTab', function (event, data) {
            $scope.activeTab = data;
        });
        $scope.$on('changeAssignmentStatus', function(event, data){
            $scope.task.assignment_status = data;
        });
        $scope.$on('taskSave', function (event, task) {
            $scope.task.title = task.title;
            $scope.task.dBegin = task.startdate ? commonService.getLocaleDate(task.startdate) : null;
            $scope.task.dEnd = task.enddate ? commonService.getLocaleDate(task.enddate) : null;
        });

        vmSaveTask().then((res) => {
            let promise = [];

            if ($stateParams.dublicateBooks && !$stateParams.dublicateStudents){ 
                promise = [vmAssignCourseToTask()]; 
            }
            if (!$stateParams.dublicateBooks && $stateParams.dublicateStudents){ 
                promise = [vmAssignStudentToTask()];
            }
            if ($stateParams.dublicateBooks && $stateParams.dublicateStudents){ 
                promise = [vmAssignCourseToTask(), vmAssignStudentToTask()];
            }

            $q.all(promise).then((resp) => {

                tasks.forEach(function (item) {
                    if ($stateParams.task == item.idnumber) {
    
                        item.assignment_status = vmSetAssignmentsStatusCourses('missing_books_students', $scope.globalValue_courses);
                                        
                        item.assignment_status = vmSetAssignmentsStatusUsers(item.assignment_status, $scope.globalValue_users);
    
                        $scope.$emit('changeAssignmentStatus', item.assignment_status);
                        return;
                    }
                })
                vmOpenTabBooks();
            })                   
        })

        function vmSaveTask() {

            return new Promise((resolve, reject) => {

                let end = $stateParams.taskEnd ? commonService.convertLocalDateToUTCUnix($stateParams.taskEnd) : null;
                let start = $stateParams.taskBegin ? commonService.convertLocalDateToUTCUnix($stateParams.taskBegin) : null;

                LMAPI.addtask(credentials.session_token, null, idnumber,  $stateParams.title, user.project, 'task', start, end,  $stateParams.description, school.id).then(function () {

                    LMAPI.singletask(credentials.session_token, idnumber, school.id).then(function (res) {
                   
                        res.data.teach_firstname = user.firstname;
                        res.data.teach_lastname = user.lastname;   
                        res.data.teach_username = credentials.username,                  
                        res.data.teacher = {
                            firstname: user.firstname,
                            id: res.data.userid,
                            lastname: user.lastname,
                            username: credentials.username
                        }
                        res.data.assignment_status = null;

                        tasks.push(res.data);

                        let _tasks = angular.copy(tasks);
                        $scope.task = _tasks.find((x) => x.idnumber == idnumber);

                        window.localStorage.removeItem('cacheQuest');

                        vmDisplayDates();

                        $scope.msg = 'Saved';
                        resolve('Succes');
    
                    }).catch(function (error) {
    
                        $scope.msg = 'Error';
                    });
                }).catch(function (error) {
    
                    $scope.msg = 'Error';
    
                })
            })

            
        }
        function vmDisplayDates() {

            let end = $stateParams.taskEnd ? commonService.convertLocalDateToUTCUnix($stateParams.taskEnd) : null;
            let start = $stateParams.taskBegin ? commonService.convertLocalDateToUTCUnix($stateParams.taskBegin) : null;
            $scope.task.dBegin = start ? commonService.getLocaleDate(start) : null;
            $scope.task.dEnd = end ? commonService.getLocaleDate(end): null;

            // for display dates on screen
            if (end) {
                let DataFromUnicx_End = moment.unix(end).toDate();
                $scope.task.date_end = new Date(DataFromUnicx_End.getUTCFullYear(), DataFromUnicx_End.getUTCMonth(), DataFromUnicx_End.getUTCDate());
            } else {
                $scope.task.date_end = null;
            }
        
            if (start) {
                let DataFromUnicx_Begin = moment.unix(start).toDate();
                $scope.task.date_begin = new Date(DataFromUnicx_Begin.getUTCFullYear(), DataFromUnicx_Begin.getUTCMonth(), DataFromUnicx_Begin.getUTCDate());
            } else {
                $scope.task.date_begin = null;
            }

        }
        function vmAssignCourseToTask() {

            return new Promise((resolve, reject) => {
                LMAPI.taskCourses.allcourses(credentials.session_token, $stateParams.originalIdnumber).then(function (response) {
      
                    let idcourses = response.data.map((x) => x.id);
                    $scope.globalValue_courses = idcourses;

                    if (idcourses.length > 0) {
                        LMAPI.taskCourses.addcourses(credentials.session_token, idnumber, idcourses).then(function () {
          
                            $scope.error = false;

                            resolve('Succes');
              
                        }).catch(function () {
              
                            $scope.error = true;
                            resolve('Succes');
                        });
                    } else {
                        $scope.error = false;
                        resolve('Succes');
                    }

                });
            })
        }
        function vmAssignStudentToTask() {

            return new Promise((resolve, reject) => {

                LMAPI.taskStudents.allstudents(credentials.session_token, $stateParams.originalIdnumber).then(function (response) {

                    var usernames = response.data.map((x) => x.login);
                    $scope.globalValue_users = usernames;
                    if (usernames.length > 0) {
                        LMAPI.taskStudents.addstudents(credentials.session_token, idnumber, usernames).then(function (res) {
            
                            $scope.error = false;

                            resolve('Succes');

                        }).catch(function () {
                
                            $scope.error = true;
                            resolve('Succes');
                        });
                    } else {
                        resolve('Succes');
                    }

                });
            })
        } 
        function vmSetAssignmentsStatusCourses(assignment_status, coursesInTask) {

            switch (assignment_status) {
                case 'missing_books_students':       
    
                       if (coursesInTask.length > 0) {
                        return 'missing_students';
                       } else {
                        return 'missing_books_students';
                       }
                case 'missing_books': 
    
                    if (coursesInTask.length > 0) {
                        return 'ready';
                    } else {
                        return 'missing_books';
                    }        
                case 'missing_students':
    
                    if (coursesInTask.length > 0) {
                        return 'missing_students';
                    } else {
                        return 'missing_books_students';
                    }    
                case 'ready':  
                    if (coursesInTask.length > 0) {
                        return 'ready';
                    } else {
                        return 'missing_books';
                    }       
            }
          
        }
        function vmSetAssignmentsStatusUsers(assignment_status, usersInTaskList) {

            switch (assignment_status) {
                case 'missing_books_students':       
    
                       if (usersInTaskList.length > 0) {
                        return 'missing_books';
                       } else {
                        return 'missing_books_students';
                       }
                case 'missing_books': 
    
                    if (usersInTaskList.length > 0) {
                        return 'missing_books';
                    } else {
                        return 'missing_books_students';
                    }                      
                   
                case 'missing_students':
    
                    if (usersInTaskList.length > 0) {
                        return 'ready';
                    } else {
                        return 'missing_students';
                    }    
                   
                case 'ready':  
                    if (usersInTaskList.length > 0) {
                        return 'ready';
                    } else {
                        return 'missing_students';
                    }                     
                  
            }
          
        }       
        function vmUpdateTask(title, start, end, description) {

            $scope.start = start ? commonService.convertLocalDateToUTCUnix(start) : null;
            $scope.end = end ? commonService.convertLocalDateToUTCUnix(end) : null;

            LMAPI.updatetask(credentials.session_token, $scope.task.id, $scope.task.idnumber, title, user.project, 'task', $scope.start, $scope.end, description, school.id, $scope.task.userid).then(function (res) {

                tasks.forEach(function (item) {
                    if (idnumber == item.idnumber) {

                        item.title = title,
                        item.description = description,
                        item.startdate = $scope.start,
                        item.enddate = $scope.end
                    }
                })
                window.localStorage.removeItem('cacheQuest');
                $scope.msg = 'Saved';
                vmBack();
            }).catch(function (error) {
                $scope.msg = 'Error';
            })

        }
        function vmBack() {
            if (localStorage.getItem('mode') === 'schooladmin') {
                $state.go('app.admin.school.tasks.list', { id: $stateParams.id, state: $stateParams.state });
            } else {
                $state.go('app.teacher.tasks.list', { id: $stateParams.id, state: $stateParams.state });
            }
           
        }
        function vmOpenTabBooks() {
        
            let mode = localStorage.getItem('mode');

            if (mode === 'schooladmin') {

                if ($stateParams.page === 'fromLibrary') {

                    $state.go('app.admin.school.tasks.dublicate.book');

                } else if ($stateParams.page === 'fromListQuest') {

                    $state.go('app.admin.school.tasks.dublicate.edit');

                } else if ($stateParams.page === 'fromCreateQuest') {

                    $state.go('app.admin.school.tasks.dublicate.user');
                }
            } else if (mode === 'teacher') {

                if ($stateParams.page === 'fromLibrary') {

                    $state.go('app.teacher.tasks.dublicate.book');

                } else if ($stateParams.page === 'fromListQuest') {

                    $state.go('app.teacher.tasks.dublicate.edit');

                } else if ($stateParams.page === 'fromCreateQuest') {

                    $state.go('app.teacher.tasks.dublicate.user');
                }               
            }   
        }
        function vmCheckDates(begin, end){

            let $begin = begin ? commonService.convertLocalDateToUTCUnix(begin) : null;
            let $end = end ? commonService.convertLocalDateToUTCUnix(end) : null;

            if ($end < $begin) {
                $scope.errorDates = true;
            } else {
                $scope.errorDates = false;
            }
        }
        function vmChangeTab(page, originalIdnumber) {
            if (localStorage.getItem('mode') === 'schooladmin') {
                if (originalIdnumber) {
                    switch (page){
                        case 'edit': {
                            $state.go('app.admin.school.tasks.dublicate.edit');
                            return;
                        }
                        case 'book': {
                            $state.go('app.admin.school.tasks.dublicate.book');
                            return;
                        }
                        case 'user': {
                            $state.go('app.admin.school.tasks.dublicate.user');
                            return;
                        }
                        case 'detailreport': {
                            $state.go('app.admin.school.tasks.dublicate.detailreport');
                            return;
                        }
                        case 'progressreport': {
                            $state.go('app.admin.school.tasks.dublicate.progressreport');
                            return;
                        }
                    }
                } else {
                    switch (page){
                        case 'edit': {
                            $state.go('app.admin.school.tasks.tupdate12.edit');
                            return;
                        }
                        case 'book': {
                            $state.go('app.admin.school.tasks.tupdate12.book');
                            return;
                        }
                        case 'user': {
                            $state.go('app.admin.school.tasks.tupdate12.user');
                            return;
                        }
                    }
                }
            } else {
                if (originalIdnumber) {
                    switch (page){
                        case 'edit': {
                            $state.go('app.teacher.tasks.dublicate.edit');
                            return;
                        }
                        case 'book': {
                            $state.go('app.teacher.tasks.dublicate.book');
                            return;
                        }
                        case 'user': {
                            $state.go('app.teacher.tasks.dublicate.user');
                            return;
                        }
                        case 'detailreport': {
                            $state.go('app.teacher.tasks.dublicate.detailreport');
                            return;
                        }
                        case 'progressreport': {
                            $state.go('app.teacher.tasks.dublicate.progressreport');
                            return;
                        }
                    }
                } else {
                    switch (page){
                        case 'edit': {
                            $state.go('app.teacher.tasks.tupdate12.edit');
                            return;
                        }
                        case 'book': {
                            $state.go('app.teacher.tasks.tupdate12.book');
                            return;
                        }
                        case 'user': {
                            $state.go('app.teacher.tasks.tupdate12.user');
                            return;
                        }
                    }
                }
            }      

        }
        function vmDeleteTask(task) {
            if (confirm('Are you sure?')) {

                LMAPI.deletetask(credentials.session_token, task.idnumber).then(function (response) {
                    tasks.forEach(function (item, i) {
                        if (item.idnumber == task.idnumber) {
                            tasks.splice(i, 1);
                        }
                    })
                    vmBack();
                })
            }
        }
        function vmOpenPopupTask(task) {

            $scope.task = angular.copy(task);
            $scope.task.$idnumber = uuid.newguid();
            $scope.popupTask = true;

        }
        function vmGoStateTodublicate(task, dublicateBooks, dublicateStudents) {
            if (localStorage.getItem('mode') === 'schooladmin') {
                $state.go('app.admin.school.tasks.dublicate', {task: task.$idnumber, 
                                                        originalIdnumber: task.idnumber,
                                                        taskEnd: task.$dateEnd,
                                                        taskBegin: task.$dateBegin,
                                                        description: task.description,
                                                        title: task.title,
                                                        dublicateBooks: dublicateBooks, 
                                                        dublicateStudents: dublicateStudents,
                                                        page: 'fromCreateQuest',
                                                        state: 'current' });
            } else {
                $state.go('app.teacher.tasks.dublicate', {task: task.$idnumber, 
                                                            originalIdnumber: task.idnumber,
                                                            taskEnd: task.$dateEnd,
                                                            taskBegin: task.$dateBegin,
                                                            description: task.description,
                                                            title: task.title,
                                                            dublicateBooks: dublicateBooks, 
                                                            dublicateStudents: dublicateStudents,
                                                            page: 'fromCreateQuest',
                                                            state: 'current' });
            }
        }
        function vmClosePopup() {

            $scope.popupTask = false;
        }
        function getAssignmentsStatus(status) {

            switch (status) {
                case 'missing_books_students':                       
                    return 'Please add books and students to finish';      
                case 'missing_books':                       
                    return 'Please add books to finish';
                case 'missing_students':
                    return 'Please add students to finish';
                case 'ready':                       
                    return 'The assignment is ready';
            }
        }
        $scope.getAssignmentsStatus = getAssignmentsStatus;
        $scope.OpenPopupTask = vmOpenPopupTask;
        $scope.deletetask = vmDeleteTask;    
        $scope.updateTask = vmUpdateTask;
        $scope.back = vmBack;
        $scope.checkDate = vmCheckDates;
        $scope.changeTab = vmChangeTab;
        $scope.goStateTodublicate = vmGoStateTodublicate;
        $scope.closePopup = vmClosePopup;
    })
})();