(function () {
    'use strict';
    
    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider       
        .state('app.admin.school.tasks.tupdate12.book', {

            url: '/book',
            controller: 'teacherBookTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/assignbook/book.html'
        })     
        .state('app.admin.school.tasks.dublicate.book', {

            url: '/book',
            controller: 'teacherBookTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/assignbook/book.html'
        })     
    }])
})();