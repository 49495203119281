(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', 'LMConfig', function ($stateProvider, LMConfig) {

        $stateProvider
            .state('app.profile.edit.orders', {
                url: '/orders',
                controller: 'profileOrdersCtrl',
                templateUrl: '/assets/ng1/profile/orders/orders.html'
            })
    }])
      .controller('profileOrdersCtrl', function ($scope, $state, token, LMWordpress, user, LMAPImarketCache, coursesService) {

        $scope.$emit('sideTabChange', 'orders');

        LMAPImarketCache.getorders().then(function (response) {

            $scope.orders = response;
            $scope.orders.forEach(function (item) {
                item.price = parseFloat(item.price).toFixed(2);
                item.order_date = moment(item.order_date).format('MM.DD.YYYY');
            });
        });
        LMAPImarketCache.getordersstatus().then(function (response) {

            $scope.orderstatus = response;
            $scope.orderstatus.push({ option_name: 'All' });
        })

        function vmGetTotal(orders) {
            var total = orders.map(x => parseFloat(x.price)).reduce(function (previousValue, currentValue) {
                return previousValue + currentValue;
            });
            return total.toFixed(2);
        }

        function vmOrderBy(id) {
            $scope.key = id;
        }

        // open cohorts curriculum
        function vmOpenCohorts(course) {

            switch (course.vms_curriculum_type) {
                case 'categories':

                    // open categories curriculum
                    $state.go('app.library.categories', { id: course.idnumber, back: $state.href($state.current.name, $state.current.params) });
                    break;

                case 'cohorts':

                    // open cohort curriculum
                    $state.go('app.library.cohorts', { id: course.idnumber, back: $state.href($state.current.name, $state.current.params) });
                    break;

                default:

                    coursesService.runTest(token, course.idnumber, null, $state.href($state.current.name, $state.current.params));
            }
        }
        function vmStatus(o, i, a) {

            if (!$scope.key || $scope.key == 'All') return true;

            var filter = $scope.key.split(' ');
            var keys = ['status_name'];

            return filter.every(function (f) {

                return keys.some(function (key) {

                    return this[key].indexOf(f) > -1;
                }, o);
            });

        }

        $scope.getTotal = vmGetTotal;
        $scope.order = vmOrderBy;
        $scope.status = vmStatus;
        $scope.openCourse = vmOpenCohorts;
    })
})();