(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.admin.school.tasks.tupdate12.user', {
            url: '/users',
            controller: 'teacherUserTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/assignstudent/users.html'
        })
        .state('app.admin.school.tasks.dublicate.user', {
            url: '/users',
            controller: 'teacherUserTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/assignstudent/users.html'
        })
    }])
})();