(function () {
    'use strict';

angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.projectadmin.page.schools', {
                url: '/schools',
                controller: 'pageSchoolsCtrl',
                templateUrl: '/assets/ng1/projectadmin/page/schools/listSchools.html'
            })
            .state('app.projectadmin.page.school', {
                url: '/create/?:school',
                controller: 'schoolCreateCtrl',
                templateUrl: '/assets/ng1/projectadmin/page/schools/createSchool.html'
            })
    }])

    .controller('pageSchoolsCtrl', function ($scope, $state) {

        $scope.$emit('modeChange', 'page');
        $scope.$emit('leftTabs', 'schools');

        function onEditSchool(school) {
            $state.go('app.projectadmin.page.school', { school: school.idnumber});
        }
        function onAssignUsers(school) {
            $state.go('app.projectadmin.page.admins', { school: school.idnumber, district: school.properties.district});
        }
        function onCreateSchool(e) {
            $state.go('app.projectadmin.page.school');
        }
        $scope.onEditSchool = onEditSchool;
        $scope.onAssignUsers = onAssignUsers;
        $scope.onCreateSchool = onCreateSchool;
    })
    .controller('schoolCreateCtrl', function ($scope, $state, $stateParams) {

        $scope.$emit('modeChange', 'page');
        $scope.$emit('leftTabs', 'schools');

        $scope.idschool = $stateParams.school ?  $stateParams.school : null;
 
        $scope.onBack = vmBack;

        function vmBack() {
            $state.go('app.projectadmin.page.schools');
        }
    })

})();