(function () {
    'use strict';

angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.root', {
                url: '/root',
                controller: 'rootDistAdminCtrl',
             //   templateUrl: '/assets/ng1/districtadmin/index.html',
                template: '<ui-view>',
                resolve: {
                    districtsByUser: function(FRAPI, credentials){
                        FRAPI.clearListDistrictsByUser();
                        return FRAPI.getDistrictsByUser().then((response) => {
                            return response.sort(function (a, b) {
                                if (a.name > b.name) {
                                    return 1;
                                }
                                if (a.name < b.name) {
                                    return -1;
                                }
                                return 0;
                            });
                        })
                    }, 
                    newNotifications: function(FRAPI, credentials){
                        FRAPI.clearNewNotifications();
                        return FRAPI.getNewNotifications().then((newNotifications) => {
                            
                            return newNotifications;
                        })
                    },  
                    notifications: function(FRAPI, credentials) {

                        return FRAPI.getUserNotifications().then(function (response) {
                                return response; 
                        });          
                    } 
                        
                },
                deepStateRedirect: {
                    default: { state: 'app.root.districtadmin' },
                    params: true
                },
            })
            .state('app.root.districtadmin', {
                url: '/districtadmin?:district',
                controller: 'districtadminCtrl',
                templateUrl: '/assets/ng1/districtadmin/index.html',
                resolve: {
                    newNotifications: function(FRAPI, credentials){                        
                        return FRAPI.getNewNotifications().then((newNotifications) => {
                            
                            return newNotifications;
                        })
                    },  
                },
                deepStateRedirect: {
                    default: { state: 'app.root.districtadmin.district' },
                    params: true
                },
            })
            .state('app.root.districtadmin.district.notifications', {
                url: '/notifications',
                controller: 'districtNotificationsCtrl',
                templateUrl: '/assets/ng1/districtadmin/notifications/notifications.html'
            })
    }])
    .controller('rootDistAdminCtrl', function ($state) {

     window.localStorage.setItem('mode', 'districtadmin');
    })

    .controller('districtadminCtrl', function ($scope, FRAPI, $state, user, LMConfig, commonService, $window, districtsByUser) {
  
        $window.document.title = "District Admin";
        // configuration
        $scope.config = LMConfig;
        // read user information
        $scope.user = user;
        // update logo and page title
        var logoObj = commonService.logo(user.project, 'projectadmin');
        $scope.logo = logoObj.logo;
        $scope.tm = logoObj.tm;
        

        $scope.$on('modeChange', function (event, data) {
            $scope.activeTab = data;
        });
        vmGetListDistrictsByUser();

        function onSelect($event) {
         
            FRAPI.clearNewNotifications();
            commonService.setCurrentDistrict($event);
            $state.go('app.root.districtadmin', { district: $event });
        }
        function vmGetListDistrictsByUser() {

            if (districtsByUser.length > 0) {
                // there are districts
                $scope.locations = districtsByUser;     

                if (!$state.params.district) {       
                    $state.params.district = districtsByUser[0].idnumber;   
                    commonService.setCurrentDistrict(districtsByUser[0].idnumber);                   
                            
                }
             } else {
                 alert("User don\'t have districts");
             }
        }
       
        $scope.onSelect = onSelect;
    })
    .controller('districtNotificationsCtrl', function ($scope, $state, newNotifications, FRAPI, user, $rootScope) {
        // throw event main tab change          
        $scope.$emit('modeChange', 'page');
        $scope.$emit('leftTabs', 'notifications');   
        $scope.$emit('adminTabChange', 'notifications');
        $scope.$emit('teacherModeChange', 'report');
        $scope.$emit('activeTabLeft', 'notifications');
        $scope.$emit('libraryTabChange', 'notifications');

        $scope.$on('EventCurrentTasks', function (event, data) {
            $rootScope.currentTasks = data;
            if ($rootScope.currentTasks && $rootScope.currentTasks.length > 0) {
                    $state.go('app.library.shelve.task');
            }
        });
        if (!$scope.$$phase) {
            $scope.$apply();
        }
        
        $scope.newNotifications = newNotifications;
        $scope.user = user;
        $scope.onSetReadNotification = onSetReadNotification;

        function onSetReadNotification(count) {
            FRAPI.clearNewNotifications();
            FRAPI.clearListUserNOtifications();
            setTimeout(function() {
                $scope.$emit('newNotifications', 0);
                $scope.$emit('unreadNotifiCount', 0);
            }, 2000);         

        } 
    })
})();