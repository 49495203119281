(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(function ($stateProvider) {

        $stateProvider
            .state('app.library.curriculum.cohorts', {
                url: '/cohorts',
                controller: 'cohortsCtrl',
                templateUrl: '/assets/ng1/library/curriculum/cohorts/index.html',
                resolve: {
                    cohorts: function (LMAPICache, token, course) {

                        var curriculum_level = 'curriculum_level';

                        return LMAPICache.cohorts(token, curriculum_level)
                                    .then(function (cohorts) {

                                        // get list of levels from current curriculum
                                        let levels = JSON.parse(course.cohorts);

                                        // return from available cohorts
                                        // only those which are levels
                                        return cohorts.filter(item => levels.some(level => level == item.id));
                                    });
                    },
                }
            })
            .state('app.library.curriculum.cohorts.shelve', {
                url: '/:cohort',
                controller: 'cohortsShelveCtrl',
                template: '<vms-course-list ng-model="courses" vms-token="token" vms-title="curriculum.name" vms-user="user"></vms-course-list>'
            })
            .state('app.teacher.curriculum.cohorts', {
                url: '/cohorts',
                controller: 'cohortsCtrl',
                templateUrl: '/assets/ng1/library/curriculum/cohorts/index.html',
                resolve: {
                    cohorts: function (LMAPICache, token, course) {

                        var curriculum_level = 'curriculum_level';

                        return LMAPICache.cohorts(token, curriculum_level)
                                    .then(function (cohorts) {

                                        // get list of levels from current curriculum
                                        let levels = JSON.parse(course.cohorts);

                                        // return from available cohorts
                                        // only those which are levels
                                        return cohorts.filter(item => levels.some(level => level == item.id));
                                    });
                    }
                }
            })
            .state('app.teacher.curriculum.cohorts.shelve', {
                url: '/:cohort',
                controller: 'cohortsShelveCtrl',
                template: '<vms-course-list ng-model="courses" vms-token="token" vms-title="curriculum.name" vms-user="user"></vms-course-list>'
            })
            .state('app.admin.school.curriculum.cohorts', {
                url: '/cohorts',
                controller: 'cohortsCtrl',
                templateUrl: '/assets/ng1/library/curriculum/cohorts/index.html',
                resolve: {
                    cohorts: function (LMAPICache, token, course) {

                        var curriculum_level = 'curriculum_level';

                        return LMAPICache.cohorts(token, curriculum_level)
                                    .then(function (cohorts) {

                                        // get list of levels from current curriculum
                                        let levels = JSON.parse(course.cohorts);

                                        // return from available cohorts
                                        // only those which are levels
                                        return cohorts.filter(item => levels.some(level => level == item.id));
                                    });
                    }
                }
            })
            .state('app.admin.school.curriculum.cohorts.shelve', {
                url: '/:cohort',
                controller: 'cohortsShelveCtrl',
                template: '<vms-course-list ng-model="courses" vms-token="token" vms-title="curriculum.name" vms-user="user"></vms-course-list>'
            })
    })

    .controller('cohortsCtrl', ['$scope', '$state', '$stateParams', 'LMAPI', 'LMConfig', 'course', 'token', 'nuts', 'cohorts', 'user', 'credentials', 'school', function ($scope, $state, $stateParams, LMAPI, LMConfig, course, token, nuts, cohorts, user, credentials, school) {

        // curriculum
        $scope.curriculum = course;
        $scope.cohorts = cohorts;
        $scope.user = user;
        $scope.username = credentials.username;
        $scope.school = school;
        $scope.onOpenQuest = onOpenQuest;
        $scope.onCreateQuest = onCreateQuest;
        $scope.mode = window.localStorage.getItem('mode');

        // throw event main tab change
        $scope.$emit('libraryTabChange', 'cohorts');

        // watch for cohorts tab changed
        $scope.$on('cohortsTabChange', function (event, data) {
            $scope.activeTab = data;
        });        

        // if cohort id is not defined
        // and cohort list is not empty
        // than open first one
        if (!$state.params.cohort && cohorts.length > 0) {

            cohorts.sort(function (a, b) {
                if (a.title.toLowerCase() > b.title.toLowerCase()) {
                    return 1;
                }
                if (a.title.toLowerCase() < b.title.toLowerCase()) {
                    return -1;
                }
                return 0;
            });

            $state.go('.shelve', { cohort: cohorts[0].id });
        };
        function onOpenQuest(quest) {

           // let mode = window.localStorage.getItem('mode');

            if ($scope.mode === 'teacher') {

                $state.go('app.teacher.tasks.tupdate12', { task: quest, page: 'fromLibrary', state: 'current' });
              
            } else if ($scope.mode === 'schooladmin') {

                $state.go('app.admin.school.tasks.tupdate12', { task: quest, page: 'fromLibrary', state: 'current' });
            
            }
        }
        function onCreateQuest() {
            if ($scope.mode === 'teacher') {

                $state.go('app.teacher.tasks.tadd', { state: 'current'});
              
            } else if ($scope.mode === 'schooladmin') {

                $state.go('app.admin.school.tasks.tadd', { state: 'current' });
            
            }
        }
    }])

    .controller('cohortsShelveCtrl', ['$scope', '$state', 'token', 'coursesService', function ($scope, $state, token, coursesService, course) {

        $scope.token = token;
        $scope.courses = null;

        // get current cohort
        var cohort = $state.params.cohort;        

        // throw event categories tab change
        $scope.$emit('cohortsTabChange', cohort);

        // load courses
        // by current cohort
        coursesService.get(token, null, cohort)
            .then(function (courses) {

                $scope.courses = courses.filter(x => x.executiontype == 'curriculum_unit' || x.executiontype == 'standalone_course');
                $scope.courses.forEach((x) => {
                    x.$course_state = x.course_state ? JSON.parse(x.course_state) : {}; 
                    x.isAssign = true; 
                })
             
            });

    }])

})();