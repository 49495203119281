(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.library', {
                url: '/library',
                controller: 'libraryCtrl',
                templateUrl: '/assets/ng1/library/index.html',
                onExit: function ($interval, $rootScope, FRAPI) {
                    
                    FRAPI.clearNewNotifications();
                   
                    if ($rootScope.countIntervalApiTask) {
                        $interval.cancel($rootScope.countIntervalApiTask);
                        $rootScope.countIntervalApiTask = undefined;
                    }
                  
                },
                resolve: {
                    nuts: function (LMAPI, token) {

                        return LMAPI.nuts(token)
                                    .then(function (scores) {

                                        var sum = 0;

                                        for (var score in scores) {

                                            if (scores.hasOwnProperty(score))
                                                if (scores[score] != null)
                                                    sum += parseInt(scores[score])
                                                else
                                                    sum = 0;
                                        };
                                        localStorage.setItem('general_coins', sum);
                                        return sum;
                                    });
                    },
                    productnumber: function (LMWordpress) {
                        return LMWordpress.productnumber()
                                    .then(x => x.data || []);
                    },                   
                    notifications: function(FRAPI, credentials) {

                        return FRAPI.getUserNotifications().then(function (response) {
                                return response; 
                        });         
                    },
                    school: function() {
                        return null;
                    }      
                },
                deepStateRedirect: {
                    default: { state: 'app.library.shelve', },
                    params: true
                }
            })
    }])

    .controller('libraryCtrl', function ($scope, $state, $interval, FRAPI, $rootScope, $window, LMConfig, nuts, user, productnumber, commonService, notifications, LMWordpress, $q) {

        window.localStorage.setItem('mode', 'library');
        // configuration
        $scope.config = LMConfig;
        $scope.locale = user.lang;

        // read user information
        $scope.nuts = nuts;
        $scope.user = user;
        $scope.productnumber = productnumber;
        FRAPI.clearNewNotifications();
        FRAPI.getNewNotifications().then((newNotifications) => {
                       
            $scope.unreadNotifiCount = newNotifications.length;

            if ($scope.unreadNotifiCount && $scope.unreadNotifiCount > 0){
 
                $state.go('app.library.notifications');
            } 
        })
        
        $scope.notifications = notifications;

        $scope.tabs = commonService.tabs(user.project);

        // update logo and page title
        var logoObj = commonService.logo(user.project, 'library');
        $scope.logo = logoObj.logo;        
        $scope.tm = logoObj.tm;
        
        $window.document.title = $scope.logo;    
        // check is student role
        // check is tester role
        $scope.isUser = user.roles.some(x => x != 'student');
        $scope.isStudent = user.roles.every(x => x == 'student');
        $scope.isTester = $scope.user.roles.some(x => x == 'tester');
        $scope.isAdmin = $scope.user.roles.some(x => x == 'schooladmin' || x == 'teacher');
        // watch for library tab changed
        $scope.$on('libraryTabChange', function (event, data) {
            $scope.activeTab = data;
        });
        $scope.$on('unreadNotifiCount', function (event, data) {
            $scope.unreadNotifiCount = data;
          
            if (!$scope.$$phase) {
                $scope.$apply();
            }
        });

        function vmOpenCart() {
            $state.go('app.library.cart');
        }
        // check quests every 1 minutes if only role student
        if($scope.isStudent) {

            getCurrentTask();
        }
      
      
        function getCurrentTask() {
            // perform some asynchronous operation, resolve or reject the promise when appropriate.
            return $q(function(resolve, reject) {
                var _date = new Date();
                let date = commonService.convertLocalDateToUTCUnix(_date);

                if (!$rootScope.countIntervalApiTask) {

                    $rootScope.countIntervalApiTask = $interval(function() {   
                    
                        FRAPI.tasksstudents(date, null).then((resp) => {
                           
                            let response = resp.data.map(function (item) {
        
                                item.date_from = commonService.getLocaleDate(item.startdate); 
                                item.date_to = commonService.getLocaleDate(item.enddate);
    
                                return item;
                            });
                            $rootScope.currentTasks = response.filter((x) => x.course_passed < x.courses);

                            $scope.$broadcast('EventCurrentTasks', $rootScope.currentTasks);

                            resolve(response);
                        });                
                    }, 1000 * 60);
                }

            });
        }

        function vmTwiceClick() {
            if ($state.current.name.indexOf('app.library.shelve') != -1) { return; }

            $state.go('app.library.shelve');
           // console.log($state.current.name);
        }

        $scope.twiceClick = vmTwiceClick;
        $scope.openCart = vmOpenCart;
    })
    .service('taskService', ['FRAPI', '$q', 'commonService', '$interval', function (FRAPI, $q, commonService, $interval) {

        this.get = function (from, to) {
            return FRAPI.tasksstudents(from, to);
    
        };

    }]);

})();