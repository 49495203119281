(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.teacher.report.hiring', {
                    url: '/:school/hiring',
                    params: {
                        school: null,
                        flagFromUpdate: null 
                    },
                    controller: 'teacherHiringCtrl',
                    templateUrl: '/assets/ng1/teacher/report/hiring/hiring.html',
        
                })
                .state('app.teacher.report.updatehired', {                  
                    url: '/:school/updatehiring/:username',
                    params: {
                        school: null,
                        username: null,
                        data: null
                    },
                    controller: 'teacherUpdateHiringCtrl',
                    templateUrl:  'teacher/report/hiring/updatehiring.html',
                })                
                .state('app.teacher.report.hiringimport', {
                    url: '/:school/hiring/import/:page',
                    params: {
                       // flagFromUpdate: null 
                    },
                    controller: 'adminHiringImportCtrl',
                    templateUrl: '/assets/ng1/admin/hiring/importHiring/importHiring.html',
                })
        }])
        .controller('teacherHiringCtrl', function ($scope,  $state, LMConfig, LMWordpress, $stateParams, $rootScope, $translate) {

            $scope.$emit('teacherModeChange', 'report');
            $scope.$emit('activeTabLeft', 'hiring');
            $scope.enableExport = false;

            $scope.status = [
                { key: 1, value: $translate.instant('admin.hiring.dropListStatus.hired'), sort: 3},
                { key: -1, value: $translate.instant('admin.hiring.dropListStatus.nothired'), sort: 4},
                { key: 0, value: $translate.instant('admin.hiring.dropListStatus.dismissed'), sort: 5},
                { key: 'all', value: $translate.instant('admin.hiring.dropListStatus.all'), sort: 1},
                { key: null, value:  $translate.instant('admin.hiring.dropListStatus.empty'), sort: 2}
            ];
            $scope.status.sort((a, b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0));

            $scope.test_results = [ { key: '0', value: '0', sort: 3 },
                                    { key: '1', value: '1', sort: 2 },
                                    { key: null, value: $translate.instant('admin.hiring.dropListStatus.all'), sort: 1 }];

            $scope.test_results.sort((a, b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0));


            let defaultStatus = $scope.status.find((x) => x.key === null).key;
            let defaultTestResult = $scope.test_results.find((x) => x.key === null).key;

            $scope.data = { people: null, cseid: null, status:  defaultStatus, test_result: defaultTestResult };

            let school_id = $stateParams.school;

            // when back from update hiring controller
            if ($stateParams.flagFromUpdate) {

                let hiringData = window.localStorage.getItem('hiringData') ? JSON.parse(window.localStorage.getItem('hiringData')) : { people: null, cseid: null, status: null, test_result: null };

                // update
                $scope.data = hiringData;

                vmShowHiring(hiringData);
            } else {
                let hiringData = window.localStorage.getItem('hiringData') ? JSON.parse(window.localStorage.getItem('hiringData')) : { people: null, cseid: null, status: null, test_result: null };

                // update
                $scope.data =  { people: hiringData.people, cseid: hiringData.cseid, status: defaultStatus, test_result: hiringData.test_result || defaultTestResult };

            }
 
            function vmShowHiring(hiringData) {          
                
                $rootScope.pageLoading = true;

                hiringData.cseid = hiringData.cseid ? hiringData.cseid : null;
                hiringData.status = hiringData.status || hiringData.status === 0 ? hiringData.status : null;
                hiringData.people = hiringData.people ? hiringData.people.replace(/,+|\s+|\t+|\n+/g, " ") : null;

                let usernames = hiringData.people !== null ? hiringData.people.trim().toLowerCase().split(/\s+/g) : [];

                window.localStorage.setItem('hiringData', JSON.stringify(hiringData));

                let locale_datebegin = window.localStorage.getItem('filterDateBegin');
                let locale_dateend = window.localStorage.getItem('filterDateEnd');

                var dateBegin = locale_datebegin && 
                                locale_datebegin !== 'undefined' && 
                                locale_datebegin !== 'null' ? moment(locale_datebegin).unix() : null;
                var dateEnd = locale_dateend && 
                              locale_dateend !== 'undefined' && 
                              locale_dateend !== 'null' ? moment(locale_dateend).endOf('day').unix() : null;

                LMWordpress.getHiringPeople(usernames, hiringData.status, school_id, hiringData.cseid, dateBegin,  dateEnd, hiringData.test_result).then(function (response) {
                    console.log(response);
                    $scope.results = response.data;
                    $scope.enableExport =  response.data && response.data.length > 0 ? true : false;

                    $scope.results.forEach(function (item) {
                        item.$test_date = new Date(item.test_date * 1000);
                    });

                    $scope.results.sort((a, b) => (a.test_date > b.test_date) ? 1 : ((b.test_date > a.test_date) ? -1 : 0));


                    $rootScope.pageLoading = false;
                }).catch(function (error) {
                    console.log('Error: api method');
                    $rootScope.pageLoading = false;
                })
    
            }

            function vmUpdate(username, results) {
                     
                $state.go('app.teacher.report.updatehired', { school: school_id, teacher: 'teach', username: username, data: results });
            }
            function vmGetResultStatus(status) {
                switch (status){
                    case 1: {
                        return $translate.instant('admin.hiring.dropListStatus.hired');
                    }
                    case -1: {
                        return $translate.instant('admin.hiring.dropListStatus.nothired');
                    }
                    case 0: {
                        return $translate.instant('admin.hiring.dropListStatus.dismissed');
                    }
                    case null: {
                        return '';
                    }
                }
            }
            function vmExport() {

                let list = $scope.results.map((item) => {

                    // because in filter we see value 'Empty'
                    // but in Excel file we see value ''
                    item._status = item.status === null ? "" : $scope.status.find((x) => x.key === item.status).value;

                    item.$test_date =  moment(new Date(item.test_date) * 1000).format("MM/DD/YYYY") ;

                    return {
                         date: item.$test_date,
                         firstname: item.firstname,
                         lastname: item.lastname,
                         login: item.username,
                         status: item._status,
                         cscid: item.cseid,
                         test_result: item.test_result
 
                    };
                })
 
                 let arGlobal = list.map((x) => {
                                             let array = [];
                                             array.push(x.date);
                                             array.push(x.firstname + ' ' + x.lastname);
                                             array.push(x.login);
                                             array.push(x.status);
                                             array.push(x.cscid);
                                             array.push(x.test_result);
 
                                             return array;
                                         });
  
 
                 let firstLine =  [$translate.instant('admin.hiring.importPage.excelColumn.date'), 
                                    $translate.instant('admin.hiring.importPage.excelColumn.name'), 
                                    $translate.instant('admin.hiring.importPage.excelColumn.login'), 
                                    $translate.instant('admin.hiring.importPage.excelColumn.status'), 
                                    $translate.instant('admin.hiring.importPage.excelColumn.cscid'),
                                    $translate.instant('admin.hiring.importPage.excelColumn.test_result')];
 
                 arGlobal.unshift(firstLine);
 
                 var ws = XLSX.utils.aoa_to_sheet(arGlobal);

                 var wscols = [{wch:12}, {wch:20}, {wch:20}, {wch:20}, {wch:20}, {wch:12}];

                 ws['!cols'] = wscols;
         
                 // A workbook is the name given to an Excel file
                 var wb = XLSX.utils.book_new();
         
                 // add Worksheet to Workbook
                 // Workbook contains one or more worksheets
                 XLSX.utils.book_append_sheet(wb, ws);
         
                 let namefile = $translate.instant('Hiring.xlsx');
         
                 // export Excel file
                 XLSX.writeFile(wb, namefile);
             }
 
             function vmGoToImport() {
             
                 $state.go('app.teacher.report.hiringimport',  { school: school_id, page: 'teachers' });
             }

            $scope.showHiring = vmShowHiring;            
            $scope.update = vmUpdate;
            $scope.getResultStatus = vmGetResultStatus;
            $scope.vmGoToImport = vmGoToImport;
            $scope.vmExport = vmExport;
        })
        .controller('teacherUpdateHiringCtrl', function ($scope, $state, LMConfig, LMWordpress, $stateParams, $rootScope,  $translate) {
      
            $scope.$emit('teacherModeChange', 'report');
            $scope.$emit('activeTabLeft', 'hiring');

            // flag for error csc id already exist
            $scope.exists = true;
      

            $scope.status = [
                { key: 1, value: $translate.instant('admin.hiring.dropListStatus.hired')},
                { key: -1, value:  $translate.instant('admin.hiring.dropListStatus.nothired')},
                { key: 0, value:  $translate.instant('admin.hiring.dropListStatus.dismissed')},
                { key: null, value: ""}
            ];

            $scope.student = $stateParams.data ? $stateParams.data.find((x) => x.username === $stateParams.username) : vmShowHiring();

            function vmUpdateStudent(student) {

                $rootScope.pageLoading = true;

                if ((student.cseid && student.cseid.trim() == '') ||  student.cseid == '') { student.cseid = null; };

                LMWordpress.updateHiringPeople(student).then(function (response) {
                    console.log(response);
                    $rootScope.pageLoading = false;
                    vmBack();
                }).catch(function (error) {
                    console.log('Error: api method');
                    $rootScope.pageLoading = false;
                })
            }

            function vmShowHiring() {

                $rootScope.pageLoading = true;

                let school_id = $stateParams.school;
   
                LMWordpress.getHiringPeople([ $stateParams.username ], 'all', school_id, null).then(function (response) {
                    $scope.student = response.data.find((x) => x.username === $stateParams.username);

                    $rootScope.pageLoading = false;
                }).catch(function (error) {
                    console.log('Error: api method');
                    $rootScope.pageLoading = false;
                })
            }

            function vmCSCIDexists(username, cscid) {

                $scope.exists = true;

                if (cscid) {

                    LMWordpress.checkCSCcode(username, cscid).then(function (res) {
                        $scope.exists = res.data;
                    })
                   
                }
            }
            function vmCheckCSCID(cscid) {

                if (cscid && (/\s/g.test(cscid.toString().trim()) || !(/^[ A-Za-z0-9]*$/.test(cscid.toString())))){
                    // has space
                    return true;
        
                } else {
                    return false;
                }
            }
            function vmBack() {
                $state.go('app.teacher.report.hiring', { school: $stateParams.school, flagFromUpdate: true});
            }


            $scope.updateStudent = vmUpdateStudent;
            $scope.back = vmBack;
            $scope.cscidexists = vmCSCIDexists;
            $scope.check = vmCheckCSCID;
        })
})();
