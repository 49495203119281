(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.parent.manage', {
                url: '/manage',
                controller: 'parentManageCtrl',
                templateUrl: '/assets/ng1/parent/manage/manage.html',
                deepStateRedirect: {
                    default: { state: 'app.parent.manage' },
                    params: ['locale']
                },
            })
    }])

    .controller('parentManageCtrl', function ($scope, $state) {

        $scope.$emit('activeTabTop', 'parent');
        // watch for library tab changed
        $scope.$on('activeTabLeft', function (event, data) {
            $scope.activeTabLeft = data;
        });
        $state.go('app.parent.manage.children');
    })
})();