(function () {
    'use strict';

    angular.module('languabooksApp')

        .directive('vmsUserMenu', function () {

            function vmContoller($scope, $state, $translate, LMProjectConfig) {
                $scope.selectedSchool = { id: $scope.school };
                $scope.RoleLinks = [];

                $scope.library = ((!hasRole('user') && hasRole('student')) && $scope.page != 'library') ? 'library' : null;
                $scope.teacher = (hasRole('teacher') && $scope.page != 'teacher') ? 'teacher' : null;
                $scope.schooladmin = ((hasRole('schooladmin') || hasRole('districtadmin')) && $scope.page != 'schooladmin') ? 'schooladmin' : null;
                //$scope.parent = (hasRole('parent') && $scope.page != 'parent') ? 'parent' : null;
                $scope.projectadministrator = (hasRole('projectadministrator') && $scope.page != 'projectadministrator') ? 'projectadministrator' : null;
                $scope.districtadmin = (hasRole('districtadmin') && $scope.page != 'districtadmin') ? 'districtadmin' : null;
                $scope.editProfile = !LMProjectConfig.properties[$scope.user.project].editProfileRoles
                    || LMProjectConfig.properties[$scope.user.project].editProfileRoles.some(r => hasRole(r));

                $scope.isUser = !(($scope.user.roles.some(x => x == 'student') && $scope.user.roles.length == 1)
                                || ($scope.user.project == 'tr' && ['teacher', 'user'].every(elem => $scope.user.roles.includes(elem)) && $scope.user.roles.length == 2));

                // for LB project logout is just exiting from the system
                // for other projects logout also redirect to main project site
                // so change menu title accordingly
                $scope.title_logout = $scope.user.project == 'lb' || $scope.user.project == 'lela' ? $translate.instant('common.user_menu.logout') : $translate.instant('common.user_menu.return');

                function hasRole(role) {

                    return $scope.user.roles.some(x => x == role);
                }

                function logoutClear() {
                    sessionStorage.removeItem('botHistory');
                    window.sessionStorage.removeItem('current_school_idnumber');
                    window.sessionStorage.removeItem('current_district_idnumber');
                    window.sessionStorage.removeItem('fr/search');
                    window.sessionStorage.removeItem('tr/search');
                    window.localStorage.removeItem('mode');
                    window.localStorage.removeItem('current_quest_id');
                    window.localStorage.removeItem('current_books');
                    window.localStorage.removeItem('schooladmin_myquest_checkmark');
                    window.localStorage.removeItem('schooladmin_quest_teacher');
                    window.localStorage.removeItem('cacheQuest');
                    window.localStorage.removeItem('general_coins');
                    window.localStorage.removeItem('import_old_angular');
                }
                $scope.logoutClear = logoutClear;

            };

            return {
                scope: {
                    user: '=',
                    locale: '=',
                    config: '=',
                    page: '=',
                    mode: '=',
                    schools: '=',
                    school: '='
                },
                controller: vmContoller,
                templateUrl: '/assets/ng1/common/user_menu.html'
            };
        })

})();