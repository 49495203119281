(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.admin.school.reports.curriculums', {
                url: '/curriculums',
                controller: 'adminCurriculumsCtrl',
                templateUrl: '/assets/ng1/teacher/courses/assignments/modules.html'
            })
            .state('app.admin.school.reports.reportcurriculums', {
                url: '/curriculums/:id',
                controller: 'adminCurriculumReportCtrl',
                templateUrl: '/assets/ng1/teacher/curriculum/curriculum_reports.html',
                deepStateRedirect: {
                    default: { state: 'app.admin.school.reports.reportcurriculums.schoolreports' },
                    params: true
                }
            })
            .state('app.admin.school.reports.reportcurriculums.schoolreports', {
                url: '/schoolreports',
                controller: 'adminCurriculumReportSchoolCtrl',
                templateUrl: '/assets/ng1/teacher/curriculum/curriculum_directive.html'
            })
            .state('app.admin.school.reports.reportcurriculums.classreports', {
                url: '/classreports',
                controller: 'adminCurriculumReportClassCtrl',
                templateUrl: '/assets/ng1/teacher/curriculum/curriculum_directive.html'
            })
            .state('app.admin.school.reports.reportcurriculums.userreports', {
                url: '/userreports',
                controller: 'adminCurriculumReportUserCtrl',
                templateUrl: '/assets/ng1/teacher/curriculum/userreport/userreport_directive.html'
            })
    }])

    .controller('adminCurriculumsCtrl', function ($scope, $state, courses) {

        $scope.$emit('adminTabChange', 'curriculums');
        $scope.$emit('adminTopTabChange', 'reports');

        $scope.curriculum = courses.filter(function (x) {
            return x.enrol == 1 && (x.curriculumtype == "categories" ||
                                   x.curriculumtype == "cohorts" ||
                                   x.curriculumtype == "levels" ||
                                   x.curriculumtype == "external");
        }).filter((x) => {
            return x.params ? JSON.parse(x.params).p_reports : null
        });

        $scope.curriculumGroup = vmGetChunks($scope.curriculum, 2); // need for table-cell view

        function vmOpenCurriculum(course) {

            $state.go('app.admin.school.reports.reportcurriculums', { id: course.id });
        }

        $scope.openCurriculum = vmOpenCurriculum;

    })
    .controller('adminCurriculumReportCtrl', function ($scope, $state, credentials, user, classes, courses) {
        $scope.$emit('adminTabChange', 'curriculums');
        $scope.$emit('adminTopTabChange', 'reports');
        $scope.$on('adminReportsTabChange', function (event, data) {
            $scope.activeTab = data;
        });

        $scope.mode = 'schooladmin';
        $scope.credentials = credentials;
        $scope.user = user;
        $scope.classes = classes;
        $scope.courses = courses; 

        function vmChangeTab(page) {
            switch (page) {
                case 'schoolreports': {
                    $state.go('app.admin.school.reports.reportcurriculums.schoolreports');
                    return;
                }
                case 'classreports': {
                    $state.go('app.admin.school.reports.reportcurriculums.classreports');
                    return;
                }
                case 'userreports': {
                    $state.go('app.admin.school.reports.reportcurriculums.userreports');
                    return;
                }
            }
        }

        $scope.changeTab = vmChangeTab;
    })
    .controller('adminCurriculumReportSchoolCtrl', function ($scope) {
        $scope.$emit('adminReportsTabChange', 'schoolreports');

        $scope.usage = 'school';
    })
    .controller('adminCurriculumReportClassCtrl', function ($scope) {
        $scope.$emit('adminReportsTabChange', 'classreports');

        $scope.usage = 'class';
    })
    .controller('adminCurriculumReportUserCtrl', function ($scope) {
        $scope.$emit('adminReportsTabChange', 'userreports');
    })
})();