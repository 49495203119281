(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.teacher.tasks', {
            url: '/task',
            controller: 'teacherTasksCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/tasks.html',
            resolve: {
                tasks: function (LMAPI, credentials, school, user) {

                    return LMAPI.gettasks(credentials.session_token, school.id).then(function (res) {

                        let isAdmin = user.roles.some(x => x == 'schooladmin');
                        if (isAdmin) {
                            return res.data.filter((x) => x.teacher.username === credentials.username);
                        } else {
                            return res.data;
                        }            
                    });
                },
                curriculum: function(courses) {

                    return courses.filter(function (item) {

                        let params = JSON.parse(item.params);
                        item.p_sortorder = params && params.p_sortorder ? params.p_sortorder : -999;
                        item.p_collection = params && params.p_collection ? params.p_collection : null;
            
                        return item.enrol == 1 && item.curriculumtype == 'assignments';
                    });
                }
            },
        })
    }])

    .controller('teacherTasksCtrl', function ($scope, $state, tasks, curriculum, school, schools) {

        $scope.$emit('teacherModeChange', 'modules');
        // watch for side tab changed
        $scope.$on('sideTabChange', function (event, data) {
            $scope.selected = data;
        });
        $scope.curriculum = curriculum;
        $scope.open = vmOpen;
        $scope.vmOpenAssignments = vmOpenAssignments;
        $scope.school = school;

        if ($state.params.state) { return; }
        if (curriculum && curriculum.length > 0) {
            vmOpenAssignments();
        } else {
            vmOpen('current');
        }

        function vmOpen(state) {
            //restructure header table to the correct position
            $('table.demo').floatThead('reflow');

            $state.go('app.teacher.tasks.list', { state: state });
        }
        function vmOpenAssignments() {
            $state.go('app.teacher.tasks.assignments');
        }
    })
})();