(function () {
    'use strict';

angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider    
            .state('app.root.districtadmin.district.schooladmins', {
                url: '/schooladmins',
                controller: 'schooladminsByDistrictCtrl',
                templateUrl: '/assets/ng1/districtadmin/schooladmins/schooladmins.html',
            })     
            .state('app.root.districtadmin.district.schooladmin', {
                url: '/createschooladmin/?:username?:school',
                controller: 'schooladminCreateByDistrictCtrl',
                templateUrl: '/assets/ng1/districtadmin/schooladmins/createadmin.html',
            })        
    }])

    .controller('schooladminsByDistrictCtrl', function ($scope, $stateParams, $rootScope, $state, districtsByUser) {

        $scope.$emit('modeChange', 'page');
        $scope.$emit('leftTabs', 'schooladmins');

        $scope.district = sessionStorage.getItem("current_district_idnumber");

        $scope.districts = districtsByUser;
        $scope.onEditSchoolAdmin = onEditSchoolAdmin;
        $scope.onCreateSchoolAdmin = onCreateSchoolAdmin;
      
        function onEditSchoolAdmin(admin) {
            $state.go('app.root.districtadmin.district.schooladmin', { username: admin.username});
        }
        function onCreateSchoolAdmin() {
            $state.go('app.root.districtadmin.district.schooladmin');
        }
    
    })
    .controller('schooladminCreateByDistrictCtrl', function ($scope, $stateParams, $rootScope, $state, districtsByUser) {

        $scope.$emit('modeChange', 'page');      

        if ($stateParams.school) {
            $scope.$emit('leftTabs', 'schools');
        } else {
            $scope.$emit('leftTabs', 'schooladmins');
        }

        $scope.username =  $stateParams.username ?  $stateParams.username : null;
        $scope.idschool = $stateParams.school ? $stateParams.school : null;

        $scope.district = sessionStorage.getItem("current_district_idnumber");

        $scope.districts = districtsByUser;

        $scope.onBack = vmBack;

        function vmBack() {
            if ($stateParams.school) {
                $state.go('app.root.districtadmin.district.assignschooladmin', { school: $stateParams.school });
            } else {
                $state.go('app.root.districtadmin.district.schooladmins');
            }         
        }
    
    })

})();