(function () {
  'use strict';

  angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

      $stateProvider
        .state('app.admin.school.wrkstedit', {

          url: '/wrkstedit/:id',
          // params: {
          //   id: null
          // },
          controller: 'editWorkstationCtrl',
          templateUrl: '/assets/ng1/admin/workstations/edit.html'
        })
    }])
    .controller('editWorkstationCtrl', function ($scope, $translate, LMWordpress, workstationSvc, $state, $stateParams) {
      $scope.$emit('adminTabChange', 'workstations');
      var cert = false;

      LMWordpress.workstations.getList(null, null, $stateParams.id).then(function (response) {
        $scope.workstation = response.data[0];
        $scope.workstation.$is_certified = '' + $scope.workstation.is_certified;
        cert = $scope.workstation.$is_certified;
      });

      function vmSave() {

        LMWordpress.workstations.updateWorkstations($stateParams.school, null, $stateParams.id, null, null, $scope.workstation.specification, $scope.workstation.comments, cert == '1')
          .then(function (response) {
            vmBack(true);
            $scope.error = false;
          }).catch(function (response) {
            $scope.error = true;
          })
      }


      function vmBack(reload) {
        $state.go('app.admin.school.workstations'/*, {reload: reload}*/);
      }

      $scope.save = vmSave;
      $scope.back = vmBack;
    })
})();