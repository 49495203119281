(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(function ($stateProvider) {

        $stateProvider
            .state('app.library.curriculum', {
                abstract: true,
                url: '/curriculum/:id',
                template: '<ui-view>',
                resolve: {
                    course: function ($stateParams, LMAPICache, token) {

                        return LMAPICache.courses(token, null, null, $stateParams.id);
                    }
                }
            })
            .state('app.teacher.curriculum', {
                abstract: true,
                url: '/curriculum/:id',
                template: '<ui-view>',
                resolve: {
                    course: function ($stateParams, LMAPICache, token) {

                        return LMAPICache.courses(token, null, null, $stateParams.id);
                    }
                }
            })
            .state('app.admin.school.curriculum', {
                abstract: true,
                url: '/curriculum/:id',
                template: '<ui-view>',
                resolve: {
                    course: function ($stateParams, LMAPICache, token) {

                        return LMAPICache.courses(token, null, null, $stateParams.id);
                    }
                }
            })
    })

})();