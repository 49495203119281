(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', 'LMConfig', function ($stateProvider, LMConfig) {

            $stateProvider
                .state('app.library.shelve.task', {
                    url: '/quest?taskId',
                    controller: 'libraryShelveTaskCtrl',
                    templateUrl: '/assets/ng1/library/task/task.html',              
                })
        }])

        .controller('libraryShelveTaskCtrl', function ($scope, $rootScope, token, currentTasks, FRAPI, LMAPI, $q, credentials, $state) {

            $scope.currentTasks = $rootScope.currentTasks ? $rootScope.currentTasks : currentTasks;
            // throw main tab change
            $scope.$emit('libraryActiveTab', 'task');
            $scope.$emit('libraryTabChange', 'library');
            // watch for title change
            // required to show
            // selected task title
            $scope.$on('titleChange', function (event, title) {
                $scope.title = title;
            });

            let promises = [];
            let $tasks = [];
           
            $scope.currentTasks.forEach((task) => {                      
              promises.push(
            
                                $q.all([FRAPI.statusCourses(task.task_id), 
                                        LMAPI.taskCourses.allcourses(credentials.session_token, task.idnumber)]).then((response) => {
                                      
                                            task.$courses = response[1].data.map(function (item) {
                                                
                                                let attempts = response[0].filter((x) => x.course_idnumber === item.id)
                                                                         .map((x) => x.attempt_id);
                                                let attеmpt = Math.max.apply(Math, attempts);

                                                let status = response[0].find((x) => x.course_idnumber === item.id && attеmpt.toString() === x.attempt_id.toString());
                                                
                                                if (status) { item.status = status.status; }
                                                // parse course scores
                                                if (item.scores)
                                                    item.scores = JSON.parse(item.scores);
                            
                                                return item;
                                            });
                                            $tasks.push(task);                                         
                                        })  
                 )             
            })
            Promise.all(promises).then((response) => {
             
                $scope.tasks = $tasks;
            
                if (!$scope.$$phase) {
                  $scope.$apply();
              }
            })

        })

        .directive('vmsQuest', function ($state, FRAPI, $filter, LMConfig, coursesService, commonService, openCourseService, $translate) {

            function vmController($scope, $rootScope, $state, $filter, LMConfig, $stateParams) {
      
              // configuration
              $scope.config = LMConfig;
              $scope.details = false;
              $scope.tasks.sort((a,b) => (a.enddate > b.enddate) ? 1 : ((b.enddate > a.enddate) ? -1 : 0));
                         
              vmCurrentQuest();

              function vmCurrentQuest() {
                
                let taskId = localStorage.getItem('taskId');
                if (taskId) {
                  let $task = $scope.tasks.find((x) => x.task_id.toString() === taskId.toString());
                  $scope.index = $task ? $scope.tasks.findIndex((x) => x.task_id.toString() === taskId.toString()) + 1 : 1;
                  $scope.task = $task ? $task : $scope.tasks[0];
                  
                } else {
                  $scope.task = $scope.tasks[0];
                  $scope.index = 1;
                }
              
              }

              function vmNextInfoCourse() {
              
                for (var i = 0; i < $scope.tasks.length; i++) {
                  if ( $scope.task.task_id == $scope.tasks[i].task_id) {
      
                    if (i != $scope.tasks.length - 1) {
                      $scope.task = $scope.tasks[i + 1];
                      $scope.index = $scope.tasks.findIndex((x) => x.task_id.toString() === $scope.task.task_id.toString()) + 1;
                      break;
                    } else {
                      //if the last element of the array  then to come back the first elment of the array
                      $scope.task = $scope.tasks[0];
                      $scope.index = $scope.tasks.findIndex((x) => x.task_id.toString() === $scope.task.task_id.toString()) + 1;
                      break;
                    }
      
                  };
                }
              };
      
              function vmPrevInfoCourse() {
      
                for (var i = 0; i < $scope.tasks.length; i++) {
                  if ($scope.task.task_id == $scope.tasks[i].task_id) {
                    if (i != 0) {
                      $scope.task = $scope.tasks[i - 1];
                      $scope.index = $scope.tasks.findIndex((x) => x.task_id.toString() === $scope.task.task_id.toString()) + 1;
                      break;
      
                    } else {
                      //if the first element of the array  then to come back the last elment of the array
                      $scope.task = $scope.tasks[$scope.tasks.length - 1];
                      $scope.index = $scope.tasks.findIndex((x) => x.task_id.toString() === $scope.task.task_id.toString()) + 1;
                      break;
                    }
      
                  };
                }
              };
      
              function vmOpenCourse(course) {
                // reset previous result 
                // for get new result
                $rootScope.currentTasks = null;
                // try to get external link from a course
                // if found than open new window
                // otherwise open course normally
                let link = vmExternalLink(course);
                if (link) {
      
                  window.open(link);
      
                } else {
                  window.localStorage.setItem('taskId', $scope.task.task_id);
                  openCourseService.get(null, course, $scope.task);
                };
              };
              function vmExternalLink(course) {

                // try to parse external link or secure external link
                // from course parameters
                const params = JSON.parse(course.params) || {};
                const link = params.p_external_link;
                const secureLink = params.p_aws_secure_link;
      
                if (link) {
      
                  return link;
      
                } else if (secureLink) {
      
                  // find Amazon S3 bucket from URL
                  // check two possible positions for it
                  var buckets = secureLink.match('https://([a-z\-]*).?s3[\.a-z0-9\-]*.amazonaws.com/([a-z\-]*)');
                  var bucket = buckets[1] || buckets[2];
      
                  // find key portion in Amazon S3 URL
                  // exclude root and bucket
                  var key = secureLink.match('amazonaws.com(/' + bucket + ')?/([A-Za-z0-9\-\/\._ ]*)')[2];
      
                  // get signed URL
                  var s3 = new AWS.S3({ signatureVersion: "v4" });
                  return s3.getSignedUrl('getObject', { Bucket: bucket, Key: key });
      
                } else {
      
                  return null;
                }
              };
              function vmGetNumberState(courses, courses_passed){

                if (!courses_passed) { return 2;}
                if (courses_passed === courses) { return 3;} 
                if (courses_passed < courses) { return 1;} 
              
              }
    
              $scope.prevInfoCourse = vmPrevInfoCourse;
              $scope.nextInfoCourse = vmNextInfoCourse;
              $scope.openCourse = vmOpenCourse;
              $scope.getNumberState = vmGetNumberState;

            };
      
            return {
              scope: {
                tasks: '=ngModel'
              },
              controller: vmController,
              templateUrl: '/assets/ng1/library/task/list.html'
            }
      
          })

})();