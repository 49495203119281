(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', 'LMConfig', function ($stateProvider, LMConfig) {

            $stateProvider
                .state('app.course.read', {
                    url: '/read/:taskId',
                    controller: 'courseReadCtrl',
                    templateUrl: '/assets/ng1/course/read.html',
                    onEnter: function ($stateParams, $state) {
                        // if notDirect value is invalid (false or null)
                        // that means that navigation was made by Direct URL 
                        // or Page Reload occurred
                        // redirect user to special Start page 
                        if (!$stateParams.notDirect) {
                            setTimeout(function () {
                                if ($stateParams.taskId) {
                                    $state.go('app.library.shelve.task');
                                } else {
                                    $state.go('app.course.start', { course: $stateParams.course, back: $stateParams.back, taskId: $stateParams.taskId });
                                }
                            }, 100);
                        }
                    },
                    onExit: function () {
                        return false;
                    },
                    resolve: {
                        fingerprint: function (commonService, user) {
                            return commonService.getClientFingerprint(user.project).then(fingrprintObj => fingrprintObj.value);
                        }
                    }
                })
        }])

        .controller('courseReadCtrl', function ($scope, $state, $stateParams, $q, LMAPI, FRAPI, LMAPICache, LMWordpress, LMConfig, LMWebStorage, token, user, course, $translate, $window, credentials, fingerprint) {

            let courseId = course.id;
            $scope.back = vmBack;

            if (course.curriculumtype == 'readonly') {

                $state.go('app.course.audio', { course: $stateParams.course, back: $stateParams.back });
            };

            // configuration
            $scope.config = LMConfig;

            //for hide footer when play course
            $(".mainWindow").addClass("blackZone");

            // throw event main tab change
            $scope.$emit('mainTabChange', 'course');

            var taskId = $stateParams.taskId;

            var player = new vimas.sco.webcourse({
                application: window.application_id,
                token: token,
                courseid: courseId,
                config: LMConfig,
                lang: user.lang,
                roles: user.roles,
                course_lang: course.course_lang,
                session: credentials.session_token
            });

            // get course info
            // get course progress
            // get last page
            let scos = LMAPICache.scos(token, courseId);
            let progress = LMAPI.activitiesprogress(token, courseId);            
            let attemptPromise = taskId !== undefined && taskId !== null && taskId !== '' ? FRAPI.getAttemptTask(courseId, taskId) : FRAPI.getAttemptLibrary(courseId);

         

            attemptPromise.then(function(attempt) {

                const requests = { scos: scos, progress: progress, attempt: attempt };

                $q.all(requests)
                .then(function (response) {
                    let lastPage;
                    if ($stateParams.activity && response.scos && response.scos.scos.length > 0) {
                        let page = response.scos.scos.filter((x) => {
                            if (x.idnumber === $stateParams.activity) {
                                return x;
                            }
                        })[0];
                        lastPage = page.id;
                    } 
                    if (response.scos && response.scos.scos.length > 0 && response.progress.data.length > 0) {

                        const filteredData = response.progress.data.filter(item => item.attempt === response.attempt.attempt);

                        let scosArray = response.scos.scos.map(sco => {                           
                            let matchingProgress = filteredData.find(progress => progress.id === sco.id);
                        
                            if (matchingProgress) {
                                sco.status = matchingProgress.status;
                            }                        
                            return sco;
                        });                            
                       
                        const _lastPage = scosArray.length > 0 ? 
                                          scosArray.find(item => item.status !== "passed" && item.status !== 'completed') :
                                        response.scos.scos[0];
                        lastPage = _lastPage.id;
                    }


                    if (taskId) {
                        // filter progress by taskId
                        var progressByTask = response.progress.data.filter(function (activityProgress) {
                            return Math.floor(activityProgress.attempt / LMConfig.task.attemptMultiplier) == taskId
                        });
                        player.loadForTask(response.scos, progressByTask, lastPage, taskId, response.attempt.attempt);
                    } else {
                        // filter out tasks progress
                        var nonTaskProgress = response.progress.data.filter(function (activityProgress) {
                            return activityProgress.attempt < LMConfig.task.attemptMultiplier;
                        });
                        player.load(response.scos, nonTaskProgress, lastPage, response.attempt.attempt);
                    }
                });
            
            })
            // attach handlers
            $(player)
                .on('access_denied', function (e) {

                    $scope.access_denied = $translate.instant('course.report.access');
                })
                .on('no_attempts', function (e) {

                    $scope.no_attempts = $translate.instant('course.report.attempts');
                })
                .on('prepared', function (e, data) {

                    // store computer fingerprint
                    // into LMS global variables
                    vimas.sco.lms.fingerprint = fingerprint;

                    $scope.scos = data.scos;
                })
                .on('current', function (e, sco) {


                    $scope.current = sco.id;
                    $scope.$apply();
                })
                .on('no_actitity_already', function (e) {

                    player.exit();
                })
                .on('webcourse.start', function (e, startInfo) {
                    if (taskId) {
                        FRAPI.taskCourseAttempt(courseId, startInfo.taskId, startInfo.attemptId).then(function (respone) {
                            console.log('FRAPI.taskCourseAttempt({0}, {1}, {2}): {3}'.format(courseId, startInfo.taskId, startInfo.attemptId, JSON.stringify(respone.data)));
                        });
                    }
                   
                })
                .on('webcourse.finish', function (e, finishInfo) {

                   //It makes sense to do not call save method if we press exit from finish course
                   //Task 28721: RLS. Use new API method for saving course scores
                })
                .on('webcourse.exit', function (e, dest) {

                    switch (dest) {

                        case 'index':
                        case 'miccheck':
                        case 'glossary':

                            $scope.$apply();
                            break;

                        case 'exit':

                            // course nuts were recalculated
                            // so remove last course record
                            window.localStorage.removeItem('languabooks.com/lastcourse');
                            window.localStorage.removeItem('languabooks.com/lastcourseTask');

                            $state.go('app.course.exit', { back: $stateParams.back });

                    };
                });

            function vmSaveLastPage(storageKey, token, courseid, scoid) {

                // var storageKey = '{0}-{1}'.format(courseid, token);

                LMWebStorage.get('Courses', null, storageKey)
                    .then(function (course) {

                        if (!course) {

                            // add data to database for course
                            // if there was none
                            LMWebStorage.add('Courses', {
                                id: storageKey,
                                scorm: courseid,
                                token: token,
                                last_sco: scoid
                            });

                        } else {

                            // update last used sco and save
                            course.last_sco = scoid;
                            LMWebStorage.set('Courses', course);
                        };
                    });
            };

            function vmBack() {
                $window.history.back();
            }
        })

})();