(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.admin.school.reports.creport', {
                    url: '/class',
                    controller: 'adminCReportCtrl',
                   /* params:{
                        showresult: null
                    },*/
                    templateUrl: '/assets/ng1/admin/reports/creport/creport.html'
                })
                .state('app.admin.school.reports.cupdatestudent', {
                    url: '/updatestudent/:student/:username/:page',
                    controller: 'studentUpdateCtrl',
                    templateUrl: '/assets/ng1/admin/classes/class/student/editstudent.html'
                })
        }])

        .controller('adminCReportCtrl', function ($scope, FRAPI, token, LMAPI, $state, $stateParams, classes, $translate, $rootScope, commonService) {

            $scope.$emit('adminTabChange', 'creport');  
            
            var _date = new Date();
            let today = commonService.convertLocalDateToUTCUnix(_date);
            $scope.showClas = false;
            // if dates are in localStorage
            // check dates, must be date end >= date begin
            vmChangeDate(null, null, true);
            vmBooleanShowAllClasses();
    
            // watch for side tab changed
            $scope.$on('sideTabChange', function (event, data) {
                $scope.selectedClass = data;
            });     
            // try to set default class
            // if found than get list of students
            $scope.selectedClass = $scope.classList.find(x => x.idnumber == $stateParams.clas);
            if ($stateParams.showresult && $scope.selectedClass.idnumber) {
                vmGetResult($scope.selectedClass.idnumber);
            }
            function vmGetResult(clas) {            
                $rootScope.pageLoading = true;
                FRAPI.classreport(clas, $scope.unix_datebegin, $scope.unix_dateend)
                           .then(function (response) {
    
                               $scope.results = [];
    
                               response.forEach(function (item) {                                  
                                               
                                item.$raw_read = parseFloat(item.raw_read).toFixed(1);
                                item.$raw_first_quiz = item.raw_first_quiz == null ? 'N/A' : parseFloat(item.raw_first_quiz).toFixed(1);
                                let Lmin = item.time_listening ? Math.trunc(parseFloat(item.time_listening) / 60) : 0;
                                let Rmin = item.time_reading ? Math.trunc(parseFloat(item.time_reading) / 60) : 0;
                                let Lsec; let Rsec;
                                if (Lmin*60 < parseInt(item.time_listening)) {
                                    Lsec = item.time_listening - Lmin*60;
                                } else {
                                    Lsec = 0;
                                }
                                if (Rmin*60 < parseInt(item.time_reading)) {
                                    Rsec = item.time_reading - Rmin*60;
                                } else {
                                    Rsec = 0;
                                }

                                item.listening_minutes = commonService.vmAddZero(Lmin) + ":" + commonService.vmAddZero(Lsec);
                                item.reading_minutes = commonService.vmAddZero(Rmin) + ":" + commonService.vmAddZero(Rsec);
                                
                                $scope.results.push(item);
                                     
                               });
                               $scope.show = true;
                               $rootScope.pageLoading = false;
                           });
            }
            function vmBooleanShowAllClasses() {

                let show = window.localStorage.getItem('showAllClasses');
                if (show && show != 'null' && show != 'undefined') {
                    if (typeof show === "string") {
                        show = JSON.parse(show);
                        vmShowClasses(show);
                    }
                } else {
                    vmShowClasses(false);
                }                
            }
    
            function vmChangeClass(data) {
                $state.go($state.current.name, { clas: data.idnumber, student: null });
            }
    
            function vmGetBookReport(user) {
                window.localStorage.removeItem('course');
                $state.go('app.admin.school.reports.sreport.book', { clas:  $scope.selectedClass.idnumber, student: user.token });
            }
    
            // method call in template if date change
            // then check dates which got
            // else check dates which are in localStorage
            // NOT READ all from local storage, when change template, storage see changes later
            function vmChangeDate(datebegin, dateend, storage) {
    
                if (storage) {
    
                    var storageBegin = localStorage.getItem('datebegin');
                    var storageEnd = localStorage.getItem('dateend');
    
                    $scope.unix_datebegin = storageBegin && storageBegin != 'null' && storageBegin != 'undefined' ? moment((new Date(storageBegin)).setHours(0, 0, 0, 0)).unix() : null;
                    $scope.unix_dateend = storageEnd && storageEnd != 'null' && storageEnd != 'undefined' ? moment((new Date(storageEnd)).setHours(23, 59, 59, 999)).unix() : null;
                
                } else {
                    $scope.unix_datebegin = datebegin ? moment(datebegin.setHours(0, 0, 0, 0)).unix() : null;
                    $scope.unix_dateend = dateend ? moment(dateend.setHours(23, 59, 59, 999)).unix() : null;
                }
                
                // one of the dates may be empty (null) - this is not a mistake
                // show mistake only when date end < date begin
                if (($scope.unix_dateend < $scope.unix_datebegin) && $scope.unix_datebegin != null && $scope.unix_dateend != null) {
    
                    $scope.errorIntervalDate = true;
    
                } else {
                    $scope.errorIntervalDate = false;
                }
    
            }
            function vmShowClasses(show) {

                window.localStorage.setItem('showAllClasses', show);
                $scope.showClas = show;

                if (show) {
                    $scope.classList = classes;        
                } else {
                    $scope.classList = classes.filter(function (item) {
                        return item.date_end >= today;
                    });        
                }
            }
    
            $scope.getResult = vmGetResult;
            $scope.getStudentInClassList = vmChangeClass;
            $scope.getBookReport = vmGetBookReport;
            $scope.changeDate = vmChangeDate;
            $scope.showClasses = vmShowClasses;
            $scope.getClassName = commonService.vmsGetClassName;
        })

})();