(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.admin.school.users.students', {
                    url: '/students',
                    controller: 'adminStudentsCtrl',
                    templateUrl: '/assets/ng1/admin/users/students/students.html',
                })
                .state('app.admin.school.users.updatestudent', {
                    url: '/editstudent/?:username/?:student',
                    params: {
                        student: null,
                        username: null
                    },
                    controller: 'studentUpdateCtrl',
                    templateUrl: '/assets/ng1/admin/classes/class/student/editstudent.html',
                })
        }])

        .controller('adminStudentsCtrl', function ($scope, $rootScope, $translate, gemstones, $timeout, LMConfig, LMWordpress, FRAPI, user, $state, $stateParams, classes, $q, commonService, schools) {
            // watch for side tab changed
            $scope.$emit('adminTabChange', 'students');
            //restructure header table to the correct position
            $('table.demo').floatThead('reflow');
     
            $scope.ext_user= LMConfig.rights.profile_ext_user(user.project);
            $scope.btnHide = commonService.btnHide(user.project);

            $scope.school = schools.find((x) => x.id === commonService.getCurrentSchool());

            $scope.gemstones_filter = angular.copy(gemstones);
            $scope.gemstones_filter.unshift({
                idnumber: null,
                name: 'All'
            })

            let value = localStorage.getItem('vmLoadSchoolStudents');
            if (value === 'false' || value === 'true') {
                vmLoadSchoolStudents(value === 'true');
                if (!$scope.$$phase) {
                    $scope.$apply();
                }
            }

            // dropdownList with schools
            if ($scope.school.district_type !== 'Languametrics' &&  $scope.school.school_type === 'Languametrics') {
                if ($scope.school.district_type === 'Clever') {
                    vmGetCleverSchools();
                }
                if ($scope.school.district_type === 'Classlink') {
                    vmGetClasslinkSchools();
                }                            
            }
            function vmLoadSchoolStudents(isAllStudents) {

                $scope.schoolStudentsActive = isAllStudents;
                let _active = isAllStudents ? null : 1;
                FRAPI.getUsersFromCohortTeacherMode(commonService.getCurrentSchool(), JSON.stringify(['student']), commonService.checkIndividualLicense(user.project), _active).then(function (response) {
                
                    response.forEach((x) => { 
                        x.gemstone_level = x.properties ? x.properties.gemstone_level : null;                                 
                    })
    
                    $scope.students = angular.copy(response);
    
                    var today = moment().startOf('day').format('X');
    
                    $scope.classes = classes.filter(function (item) {
                        return item.date_end >= today;
                    });
                    localStorage.setItem('vmLoadSchoolStudents', isAllStudents);
                    //restructure header table to the correct position
                    $('table.demo').floatThead('reflow');
                });
            }

            function vmGetUsers() {
   
                switch ($scope.school.district_type) {
                    case 'Clever':                       
                        vmGetCleverUsersInSchool();                                           
                        break;
                    case 'Classlink':                       
                        vmGetClasslinkUsersInSchool();                                                 
                        break;
                    case 'Languametrics':
                        break;
                }
            }
            function vmGetCleverUsersInSchool() {

                $rootScope.pageLoading = true;

                if ($scope.school.school_type === 'Languametrics' && $scope.school.district_type !== 'Languametrics') {
                 
                    $q.all([FRAPI.getUsersClever($scope._additionalSchool.id, $scope._additionalSchool.district, 'student'),
                            FRAPI.getUsersFromCohortTeacherMode(commonService.getCurrentSchool(), JSON.stringify(['student']), commonService.checkIndividualLicense(user.project), null)])
                    .then((response) => {
                        $scope.users = response[0].filter((x) => !response[1].some((y) => x.username === y.username));
                        $rootScope.pageLoading = false;
                    })
                } else {
                    $q.all([FRAPI.getUsersClever(commonService.getCurrentSchool(), $scope.school.district, 'student'),
                            FRAPI.getUsersFromCohortTeacherMode(commonService.getCurrentSchool(), JSON.stringify(['student']), commonService.checkIndividualLicense(user.project), null)])
                    .then((response) => {
                        $scope.users = response[0].filter((x) => !response[1].some((y) => x.username === y.username));
                        $rootScope.pageLoading = false;                     
                    })
                } 
                 //restructure header table to the correct position
                 $('table.demo').floatThead('reflow');            
            }
            function vmGetClasslinkUsersInSchool() {
                $rootScope.pageLoading = true;

                if ($scope.school.school_type === 'Languametrics' && $scope.school.district_type !== 'Languametrics') {

                    $q.all([FRAPI.getUsersClasslink($scope._additionalSchool.idnumber, 'student'),
                            FRAPI.getUsersFromCohortTeacherMode(commonService.getCurrentSchool(), JSON.stringify(['student']), commonService.checkIndividualLicense(user.project), null)])
                    .then((response) => {
                        $scope.users = response[0].filter((x) => !response[1].some((y) => x.username === y.username)); 
                        $rootScope.pageLoading = false;                
                    })
                } else {
                    $q.all([FRAPI.getUsersClasslink(commonService.getCurrentSchool(), 'student'),
                            FRAPI.getUsersFromCohortTeacherMode(commonService.getCurrentSchool(), JSON.stringify(['student']), commonService.checkIndividualLicense(user.project), null)])
                        .then((response) => {
                        $scope.users =  response[0].filter((x) => !response[1].some((y) => x.username === y.username));
                        $rootScope.pageLoading = false;                
                    })
                }              
            }
            function vmGetCleverSchools() {
                $rootScope.pageLoading = true;

                FRAPI.cleverSchools($scope.school.district).then((response) => {
                    $scope.$schools = response;       
                    $rootScope.pageLoading = false;

                     //restructure header table to the correct position
                     $('table.demo').floatThead('reflow');
                })
            }
            function vmGetClasslinkSchools() {
                FRAPI.classlinkSchools($scope.school.district).then((response) => {
                    $scope.$schools = response;   
                     //restructure header table to the correct position
                     $('table.demo').floatThead('reflow');      
                })
            }
            function vmAssignUser(user) {

                $rootScope.pageLoading = true;

                let isArray = Array.isArray(user);               
                user = isArray ? user.filter((y) => y.select) : user;

                if (isArray && user.length > 100) {
                    $rootScope.pageLoading = false;
                    alert("Can't add more than 100 users one time");
                    return;
                }
                             
                if ($scope.school.district_type === 'Classlink'){

                    let schoolNumberClasslink = $scope.school.school_type === 'Languametrics' && $scope.school.district_type !== 'Languametrics' ? $scope._additionalSchool.idnumber : $scope.school.id; 
                    let sourcedId = isArray ? user.map((x) => x.sourcedId) : [user.sourcedId];

                    FRAPI.assignUserClasslink(sourcedId, 'student', $scope.school.district, $scope.school.id, schoolNumberClasslink).then((response) => {
                     
                        if (!isArray) {

                            $scope.users = $scope.users.filter((x) => x.username != user.username);
                            $scope.students.push(user);
                            user.token = response.data.find((x) => x.id == user.username).token;
                            user.properties = { ext_integration: "classlink" };

                        } else {

                            $scope.users = $scope.users.filter((x) => !user.some(y => x.username === y.username));

                            user.forEach((item) => {
                                item.token = response.data.find((x) => x.id == item.username).token;
                                item.properties = { ext_integration: "classlink" };
                                item.select = false;
                                $scope.selectUser = false;
                                $scope.students.push(item);
                            });          
                        }
                        FRAPI.cleargetUsersFromCohort();
                        vmSelectAll2(false, null);
                        $rootScope.pageLoading = false;

                      //  FRAPI.clearUsersClasslink();

                    })
                } else if ($scope.school.district_type === 'Clever') {
                  
                    let schoolNumberClever = $scope.school.school_type === 'Languametrics' && $scope.school.district_type !== 'Languametrics' ? $scope._additionalSchool.id : $scope.school.id; 
                    let username = isArray ? user.filter((y) => y.select).map((x) => x.username) : [user.username];

                    FRAPI.assignUserClever(username, 'student', $scope.school.district, 
                                            $scope.school.id, schoolNumberClever).then((response) => {
                        if (!isArray) {                        
                            $scope.users = $scope.users.filter((x) => x.username != user.username);
                            user.token = response.data.find((x) => x.id == user.username).token; 
                            user.properties = { ext_integration: "clever" };
                            $scope.students.push(user);
                        } else {
                            $scope.users = $scope.users.filter((x) => !user.some(y => x.username === y.username));

                            user.forEach((item) => {
                                item.token = response.data.find((x) => x.id == item.username).token;
                                item.properties = { ext_integration: "clever" };
                                item.select = false;
                                $scope.selectUser = false;
                                $scope.students.push(item);
                            })      
                        }
                        FRAPI.cleargetUsersFromCohort();
                        vmSelectAll2(false, null);
                        $rootScope.pageLoading = false;
                       // FRAPI.clearUsersClever();
                    })
                }             
               //restructure header table to the correct position
               $('table.demo').floatThead('reflow');
            }
            function vmGetGemLevel(gemstone_level) {

                if (!gemstone_level) return;

                let gemstone =  gemstones.find((x) => x.idnumber === gemstone_level);
 
                return gemstone ? gemstone.name : null;
                
            }
            function vmRemoveStudents(student) {

                if (confirm('Are you sure?')) {

                    $rootScope.pageLoading = true;
                    FRAPI.deleteStudent(student.token, $scope.school.id).then(function (response) {

                        $scope.students.forEach(function (item, i) {
                            if (item.username == student.username) {
                                $scope.students.splice(i, 1);
                                if ($scope.users && item.properties && 
                                    (item.properties.ext_integration === 'classlink' || item.properties.ext_integration === 'clever' )) {
                                    
                                    $scope.users.push(item); 
                                }
                            }
                        });
                       // FRAPI.clearUsersClever();
                       // FRAPI.clearUsersClasslink();
                        FRAPI.cleargetUsersFromCohort();

                        $rootScope.pageLoading = false;
                        //restructure header table to the correct position
                        $('table.demo').floatThead('reflow');
                    });
                }
            }
            function vmOpenSendEmail() {

                let recipients = $scope.students.filter((y) => y.select);                

                $state.go('app.admin.school.users.email', { recipients: recipients, people: $scope.students });
            }
            function vmFlagRemoveSelected(select) {
                $scope.isSeleted = $scope.students.some((x) => x.select);
            }
            function vmFlagAllRemoveSelected(select, listUsersClass) {
               
                $scope.isSeleted = select;
                $scope.selectassigned = select;

                listUsersClass.forEach((x) => {                   
                   
                    let item = $scope.students.find((y) => y.username === x.username);
                    item.select = select;
                })
            }
            function vmRemoveSelected() {

                if (confirm('Are you sure?')) {
                    let selectedStudents = $scope.students.filter((x) => x.select);
                    selectedStudents.forEach(function (student) {
                        FRAPI.deleteStudent(student.token, $scope.school.id).then(function (response) {

                            $scope.allselect = false;
                            $scope.students.forEach(function (item, i) {
                                if (item.username == student.username) {
                                    $scope.students.splice(i, 1);
                                    vmFlagRemoveSelected(false, item.username, item.token);
                                }
                            })
                            FRAPI.cleargetUsersFromCohort();
                            //restructure header table to the correct position
                            $('table.demo').floatThead('reflow');
                        });
                    });
                }

            }
            function vmChangeSchool(school123) {
                $scope._additionalSchool = school123;
            }
            function vmSelectAll2(select, _users) {
                if (_users) {
                    $scope.users.forEach((x) => {
                        let user = _users.find((y) => y.username === x.username);
                        if (user) {
                            x.select = select;
                        }
                    })
                } else {
                    $scope.users.forEach((x) => {                   
                        x.select = select;                    
                    })
                }              
            }    
            function isDisabledSelectedBtn() {
               return $scope.users.every((x) => !x.select);
            }
            function vmGetNameClassType(type) {
                switch (type) {
                    case 'Clever':                       
                        return 'Clever';      
                    case 'Classlink':                       
                        return 'ClassLink';
                    case 'Languametrics':
                        return 'Languametrics';
                }
            }

            $scope.removeStudents = vmRemoveStudents;
            $scope.openSendEmail = vmOpenSendEmail;
            $scope.vmFlagRemoveSelected = vmFlagRemoveSelected;
            $scope.vmFlagAllRemoveSelected = vmFlagAllRemoveSelected;
            $scope.removeSelected = vmRemoveSelected;
            $scope.vmGetUsers = vmGetUsers;
            $scope.vmAssignUser = vmAssignUser;
            $scope.vmChangeSchool = vmChangeSchool;
            $scope.vmGetGemLevel = vmGetGemLevel;
            $scope.vmSelectAll2 = vmSelectAll2;
            $scope.isDisabledSelectedBtn = isDisabledSelectedBtn;
            $scope.vmGetNameClassType = vmGetNameClassType;
            $scope.vmLoadSchoolStudents = vmLoadSchoolStudents;
        })
        .controller('studentUpdateCtrl', function ($scope, gemstones, FRAPI, user, $state, $stateParams, commonService, schools) {

            $scope.$emit('activeTab', 'students');
            $scope.$emit('activeTabLeft', 'creport');
            $scope.$emit('adminTabChange', 'students');

            $scope.listgrades = commonService.listGrades();
            $scope.listgemstones = gemstones;

            $scope.username = $stateParams.student;
            $scope.idclass = $stateParams.clas;    
            $scope.idschool =  sessionStorage.getItem('current_school_idnumber');                    
            //method call when add/edit student
            function vmBack() {
                FRAPI.cleargetUsersFromCohort();
                if (!$stateParams.page) {
                    $state.go('app.admin.school.users.students');
                } else if ($stateParams.page === 'teacherReport') {
                    $state.go('app.teacher.report.creport', {showresult: true});
                } else if ($stateParams.page === 'schooladminReport') {
                    $state.go('app.admin.school.reports.creport', {showresult: true});
                }
            }
           
            $scope.onBack = vmBack;
        })
})();