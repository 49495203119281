(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.admin.school.notifications', {
                    url: '/notifications',
                    controller: 'adminNotificationsIndexCtrl',
                    templateUrl: '/assets/ng1/admin/notifications/index.html',
                    deepStateRedirect: {
                        default: { state: 'app.admin.school.notifications.notification', },
                        params: true
                    }
                })

        }])
        .controller('adminNotificationsIndexCtrl', function ($scope, $state, $window, LMConfig, user, commonService, schools) {
         
            $scope.$emit('adminTopTabChange', 'notifications');

        })

})();