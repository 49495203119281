(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.teacher.report.sreport', {

                    url: '/sreport',
                    controller: 'teacherReportStudentCtrl',
                    templateUrl: '/assets/ng1/teacher/report/student/student.html',
                    deepStateRedirect: {
                        default: { state: 'app.teacher.report.sreport.book' },
                        params: true
                    }
                })
                .state('app.teacher.report.sreport.book', {

                    url: '/book',
                    controller: 'teacherReportBookCtrl',
                    templateUrl: '/assets/ng1/teacher/report/book/book.html'
                })
                .state('app.teacher.report.sreport.calendar', {

                    url: '/calendar',
                    controller: 'libraryReportCalendarCtrl',
                    templateUrl: '/assets/ng1/library/report/calendar.html',
                    params: {
                        token: null
                    },
                    resolve: {
                        token: function ($stateParams) {

                            return $stateParams.student;
                        }
                    }
                })
                .state('app.teacher.report.sreport.summary', {

                    url: '/summary',
                    controller: 'libraryReportSummaryCtrl',
                    templateUrl: '/assets/ng1/library/report/summary.html',
                    params: {
                        token: null
                    },
                    resolve: {
                        token: function ($stateParams) {

                            return $stateParams.student;
                        }
                    }
                })
                .state('app.teacher.report.sreport.pronunciation', {

                    url: '/pronunciation',
                    controller: 'libraryReportPronunciationCtrl',
                    templateUrl: '/assets/ng1/library/report/pronunciation.html',
                    params: {
                        token: null
                    },
                    resolve: {
                        token: function ($stateParams) {

                            return $stateParams.student;
                        }
                    }
                })
                .state('app.teacher.report.sreport.certificates', {
                    url: '/reportcertificate',
                    controller: 'libraryReportCertificatesCtrl',
                    templateUrl: '/assets/ng1/library/report/certificates.html',
                    params: {
                        token: null
                    },
                    resolve: {
                        token: function ($stateParams) {

                            return $stateParams.student;
                        }
                    }
                })
        }])

        .controller('teacherReportStudentCtrl', function ($scope, FRAPI, $state, $stateParams, $translate, classes, commonService) {

            $scope.mode = 'teacher';
            $scope.$emit('activeTabLeft', 'sreport');
            $scope.$on('studentReportsTabChange', function (event, data) {
                $scope.activeTab = data;
            });
            $scope.getStudentInClassList = vmChangeClass;
            $scope.changeStudent = vmChangeStudent;
            $scope.getClassName = commonService.vmsGetClassName;
            $scope.changeTab = vmChangeTab;

            $scope.classList = angular.copy(classes);

            if ($stateParams.clas) {

                $scope.selectedClass = $scope.classList.find(x => x.idnumber == $stateParams.clas);

                FRAPI.getUsersFromCohortTeacherMode($stateParams.clas, JSON.stringify(['student'])).then(function (response) {
        
                    $scope.studentsList = response.unique(x => x.token);
                    $scope.selectedStudent = $scope.studentsList.find(x => x.token == $stateParams.student);
                });
            }

            function vmChangeTab(page) {
                switch (page) {
                    case 'breport': {
                        $state.go('app.teacher.report.sreport.book');
                        return;
                    }
                    case 'calendar': {
                        $state.go('app.teacher.report.sreport.calendar');
                        return;
                    }
                    case 'summary': {
                        $state.go('app.teacher.report.sreport.summary');
                        return;
                    }
                }
            }

            function vmChangeClass(data) {
                $state.go($state.current.name, { clas: data.idnumber, student: null });

            };

            function vmChangeStudent(token) {
                $state.go($state.current.name, { clas: $stateParams.clas, student: token });
            }
        })
})();