(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.parent.child', {
                url: '/child/:child',
                controller: 'parentChildCtrl',
                templateUrl: '/assets/ng1/library/report/report.html',
                deepStateRedirect: {
                    default: { state: 'app.parent.child.calendar' },
                    params: true
                }
            })
            .state('app.parent.child.calendar', {
                url: '/calendar',
                params:{
                    token: null
                },
                resolve: {
                    token: function ($stateParams) {

                        return $stateParams.child;
                    }
                },              
                controller: 'libraryReportCalendarCtrl',
                templateUrl: '/assets/ng1/library/report/calendar.html'
            })
            .state('app.parent.child.summary', {
                url: '/summary',
                params: {
                    token: null
                },
                resolve: {
                    token: function ($stateParams) {

                        return $stateParams.child;
                    }
                },
                controller: 'libraryReportSummaryCtrl',
                templateUrl: '/assets/ng1/library/report/summary.html'
            })
            .state('app.parent.child.pronunciation', {
                url: '/pronunciation',
                params: {
                    token: null
                },
                resolve: {
                    token: function ($stateParams) {

                        return $stateParams.child;
                    }
                },
                controller: 'libraryReportPronunciationCtrl',
                templateUrl: '/assets/ng1/library/report/pronunciation.html'
            })
    }])

    .controller('parentChildCtrl', function ($scope, $stateParams, $state) {

        $scope.$emit('activeTabTop', $stateParams.child);

        // watch for library tab changed
        $scope.$on('sideTabChange', function (event, data) {
            $scope.activeTab = data;
        });
        $state.go('app.parent.child.calendar');
    })
})();