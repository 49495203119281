(function () {
  'use strict';

  angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

      $stateProvider
        .state('app.teacher.workstations.register', {

          url: '/:currentClass/register',
          params: {
            restore: false
          },
          controller: 'registerWorkstationCtrl',
          templateUrl: '/assets/ng1/teacher/workstations/register.html'
        })
    }])
    .controller('registerWorkstationCtrl', function ($scope, $translate, commonService, school, workstationSvc, classes, $state, $stateParams, user) {
      var clientFingerprint = null;
      var workstationData = null;
      var restoreWorkstations = [];
      var school_id = school.id || $stateParams.school;
      commonService.getClientFingerprint(user.project).then(function (fingerprintObj) {
        if (fingerprintObj.msg) alert(fingerprintObj.msg);
        if (!fingerprintObj.value) return;
        clientFingerprint = fingerprintObj.value;
        workstationData = fingerprintObj.components;
      });

      if ($stateParams.currentClass)
        $scope.$emit('sideTabChange', $stateParams.currentClass);
      $scope.testSiteList = workstationSvc.getTestSiteList(classes);
      $scope.workstationNumbers = [];

      var testSite = $stateParams.currentClass ? workstationSvc.getTestSiteByIdnumber(classes, $stateParams.currentClass) : null;
      $scope.workstation = {
        testCenterId: school_id,
        site: testSite,
        number: null,
        specification: null,
        comments: null
        // idPlaceholder: $translate.instant('placeholder.workstationId'),
        // idHint: $translate.instant('hints.workstationId', { schoolId: school_id, testSite: testSite })
      };

      function vmRegister() {
        var workstationId = school_id + '-' + $scope.workstation.site + '-' + $scope.workstation.number;
        var operationPromise = $stateParams.restore
          ? workstationSvc.restoreRegistration(school_id, $scope.workstation.site, workstationId, workstationData, clientFingerprint, $scope.workstation.specification, $scope.workstation.comments)
          : workstationSvc.registerWorkstation(school_id, $scope.workstation.site, workstationId, workstationData, clientFingerprint, $scope.workstation.specification, $scope.workstation.comments);

        operationPromise.then(function (response) {
          vmBack(true);
          $scope.error = false;
        }).catch(function (response) {
          $scope.error = true;
        })
      }


      function vmBack(reload) {
        if ($stateParams.currentClass)
          $state.go('app.teacher.workstations.list', { selectFirstClass: false, currentClass: $stateParams.currentClass }, { reload: reload });
        else
          $state.go('app.admin.school.workstations', { selectFirstClass: false }, { reload: reload });
      }

      function vmOnSiteChange() {
        if ($stateParams.restore) {
          workstationSvc.getRegisteredNumbers(school_id, $scope.workstation.site)
            .then(function (response) {
              $scope.workstationNumbers = response.data.map(function (i) { return i.number; });
              restoreWorkstations = response.data;
            });
        }
        else {
          workstationSvc.getFreeNumbers(school_id, $scope.workstation.site)
            .then(function (response) {
              $scope.workstationNumbers = response.data;
            });
        }
      }

      function vmOnNumberChange() {
        if (!$stateParams.restore)
          return;

        var selectedWorkst = restoreWorkstations.find(function (i) { return i.number == $scope.workstation.number; })
        if (selectedWorkst) {
          $scope.workstation.specification = selectedWorkst.specification;
          $scope.workstation.comments = selectedWorkst.comments;
        }
      }

      $scope.register = vmRegister;
      $scope.back = vmBack;
      $scope.onSiteChange = vmOnSiteChange;
      $scope.onNumberChange = vmOnNumberChange;

      vmOnSiteChange();
    })
    .factory('workstationSvc', ['LMWordpress', function (LMWordpress) {
      return {
        getTestSiteByIdnumber: function (classes, idnumber) {
          var cls = classes.find(function (c) { return c.idnumber == idnumber; });
          var testSite = this.getTestSite(cls);
          return testSite;
        },
        getTestSite: function (clsObj) {
          var testSite = clsObj.properties ? JSON.parse(clsObj.properties).district : '';
          return testSite;
        },
        getTestSiteList: function (classes) {
          var that = this;
          return classes.map(function (c) {
            return that.getTestSite(c);
          }).unique(function (c) { return c; });
        },
        checkWorkstationIdExist: function (id) {
          return LMWordpress.workstations.checkWorkstationIdExist(id);
        },
        registerWorkstation: function (testCenterId, testSite, workstationId, workstationData, clientFingerprint, specification, comments) {
          return LMWordpress.workstations.updateWorkstations(testCenterId, testSite, workstationId, workstationData, clientFingerprint, specification, comments);
        },
        restoreRegistration: function (testCenterId, testSite, workstationId, workstationData, clientFingerprint, specification, comments) {
          return LMWordpress.workstations.restoreRegistration(testCenterId, testSite, workstationId, workstationData, clientFingerprint, specification, comments);
        },
        getRegisteredNumbers: function (testCenter, testSite) {
          return LMWordpress.workstations.getRegisteredNumbers(testCenter, testSite);
        },
        getFreeNumbers: function (testCenter, testSite) {
          return LMWordpress.workstations.getFreeNumbers(testCenter, testSite);
        }
      };
    }]);
})();