(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.teacher.tasks.tupdate12', {

            url: '/edit/:task?:page?:state',
            controller: 'teacherUpdateTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/edit.html',
            deepStateRedirect: {
                default: { state: 'app.teacher.tasks.tupdate12.edit' },
                params: true
            }
        })
    }])
    .controller('teacherUpdateTaskCtrl', function ($scope, uuid, commonService, LMAPI, $stateParams, $state, tasks, credentials, user, school) {

        $scope.$emit('sideTabChange', $stateParams.state);
        $scope.$on('editTaskTab', function (event, data) {
            $scope.activeTab = data;
        });
        $scope.$on('taskSave', function (event, task) {
            $scope.task.title = task.title;
            $scope.task.dBegin = task.startdate ? commonService.getLocaleDate(task.startdate) : null;
            $scope.task.dEnd = task.enddate ? commonService.getLocaleDate(task.enddate) : null;
        });

        let _tasks = angular.copy(tasks);

        $scope.task = _tasks.find((x) => x.idnumber == $stateParams.task);
        $scope.task.dBegin = $scope.task.startdate == 0 || $scope.task.startdate == null ? 0 : commonService.getLocaleDate($scope.task.startdate);
        $scope.task.dEnd = $scope.task.enddate == 0 || $scope.task.enddate == null ? 0 : commonService.getLocaleDate($scope.task.enddate);
        vmOpenTabBooks();
        function vmDeleteTask(task) {
            if (confirm('Are you sure?')) {

                LMAPI.deletetask(credentials.session_token, task.idnumber).then(function (response) {
                    tasks.forEach(function (item, i) {
                        if (item.idnumber == task.idnumber) {
                            tasks.splice(i, 1);
                        }
                    })
                    vmBack();
                })
            }
        }
        function vmBack() {
            let mode = localStorage.getItem('mode');
            if (mode === 'schooladmin') {
                $state.go('app.admin.school.tasks.list', { id: $stateParams.id, state: $stateParams.state });
            } else if (mode === 'teacher'){
                $state.go('app.teacher.tasks.list', { id: $stateParams.id, state: $stateParams.state });
            }           
        }
        function vmOpenTabBooks() {
            let mode = localStorage.getItem('mode');
            // fromLibrary
            // fromListQuest
            // fromCreateQuest
            if (mode === 'schooladmin') {

                if ($stateParams.page === 'fromLibrary') {

                    $state.go('app.admin.school.tasks.tupdate12.book');

                } else if ($stateParams.page === 'fromListQuest') {

                    switch ($scope.task.assignment_status) {
                        case 'missing_books_students': 
                        case 'missing_students':                      
                            $state.go('app.admin.school.tasks.tupdate12.user');    
                            break;
                        case 'missing_books':                       
                            $state.go('app.admin.school.tasks.tupdate12.book');
                            break;
                        case 'ready':                       
                            $state.go($state.current.name);
                            break;
                    }

                } else if ($stateParams.page === 'fromCreateQuest') {

                    $state.go('app.admin.school.tasks.tupdate12.user');
                } else if ($stateParams.page === 'btnDetailReport') {
                    if ($scope.task.assignment_status == 'ready') {
                        $state.go('app.admin.school.tasks.tupdate12.detailreport');
                    } else {
                        $state.go('app.admin.school.tasks.tupdate12.edit');
                    }
                } else if ($stateParams.page === 'btnProgressReport') {
                    if ($scope.task.assignment_status == 'ready') {
                        $state.go('app.admin.school.tasks.tupdate12.progressreport');
                    } else {
                        $state.go('app.admin.school.tasks.tupdate12.edit');
                    }
                }
            } else if (mode === 'teacher') {

                if ($stateParams.page === 'fromLibrary') {

                    $state.go('app.teacher.tasks.tupdate12.book');

                } else if ($stateParams.page === 'fromListQuest') {

                    switch ($scope.task.assignment_status) {
                        case 'missing_books_students': 
                        case 'missing_students':                      
                            $state.go('app.teacher.tasks.tupdate12.user');   
                            break;
                        case 'missing_books':                       
                            $state.go('app.teacher.tasks.tupdate12.book');
                            break;
                        case 'ready':                       
                            $state.go($state.current.name);
                            break;
                    }                    

                } else if ($stateParams.page === 'fromCreateQuest') {

                    $state.go('app.teacher.tasks.tupdate12.user');
                } else if ($stateParams.page === 'btnDetailReport') {
                    if ($scope.task.assignment_status == 'ready') {
                        $state.go('app.teacher.tasks.tupdate12.detailreport');
                    } else {
                        $state.go('app.teacher.tasks.tupdate12.edit');
                    }
                } else if ($stateParams.page === 'btnProgressReport') {
                    if ($scope.task.assignment_status == 'ready') {
                        $state.go('app.teacher.tasks.tupdate12.progressreport');
                    } else {
                        $state.go('app.teacher.tasks.tupdate12.edit');
                    }
                }
            }            
        }
        function vmGoStateTodublicate(task, dublicateBooks, dublicateStudents) {
            if (localStorage.getItem('mode') === 'schooladmin') {
                $state.go('app.admin.school.tasks.dublicate', {task: task.$idnumber, 
                                                        originalIdnumber: task.idnumber,
                                                        taskEnd: task.$dateEnd,
                                                        taskBegin: task.$dateBegin,
                                                        description: task.description,
                                                        title: task.title,
                                                        dublicateBooks: dublicateBooks, 
                                                        dublicateStudents: dublicateStudents,
                                                        page: 'fromCreateQuest',
                                                        state: 'current' });
            } else {
                $state.go('app.teacher.tasks.dublicate', {task: task.$idnumber, 
                                                            originalIdnumber: task.idnumber,
                                                            taskEnd: task.$dateEnd,
                                                            taskBegin: task.$dateBegin,
                                                            description: task.description,
                                                            title: task.title,
                                                            dublicateBooks: dublicateBooks, 
                                                            dublicateStudents: dublicateStudents,
                                                            page: 'fromCreateQuest',
                                                            state: 'current' });
            }
        }
        function vmChangeTab(page, originalIdnumber) {
            if (localStorage.getItem('mode') === 'schooladmin') {
                if (originalIdnumber) {
                    switch (page){
                        case 'edit': {
                            $state.go('app.admin.school.tasks.dublicate.edit');
                            return;
                        }
                        case 'book': {
                            $state.go('app.admin.school.tasks.dublicate.book');
                            return;
                        }
                        case 'user': {
                            $state.go('app.admin.school.tasks.dublicate.user');
                            return;
                        }
                        case 'detailreport': {
                            $state.go('app.admin.school.tasks.dublicate.detailreport');
                            return;
                        }
                        case 'progressreport': {
                            $state.go('app.admin.school.tasks.dublicate.progressreport');
                            return;
                        }
                    }
                } else {
                    switch (page){
                        case 'edit': {
                            $state.go('app.admin.school.tasks.tupdate12.edit', { page: 'fromListQuest' });
                            return;
                        }
                        case 'book': {
                            $state.go('app.admin.school.tasks.tupdate12.book', { page: 'fromListQuest' });
                            return;
                        }
                        case 'user': {
                            $state.go('app.admin.school.tasks.tupdate12.user', { page: 'fromListQuest' });
                            return;
                        }
                        case 'detailreport': {
                            $state.go('app.admin.school.tasks.tupdate12.detailreport', { page: 'btnDetailReport' });
                            return;
                        }
                        case 'progressreport': {
                            $state.go('app.admin.school.tasks.tupdate12.progressreport', { page: 'btnProgressReport' });
                            return;
                        }
                    }
                }
            } else {
                if (originalIdnumber) {
                    switch (page){
                        case 'edit': {
                            $state.go('app.teacher.tasks.dublicate.edit');
                            return;
                        }
                        case 'book': {
                            $state.go('app.teacher.tasks.dublicate.book');
                            return;
                        }
                        case 'user': {
                            $state.go('app.teacher.tasks.dublicate.user');
                            return;
                        }
                        case 'detailreport': {
                            $state.go('app.teacher.tasks.dublicate.detailreport');
                            return;
                        }
                        case 'progressreport': {
                            $state.go('app.teacher.tasks.dublicate.progressreport');
                            return;
                        }
                    }
                } else {
                    switch (page){
                        case 'edit': {
                            $state.go('app.teacher.tasks.tupdate12.edit', { page: 'fromListQuest' });
                            return;
                        }
                        case 'book': {
                            $state.go('app.teacher.tasks.tupdate12.book', { page: 'fromListQuest' });
                            return;
                        }
                        case 'user': {
                            $state.go('app.teacher.tasks.tupdate12.user', { page: 'fromListQuest' });
                            return;
                        }
                        case 'detailreport': {
                            $state.go('app.teacher.tasks.tupdate12.detailreport', { page: 'btnDetailReport' });
                            return;
                        }
                        case 'progressreport': {
                            $state.go('app.teacher.tasks.tupdate12.progressreport', { page: 'btnProgressReport' });
                            return;
                        }
                    }
                }
            }      

        }
        function vmOpenPopupTask(task) {

            $scope.task = angular.copy(task);
            $scope.task.$idnumber = uuid.newguid();
            $scope.popupTask = true;

        }
        function vmClosePopup() {

            $scope.popupTask = false;
        }
        function vmCheckDates(begin, end){

            let $begin = begin ? commonService.convertLocalDateToUTCUnix(begin) : null;
            let $end = end ? commonService.convertLocalDateToUTCUnix(end) : null;

            if ($end < $begin) {
                $scope.errorDates = true;
            } else {
                $scope.errorDates = false;
            }
        }
        function getAssignmentsStatus(status) {

            switch (status) {
                case 'missing_books_students':                       
                    return 'Please add books and students to finish';      
                case 'missing_books':                       
                    return 'Please add books to finish';
                case 'missing_students':
                    return 'Please add students to finish';
                case 'ready':                       
                    return 'The assignment is ready';
            }
        }

        $scope.$on('EventEditFormErrorDates', function (event, data) {
            $scope.errorDates = data;      
        });
        $scope.$on('changeAssignmentStatus', function(event, data){
            $scope.task.assignment_status = data;
        });


        $scope.goStateTodublicate = vmGoStateTodublicate;
        $scope.getAssignmentsStatus = getAssignmentsStatus;
        $scope.back = vmBack;
        $scope.deletetask = vmDeleteTask;        
        $scope.OpenPopupTask = vmOpenPopupTask;
        $scope.closePopup = vmClosePopup;
        $scope.changeTab = vmChangeTab;
        $scope.checkDates = vmCheckDates;
    })
})();