(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.teacher.class', {

            url: '/class',
            controller: 'teacherClassCtrl',
            templateUrl: '/assets/ng1/teacher/class/index.html',
            deepStateRedirect: {
                params: true
            },

        })
    }])

    .controller('teacherClassCtrl', function ($scope, $state, LMWordpress, school, token, classes, user, schools) {

        $scope.$emit('teacherModeChange', 'school');

        // watch for side tab changed
        $scope.$on('sideTabChange', function (event, data) {
            $scope.selectedClass = data;
        });
        $scope.token = token;
        $scope.schooladmin = user.roles.some(x => x == 'schooladmin');
        $scope.school = school;

        var schoolId = school.id;

        // get all class which is in school
        // filter classes where current teacher is assigned 
        // and are not expired
        var classList = classes;

        classList.sort(function (a, b) {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        });
        $scope.classList = classList;

        function vmSelectClass(clas) {

            $state.go('app.teacher.class.liststudent', { currentClass: clas.idnumber, teacher: clas.teacher, school: school.id });

        }

        $scope.selectClass = vmSelectClass;

    })
})();