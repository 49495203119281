(function () {
	'use strict';

	angular.module('languabooksApp')

		.config(['$stateProvider', function ($stateProvider) {

			$stateProvider
				.state('app.admin.school.licenses.licensestudents', {
					url: '/licensestudents',
					controller: 'adminLicenseCtrl',
					template: '<ui-view>',
					resolve: {
						schools: function (FRAPI, credentials) {

							return FRAPI.getSchoolsByUser()
								.then((x) => x
									.sort((a, b) => a.title > b.title));

						},
						school: function (commonService, schools) {

							if (schools.length === 0) {
								return null;
							} else {
								var schoolCurrent = commonService.getCurrentSchool() ? schools.find((x) => x.id == commonService.getCurrentSchool()) : schools[0];
								return schoolCurrent;
							}
						}
					},
					deepStateRedirect: {
						default: { state: 'app.admin.school.licenses.licensestudents.list' },
						params: true
					},
				})
				.state('app.admin.school.licenses.licensestudents.list', {
					url: '/list',
					controller: 'adminLicenseStudentsListCtrl',
					templateUrl: '/assets/ng1/admin/licenses/license/license.html'
				})
				.state('app.admin.school.licenses.licensestudents.childCourses', {
					url: '/courses/:group_license_id',
					controller: 'adminLicenseChildCoursesCtrl',
					templateUrl: '/assets/ng1/teacher/license/licenseChildCourses.html'
				})
				.state('app.admin.school.licenses.licensestudents.assign', {
					url: '/assign/:group_license_id?childCourse',
					controller: 'adminLicenseStudentsAssignCtrl',
					templateUrl: '/assets/ng1/teacher/license/licensestudents.html',
					resolve: {
						current_classes: function(classes, commonService) {
							var _date = new Date();
							let currentDate = commonService.convertLocalDateToUTCUnix(_date);

							let _classes = classes.filter((item) => currentDate <= (item.date_end || 1924905600));
							return _classes;
						},
						students: function (FRAPI, current_classes, credentials, commonService) {

							if (current_classes.length > 0) {
								// get list of available classes id's
								var classIds = current_classes.map((item) => item.idnumber).join();
			  
								return FRAPI.getUsersFromCohortTeacherMode(classIds, JSON.stringify(['student']))
									.then(function (response) {
			  
										let uniqStudents = response.unique(x => x.token);
										return uniqStudents.map((student) => {
			  
										  student.classes = response.filter((x) => x.token == student.token)
																	.map((y) => y.idnumber);
			  
										  return student;
										});
									})
							} else {
								return [];
							}

						}

					}
				})
		}])
		.controller('adminLicenseStudentsListCtrl', function ($scope, $state, $stateParams, licenses, LMConfig) {
			$scope.page = 'studentlicenses';
			// watch for side tab changed
			$scope.$emit('adminTabChange', 'licensestudents');

			// configuration
			$scope.config = LMConfig;
			//get id school
			$scope.school = $stateParams.school;
			let _licenses = licenses.filter((x) => x.license_type != 'teacher');
			// need for table-cell view
			$scope.licenses = vmGetChunks(_licenses, 2);

			function vmOpenLicense(license) {
				if (license.vms_execution_type == 'license') {
					$state.go('app.admin.school.licenses.licensestudents.childCourses', {
						group_license_id: license.group_license_id,
						//state: $stateParams.state
					});
				}
				else {
					$state.go('app.admin.school.licenses.licensestudents.assign', {
						license: license.course_idnumber,
						group_license_id: license.group_license_id,
						// state: $stateParams.state
					});
				}
			}
			//restructure header table to the correct position
			$('table.demo').floatThead('reflow');

			$scope.openLicense = vmOpenLicense;
		})
		.controller('adminLicenseChildCoursesCtrl', function (LMConfig, $scope, $state, $stateParams, LMWordpress, licenses) {
			$scope.$emit('adminTabChange', 'licensestudents');
			$scope.back = vmBack;
			$scope.openLicenseCourse = vmOpenLicenseCourse;
			// configuration
			$scope.config = LMConfig;
			// $scope.childCourses = [];
			var license = licenses.find(function (l) { return l.group_license_id == $stateParams.group_license_id; });

			LMWordpress.license.getChildCourses(license.course_idnumber).then(function (response) {
				$scope.childCourses = response.data;
				$scope.childCourseGroups = vmGetChunks($scope.childCourses, 2);
			});

			function vmBack() {
				$state.go('app.admin.school.licenses.licensestudents');
			}

			function vmOpenLicenseCourse(course) {
				$scope.$parent.currentChildCourse = course;
				$state.go('app.admin.school.licenses.licensestudents.assign', {
					group_license_id: license.group_license_id,
					childCourse: course.id,
					// state: $stateParams.state
				});
			}
		})
		.controller('adminLicenseStudentsAssignCtrl', function ($rootScope, $state, LMWordpress, FRAPI, $scope, $stateParams, user, commonService, current_classes, licenses, students, $translate) {

			$scope.$emit('adminTabChange', 'licensestudents');
			$scope.back = vmBack;
			$scope.showAssigned = commonService.showAssignedLicensesWithUTR(user.project);
			$scope.isAdmin = true;
			$scope.classes = angular.copy(current_classes);
			$scope.classes2 =angular.copy(current_classes);
			$scope.classes.push({
				idnumber: null,
				name: 'All'
			});
			$scope.classes2.push({
				idnumber: null,
				name: 'All'
			})
			$scope.arraySelectedAssigned = [];
			$scope.arraySelectedAvailable = [];

			$scope.license = licenses.find((x) => x.group_license_id == $stateParams.group_license_id);
			$scope.students = angular.copy(students);

			$scope.days = $scope.license.date_end ? Math.round((moment(new Date($scope.license.date_end)).unix() - moment(new Date($scope.license.date_start)).unix()) / 60 / 60 / 24) : null;
			$scope.childCourse = null;

			var _date = new Date();
            let today = commonService.convertLocalDateToUTCUnix(_date);

			FRAPI.licenseGetStudents($scope.license.group_license_id, $stateParams.childCourse).then(function (data) {

				$scope.licensestudent = data;
				if ($scope.licensestudent.length > 0) {
					$scope.licensestudent.forEach((x) => {
						let student = students.find((item) => item.token === x.student_token);
						if (student) {
							x.firstname = student.firstname;
							x.lastname = student.lastname;
							x.username = student.username;
							x.classes = student.classes;
							x.token = student.token;

							$scope.students = $scope.students.filter(function (elem) {
								return elem.token != student.token;
							});
						}
						x.$dateAssigned = x.date_assigned ? moment.unix(x.date_assigned).format('MM/DD/YYYY') : null;
						x.$dateTested = x.date_tested ? moment.unix(x.date_tested).format('MM/DD/YYYY') : null;
					})
					$scope.licensestudent = $scope.licensestudent.filter((x) => x.classes);
				}
				//restructure header table to the correct position
				$('table.demo').floatThead('reflow');
			})

			if ($stateParams.childCourse) {
				if ($scope.$parent.currentChildCourse && $scope.$parent.currentChildCourse.id == $stateParams.childCourse) {
					$scope.childCourse = $scope.$parent.currentChildCourse;
				} else {
					LMWordpress.license.getChildCourses($scope.license.course_idnumber, $stateParams.childCourse).then(function (response) {
						$scope.childCourse = response.data[0];
					});
				}
			}

			function vmLicenseAddUsers(studentArr) {

				if (parseInt($scope.license.available_licenses) === 0) {
					alert($translate.instant('teacher.error.notEnough'));
					return;
				}

				if (!$scope.license.is_expired && parseInt($scope.license.available_licenses) > 0) {

					$rootScope.pageLoading = true;

					FRAPI.licenseAssignStudents($scope.license.group_license_id, studentArr.map(function (st) { return st.token }), $stateParams.childCourse).then(function (responce) {
							$rootScope.pageLoading = false;

							studentArr.forEach(function (student) {
								student.comment = $scope.license.comment;
								student.$dateAssigned = moment().format("MM/DD/YYYY");
								$scope.licensestudent.push(student);
								$scope.students = $scope.students.filter(function (elem) {
									return elem.token != student.token;
								});
								$scope.license.available_licenses = parseInt($scope.license.available_licenses) - 1;
								if ($scope.showAssigned) {
									$scope.license.assigned_licenses = parseInt($scope.license.assigned_licenses) + 1;
								}

								student.select = false;
							});
							$scope.arraySelectedAvailable = [];
							//restructure header table to the correct position
							$('table.demo').floatThead('reflow');

						}).catch(function (error) {
							$rootScope.pageLoading = false;

							if (error && error.data) {
								if (error.status == 500) {
									alert(error.data.message);
								} else if (error.status == 403) {
									if (error.data.code === 'U0203') {
										alert($translate.instant('teacher.error.notEnough'));
									} else if (error.data.code === 'U0204') {
										if (studentArr.length == 1) {
											alert($translate.instant('teacher.error.isLicense', { username: studentArr[0].username }));
										} else {
											alert($translate.instant('teacher.error.isLicense'));
										}                                    
									} else {
										alert($translate.instant('teacher.error.error'));
									}
								}
								else {
									alert($translate.instant('teacher.error.error'));
								}
							} else {
								alert($translate.instant('teacher.error.error'));
							}

						})
				} else {
					alert($translate.instant('teacher.error.notEnough'));
				}
			}


			function vmLicenseDeleteUsers(studentArr) {

				$rootScope.pageLoading = true;

				FRAPI.licenseRemoveStudents($scope.license.group_license_id, studentArr.map(function (st) { return st.token }),	$stateParams.childCourse).then(function (responce) {
					$rootScope.pageLoading = false;
					studentArr.forEach(function (student) {
						$scope.students.push(student);
						$scope.licensestudent = $scope.licensestudent.filter(function (elem) {
							return elem.token != student.token;
						});

						var license = $scope.license;
						if (student.group_license_id && student.group_license_id != license.group_license_id) {
							license = licenses.find((x) => x.group_license_id == student.group_license_id);
						}
						license.available_licenses = parseInt(license.available_licenses) + 1;
						if ($scope.showAssigned) {
							license.assigned_licenses = parseInt(license.assigned_licenses) - 1;
						}

						$scope.arraySelectedAssigned = [];
						student.select = false;
					});

					//restructure header table to the correct position
					$('table.demo').floatThead('reflow');
				}).catch(function (error) {
					$rootScope.pageLoading = false;
					console.log(error);
					if (error && error.data) {
						if (error.status == 500) {
							alert(error.data.message);
						} else if (error.status == 403) {
							if (error.data.code === 'U0010') {
								if (studentArr.length == 1) {
									alert($translate.instant('teacher.error.licenseCantBeDeleted', { logins: studentArr[0].username }));
								} else {
									alert($translate.instant('teacher.error.licenseCantBeDeleted'));
								}                                    
							} else {
								alert($translate.instant('teacher.error.error'));
							}
						}
						else {
							alert($translate.instant('teacher.error.error'));
						}
					} else {
						alert($translate.instant('teacher.error.error'));
					}
				})
			}

			function vmAssignAllToLicense(students) {
				vmLicenseAddUsers(students);
			}

			function vmSelectAllAssigned(select) {

				if (select) {
					$scope.listselectedstudent.forEach((x) => x.select = true);
				} else {
					$scope.listselectedstudent.forEach((x) => x.select = false);
				}
			}
			function vmSelectAllAvailable(select) {

				if (select) {
					$scope.list.forEach((x) => x.select = true);
				} else {
					$scope.list.forEach((x) => x.select = false);
				}
			}
			function vmRemoveSelected() {

				$scope.selectassigned = false;
				let selectedStudents = $scope.licensestudent.filter((x) => x.select === true);

				if (selectedStudents.length > 0) {
					vmLicenseDeleteUsers(selectedStudents);
				}
			}

			function vmAssignSelected() {

				$scope.selectavailable = false;

				let selectedStudents = $scope.list.filter((x) => x.select === true);

				if (selectedStudents.length > 0) {
					vmLicenseAddUsers(selectedStudents);
				}
			}

			function vmFlagRemoveSelected(select, username) {

				if (select) {
					$scope.arraySelectedAssigned.push({ select: select, username: username });
				} else {
					$scope.arraySelectedAssigned = $scope.arraySelectedAssigned.filter((x) => x.username !== username);
					if ($scope.arraySelectedAssigned.length === 0) {
						$scope.selectassigned = false;
					}
				}

			}
			function vmFlagAllRemoveSelected(selectassigned) {

				if (selectassigned) {
					$scope.listselectedstudent.forEach((x) => {
						$scope.arraySelectedAssigned.push({ select: true, username: x.username });
					})
				} else {
					$scope.arraySelectedAssigned = [];
				}

			}


			function vmFlagAssignedSelected(select, username) {

				if (select) {
					$scope.arraySelectedAvailable.push({ select: select, username: username });
				} else {
					$scope.arraySelectedAvailable = $scope.arraySelectedAvailable.filter((x) => x.username !== username);
					if ($scope.arraySelectedAvailable.length === 0) {
						$scope.selectavailable = false;
					}
				}

			}
			function vmFlagAllAssignedSelected(selectavailable) {

				if (selectavailable) {
					$scope.list.forEach((x) => {
						$scope.arraySelectedAvailable.push({ select: true, username: x.username });
					})
				} else {
					$scope.arraySelectedAvailable = [];
				}

			}

			function vmBack() {
				var nextState = 'app.admin.school.licenses.licensestudents';
				var params = {};
				if ($stateParams.childCourse) {
					nextState = 'app.admin.school.licenses.licensestudents.childCourses';
					params = {
						group_license_id: $scope.license.group_license_id,
						//state: $stateParams.state
					}
				}
				$state.go(nextState, params);
			}


			$scope.limitAssign = 30;
			$scope.limitAvailable = 30;
			$scope.loadMoreAssign = function () {
				$scope.limitAssign += 20;
			};
			$scope.loadMoreAvailable = function () {
				$scope.limitAvailable += 20;
			};
			$scope.licensedeleteusers = vmLicenseDeleteUsers;
			$scope.licenseaddusers = vmLicenseAddUsers;
			$scope.assignAllToLicense = vmAssignAllToLicense;
			$scope.selectAllAvailable = vmSelectAllAvailable;
			$scope.selectAllAssigned = vmSelectAllAssigned;
			$scope.removeSelected = vmRemoveSelected;
			$scope.assignselected = vmAssignSelected;
			$scope.vmFlagRemoveSelected = vmFlagRemoveSelected;
			$scope.vmFlagAllRemoveSelected = vmFlagAllRemoveSelected;
			$scope.vmFlagAssignedSelected = vmFlagAssignedSelected;
			$scope.vmFlagAllAssignedSelected = vmFlagAllAssignedSelected;

		})
})();