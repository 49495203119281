(function () {
    'use strict';

    angular.module('languabooksApp')

    .directive('vmsUserAdd', function (commonService, FRAPI) {

        function vmContoller($scope, $translate, commonService, FRAPI) {

            //get all possible options languages
            $scope.languages = commonService.languages($scope.user.project);
            $scope.people = $scope.people ? $scope.people : {};
            $scope.people.lang = $scope.languages.find((x) => x.key === 'en').key;

            function vmCheckUsername(str) {

                return commonService.checkUsername(str);
            }

            function vmCheck(str) {

                $scope.space = str && (/\s/.test(str));

                return $scope.space;
            }

            function vmIsPassword(password) {

                if (($scope.editFlag && !password) || ($scope.editFlag && password.length === 0)) {

                    return true;
                } else {

                    $scope.lenPassword = password && password.length >= 8 && !(/\s/.test(password));

                    return $scope.lenPassword;
                }               
            }

            function vmUserExists(login) {

                if (login) {
                    var space = vmCheck(login);

                    if (!space) {

                        FRAPI.userexists(login).then(function (res) {
                             $scope.exists = res.data;
                        })
                    }
                }
            }

            function vmAvailable(password, username) {

                $scope.available = !vmCheck(username) && vmIsPassword(password) && vmCheckUsername(username);
            }
            function vmChangeField(){

                if (!$scope.editFlag) {  $scope.checkChange = true; return;}    
               
                if ($scope.people.email !== $scope._people.email || 
                    $scope.people.age !== $scope._people.age ||
                    $scope.people.lang !== $scope._people.lang ||
                    $scope.people.lastname !== $scope._people.lastname ||
                    $scope.people.firstname !== $scope._people.firstname ||
                    $scope.people.password) {
    
                    $scope.checkChange = true;
                } else{
                    $scope.checkChange = false;
                }
                
            }
            $scope.vmChangeField = vmChangeField;
            $scope.checkUsername = vmCheckUsername;
            $scope.check = vmCheck;
            $scope.userexists = vmUserExists;
            $scope.vmAvailable = vmAvailable;
            $scope.isPassword = vmIsPassword;
        };

        return {
            scope: {
                people: '=vmsPeople',
                available: '=vmsAvailable',
                exists: '=vmsUserExists',
                user: '=',
                _people: '=vmsOldPeople',
                checkChange: '=',
                editFlag: '=editFlag'
            },
            controller: vmContoller,
            templateUrl: '/assets/ng1/common/user_add.html'
        };
    })
    // voice model
     .directive("voiceModel", function (commonService) {
         return {
             restrict: 'A',
             scope: {
                 ngModel: '=ngModel',
                 user: '=user',
                 voices: '=voices'
             },
             controller: function ($scope, $translate, commonService) {
                 let keysVoices = commonService.voiceModels($scope.user.project);
                 let voices = commonService.voices();
                 $scope.voices = keysVoices.map((x) => voices.find((y) => y.key === x))

             }
         }
     })

})();