(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.teacher.workstations.list', {

                    url: '/:currentClass',
                    controller: 'workstationsInClass',
                    templateUrl: '/assets/ng1/teacher/workstations/class.html'
                });
        }])

        .controller('workstationsInClass', function ($scope, LMWordpress, commonService, $stateParams, school, classes, user) {
            $scope.$emit('sideTabChange', $stateParams.currentClass);
            $scope.notRegistered = false;
            $scope.showRestore = false;
            $scope.clas = $stateParams.currentClass;

            $scope.limitAssign = 30;
            $scope.limitAvailable = 30;

            $scope.loadMoreAssign = function () {
                $scope.limitAssign += 20;
            };
            $scope.loadMoreAvailable = function () {
                $scope.limitAvailable += 20;
            };

            vmLoad();

            function vmLoad() {
                commonService.getClientFingerprint(user.project).then(function (fingerprintObj) {
                    // if (fingerprintObj.msg) alert(fingerprintObj.msg); - SAME message shown on 'app.teacher.workstations' load
                    if (!fingerprintObj.value) return;
                    LMWordpress.workstations.isRegistered(fingerprintObj.value).then(function (response) {
                        $scope.notRegistered = !response.data.workstation;
                    });
                })

                LMWordpress.workstations.getList(null, $scope.clas)
                    .then(function (response) {
                        $scope.listWorkstations = response.data;

                        //restructure header table to the correct position
                        $('table.demo').floatThead('reflow');
                    });

                LMWordpress.workstations.canRestore(school.id, classes.map(function (c) { return c.idnumber; })).then(function (response) {
                    $scope.showRestore = response.data;
                });
            }
        })

})();