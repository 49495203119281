(function () {
    'use strict';

    angular.module('languabooksApp')

    .config( function ($stateProvider) {

        $stateProvider
            .state('app.library.curriculum.categories', {
                url: '/categories',
                controller: 'categoriesCtrl',
                templateUrl: '/assets/ng1/library/curriculum/categories/index.html',
                resolve: {
                    categories: function (LMAPICache, course) {

                        return LMAPICache.categories()
                                    .then(function (response) {

                                        // get list of categories
                                        // which are children of curriculum category
                                        var categories = response.filter(function (item) {
                                            return item.parentidnumber == course.categoryidnumber;
                                        });

                                        return categories;
                                    });

                    },
                }
            })
            .state('app.library.curriculum.categories.shelve', {
                url: '/:category',
                controller: 'categoriesShelveCtrl',
                template: '<vms-course-list ng-model="courses" vms-token="token" vms-title="curriculum.name" vms-user="user"></vms-course-list>'
            })
            .state('app.teacher.curriculum.categories', {
                url: '/categories',
                controller: 'categoriesCtrl',
                templateUrl: '/assets/ng1/library/curriculum/categories/index.html',
                resolve: {
                    categories: function (LMAPICache, course) {

                        return LMAPICache.categories()
                                    .then(function (response) {

                                        // get list of categories
                                        // which are children of curriculum category
                                        var categories = response.filter(function (item) {
                                            return item.parentidnumber == course.categoryidnumber;
                                        });

                                        return categories;
                                    });

                    }
                }
            })
            .state('app.teacher.curriculum.categories.shelve', {
                url: '/:category',
                controller: 'categoriesShelveCtrl',
                template: '<vms-course-list ng-model="courses" vms-token="token" vms-title="curriculum.name" vms-user="user"></vms-course-list>'
            })
            .state('app.admin.school.curriculum.categories', {
                url: '/categories',
                controller: 'categoriesCtrl',
                templateUrl: '/assets/ng1/library/curriculum/categories/index.html',
                resolve: {
                    categories: function (LMAPICache, course) {

                        return LMAPICache.categories()
                                    .then(function (response) {

                                        // get list of categories
                                        // which are children of curriculum category
                                        var categories = response.filter(function (item) {
                                            return item.parentidnumber == course.categoryidnumber;
                                        });

                                        return categories;
                                    });

                    }
                }
            })
            .state('app.admin.school.curriculum.categories.shelve', {
                url: '/:category',
                controller: 'categoriesShelveCtrl',
                template: '<vms-course-list ng-model="courses" vms-token="token" vms-title="curriculum.name" vms-user="user"></vms-course-list>'
            })
    })

    .controller('categoriesCtrl', ['$scope', '$state', '$stateParams', 'LMAPI', 'LMConfig', 'course', 'token', 'nuts', 'categories','user', 'credentials', 'school', function ($scope, $state, $stateParams, LMAPI, LMConfig, course, token, nuts, categories, user, credentials, school) {

        // curriculum
        // and list of child categories
        $scope.curriculum = course;
        $scope.categories = categories;
        $scope.user = user;
        $scope.username = credentials.username;
        $scope.school = school;
        $scope.onOpenQuest = onOpenQuest;
        $scope.onCreateQuest = onCreateQuest;
        $scope.mode = window.localStorage.getItem('mode');

        // throw event main tab change
        $scope.$emit('libraryTabChange', 'categories');

        // watch for categories tab changed
        $scope.$on('categoriesTabChange', function (event, data) {
            $scope.activeTab = data;
        });
      
        // if category id is not defined
        // and categories list is not empty
        // than open first one
        if (!$state.params.category && categories.length > 0) {

            categories.sort(function (a, b) {
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });

            $state.go('.shelve', { category: categories[0].idnumber });
        }
        function onOpenQuest(quest) {

           // let mode = window.localStorage.getItem('mode');

            if ($scope.mode === 'teacher') {

                $state.go('app.teacher.tasks.tupdate12', { task: quest, page: 'fromLibrary', state: 'current'});
              
            } else if ($scope.mode === 'schooladmin') {

                $state.go('app.admin.school.tasks.tupdate12', { task: quest, page: 'fromLibrary', state: 'current' });
            
            }
        }
        function onCreateQuest() {
            if ($scope.mode === 'teacher') {

                $state.go('app.teacher.tasks.tadd', { state: 'current'});
              
            } else if ($scope.mode === 'schooladmin') {

                $state.go('app.admin.school.tasks.tadd', { state: 'current' });
            
            }
        }

    }])

    .controller('categoriesShelveCtrl', ['$scope', '$state', 'token', 'coursesService', function ($scope, $state, token, coursesService) {
        
        $scope.token = token;
        $scope.courses = null;

        // get current category
        var category = $state.params.category;        

        // throw event categories tab change
        $scope.$emit('categoriesTabChange', category);

        // load courses
        // by current category
        coursesService.get(token, category)
            .then(function (courses) {

                $scope.courses = courses.filter(x => x.executiontype == 'curriculum_unit' || x.executiontype == 'standalone_course');

                $scope.courses.sort(function (a, b) {
                    if (a.name > b.name) {
                        return 1;
                    }
                    if (a.name < b.name) {
                        return -1;
                    }
                    return 0;
                });
                $scope.courses.forEach((x, i) => {
                    x.default = i + 1;
                    x.$course_state = x.course_state ? JSON.parse(x.course_state) : {};  
                    x.isAssign = false; 
                })
               
            });
    }])

})();