(function () {
  'use strict';

  angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

      $stateProvider
        .state('app.projectadmin.page.certificates', {
          url: '/certificates',
          resolve: {
            schoolsProjectAdmin: function(FRAPI, credentials) {

              return FRAPI.getSchools().then((response) => {
                  return response.data;
              })
            },
          },
          controller: 'projAdminCertificatesCtrl',
          templateUrl: '/assets/ng1/projectadmin/page/certificates/index.html'
        })
        .state('app.projectadmin.page.cert_details', {
          url: '/certificate_details/:usertoken',
          controller: 'projAdminCertificateDetailsCtrl',
          templateUrl: '/assets/ng1/projectadmin/page/certificates/details.html',
        });
    }])
    .controller('projAdminCertificatesCtrl', function ($scope, $stateParams, projectAdminSvc, schoolsProjectAdmin) {
      $scope.$emit('modeChange', 'page');
      $scope.$emit('leftTabs', 'certificates');
      $scope.schools = projectAdminSvc.getActiveSchools(schoolsProjectAdmin).map(function (schl) { return { id: schl.idnumber, title: schl.name } });;
    })
    .controller('projAdminCertificateDetailsCtrl', function ($scope, $stateParams) {
      $scope.usertoken = $stateParams.usertoken;
    })
})();