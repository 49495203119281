(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.projectadmin.page.ilicense', {
                    url: '/license',
                    controller: 'projectAdminLicenseCtrl',
                    template: '<ui-view>'
                })
                .state('app.projectadmin.page.ilicense.license', {
                    url: '/license',
                    controller: 'pageLicenseCtrl',
                    templateUrl: '/assets/ng1/projectadmin/page/licenses/licenses.html',
                })
                .state('app.projectadmin.page.ilicense.createlicense', {
                    url: '/createlicense/?:license',
                    controller: 'createLicensesCtrl',
                    templateUrl: '/assets/ng1/projectadmin/page/licenses/createLicense.html'
                })
                .state('app.projectadmin.page.ilicense.addQuantity', {
                    url: '/addLicensePO/:licenseId',
                    controller: 'addLicensePO',
                    templateUrl: '/assets/ng1/projectadmin/page/licenses/editLicensePO.html',
                })
                .state('app.projectadmin.page.ilicense.editPO', {
                    url: '/editLicensePO/:licenseId/:orderId',
                    controller: 'addLicensePO',
                    templateUrl: '/assets/ng1/projectadmin/page/licenses/editLicensePO.html',
                }
                )
        }])
        .controller('projectAdminLicenseCtrl', function ($scope) {
            // empty
        })
        .controller('pageLicenseCtrl', function ($scope, $state, commonService, $rootScope) {

            $scope.$emit('modeChange', 'page');
            $scope.$emit('leftTabs', 'license');
            let _date = new Date($rootScope.date);          
            $scope.today = commonService.convertLocalDateToUTCUnix(_date);

            function onEditLicense(license) {
                $state.go('app.projectadmin.page.ilicense.createlicense', { license: license.shop_license_group_idnumber });
            }
            function onCreateLicense() {
                $state.go('app.projectadmin.page.ilicense.createlicense');
            }
            $scope.onEditLicense = onEditLicense;
            $scope.onCreateLicense = onCreateLicense;

        })
        .controller('createLicensesCtrl', function ($scope, $state, $stateParams, commonService) {

            $scope.$emit('modeChange', 'page');
            $scope.$emit('leftTabs', 'license');
            
            $scope.idlicense = $stateParams.license;
            $scope.onBack = onBack;
            $scope.onCreateLicense = onCreateLicense;
            $scope.onCreatePurchase = onCreatePurchase;
            $scope.onEditPurchase = onEditPurchase;

            function onCreateLicense(license) {
                $state.go('app.projectadmin.page.ilicense.createlicense', { license: license.shop_license_group_idnumber });
            }

            function onCreatePurchase(license){
                $state.go('app.projectadmin.page.ilicense.addQuantity', { licenseId: license.shop_license_group_idnumber });
            }
            function onEditPurchase(data) {
                $state.go('app.projectadmin.page.ilicense.editPO', { licenseId: data.shop_license_group_idnumber, orderId: data.order_id });
            }
            function onBack() {
                $state.go('app.projectadmin.page.ilicense.license');
            }
        })
        .controller('addLicensePO', function ($scope, $state, $stateParams) {
         
            $scope.$emit('leftTabs', 'license');
            $scope.orderid = $stateParams.orderId;
            $scope.licenseid = $stateParams.licenseId;
            $scope.onBack = onBack;

            function onBack(license) {
                $state.go('app.projectadmin.page.ilicense.createlicense', { license: license });
            }
            
        })

        .filter('propsFilter', function () {
            return function (items, props) {
                var out = [];

                if (angular.isArray(items)) {
                    var keys = Object.keys(props);

                    items.forEach(function (item) {
                        var itemMatches = false;

                        for (var i = 0; i < keys.length; i++) {
                            var prop = keys[i];
                            var text = props[prop].toLowerCase();
                            if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                                itemMatches = true;
                                break;
                            }
                        }

                        if (itemMatches) {
                            out.push(item);
                        }
                    });
                } else {
                    // Let the output be the input untouched
                    out = items;
                }

                return out;
            };
        })
})();