(function () {
  'use strict';

  angular.module('languabooksApp')

  .config(['$stateProvider', 'LMConfig', function ($stateProvider, LMConfig) {

    $stateProvider
      .state('app.library.market', {
        url: '/market',
        controller: 'marketCtrl',
        templateUrl: '/assets/ng1/library/market/market.html',
        params: {
          current: null
        },
        resolve: {
          subscriptions: function (LMAPImarketCache, $rootScope) {

            let today = moment($rootScope.date).unix();

            return LMAPImarketCache.subscriptions().then(function (response) {
              let Allsubscriptions = [];
              if (response.length > 0) {

                let uniqueSubscriptions = response.unique(x => x.course_id);

                uniqueSubscriptions.forEach(function (elem) {

                  // get id for a course
                  // which was attempted last
                  // this will be the default selected test
                  let subscription1 = response.filter((x) => x.course_id === elem.course_id);

                  let subscription = subscription1.sort((x, y) => {
                    if (y.date_end)
                      return Number.POSITIVE_INFINITY;
                    if (x.date_end)
                      return Number.NEGATIVE_INFINITY;

                    return moment(new Date(y.date_end * 1000)).unix() - moment(new Date(x.date_end * 1000)).unix();
                  })[0];

                  if (subscription.period_code === 'FOREVER' || subscription.date_end === null) {

                    subscription.label = 'ulimited';
                  }
                  if (subscription.license_type_code === 'RENEWABLE') {

                    subscription.label = 'autorenewable';
                  }
                  if (subscription.license_type_code === 'STANDARD' && today <= moment(new Date(subscription.date_end * 1000)).unix()) {

                    subscription.label = 'purchased';
                  }
                  if (subscription.license_type_code === 'STANDARD' && subscription.period_code === 'TRIAL' && subscription.date_end > today) {

                    subscription.label = 'trial';
                  }
                  Allsubscriptions.push(subscription);

                })
              }
              return Allsubscriptions;
            })
          }
        },
        deepStateRedirect: {
          default: { state: 'app.library.market.curriculums' },
          params: ['locale']
        },
      })
      .state('app.library.market.curriculums', {
        url: '/curriculums',
        controller: 'libraryMarketCurriculumsCtrl',
        template: '<vms-market-list ng-model="courses" vms-user-lang="user.lang" vms-product-number="productnumber" vms-current="current"></vms-market-list>'
      })
      .state('app.library.market.books', {
        url: '/books',
        controller: 'libraryMarketBooksCtrl',
        template: '<vms-market-list ng-model="courses" vms-user-lang="user.lang" vms-product-number="productnumber" vms-current="current"></vms-market-list>'
      })
      .state('app.library.market.tests', {
        url: '/tests',
        controller: 'libraryMarketTestsCtrl',
        template: '<vms-market-list ng-model="courses" vms-user-lang="user.lang" vms-product-number="productnumber" vms-current="current"></vms-market-list>'
      })
      .state('app.library.market.other', {
        url: '/other',
        controller: 'libraryMarketOtherCtrl',
        template: '<vms-market-list ng-model="courses" vms-user-lang="user.lang" vms-product-number="productnumber" vms-current="current"></vms-market-list>'
      })
  }])

  .controller('marketCtrl', ['$scope', '$state', 'LMAPICache', 'LMAPImarketCache', 'token', '$stateParams', function ($scope, $state, LMAPICache, LMAPImarketCache, token, $stateParams) {

    // throw main tab change
    $scope.$emit('libraryTabChange', 'market');
    // watch for side tab changed
    $scope.$on('sideTabChange', function (event, data) {
      $scope.activeTab = data;
    });

    LMAPImarketCache.booksmarket().then(function (books) {
      $scope.isBooksTab = books && books.length;
    });

    LMAPImarketCache.testsmarket().then(function (tests) {
      $scope.isTestsTab = tests && tests.length;
    });

    LMAPImarketCache.othermarket().then(function (otherProds) {
      $scope.isOtherTab = otherProds && otherProds.length;
    });

    if ($stateParams.current == 'curriculums') {

      $state.go('app.library.market.curriculums');

    } else if ($stateParams.current == 'books') {

      $state.go('app.library.market.books');

    } else if ($stateParams.current == 'tests') {

      $state.go('app.library.market.tests');
    } else if ($stateParams.current == 'other') {

      $state.go('app.library.market.other');
    }
  }])
  .controller('libraryMarketCurriculumsCtrl', function ($scope, $state, token, $rootScope, LMWordpress, user, productnumber, $q, LMAPImarketCache, subscriptions) {

    $scope.$emit('sideTabChange', 'curriculums');
    $scope.current = 'curriculums';

    let today = moment($rootScope.date);
    let todayUnx = today.unix();

    LMAPImarketCache.collectionmarket().then(function (response) {

      $scope.courses = response;
      response.forEach(function (item) {
        item.price_max = parseFloat(item.price_max).toFixed(2);
        item.price_min = parseFloat(item.price_min).toFixed(2);
        item.end = today.toDate();
        subscriptions.forEach(function (elem) {
          if (elem.idnumber === item.product_shortname) {
            item.label = elem.label;
            item.end = elem.date_end && elem.date_end > todayUnx  ? new Date(elem.date_end * 1000) : today.toDate();
            item.subscriptions = true;
          }
        })
      });
    })
  })
  .controller('libraryMarketBooksCtrl', function ($scope, $state, token, $rootScope, LMWordpress, user, productnumber, $q, LMAPImarketCache, subscriptions) {

    $scope.$emit('sideTabChange', 'books');
    $scope.current = 'books';

    let today = moment($rootScope.date);
    let todayUnx = today.unix();

    LMAPImarketCache.booksmarket().then(function (response) {

      $scope.courses = response;

      response.forEach(function (item) {
        item.price_max = parseFloat(item.price_max).toFixed(2);
        item.price_min = parseFloat(item.price_min).toFixed(2);
        item.end = today.toDate();
        subscriptions.forEach(function (elem) {

          if (elem.idnumber === item.product_shortname) {
            item.label = elem.label;
            item.end = elem.date_end && elem.date_end > todayUnx ? new Date(elem.date_end * 1000) : today.toDate();
            item.subscriptions = true;
          }
        });
      });
    })
  })
  .controller('libraryMarketTestsCtrl', function ($scope, $state, token, $rootScope, LMWordpress, user, productnumber, $q, LMAPImarketCache, subscriptions) {

    $scope.$emit('sideTabChange', 'tests');
    $scope.current = 'tests';

    let today = moment($rootScope.date);
    let todayUnx = today.unix();

    LMAPImarketCache.testsmarket().then(function (response) {

      $scope.courses = response;

      response.forEach(function (item) {
        item.price_max = parseFloat(item.price_max).toFixed(2);
        item.price_min = parseFloat(item.price_min).toFixed(2);
        item.end = today.toDate();

        subscriptions.forEach(function (elem) {

          if (elem.idnumber === item.product_shortname) {
            item.label = elem.label;
            item.end = elem.date_end && elem.date_end > todayUnx ? new Date(elem.date_end * 1000) : today.toDate();
            item.subscriptions = true;
          }
        });
      });
    });
  })
  .controller('libraryMarketOtherCtrl', function ($scope, $state, token, $rootScope, LMWordpress, user, productnumber, $q, LMAPImarketCache, subscriptions) {

    $scope.$emit('sideTabChange', 'other');
    $scope.current = 'other';

    let today = moment($rootScope.date);
    let todayUnx = today.unix();

    LMAPImarketCache.othermarket().then(function (response) {

      $scope.courses = response;

      response.forEach(function (item) {
        item.price_max = parseFloat(item.price_max).toFixed(2);
        item.price_min = parseFloat(item.price_min).toFixed(2);
        item.end = today.toDate();
        subscriptions.forEach(function (elem) {

          if (elem.idnumber === item.product_shortname) {
            item.label = elem.label;
            item.end = elem.date_end && elem.date_end > todayUnx ? new Date(elem.date_end * 1000) : today.toDate();
            item.subscriptions = true;
          }
        });
      });
    });
  })

  .directive('vmsMarketList', ['$state', '$http', '$window', 'LMConfig', 'LMWordpress', function ($state, $http, $window, LMConfig, LMWordpress, LMAPImarketCache) {

    function vmController($scope, $state, LMConfig, $http, LMWordpress, LMAPImarketCache) {

      //   $scope.dateBeginSubscription = new Date();

      vmGetLicenseTypes();

      $scope.config = LMConfig;

      //sort by default name
      vmOrderBy('a_name');

      function vmCloseInfo() {

        $scope.info_course = false;
      };

      function vmOrderBy(name) {
        //for lighting current sort
        $scope.key = name;

        if (name == 'price_max') {

          $scope.orderby = vmOrderPriceDesc;

        } else if (name == 'price_min') {

          $scope.orderby = vmOrderPriceAsc;

        } else if (name == 'a_name') {

          $scope.orderby = vmOrderTitleA;
        } else if (name == 'z_name') {
          $scope.orderby = '-product_fullname';
        }

        function vmOrderTitleA(course) {

          return course.product_fullname || 'ZZZZZ';
        }

        function vmOrderPriceAsc(course) {

          return -(parseInt(course.product_id)) || 99999;
        }
        function vmOrderPriceDesc(course) {

          return parseInt(course.product_id) || 99999;
        }
      };

      //get info.xml
      function vmInfoXml(folder) {

        var url = $scope.config.storage + '/Library/{0}/info.xml'.format(folder);
        return $http({
          method: 'GET',
          url: url,
        });
      }

      function vmAddProduct(product_variant_id, licensetype, datestart, quantity) {

        return LMWordpress.addproduct(product_variant_id, licensetype, datestart, quantity).then(function (responce) {

          //return 1 if product add to cart
          //return 0 if product is already in cart
          if (responce.data[0].vms_shop_add_product_to_cart == 1) {
            //show cart icon with number of products in cart in red circle
            if ($scope.productnumber.length != 0) {
              $scope.productnumber[0].products_qty = $scope.productnumber[0].products_qty + 1;
            } else {
              $scope.productnumber.push({ products_qty: 1 });
            }
          }
        }).catch(function (err) {
          err;
        });
      }

      function vmIsProductCart(product_variant_id, licensetype, datestart, quantity, product_id) {

        LMWordpress.cart().then(function (products) {

          var isCart = products.data.find((x) => product_id === x.product_id);
          if (isCart) {

            LMWordpress.deleteproduct(isCart.product_variant_id).then(function () {

              $scope.productnumber[0].products_qty = $scope.productnumber[0].products_qty - 1;

              vmAddProduct(product_variant_id, licensetype, datestart, quantity)
                          .then(function () {
                            $state.go('app.library.cart', { current: $scope.current });
                          });
            })
          } else {

            vmAddProduct(product_variant_id, licensetype, datestart, quantity)
                  .then(function () {
                    $state.go('app.library.cart', { current: $scope.current });
                  });
          }
        })
      }

      function vmCheckBeginSubscriptionDate(date) {

        var curDate = new Date();

        if (date.setHours(0, 0, 0, 0) < curDate.setHours(0, 0, 0, 0))
          $scope.error = true;
        else
          $scope.error = false;
      }

      function vmSelectDefaultLicenseType(select_variant) {

        if ($scope.licenseTypes && select_variant) {
          $scope.licenseType = $scope.licenseTypes.find(function (item) {
            return item.option_id == select_variant.license_type;
          });
          $scope.select_variant = select_variant;
        }

      }

      function vmGetLicenseTypes() {

        LMAPImarketCache.getlicensetypes().then(function (response) {

          $scope.licenseTypes = response;

        });
      }

      function vmBuyProduct(course) {

        vmLoadDetails(course).then(function (response) {

          if ($scope.info.variants.length == 1) {

            var date = parseInt(new Date().getTime() / 1000);

            vmIsProductCart($scope.info.variants[0].product_variant_id, $scope.info.variants[0].license_type_code, date, 1, course.product_id);

          } else {
            //open info page
            $scope.info_course = true;
          }
        })
      }
      function vmLoadDetails(course) {

        $scope.select_variant = null;
        //load details
        return LMWordpress.productdetails(course.product_id).then(function (response) {
          $scope.info = response.data[0];
          $scope.course = course;
          $scope.info.variants = JSON.parse(response.data[0].variants_json);
          $scope.info.shop_links = JSON.parse(response.data[0].shop_links_json);

          if ($scope.info.variants.length === 1) {
            $scope.licenseType = $scope.licenseTypes.find(function (x) { return $scope.info.variants[0].license_type === x.option_id });
          }

          LMWordpress.translation($scope.info.description_id, $scope.userLang).then(function (response) {
            $scope.translation = response.data;
          });

          //get info.xml then parse xml
          //search attr = text_filename and create url to file
          if (course.vms_course_data_folder) {

            vmInfoXml(course.vms_course_data_folder)
              .then(x => x.data).then(function (info) {

                var xmlDoc = $.parseXML(info);
                var $xml = $(xmlDoc);

                var $file = $xml.find("entry").each(function () {

                  var key = $(this).attr('key');
                  if (key == 'text_filename') {

                    $scope.url = $scope.config.storage + '/Library/{0}/{1}'.format(course.vms_course_data_folder, $(this).text());
                  }
                })
              })
          }
        })
      }
      function vmOpenDetails(course) {

        $scope.msg = false;
        $scope.licenseType = null;

        var promise = vmLoadDetails(course);

        promise.then(function (response) {
          //open info page
          $scope.info_course = true;
        })
      }
      function vmBuy(course, count, select_variant, licenseType, datestart) {

        datestart = parseInt(datestart.getTime() / 1000);

        $scope.msg = false;

        if (count == 1 && !select_variant && !$scope.info_course) {
          $state.go('app.library.cart', { current: $scope.current });

        } else if (select_variant && count != 1) {
          vmIsProductCart(select_variant.product_variant_id, licenseType, datestart, 1, course.product_id);

        } else if (!select_variant && count != 1) {

          $scope.msg = 'You should select variant first';
        } else if (!select_variant && count == 1 && $scope.info_course) {
          vmIsProductCart($scope.info.variants[0].product_variant_id, licenseType, datestart, 1, course.product_id);
        }
      }

      function vmChangeLicenseType(licenseType) {

        $scope.licenseType = licenseType;
      }

      function vmOpenReferenceInNewTab(link) {
        $window.open(link, '_blank');
      }

      $scope.closeInfo = vmCloseInfo;
      $scope.order = vmOrderBy;
      $scope.buyproduct = vmBuyProduct;
      $scope.loaddetails = vmLoadDetails;
      $scope.opendetails = vmOpenDetails;
      $scope.buy = vmBuy;
      $scope.selectDefaultLicenseType = vmSelectDefaultLicenseType;
      $scope.checkBeginSubscriptionDate = vmCheckBeginSubscriptionDate;
      $scope.openReferenceInNewTab = vmOpenReferenceInNewTab;
      $scope.changeLicenseType = vmChangeLicenseType;
    }
    return {
      scope: {
        courses: '=ngModel',
        userLang: '=vmsUserLang',
        productnumber: '=vmsProductNumber',
        current: '=vmsCurrent'
      },
      controller: vmController,
      templateUrl: '/assets/ng1/library/market/list.html'
    }

  }])

})();