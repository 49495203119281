(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.teacher.tasks.tupdate12.user', {

            url: '/users',
            controller: 'teacherUserTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/assignstudent/users.html'
        })
        .state('app.teacher.tasks.dublicate.user', {

            url: '/users',
            controller: 'teacherUserTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/assignstudent/users.html'
        })
    }])
.controller('teacherUserTaskCtrl', function ($scope, $state, $rootScope, $stateParams, FRAPI, $q, gemstones, LMAPI, commonService, user, classes, tasks, credentials) {

    $scope.$emit('sideTabChange', $stateParams.state);
    $scope.$emit('editTaskTab', 'user');   

    var _date = new Date();
    let currentDate = commonService.convertLocalDateToUTCUnix(_date);
    $scope.classes  = classes.filter((item) => currentDate <= (item.date_end || 1924905600));

    $scope.load = false;

    let _tasks = angular.copy(tasks);
    $scope.task = _tasks.find((x) => x.idnumber == $stateParams.task);

    $scope.gemstones_filter = angular.copy(gemstones);
    $scope.gemstones_filter.unshift({
        idnumber: null,
        name: 'All'
    })

    vmGetUsers();

    function vmGetUsers() {        
       
        $scope.classIds = $scope.classes.map((item) => item.idnumber).join();
        if ($scope.classIds.length == 0) { 
           // alert('No Available Classes! Please create class to assign students to Quest.');
            $scope.load = true;
            return;
        }
        let students = FRAPI.getUsersFromCohortTeacherMode($scope.classIds, JSON.stringify(['student']));
        let studentsInTask = LMAPI.taskStudents.allstudents(credentials.session_token, $stateParams.task);

        $q.all({students: students, 
                studentsInTask: studentsInTask}).then((response) => {

            let uniqStudents = response.students.unique(x => x.token);
            let  _availableStudents = uniqStudents.map((student) => {

                student.classes = response.students.filter((x) => x.token == student.token)
                                        .map((y) => y.idnumber);

                return student;
            });

            _availableStudents.forEach((x) => { 
                x.gemstone_level = x.properties ? x.properties.gemstone_level : null;
                x.name_gemstone_level = x.properties ? vmGetGemLevel(x.properties.gemstone_level) : null;
            });

            var assignStudents = [];

            //students which assigned to teacher
            var allStudent = _availableStudents;

            //find students which are assigend and are in current teacher
            allStudent.forEach(function (item, i) {
                response.studentsInTask.data.forEach(function (elem) {

                    if (item.token == elem.token) {
                        assignStudents.push(item);
                        assignStudents.unique(x => x.token);
                    }
                })
            })

            //delete assign student from array all students
            assignStudents.forEach(function (item) {
                allStudent.forEach(function (elem, i) {

                    if (item.token == elem.token) {
                        allStudent.splice(i, 1);

                    }
                })
            })

            $scope.usersInTaskList = assignStudents;
            $scope.usersInSchoolList = allStudent;

            $scope.load = true;

            //restructure header table to the correct position
            $('table.demo').floatThead('reflow');

        })

    }

    function vmBack() {
        if (localStorage.getItem('mode') === 'schooladmin') {
            $state.go('app.admin.school.tasks.list', { state: $stateParams.state });
        }
        else {
            $state.go('app.teacher.tasks.list', { state: $stateParams.state });
        }
    }

    function vmDeleteTask() {
        if (confirm('Are you sure?')) {

            LMAPI.deletetask($stateParams.task).then(function (response) {
                tasks.forEach(function (item, i) {
                    if (item.idnumber == $stateParams.task) {
                        tasks.splice(i, 1);
                        if (localStorage.getItem('mode') === 'schooladmin') {
                            $state.go('app.admin.school.tasks.list', { state: $stateParams.state });
                        }
                        else {
                            $state.go('app.teacher.tasks.list', { state: $stateParams.state });
                        }
                    }
                })
            })
        }
    }

    function vmRemoveStudentFromTask(user) {

        var username = [user.username];
        
        LMAPI.taskStudents.deletestudents(credentials.session_token, $stateParams.task, username).then(function () {

            $scope.error = false;

            $scope.usersInSchoolList.push(user);
            $scope.usersInTaskList = $scope.usersInTaskList.filter(function (item) {
                return item.token != user.token;
            })
            
            tasks.forEach(function (item) {
                if ($stateParams.task == item.idnumber) {

                    item.assignment_status = vmSetAssignmentsStatus(item.assignment_status, $scope.usersInTaskList);
                    $scope.$emit('changeAssignmentStatus', item.assignment_status);
                    return;
                }
            })
            //restructure header table to the correct position
            $('table.demo').floatThead('reflow');

        }).catch(function () {

            $scope.error = true;
        });
    }

    function vmSetAssignmentsStatus(assignment_status, usersInTaskList) {

        switch (assignment_status) {
            case 'missing_books_students':       

                   if (usersInTaskList.length > 0) {
                    return 'missing_books';
                   } else {
                    return 'missing_books_students';
                   }
            case 'missing_books': 

                if (usersInTaskList.length > 0) {
                    return 'missing_books';
                } else {
                    return 'missing_books_students';
                }                      
               
            case 'missing_students':

                if (usersInTaskList.length > 0) {
                    return 'ready';
                } else {
                    return 'missing_students';
                }    
               
            case 'ready':  
                if (usersInTaskList.length > 0) {
                    return 'ready';
                } else {
                    return 'missing_students';
                }                     
              
        }
      
    }

    function vmAssignStudentToTask(user) {

        var username = [user.username];

        LMAPI.taskStudents.addstudents(credentials.session_token, $stateParams.task, username).then(function (res) {

            $scope.error = false;

            $scope.usersInTaskList.push(user);
            $scope.usersInSchoolList = $scope.usersInSchoolList.filter(function (item) {
                return item.token != user.token;
            });
            tasks.forEach(function (item) {
                if ($stateParams.task == item.idnumber) {

                    item.assignment_status = vmSetAssignmentsStatus(item.assignment_status, $scope.usersInTaskList);
                    $scope.$emit('changeAssignmentStatus', item.assignment_status);
                    return;
                }
            })
            //restructure header table to the correct position
            $('table.demo').floatThead('reflow');

        }).catch(function () {

            $scope.error = true;
        });
    }

    function vmFilterByClass(o, i, a) {

        if (!$scope.clasAll) return true;

        var filter = $scope.clasAll.split(' ');
        var keys = ['classes'];

        return filter.every(function (f) {

            return keys.some(function (key) {

                return this[key].indexOf(f) > -1;
            }, o);
        });
    }

    function vmFilterAssignByClass(o, i, a) {

        if (!$scope.clas) return true;

        var filter = $scope.clas.split(' ');
        var keys = ['classes'];

        return filter.every(function (f) {

            return keys.some(function (key) {

                return this[key].indexOf(f) > -1;
            }, o);
        });
    }

    function vmAssignAllToClass(list) {

        if (list.length > 0) {
            var usernames = list.map((x) => x.username);

            LMAPI.taskStudents.addstudents(credentials.session_token, $stateParams.task, usernames).then(function (res) {

                $scope.error = false;

                $scope.usersInTaskList = $scope.usersInTaskList.concat(list);
                var tokens = list.map((x) => x.token);
              
                $scope.usersInSchoolList = $scope.usersInSchoolList.filter(function (item) {
                    return tokens.indexOf(item.token) < 0;
                });

                tasks.forEach(function (item) {
                    if ($stateParams.task == item.idnumber) {
    
                        item.assignment_status = vmSetAssignmentsStatus(item.assignment_status, $scope.usersInTaskList);
                        $scope.$emit('changeAssignmentStatus', item.assignment_status);
                        return;
                    }
                })
                
                //restructure header table to the correct position
                $('table.demo').floatThead('reflow');

            }).catch(function () {

                $scope.error = true;
            });
        }
    }
    function vmGetGemLevel(gemstone_level) {

        if (!gemstone_level) return;

        let gemstone =  gemstones.find((x) => x.idnumber === gemstone_level);

        return gemstone ? gemstone.name : null;
        
    }
    
    $scope.limitAssign = 30;
    $scope.limitAvailable = 30;
    $scope.loadMoreAssign = function () {
        $scope.limitAssign += 20;
    };
    $scope.loadMoreAvailable = function () {
        $scope.limitAvailable += 20;
    };
    $scope.filterInClass = vmFilterAssignByClass;
    $scope.filterInClassAll = vmFilterByClass;
    $scope.assignAllToClass = vmAssignAllToClass;
    $scope.assignStudentToTask = vmAssignStudentToTask;
    $scope.removeStudentFromTask = vmRemoveStudentFromTask;
    $scope.deletetask = vmDeleteTask;
    $scope.back = vmBack;
    $scope.getClassName = commonService.vmsGetClassName;
    })
})();