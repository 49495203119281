angular.module('LMAPI', ['LMConfig'])

.service('LMAPI', ['$http', '$q', 'LMConfig', function ($http, $q, LMConfig) {

    let currentSession;
    let listgetUSStates = [];

    this.setSession = function(_session) {
        currentSession = _session;
    }

    this.login = function (login, password) {

        return $http({
            method: "get",
            url: LMConfig.api.login,
            params: {
                login: login,
                password: password
            }
        });
    };

    this.getCDNAccess = function (session) {

        return $http({
            method: "get",
            url: LMConfig.api.getCDNAccess,
            headers: {
                'x-vms-session': session
            },
            withCredentials: true
        });
    };

    this.getUSStates = function() {

        if (listgetUSStates.length > 0) return Promise.resolve(listgetUSStates);

        let request = $http.get('https://gist.githubusercontent.com/mshafrir/2646763/raw/8b0dbb93521f5d6889502305335104218454c2bf/states_hash.json');
        return request.then(function (resp) {

            let datas = Object.entries(resp.data).map((i) => ({ code: i[0], name: i[1] }));
            
            listgetUSStates = datas;

          return datas;
        });
    };

    this.botQuery = function (text, lang, sessionId, username, token, accessToken) {

        var params = JSON.stringify({
            'query': text,
            'lang': lang,
            'sessionId': sessionId,
            'originalRequest': {
                'source': "LM Bot",
                'data': { 'username': username, 'token': token }
            }
        });

        return $http({

            method: 'POST',
            url: LMConfig.bapi.query,
            headers: {
                "Authorization": "Bearer " + accessToken,
                'Content-Type': 'application/json; charset=UTF-8',
                'x-vms-session': currentSession,              
            },
            data: params
        });
    }
    this.botQueryFR = function(text) {

        let req = {
            method: 'POST',
            url: LMConfig.bapi.dialogflowQuery,
            headers: {
                "Accept": "application/json, text/plain, ",
                "Content-Type": "application/json; charset=utf-8",
                "x-api-key": "rxYIKogYu52xXVvgi12Ig6g7ygddAgWY1TreRm1J"             
            },
            data: JSON.stringify({
                queries: [text],
                languageCode: "en",
                projectId: "fr-dev-qxvk",
                sessionId: "6524037095817331",
            })
        }
        return $http(req).then((r) => { return r});

    }
    this.sentToEmail = function (recipients, subject, body_text, session, file) {
           
        var params = {
            'recipients': recipients,
            'subject': subject,
            'body_text': body_text
        };
        // generate boundary parameter
        // this parameter is mandatory!!
        var boundary = String(Math.random()).slice(2);
        var boundaryMiddle = '--' + boundary + '\r\n';
        var boundaryLast = '--' + boundary + '--\r\n';

        var body = ['\r\n'];

        for (var key in params) {

            body.push('Content-Disposition: form-data; name="' + key + '"\r\n\r\n' + params[key] + '\r\n');
        }

        body = body.join(boundaryMiddle) + boundaryLast;

        return $http({

            method: 'post',
            url: LMConfig.bapi.email,
            headers: {
                'Content-Type': 'multipart/form-data; boundary=' + boundary,
                'x-vms-session': session
            },
            data: body
        });
    }

    this.reminder = function (recipients, date_start, date_end, subject, body_text, session) {

        var params = {
            'recipients': recipients,
            'subject': subject,
            'body_text': body_text,
            'date_start': date_start,
            'date_end': date_end
        };
        // generate boundary parameter
        // this parameter is mandatory!!
        var boundary = String(Math.random()).slice(2);
        var boundaryMiddle = '--' + boundary + '\r\n';
        var boundaryLast = '--' + boundary + '--\r\n';

        var body = ['\r\n'];

        for (var key in params) {

            body.push('Content-Disposition: form-data; name="' + key + '"\r\n\r\n' + params[key] + '\r\n');
        }

        body = body.join(boundaryMiddle) + boundaryLast;

        return $http({

            method: 'post',
            url: LMConfig.bapi.reminder,
            headers: {
                'Content-Type': 'multipart/form-data; boundary=' + boundary,
                'x-vms-session': session
            },
            data: body
        });

    }

    this.categories = function () {

        return $http({
            method: "get",
            url: LMConfig.api.categories,
            headers: {
                'x-vms-session': currentSession,
            },
        })
        .then(x => x.data);
    };
    this.gettasks = function (session, school) {

        return  $http({
                method: 'GET', 
                url: LMConfig.api.gettask,
                headers: {
                    'x-vms-session': session,
                    'Content-Type': 'application/json; charset=UTF-8'
                },
                params: {
                    school: school
                }
        })
    }
    this.addtask = function (session, id, idnumber, title, project, type, start, end, description, school) {

        var params = {
            'id': id,
            'idnumber': idnumber,
            'title': title,
            'project': project,
            'type': type,
            'startdate': start,
            'enddate': end,
            'description': description,
            'school': school,
            'userid': null

        };

        return $http({
            method: 'POST',
            url: LMConfig.api.gettask,
            headers: {
                'x-vms-session': session,
                'Content-Type': 'application/json; charset=UTF-8'
            },
            data: params
        })
    }

    this.updatetask = function (session, id, idnumber, title, project, type, start, end, description, school, userid) {

        var params = {
            'id': id,
            'idnumber': idnumber,
            'title': title,
            'project': project,
            'type': type,
            'startdate': start,
            'enddate': end,
            'description': description,
            'school': school,
            'userid': userid

        };

        return $http({
            method: 'PUT',
            url: LMConfig.api.gettask,
            headers: {
                'x-vms-session': session,
                'Content-Type': 'application/json; charset=UTF-8'
            },
            data:  params
        })
    }
    this.singletask = function (session, task, school) {

        var url = LMConfig.api.singletask.format(task);
        return $http({
            method: 'GET',
            url: url,
            headers: {
                'x-vms-session': session
            },
            params: {
                school: school
            }
        });
    }
    this.arrayTasks = [];

    this.singletaskByID = function (session, task, school) {

        let element = this.arrayTasks.find((x) => x.key == task + school);

        if (element) {

            return Promise.resolve(element.value);

        } else {
            let url = LMConfig.api.singletaskByID.format(task);
            let request = $http({
                method: 'GET',
                url: url,
                headers: {
                    'x-vms-session': session
                },
                params: {
                    school: school
                }
            });
            return request.then((response) => {

                this.arrayTasks.push({
                    key:   task + school,
                    value: response.data
                });
                return response.data;
            });          
        }
    }
    this.deletetask = function (session, task) {

        var url = LMConfig.api.singletask.format(task);
        return $http({
            method: 'DELETE',
            url: url,
            headers: {
                'x-vms-session': session
            }
        });
    }
    this.taskCourses = {
        allcourses: function (session, task) {

            var url = LMConfig.api.taskCourses.courses.format(task);
            return $http({
                method: 'GET',
                url: url,
                headers: {
                    'x-vms-session': session,
                    'Content-Type': 'application/json; charset=UTF-8'
                }
            });
        },
        addcourses: function (session, task, courses) {

            var params = {
                'courses': JSON.stringify(courses) 
            };

            var url = LMConfig.api.taskCourses.courses.format(task);
            return $http({
                method: 'POST',
                url: url,
                headers: {
                    'x-vms-session': currentSession,
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                data: $.param(params)
            });
        },
        deletecourses: function (session, task, courses) {

            var params = {
                'courses': JSON.stringify(courses)
            };

            var url = LMConfig.api.taskCourses.courses.format(task);
            return $http({
                method: 'DELETE',
                url: url,
                headers: {
                    'x-vms-session': currentSession,
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                data: $.param(params)
            });
        }
    }

    this.taskStudents = {

        allstudents: function (session, task) {
            var url = LMConfig.api.taskStudents.students.format(task);
            return $http({
                method: 'GET',
                url: url,
                headers: {
                    'x-vms-session': session
                }
            });
        },
        addstudents: function (session, task, students) {

         
            var params = {
                'students': JSON.stringify(students)
            };

            var url = LMConfig.api.taskStudents.students.format(task);
            return $http({
                method: 'POST',
                url: url,
                headers: {
                    'x-vms-session': session,
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                data: $.param(params)
            });
        },
        deletestudents: function (session, task, students) {

            var params = {
                'students': JSON.stringify(students)
            };

            var url = LMConfig.api.taskStudents.students.format(task);
            return $http({
                method: 'DELETE',
                url: url,
                headers: {
                    'x-vms-session': session,
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                data: $.param(params)
            });
        }
    }
    this.getstudentstasks = function (session, task, date_from, date_to) {

        var url = LMConfig.api.getstudentstasks.format(task);

        return $http({
            method: 'GET',
            url: url,
            headers: {
                'x-vms-session': session
            },
            params: {
                date_from: date_from,
                date_to: date_to
            }
        });
    }
   
    this.courses = function (token, cohort, category, course) {

        var request = $http({
            method: "get",
            url: LMConfig.api.courses,
            headers: { 
                'x-vms-session': currentSession
            },
            params: {
                token: token,
                cohortidnumber: cohort,
                categoryidnumber: category,
                courseidnumber: course
            }
        });

        return request.then(function (response) {

            var courses = response.data;

            courses.forEach(function (course) {

                if (course.enrol)
                    course.enrol = JSON.parse(course.enrol);
                else
                    course.enrol = [];
            })

            return courses;
        });
    };

    this.listCohort = function (token, type) {

        return $http({
            method: "get",
            url: LMConfig.api.listCohort,
            headers: { 
                'x-vms-session': currentSession
            },
            params: {
                token: token,
                type: type
            }
        })
        .then(x => x.data);
    }

    this.getcurriculumreports = function (session,idnumber, role, usage) {

        let params = {
            idnumber: idnumber,
            role: role
        }
        if (usage) {
            params.usage = usage;
        }

        return $http({
            method: "get",
            url: LMConfig.api.getcurriculumreports,
            headers: {
                'x-vms-session': session,
            },
            params: params
        })
    }

    this.getcurriculum = function (session, idnumber, CohortID, DateBegin, DateEnd, DownloadType, CurriculumID, role) {

        return $http({
            method: "get",
            url: LMConfig.api.getcurriculum,
            headers: {
                'x-vms-session': session,
            },
            params: {
                idnumber: idnumber,
                CohortID: CohortID,
                DateBegin: DateBegin,
                DateEnd: DateEnd,
                DownloadType: DownloadType,
                CurriculumID: CurriculumID,
                timezone: moment().utcOffset(),
                role: role
            }
        })
    }

    this.getcurriculumDownload = function (session, idnumber, CohortID, DateBegin, DateEnd, DownloadType, CurriculumID, role) {

        return $http({
            method: "get",
            url: LMConfig.api.getcurriculum,
            headers: {
                'x-vms-session': session,
            },
            params: {
                idnumber: idnumber,
                CohortID: CohortID,
                DateBegin: DateBegin,
                DateEnd: DateEnd,
                DownloadType: DownloadType,
                CurriculumID: CurriculumID,
                timezone: moment().utcOffset(),
                role: role
            },
            responseType: 'arraybuffer'
        })
    }

    this.getcurriculumuserreports = function (session, idnumber, role) {

        return $http({
            method: "get",
            url: LMConfig.api.getcurriculumuserreports,
            headers: {
                'x-vms-session': session,
            },
            params: {
                idnumber: idnumber,
                role: role
            }
        })
    }

    this.getcurriculumuser = function (session, token, idnumber, CohortID, DateBegin, DateEnd, DownloadType, CurriculumID, role) {

        return $http({
            method: "get",
            url: LMConfig.api.getcurriculumuser,
            headers: {
                'x-vms-session': session,
            },
            params: {
                token: token,
                idnumber: idnumber,
                CohortID: CohortID,
                DateBegin: DateBegin,
                DateEnd: DateEnd,
                DownloadType: DownloadType,
                CurriculumID: CurriculumID,
                timezone: moment().utcOffset(),
                role: role
            }         
        })
    }

    this.getcurriculumuserDownload = function (session, token, idnumber, CohortID, DateBegin, DateEnd, DownloadType, CurriculumID, role) {

        return $http({
            method: "get",
            url: LMConfig.api.getcurriculumuser,
            headers: {
                'x-vms-session': session,
            },
            params: {
                token: token,
                idnumber: idnumber,
                CohortID: CohortID,
                DateBegin: DateBegin,
                DateEnd: DateEnd,
                DownloadType: DownloadType,
                CurriculumID: CurriculumID,
                timezone: moment().utcOffset(),
                role: role
            },
            responseType: 'arraybuffer'
        })
    }

    this.user_properties = function (token) {

        return $http({
            method: "GET",
            url: LMConfig.api.user_properties,
            headers: { 
                'x-vms-session': currentSession
            },
            params: {
                token: token
            }
        });
    }

    this.cohortInfo = function (cohorts) {

        return $http({
            method: "get",
            url: LMConfig.api.cohortInfo,
            headers: { 
                'x-vms-session': currentSession
            },
            params: {
                cohorts: cohorts
            }
        })
        .then(x => x.data);
    }

    this.levelsummary = function (token, dateAgo, currentDate) {

        return $http({
            method: "get",
            url: LMConfig.api.levelsummary,
            headers: { 
                'x-vms-session': currentSession
            },
            params: {
                token: token,
                from: dateAgo,
                to: currentDate
            }
        });
    };

    this.pronunciation = function (token, dateAgo, currentDate) {

        return $http({
            method: "get",
            url: LMConfig.api.pronunciation,
            headers: { 
                'x-vms-session': currentSession
            },
            params: {
                token: token,
                from: dateAgo,
                to: currentDate
            }
        });
    };

    this.bestpronounciation = function (token) {

        return $http({
            method: "get",
            url: LMConfig.api.bestpronounciation,
            headers: { 
                'x-vms-session': currentSession
            },
            params: {
                token: token
            }
        });
    };

    this.worstresults = function (token) {

        return $http({
            method: "get",
            url: LMConfig.api.worstresults,
            headers: { 
                'x-vms-session': currentSession
            },
            params: {
                token: token
            }
        });
    };

    this.activitiesprogress = function (token, course, attempt) {

        return $http({
            method: "get",
            url: LMConfig.api.activitiesprogress,
            headers: { 
                'x-vms-session': currentSession
            },
            params: {
                token: token,
                courseidnumber: course,
                attempt: attempt
            }
        });
    };

    this.teachertasks = function (token, project) {

        return $http({
            method: "get",
            url: LMConfig.api.teachertasks,
            headers: { 
                'x-vms-session': currentSession
            },
            params: {
                token: token,
                project: project
            }
        });
    }

    this.scos = function (token, course) {

        return $http({
            method: "get",
            url: LMConfig.api.scos,
            headers: { 
                'x-vms-session': currentSession
            },
            params: {
                token: token,
                courseidnumber: course
            }
        })
        .then(x => x.data);
    };

    this.track = function (token, sco, param) {

        return $http({
            method: "get",
            url: LMConfig.api.track,
            headers: { 
                'x-vms-session': currentSession
            },
            params: {
                token: token,
                sco: sco,
                param: param
            }
        })
    }

    this.sco = function (token, sco) {

        return $http({
            method: "get",
            url: LMConfig.api.sco,
            headers: {
                'x-vms-session': currentSession,
            },
            params: {
                token: token,
                activityid: sco
            }
        });
    };


    this.stats = function (user) {

        var def = $q.defer();

        def.resolve({
            total: 80,
            completed: 20,
            inprogress: 10,
            average: 50
        });

        return def.promise;
    };

    this.events = function (token, application) {

        var request = $http({
            method: "get",
            url: LMConfig.api.events,
            headers: {
                'x-vms-session': currentSession,
            },
            params: {
                token: token,
                applicationid: application
            }
        });

        return request.then(function (response) {

            return response.data;
        });
    };

    this.calendar = function (token, from, to, coursesFilter) {

        var request = $http({
            method: 'get',
            url: LMConfig.api.calendar,
            headers: {
                'x-vms-session': currentSession,
            },
            params: {
                token: token,
                from: from,
                to: to,
                coursesfilter: coursesFilter
            }
        });

        return request;
    }

    this.summary = function (token, from, to, periodtype, coursesFilter) {

        var request = $http({
            method: 'get',
            url: LMConfig.api.summary,
            headers: {
                'x-vms-session': currentSession,
            },
            params: {
                token: token,
                from: from,
                to: to,
                periodtype: periodtype,
                coursesfilter: coursesFilter
            }
        });

        return request;
    }

    this.nuts = function (token) {

        return $http({

            method: 'get',
            url: LMConfig.api.achievments_nuts,
            headers: {
                'x-vms-session': currentSession,
            },
            params: {
                token: token
            }
        })
        .then(x => x.data);
    }

    this.searchCourses = function (token, searchcriterias) {

        var params = {

            'token': token,
            'searchcriterias': JSON.stringify(searchcriterias)
        };

        var request = $http({

            method: 'post',
            url: LMConfig.api.courses_search,
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'x-vms-session': currentSession
            },
            data: $.param(params)
        });

        return request.then(function (response) {

            return response.data;
        });
    }

    this.coursesScores = function (token, categoryIdNumber, cohortidnumber) {

        var request = $http({

            method: 'get',
            url: LMConfig.api.courses_scores,
            headers: {
                'x-vms-session': currentSession,
            },
            params: {
                token: token,
                categoryidnumber: categoryIdNumber,
                cohortidnumber: cohortidnumber

            }
        });

        return request;
    }

    this.latest = function (token, limit) {

        var request = $http({

            method: 'get',
            url: LMConfig.api.latest,
            headers: {
                'x-vms-session': currentSession,
            },
            params: {
                token: token,
                limit: limit
            }
        });

        return request;
    }

    this.schools = function (token, project) {

        var request = $http({

            method: 'get',
            url: LMConfig.api.schools,
            headers: {
                'x-vms-session': currentSession,
            },
            params: {
                token: token,
                project: project
            }
        });

        return request;

    }

    this.classes = function (token, project, school) {

        var request = $http({

            method: 'get',
            url: LMConfig.api.classes,
            headers: {
                'x-vms-session': currentSession,
            },
            params: {
                token: token,
                project: project,
                school: school
            }
        });

        return request;

    }

    this.tasks = function (token, from, to) {

        var request = $http({

            method: 'get',
            url: LMConfig.api.tasks,
            headers: {
                'x-vms-session': currentSession,
            },
            params: {
                token: token,
                from: from,
                to: to
            }
        });

        return request;
    }

    this.students = function (token, cohort) {

        var request = $http({

            method: 'get',
            url: LMConfig.api.students,
            headers: {
                'x-vms-session': currentSession,
            },
            params: {
                token: token,
                cohort: cohort
            }
        });

        return request;

    }
    this.levels = function (session,curriculumtype, levels) {

        return $http({
            method: "get",
            url: LMConfig.api.levels,
            headers: {
                'x-vms-session': session,
                'Content-Type': 'application/json; charset=UTF-8'
            },
            params: {
                levels: levels,
                curriculum_type: curriculumtype
            }
        }).then(x => x.data);
    }
    this.levelCourses = function (session, levelId) {

        var url = LMConfig.api.levelCourses.format(levelId);

        return $http({
            method: 'GET',
            url: url,
            headers: {
                'x-vms-session': session
            }
        });     
    }


}]);