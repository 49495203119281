angular.module('LMAPI')

.service('LMWebStorage', ['$q', function ($q) {

  // connect to indexed DB
  function vmConnectDB(callback) {

    var request = indexedDB.open('Languabooks', 11);
    request.onsuccess = function () {

      // return database on success
      callback(request.result);
    };
    request.onupgradeneeded = function (e) {

      // get database
      var db = e.currentTarget.result;

      // delete courses cache table if exists
      if (db.objectStoreNames.contains('Courses'))
        db.deleteObjectStore('Courses');

      // delete categories cache table if exists
      if (db.objectStoreNames.contains('CategoriesCourseCache'))
        db.deleteObjectStore('CategoriesCourseCache');

      // delete cohorts cache table if exists
      if (db.objectStoreNames.contains('CohortsCourseCache'))
        db.deleteObjectStore('CohortsCourseCache');

      // delete courses cache table if exists
      if (db.objectStoreNames.contains('CoursesCache'))
        db.deleteObjectStore('CoursesCache');

      // delete scos cache table if exists
      if (db.objectStoreNames.contains('ScosCache'))
        db.deleteObjectStore('ScosCache');

      // delete search cache table if exists
      if (db.objectStoreNames.contains('SearchCache'))
        db.deleteObjectStore('SearchCache');

      // delete categories cache table if exists
      if (db.objectStoreNames.contains('CategoriesCache'))
        db.deleteObjectStore('CategoriesCache');

      // delete cohorts cache table if exists
      if (db.objectStoreNames.contains('CohortsCache'))
        db.deleteObjectStore('CohortsCache');

      // delete subscriptions cache table if exists
      if (db.objectStoreNames.contains('MarketSubscriptionsCache'))
        db.deleteObjectStore('MarketSubscriptionsCache');

      // delete order status cache table if exists
      if (db.objectStoreNames.contains('MarketOrderStatusCache'))
        db.deleteObjectStore('MarketOrderStatusCache');

      // delete order cache table if exists
      if (db.objectStoreNames.contains('MarketOrderCache'))
        db.deleteObjectStore('MarketOrderCache');

      // delete license cache table if exists
      if (db.objectStoreNames.contains('MarketLicenseCache'))
        db.deleteObjectStore('MarketLicenseCache');

      // delete collections cache table if exists
      if (db.objectStoreNames.contains('MarketCollectionsCache'))
        db.deleteObjectStore('MarketCollectionsCache');

      // delete books cache table if exists
      if (db.objectStoreNames.contains('MarketBooksCache'))
        db.deleteObjectStore('MarketBooksCache');

      // delete tests cache table if exists
      if (db.objectStoreNames.contains('MarketTestsCache'))
        db.deleteObjectStore('MarketTestsCache');

      // delete other cache table if exists
      if (db.objectStoreNames.contains('MarketOtherCache'))
        db.deleteObjectStore('MarketOtherCache');

      // create tables
      // and add indexes
      db.createObjectStore("Courses", { keyPath: 'id' });
      db.createObjectStore("CategoriesCourseCache", { keyPath: 'id' });
      db.createObjectStore("CohortsCourseCache", { keyPath: 'id' });
      db.createObjectStore("CoursesCache", { keyPath: 'id' });
      db.createObjectStore("ScosCache", { keyPath: 'id' });
      db.createObjectStore("SearchCache", { keyPath: 'criteria' });
      db.createObjectStore("CategoriesCache", { keyPath: 'id' });
      db.createObjectStore("CohortsCache", { keyPath: 'id' });
      db.createObjectStore("MarketSubscriptionsCache", { keyPath: 'license_id' });
      db.createObjectStore("MarketOrderStatusCache", { keyPath: 'option_id' });
      db.createObjectStore("MarketOrderCache", { keyPath: 'idnumber' });
      db.createObjectStore("MarketLicenseCache", { keyPath: 'option_id' });
      db.createObjectStore("MarketCollectionsCache", { keyPath: 'product_id' });
      db.createObjectStore("MarketBooksCache", { keyPath: 'product_id' });
      db.createObjectStore("MarketTestsCache", { keyPath: 'product_id' });
      db.createObjectStore("MarketOtherCache", { keyPath: 'product_id' });
    };
    request.onerror = vmOnError;
  };

  // get value from database DB
  function vmGet(store, index, key, callback) {

    var def = $q.defer();

    vmConnectDB(function (db) {

      var transaction = db.transaction([store], "readonly");

      var objectStore = transaction.objectStore(store);

      let request;
      if (index && key) {

        request = objectStore.index(index).get(key);

      } else if (key) {

        request = objectStore.get(key);

      } else {

        request = objectStore.getAll();
      };

      request.onsuccess = function () {

        // return data with callback
        var result = request.result ? request.result : null;
        def.resolve(result);
      };
      request.onerror = vmOnError;
    });

    return def.promise;
  };

  // add value to database DB
  function vmAdd(store, data) {

    vmConnectDB(function (db) {

      var transaction = db.transaction([store], "readwrite");

      var request = transaction.objectStore(store).add(data);
      request.onerror = vmOnError;
    });

  };

  // update value in database DB
  function vmSet(store, data) {

    vmConnectDB(function (db) {

      var transaction = db.transaction([store], "readwrite");

      var request = transaction.objectStore(store).put(data);
      request.onerror = vmOnError;
    });
  };

  function vmUpdate(store, index, key, data) {

    vmGet(store, index, key)
        .then(function (item) {

          if (item)
            vmSet(store, data);
          else
            vmAdd(store, data);
        });
  }

  function vmDelete(store, key) {

    vmConnectDB(function (db) {

      var transaction = db.transaction([store], "readwrite");

      var objectStore = transaction.objectStore(store);
      var request = key ? objectStore.delete(key) : objectStore.clear();
      request.onerror = vmOnError;
    });
  }

  function vmOnError(message) {
    // console.log(message);
  };

  return {
    add: vmAdd,
    get: vmGet,
    set: vmSet,
    update: vmUpdate,
    delete: vmDelete
  };

}]);