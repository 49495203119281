(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.projectadmin.page.districts', {
                url: '/districts',
                controller: 'pageDistrictsCtrl',
                templateUrl: '/assets/ng1/projectadmin/page/districts/districts.html'
            })
    }])

    .controller('pageDistrictsCtrl', function ($scope, $state) {

        $scope.$emit('modeChange', 'page');
        $scope.$emit('leftTabs', 'districts');    
        
        function onEditDistrict(district) {
            $state.go('app.projectadmin.page.district', { district: district.idnumber, district_type: district.properties.district_type});
        }
        function onAssignDistrictAdmins(district) {
            $state.go('app.projectadmin.page.districtadmins', { district: district});
        }
        function onCreateDistrict(e) {
            $state.go('app.projectadmin.page.district');
        }
        $scope.onEditDistrict = onEditDistrict;
        $scope.onAssignDistrictAdmins = onAssignDistrictAdmins;
        $scope.onCreateDistrict = onCreateDistrict;
    })

})();