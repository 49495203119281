(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.admin.school.library', {
                url: '/shelve',
                controller: 'libraryShelveCtrl',
                templateUrl: '/assets/ng1/library/shelve/shelve.html',
                resolve: {
                    libraryCourses: function (token, coursesService, user) {


                        return coursesService.get(token, null, user.project).then(function (courses) {
                      
                            courses.forEach((x) => {
                                x.$course_state = x.course_state ? JSON.parse(x.course_state) : {}; 
                                x.isAssign = true; 
                            });
                          
                            return courses;
                             
                        });
                    },
                    cohorts: function (LMAPICache, token) {

                        return LMAPICache.cohorts(token, 'user');
                    },
                    latestCourses: function() {
                       
                       return [];
                    },
                    // quests in Library must show only for student role
                    // that if user have role teacher or admin
                    // api dont must call
                    currentTasks: function (taskService, credentials, commonService, user) {

                        let isStudent = user.roles.every(x => x == 'student');

                        if (isStudent) {
                            // get start of day UTC
                            // and format it
                            var _date = new Date();
                            let date = commonService.convertLocalDateToUTCUnix(_date);
    
                            // get tasks which are valid on current date                     
                            return taskService.get(date, null).then(function (responce) {
                            
                                return responce.data.filter((x) => x.course_passed < x.courses)
                                                    .map(function (item) {
                            
                                                        item.date_from = commonService.getLocaleDate(item.startdate); 
                                                        item.date_to = commonService.getLocaleDate(item.enddate);
                            
                                                        return item;
                                                    })
                            });
                        } else {
                            return [];
                        }

                    },
                }
            })
            .state('app.admin.school.library.curriculums', {
                url: '/curriculums',
                controller: 'libraryShelveCurriculumsCtrl',
                templateUrl: '/assets/ng1/library/shelve/collections.html',
                resolve: {
                    disableCourses: function (LMAPI, libraryCourses, credentials, $q, user) {

                        let isStudent = user.roles.every(x => x == 'student');
                        if (isStudent) {

                            let promise = [];
                            let obj = {};
                            let disableCourses = [];

                            // get curriculum collection
                            let courses = libraryCourses.filter(x => x.enrol && x.enrol == 1)
                                                            .filter(x => x.curriculumtype == "assignments");
                            courses.forEach((y) => { y.$params = JSON.parse(y.params); });

                            let idsCourses = courses.map((x) => x.id);
                            
                            courses.forEach((course) => {
                                promise.push({
                                    course: course.id,
                                    api: LMAPI.levels(credentials.session_token, course.curriculumtype, JSON.stringify(course.$params.p_levels))
                                });
                            });
                            
                            promise.forEach((x) => {
                                obj[x.course] = x.api;
                            });

                            return $q.all(obj).then((responce) => {
                                
                                idsCourses.forEach((x) => {
                                    let isDisableCourse = responce[x].every((x) => !x.assigned);
                                    if (isDisableCourse) {
                                        disableCourses.push(x);
                                    }
                                })
                                return disableCourses;
                            })
                        } else {
                            return [];
                        }
                    }
                }
            })
            .state('app.admin.school.library.books', {
                url: '/books',
                controller: 'libraryShelveBooksCtrl',
                template: '<vms-course-list ng-model="courses" vms-token="token" vms-user="user" vms-page="page"></vms-course-list>'
            })
            .state('app.admin.school.library.questionnaire', {
                url: '/questionnaire',
                controller: 'libraryShelveQuestionnaireCtrl',
                template: '<vms-course-list ng-model="courses" vms-token="token" vms-user="user"></vms-course-list>'
            })
            .state('app.admin.school.library.help', {
                url: '/help',
                controller: 'libraryShelveHelpCtrl',
                template: '<vms-course-list ng-model="courses" vms-token="token" vms-user="user" vms-page="page"></vms-course-list>'
            })
            .state('app.admin.school.library.tests', {
                url: '/tests',
                controller: 'libraryShelveTestsCtrl',
                template: '<vms-course-list ng-model="tests" vms-token="token" vms-user="user"></vms-course-list>'
            })
            .state('app.admin.school.library.search', {
                url: '/search',                
                controller: 'searchCtrl',
                templateUrl: '/assets/ng1/library/search/search.html',
            })
            .state('app.admin.school.library.latest', {
                url: '/latest',
                controller: 'libraryMyLatestCtrl',
                template: '<vms-course-list vms-more-unread="showmsg" ng-model="courses" vms-token="token" vms-remove-sort="true" vms-user="user" vms-page="page"></vms-course-list>'
            })
            .state('app.teacher.library', {
                url: '/shelve',
                controller: 'libraryShelveCtrl',
                templateUrl: '/assets/ng1/library/shelve/shelve.html',
                resolve: {
                    libraryCourses: function (token, coursesService, user) {


                        return coursesService.get(token, null, user.project).then(function (courses) {
                      
                            courses.forEach((x) => {
                                x.$course_state = x.course_state ? JSON.parse(x.course_state) : {}; 
                            });
                          
                            return courses;
                             
                        });
                    },
                    cohorts: function (LMAPICache, token) {

                        return LMAPICache.cohorts(token, 'user');
                    },
                    latestCourses: function() {
                       
                       return [];
                    },
                    // quests in Library must show only for student role
                    // that if user have role teacher or admin
                    // api dont must call
                    currentTasks: function (taskService, credentials, commonService, user) {

                        let isStudent = user.roles.every(x => x == 'student');

                        if (isStudent) {
                            // get start of day UTC
                            // and format it
                            var _date = new Date();
                            let date = commonService.convertLocalDateToUTCUnix(_date);
    
                            // get tasks which are valid on current date                     
                            return taskService.get(date, null).then(function (responce) {
                            
                                return responce.data.filter((x) => x.course_passed < x.courses)
                                                    .map(function (item) {
                            
                                                        item.date_from = commonService.getLocaleDate(item.startdate); 
                                                        item.date_to = commonService.getLocaleDate(item.enddate);
                            
                                                        return item;
                                                    })
                            });
                        } else {
                            return [];
                        }

                    },
                }
            })
            //app.library.shelve.curriculums
            .state('app.teacher.library.curriculums', {
                url: '/curriculums',
                controller: 'libraryShelveCurriculumsCtrl',
                templateUrl: '/assets/ng1/library/shelve/collections.html',
                resolve: {
                    disableCourses: function (LMAPI, libraryCourses, credentials, $q, user) {

                        let isStudent = user.roles.every(x => x == 'student');
                        if (isStudent) {

                            let promise = [];
                            let obj = {};
                            let disableCourses = [];

                            // get curriculum collection
                            let courses = libraryCourses.filter(x => x.enrol && x.enrol == 1)
                                                            .filter(x => x.curriculumtype == "assignments");
                            courses.forEach((y) => { y.$params = JSON.parse(y.params); });

                            let idsCourses = courses.map((x) => x.id);
                            
                            courses.forEach((course) => {
                                promise.push({
                                    course: course.id,
                                    api: LMAPI.levels(credentials.session_token, course.curriculumtype, JSON.stringify(course.$params.p_levels))
                                });
                            });
                            
                            promise.forEach((x) => {
                                obj[x.course] = x.api;
                            });

                            return $q.all(obj).then((responce) => {
                                
                                idsCourses.forEach((x) => {
                                    let isDisableCourse = responce[x].every((x) => !x.assigned);
                                    if (isDisableCourse) {
                                        disableCourses.push(x);
                                    }
                                })
                                return disableCourses;
                            })
                        } else {
                            return [];
                        }
                    }
                }
            })
            .state('app.teacher.library.books', {
                url: '/books',
                controller: 'libraryShelveBooksCtrl',
                template: '<vms-course-list ng-model="courses" vms-token="token" vms-user="user" vms-page="page"></vms-course-list>'
            })
            .state('app.teacher.library.questionnaire', {
                url: '/questionnaire',
                controller: 'libraryShelveQuestionnaireCtrl',
                template: '<vms-course-list ng-model="courses" vms-token="token" vms-user="user"></vms-course-list>'
            })
            .state('app.teacher.library.help', {
                url: '/help',
                controller: 'libraryShelveHelpCtrl',
                template: '<vms-course-list ng-model="courses" vms-token="token" vms-user="user" vms-page="page"></vms-course-list>'
            })
            .state('app.teacher.library.tests', {
                url: '/tests',
                controller: 'libraryShelveTestsCtrl',
                template: '<vms-course-list ng-model="tests" vms-token="token" vms-user="user"></vms-course-list>'
            })
            .state('app.teacher.library.search', {
                url: '/search',                
                controller: 'searchCtrl',
                templateUrl: '/assets/ng1/library/search/search.html',
            })
            .state('app.teacher.library.latest', {
                url: '/latest',
                controller: 'libraryMyLatestCtrl',
                template: '<vms-course-list vms-more-unread="showmsg" ng-model="courses" vms-token="token" vms-remove-sort="true" vms-user="user" vms-page="page"></vms-course-list>'
            })
    }])

    .controller('adminSchoolLibraryCtrl', function ($scope, $state, $stateParams) {
        $scope.$emit('adminTopTabChange', 'library');
        $scope.$emit('teacherModeChange', 'library');
    });

})();