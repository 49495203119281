(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.projectadmin.page.notifications', {
                    url: '/notifications',
                    controller: 'notificationsCtrl',
                    templateUrl: '/assets/ng1/projectadmin/page/notifications/notifications.html',
                })
                .state('app.projectadmin.page.editNotification', {
                    url: '/notification/:id',
                    controller: 'editNotificationCtrl',
                    templateUrl: '/assets/ng1/projectadmin/page/notifications/editNotification.html'
                })
        }])
        .controller('notificationsCtrl', function ($scope, user, $state) {

            $scope.$emit('modeChange', 'page');
            $scope.$emit('leftTabs', 'notifications');

            $scope.user = user;
            $scope.onEditNotification = onEditNotification;
            $scope.onCreateNotification = onCreateNotification;

            function onEditNotification(id){
                $state.go('app.projectadmin.page.editNotification', { id: id});
            }
            function onCreateNotification() {
                $state.go('app.projectadmin.page.editNotification');
            }

        })
        .controller('editNotificationCtrl', function ($scope, $state, $stateParams, user, commonService) {

            $scope.$emit('modeChange', 'page');
            $scope.$emit('leftTabs', 'notifications');

            $scope.user = user;
            $scope.id = $stateParams.id;

            function onBack() {
                $state.go('app.projectadmin.page.notifications');
            }
            $scope.onBack = onBack;
        })
        .filter('propsFilter', function () {
            return function (items, props) {
                var out = [];

                if (angular.isArray(items)) {
                    var keys = Object.keys(props);

                    items.forEach(function (item) {
                        var itemMatches = false;

                        for (var i = 0; i < keys.length; i++) {
                            var prop = keys[i];
                            var text = props[prop].toLowerCase();
                            if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                                itemMatches = true;
                                break;
                            }
                        }

                        if (itemMatches) {
                            out.push(item);
                        }
                    });
                } else {
                    // Let the output be the input untouched
                    out = items;
                }

                return out;
            };
        })
})();