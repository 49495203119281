(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.teacher.tasks.tupdate12.detailreport', {
            params: {
                token: null
            },
            url: '/detailreport',
            controller: 'teacherDetailReportTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/detailreport/detailreport.html'
        })
        .state('app.teacher.tasks.dublicate.detailreport', {
            params: {
                token: null
            },
            url: '/detailreport',
            controller: 'teacherDetailReportTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/detailreport/detailreport.html'
        })
        .state('app.teacher.tasks.bookresults', {

            url: '/bookresults?:idnumber?:token?:state?:title',
            params: {
                extendedParams: null
            },
            controller: 'teacherReportBookResultsCtrl',
            templateUrl: '/assets/ng1/teacher/report/book/results.html'
        })
    }])
    .controller('teacherDetailReportTaskCtrl', function ($scope, tasks, $stateParams, commonService, LMAPI, FRAPI, credentials, user, school, $rootScope, $q, $state) {
        $scope.$emit('sideTabChange', $stateParams.state);
        $scope.$emit('editTaskTab', 'detailreport');

        let detailReport = FRAPI.taskDetailReport($stateParams.task);
        let students = LMAPI.taskStudents.allstudents(credentials.session_token, $stateParams.task);

        $rootScope.pageLoading = true;
        $q.all({
            detailReport: detailReport,
            students: students
        }).then((response) => {
            let results = response.detailReport.sort(function (x, y) {
                return y.lastuse - x.lastuse;
            }).sort((a,b) => (a.attempt_start_date < b.attempt_start_date) ? 1 : ((b.attempt_start_date < a.attempt_start_date) ? -1 : 0));

            results.forEach(function (item) {
                item.course_scores = JSON.parse(item.course_scores);
                if (item.course_scores) {
                    item.$raw_read = item.course_scores.state == 'in_progress' ? null : item.course_scores.raw_read ? Math.round(item.course_scores.raw_read) : 0;
                    item.$raw_first_quiz = item.course_scores.state == 'in_progress' ? null : item.course_scores.raw_first_quiz == null ? 'N/A' : Math.round(item.course_scores.raw_first_quiz);
                    item.$coins_earned = item.course_scores.state == 'in_progress' ? null : item.coins_earned;
                }

                item.$attempt_start_date = item.attempt_start_date ? commonService.getLocaleDate(item.attempt_start_date) : 0;
                item.$lastuse = item.lastuse ? commonService.getLocaleDate(item.lastuse) : 0;

                let Lmin = item.time_listening ? Math.trunc(parseFloat(item.time_listening) / 60) : 0;
                let Rmin = item.time_reading ? Math.trunc(parseFloat(item.time_reading) / 60) : 0;
                let Lsec; let Rsec;
                if (Lmin*60 < parseInt(item.time_listening)) {
                    Lsec = item.time_listening - Lmin*60;
                } else {
                    Lsec = 0;
                }
                if (Rmin*60 < parseInt(item.time_reading)) {
                    Rsec = item.time_reading - Rmin*60;
                } else {
                    Rsec = 0;
                }

                item.$time_listening_min  = commonService.vmAddZero(Lmin) + ":" + commonService.vmAddZero(Lsec);
                item.$time_reading_min = commonService.vmAddZero(Rmin) + ":" + commonService.vmAddZero(Rsec);

                item.lastuse = item.lastuse == 0 || item.lastuse == null ? 0 : moment.unix(item.lastuse).format("MM/DD/YYYY");                
            });
            
            let students = response.students.data;
            students.unshift({
                firstname: ' All',
                lastname: 'Students',
                token: null
            });

            $scope.results = results;
            $scope.students = students;
            if ($stateParams.token == null) {
                $scope.selectStudent = $scope.students.find((x) => x.token === null);
            } else {
                $scope.selectStudent = $scope.students.find((x) => x.token === $stateParams.token);
            }

            $rootScope.pageLoading = false;
        }).catch((error) => {
            $rootScope.pageLoading = false;
        });

        function vmOpenResult(result) {
            let mode = localStorage.getItem('mode');
            let statename = (mode === 'schooladmin') ? 'app.admin.school.tasks.bookresults' : 'app.teacher.tasks.bookresults';

            $state.go(statename, {
                idnumber: result.idnumber,
                token: result.token,
                title: result.title,
                state: $stateParams.state,
                extendedParams: {
                    statename: $state.current.name,
                    token: $scope.selectStudent.token,
                    page: $stateParams.page,
                    task: $stateParams.task,
                    state: $stateParams.state
                }
            });
        };

        $scope.openResult = vmOpenResult;
    })
})();