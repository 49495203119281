(function () {
  'use strict';

  angular.module('languabooksApp')
    .config(['$stateProvider', 'LMConfig', function ($stateProvider, LMConfig) {

      $stateProvider
        .state('app.library.cart', {
          url: '/cart',
          controller: 'cartCtrl',
          params: {
            current: null
          },
          templateUrl: '/assets/ng1/library/market/cart/cart.html'
        })
    }])
    .controller('cartCtrl', ['$scope', '$state', 'LMAPICache', 'LMConfig', 'token', 'user', 'LMWordpress', 'productnumber', '$stateParams', '$q', 'LMAPImarketCache', function ($scope, $state, LMAPICache, LMConfig, token, user, LMWordpress, productnumber, $stateParams, $q, LMAPImarketCache) {

      var confPayment = LMConfig.payment[user.project] || LMConfig.payment.default;

      $scope.paymentUrl = confPayment.url;

      $q.all([LMWordpress.getlicensetypes(), LMWordpress.cart()]).then(function (responses) {

        $scope.licenseTypes = responses[0];

        $scope.total = 0.0;
        responses[1].data.forEach(function (item) {
          item.price = parseFloat(item.price).toFixed(2);
          $scope.total = $scope.total + item.quantity * parseFloat(item.price);
          $scope.show_total = $scope.total.toFixed(2);
          item.date_start = new Date(item.date_start);
          item.license_type = $scope.licenseTypes.find(x=>x.option_id == item.license_type);

        });
        $scope.products = responses[1].data;

      });

      function vmDeleteProduct(id, price) {

        if (confirm('Are you sure?')) {

          LMWordpress.deleteproduct(id).then(function (response) {

            productnumber[0].products_qty = productnumber[0].products_qty - 1;
            $scope.total = $scope.total - price;
            $scope.show_total = $scope.total.toFixed(2);
            $scope.products.forEach(function (item, i) {
              if (item.product_variant_id == id) {
                $scope.products.splice(i, 1);
              }
            })
          })
        }
      }

      function vmUpdateChangesForProduct() {

        var productChangesJSON = $scope.products.map(function (item) {

          return {
            'cart_product_id': item.cart_product_id,
            'license_type': item.license_type.option_code,
            'date_start': parseInt(item.date_start.getTime() / 1000)
          };
        });

        productChangesJSON = JSON.stringify(productChangesJSON);

        LMWordpress.editproductproperties(productChangesJSON).then(function (response) {

          console.log(response);

        }).catch(function (err) {

          console.log(err);
        });
      };

      function vmBack() {

        vmUpdateChangesForProduct();

        $state.go('app.library.market', { current: $stateParams.current });
      }

      function submitPaymentToForm(purchaseOrderId) {
        var login = confPayment.pageLogin;
        var sequenceNumber = Math.ceil(Math.random() * confPayment.maxRandomNumber);
        var unixTimeStamp = Math.ceil(Date.now() / 1000);
        var amount = $scope.show_total;
        var currencyCode = confPayment.currencyCode;

        var data = login + '^' + sequenceNumber + '^' + unixTimeStamp + '^' + amount + '^' + currencyCode;
        var key = confPayment.transactionKey;

        var hash = md5(data, key);

        $scope.payment = {
          login: login,
          project: user.project,
          sequence: sequenceNumber,
          timestamp: unixTimeStamp,
          currencyCode: currencyCode,
          price: amount,
          purchaseOrderId: purchaseOrderId,
          hash: hash,
          items: $scope.products
        }

        $scope.$$postDigest(function () {

          document.querySelector('form[name="paymentForm"]').submit();
        });
      }

      function submitPaymentToServer(purchaseOrderId) {
        LMWordpress.addlicense(purchaseOrderId).then(function (response) {
          alert('License added');
          LMWordpress.productnumber()
          .then(function (x) {
            var arr = x.data || [];
            angular.copy(arr, productnumber);

            LMAPICache.reset();
            LMAPImarketCache.reset();

            $state.go('app.library.market');
          });
        }).catch(function (response) {
          alert(response.data);
        });
      }

      function submitPayment(purchaseOrderId) {
        if ($scope.total) {
          submitPaymentToForm(purchaseOrderId);
        }
        else {
          submitPaymentToServer(purchaseOrderId);
        }
      }

      function vmProceedToCheckout() {

        vmUpdateChangesForProduct();

        LMWordpress.addorder().then(function (response) {
          submitPayment(response.data.vms_shop_order_add);
        });
      }

      function vmCheckBeginSubscriptionDate(date) {

        var curDate = new Date();

        if (date.setHours(0, 0, 0, 0) < curDate.setHours(0, 0, 0, 0))
          $scope.error = true;
        else
          $scope.error = false;
      }

      $scope.deleteproduct = vmDeleteProduct;
      $scope.back = vmBack;
      $scope.proceedToCheckout = vmProceedToCheckout;
      $scope.checkBeginSubscriptionDate = vmCheckBeginSubscriptionDate;
    }])
})();