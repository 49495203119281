(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(function ($stateProvider) {

            $stateProvider
                .state('app.library.curriculum.levels', {
                    url: '/levels',
                    controller: 'levelsCtrl',
                    templateUrl: '/assets/ng1/library/curriculum/levels/index.html',
                    resolve: {
                        levels: function (LMAPI, course, credentials) {

                            let params = JSON.parse(course.params);
                            if (params.p_levels) {
                                return LMAPI.levels(credentials.session_token, course.curriculumtype, JSON.stringify(params.p_levels)).then(function (response) {

                                    params.p_levels.forEach((x, i) => {
                                        response.forEach((elem) => {

                                            if (elem.idnumber === x) {

                                                elem.order = i + 1;
                                            }
                                        });
                                    });
                                    response.sort(function (a, b) {
                                        if (a.order > b.order) {
                                            return 1;
                                        }
                                        if (a.order < b.order) {
                                            return -1;
                                        }
                                        return 0;
                                    });
                                    return response;
                                });
                            } else {
                                return [];
                            }

                        }
                    }
                })
                .state('app.library.curriculum.levels.shelves', {
                    url: '/:level',
                    controller: 'levelsShelveCtrl',
                    template: '<vms-course-list ng-model="courses" vms-levels="levels" vms-level="level" vms-token="token" vms-title="curriculum.name" vms-user="user"></vms-course-list>'
                })
                .state('app.teacher.curriculum.levels', {
                    url: '/levels',
                    controller: 'levelsCtrl',
                    templateUrl: '/assets/ng1/library/curriculum/levels/index.html',
                    resolve: {
                        levels: function (LMAPI, course, credentials) {

                            let params = JSON.parse(course.params);
                            if (params.p_levels) {
                                return LMAPI.levels(credentials.session_token, course.curriculumtype, JSON.stringify(params.p_levels)).then(function (response) {

                                    params.p_levels.forEach((x, i) => {
                                        response.forEach((elem) => {

                                            if (elem.idnumber === x) {

                                                elem.order = i + 1;
                                            }
                                        });
                                    });
                                    response.sort(function (a, b) {
                                        if (a.order > b.order) {
                                            return 1;
                                        }
                                        if (a.order < b.order) {
                                            return -1;
                                        }
                                        return 0;
                                    });
                                    return response;
                                });
                            } else {
                                return [];
                            }

                        }
                    }
                })
                .state('app.teacher.curriculum.levels.shelves', {
                    url: '/:level',
                    controller: 'levelsShelveCtrl',
                    template: '<vms-course-list ng-model="courses" vms-levels="levels" vms-level="level" vms-token="token" vms-title="curriculum.name" vms-user="user"></vms-course-list>'
                })
                .state('app.admin.school.curriculum.levels', {
                    url: '/levels',
                    controller: 'levelsCtrl',
                    templateUrl: '/assets/ng1/library/curriculum/levels/index.html',
                    resolve: {
                        levels: function (LMAPI, course, credentials) {

                            let params = JSON.parse(course.params);
                            if (params.p_levels) {
                                return LMAPI.levels(credentials.session_token, course.curriculumtype, JSON.stringify(params.p_levels)).then(function (response) {

                                    params.p_levels.forEach((x, i) => {
                                        response.forEach((elem) => {

                                            if (elem.idnumber === x) {

                                                elem.order = i + 1;
                                            }
                                        });
                                    });
                                    response.sort(function (a, b) {
                                        if (a.order > b.order) {
                                            return 1;
                                        }
                                        if (a.order < b.order) {
                                            return -1;
                                        }
                                        return 0;
                                    });
                                    return response;
                                });
                            } else {
                                return [];
                            }

                        }
                    }
                })
                .state('app.admin.school.curriculum.levels.shelves', {
                    url: '/:level',
                    controller: 'levelsShelveCtrl',
                    template: '<vms-course-list ng-model="courses" vms-levels="levels" vms-level="level" vms-token="token" vms-title="curriculum.name" vms-user="user"></vms-course-list>'
                })
        })
        .controller('levelsCtrl', function ($scope, $state, $stateParams, LMAPI, LMConfig, course, token, levels, user, credentials, school) {

            // curriculum
            $scope.curriculum = course;
            $scope.user = user;
            $scope.username = credentials.username;
            $scope.school = school;
            $scope.vmOpenLevel = vmOpenLevel;
            $scope.onOpenQuest = onOpenQuest;
            $scope.onCreateQuest = onCreateQuest;
            $scope.onChangeQuest = onChangeQuest;
            $scope.mode = window.localStorage.getItem('mode');

            let hasRole = user.roles.some((x) => x === 'teacher' || x === 'schooladmin' || x === 'projectadministrator');

            if ((course.curriculumtype === 'assignments' || course.curriculumtype === 'grades') && !hasRole) {

                $scope.levels = levels.filter((x) => x.assigned);
            } else {
                // show all levels
                $scope.levels = levels;
            }
            function onOpenQuest(quest) {

              //  let mode = window.localStorage.getItem('mode');

                if ($scope.mode === 'teacher') {

                    $state.go('app.teacher.tasks.tupdate12', { task: quest, page: 'fromLibrary', state: 'current'});
                  
                } else if ($scope.mode === 'schooladmin') {

                    $state.go('app.admin.school.tasks.tupdate12', { task: quest, page: 'fromLibrary', state: 'current' });
                
                }
            }
            function onCreateQuest() {
                if ($scope.mode === 'teacher') {

                    $state.go('app.teacher.tasks.tadd', { state: 'current'});
                  
                } else if ($scope.mode === 'schooladmin') {

                    $state.go('app.admin.school.tasks.tadd', { state: 'current' });
                
                }
            }

            function vmOpenLevel(levelID) {

                let mode = window.localStorage.getItem('mode');

                if (mode === 'library') {
                  // open levels curriculum
                  $state.go('app.library.curriculum.levels.shelves', { level: levelID });
                
                } else if (mode === 'teacher') {
                  $state.go('app.teacher.curriculum.levels.shelves', { level: levelID });
                
                } else if (mode === 'schooladmin') {
                  $state.go('app.admin.school.curriculum.levels.shelves', { level: levelID });
                 
                }
            }
            function onChangeQuest(quest) {
                $scope.$broadcast('change_quest', { quest: quest });
                if (!$scope.$$phase) {
                    $scope.$apply();
                }                
            }
            $scope.$on('limits_books', function(event, data) {

                if ($scope.limitbooks === data.length && data.length >= 10) {
                    $scope.limitbooks = $scope.limitbooks + (Math.random() * 10 + 1);

                } else if ($scope.limitbooks === data.length && data.length < 10) {
                    $scope.limitbooks = $scope.limitbooks - (Math.random() * 10 + 1);
                } else {
                    $scope.limitbooks = data.length;
                }
              
            });

            // throw event main tab change
            $scope.$emit('libraryTabChange', 'cohorts');

            // watch for cohorts tab changed
            $scope.$on('cohortsTabChange', function (event, data) {
                $scope.activeTab = data;
            });

            // if levels id is not defined
            // and levels list is not empty
            // than open first one
            if (!$state.params.level && $scope.levels.length > 0) {
                
                let last_stage = localStorage.getItem(user.token + '_' + $state.params.id);
                let islevel = $scope.levels.find((x) => x.idnumber === last_stage);
                let gotoLevel = islevel ? last_stage : null;
                
                let mode = window.localStorage.getItem('mode');
                if (mode === 'library') {
                  // open levels curriculum
                  $state.go('app.library.curriculum.levels.shelves', { level: gotoLevel || $scope.levels[0].idnumber });
                
                } else if (mode === 'teacher') {
                  $state.go('app.teacher.curriculum.levels.shelves', { level: gotoLevel || $scope.levels[0].idnumber });
                
                } else if (mode === 'schooladmin') {
                  $state.go('app.admin.school.curriculum.levels.shelves', { level: gotoLevel || $scope.levels[0].idnumber });
                 
                }
            };
        })

        .controller('levelsShelveCtrl', function (LMAPI, credentials, user, $scope, $state, token, course, $stateParams, levels) {

            $scope.token = token;
            $scope.courses = null;

            localStorage.setItem(user.token + '_' + $state.params.id, $stateParams.level);

            WriteHistory();

            $scope.level = levels.find((x) => x.idnumber === $stateParams.level);
            //$scope.levels = levels;
            let hasRole = user.roles.some((x) => x === 'teacher' || x === 'schooladmin' || x === 'projectadministrator');

            if ((course.curriculumtype === 'assignments' || course.curriculumtype === 'grades') && !hasRole) {

                $scope.levels = levels.filter((x) => x.assigned);
            } else {
                // show all levels
                $scope.levels = levels;
            }
            // throw event categories tab change
            $scope.$emit('cohortsTabChange', $state.params.level);

            $scope.$on('change_quest', function(event, data) {
               
                let books = localStorage.getItem('current_books');
                if ($scope.courses && $scope.courses.length > 0){
                    $scope.courses.forEach((item) => {
                        let _b = JSON.parse(books).find((y) => y === item.id);
                        item.isAssign = _b ? true : false;
                    })
                }
                if (!$scope.$$phase) {
                    $scope.$apply();
                }                 
            });
            LMAPI.levelCourses(credentials.session_token, $state.params.level)
                .then(function (response) {
                    response.data = response.data.filter((x) => x.enrol !== '0');

                    let isStudent = user.roles.every(x => x == 'student');

                    let books = !isStudent ? localStorage.getItem('current_books') : null;
                    $scope.courses = response.data.map(function (item, i) {

                        item.$course_state = item.course_state ? JSON.parse(item.course_state) : {};
                        item.default = i + 1;
                        item.isAssign = books ? vmShowAssignBooks(books, item.id) : false;

                        // parse course scores
                        if (item.scores)
                            item.scores = JSON.parse(item.scores);

                        return item;
                    });

            });

            function vmShowAssignBooks(books, id) {
                let _b = JSON.parse(books).find((y) => y === id);
                return _b ? true : false;
            }
                
            function WriteHistory(){

                let backParameters = window.localStorage.getItem('historyBackShelve') ? JSON.parse(window.localStorage.getItem('historyBackShelve')) : [];

                let _page = backParameters.find((x) => x.params.id === $stateParams.id);
                let _level = _page ? backParameters.find((x) => x.params.id === $stateParams.id && x.params.level !== $stateParams.level) : null;

                if (!_page) {
                    backParameters.push({
                        state: $state.current.name,
                        params: { id: $stateParams.id, level: $stateParams.level },
                        number: backParameters.length
                    });
                }
                if (_level) {
                    backParameters.forEach((x) => {
                        if (x.params.id === $stateParams.id) {
                            x.params = { id: $stateParams.id, level: $stateParams.level }
                        }                        
                    })
                }

                window.localStorage.setItem('historyBackShelve', JSON.stringify(backParameters));
    
                $('.mainWindow').animate({scrollTop:0}, 'slow');
                $('body, html').animate({scrollTop:0}, 'slow');
            }

        })


})();