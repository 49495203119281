(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.projectadmin.page.district', {
                url: '/createdistrict/?:district?:district_type',
                controller: 'districtCreateCtrl',
                templateUrl: '/assets/ng1/projectadmin/page/districts/createdistrict/createdistrict.html'
            })
    }])

    .controller('districtCreateCtrl', function ($state, $scope, $stateParams) {

        $scope.$emit('modeChange', 'page');
        $scope.$emit('leftTabs', 'districts');
       
        $scope.districtID = $stateParams.district;
        $scope.type = $stateParams.district_type;
        $scope.onBack = onBack;

        function onBack() {
            $state.go('app.projectadmin.page.districts');
        }
    })

})();