(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.admin.school.notifications.notification', {
                    url: '/notification',
                    controller: 'adminNotificationsCtrl',
                    templateUrl: '/assets/ng1/admin/notifications/notification/notification.html',
                })
                .state('app.admin.school.notifications.editNotification', {
                    url: '/notification/:id',
                    controller: 'adminEditNotificationCtrl',
                    templateUrl: '/assets/ng1/admin/notifications/notification/editNotification.html'
                })
        }])

        .controller('adminNotificationsCtrl', function ($scope, user, commonService, $state) {

            // watch for side tab changed
            $scope.$emit('adminTabChange', 'notification');
            $scope.user = user;
            $scope.idschool = commonService.getCurrentSchool();

            $scope.onEditNotification = onEditNotification;
            $scope.onCreateNotification = onCreateNotification;

            function onEditNotification(id){
                $state.go('app.admin.school.notifications.editNotification', { id: id});
            }
            function onCreateNotification() {
                $state.go('app.admin.school.notifications.editNotification');
            }

        })
        .controller('adminEditNotificationCtrl', function ($scope, $state, $stateParams, user, commonService) {

            // watch for side tab changed
            $scope.$emit('adminTabChange', 'notification');
            $scope.id = $stateParams.id;
            $scope.user = user;
            $scope.idschool = commonService.getCurrentSchool();

            function onBack() {
                $state.go('app.admin.school.notifications.notification');
            }
            $scope.onBack = onBack;
        })
})();