(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.projectadmin.page.workstations', {
                    url: '/workstations',
                    resolve: {
                        schoolsProjectAdmin: function(FRAPI, credentials) {

                            return FRAPI.getSchools().then((response) => {
                                return response.data;
                            })
                        },
                    },
                    controller: 'projAdminWorkstationsCtrl',
                    templateUrl: '/assets/ng1/projectadmin/page/workstations/list.html',
                })
        }])
        .controller('projAdminWorkstationsCtrl', function ($rootScope, $scope, $translate, LMWordpress, $stateParams, schoolsProjectAdmin, projectAdminSvc, $filter) {

            $scope.$emit('modeChange', 'page');
            $scope.$emit('leftTabs', 'workstations');
            $scope.workstations = [];
            $scope.statuses = [
                { key: '1', name: $translate.instant('admin.workstation.list.Certified') },
                { key: '0', name: $translate.instant('admin.workstation.list.notCertified') }];
            var schools = projectAdminSvc.getActiveSchools(schoolsProjectAdmin).map(function (schl) { return { id: schl.idnumber, title: schl.name } });
            $scope.schools = schools;
            $scope.selectAll = false;
            var currentSchool = schools && schools.length && schools[0];
            $scope.selectedSchool = { value: currentSchool };

            setTimeout(function () {
                if ($scope.selectedSchool && $scope.selectedSchool.value) {
                    vmOnSchoolChange();
                }
            }, 500);

            function vmOnSchoolChange() {
                $rootScope.pageLoading = true;
                $scope.selectAll = false;
                LMWordpress.workstations.getList($scope.selectedSchool.value.id)
                    .then(function (response) {
                        $scope.workstations = response.data;
                        //restructure header table to the correct position
                        $('table.demo').floatThead('reflow');
                        $scope.workstations.forEach(function (item) {
                            item.$date_creation = item.date_creation ? moment.unix(item.date_creation).utc().format("MM/DD/YYYY") : '';
                        });
                    })
                    .catch(function (e) {
                        $scope.workstations = [];
                        console.error(e);
                    })
                    .finally(function () {
                        $rootScope.pageLoading = false;
                    });
            }

            function vmRemoveWorkstation(workstation) {

                if (confirm($translate.instant('admin.workstation.list.deleteConfirmation'))) {

                    LMWordpress.workstations.deleteWorkstation(workstation.workstation_id).then(function () {
                        $scope.workstations = $scope.workstations.filter(function (w) {
                            return w.workstation_id != workstation.workstation_id;
                        });
                    })
                }
            }

            function vmSearchIsStatus(value, index, array) {
                if (!$scope.status) return true;

                var filter = $scope.status;

                return value.is_certified == filter;
            }

            function vmSelectAllChanged() {
                var allVisibleItems = vmGetFilteredItems();
                allVisibleItems.forEach(function (w) {
                    w.$selected = $scope.selectAll;
                });
            }

            function vmGetFilteredItems() {
                var workstIdFilter = $filter('vmsFilterByList');
                var statusFilter = $filter('filter');
                var filteredItems = workstIdFilter($scope.workstations, $scope.searchWorkstation, ['workstation_id']);
                filteredItems = statusFilter(filteredItems, $scope.searchIsStatus);

                return filteredItems;
            }

            function vmUpdateSelectAll() {
                var filtered = vmGetFilteredItems();
                $scope.selectAll = filtered.length && filtered.every(function (i) { return i.$selected; });
            }

            function vmOnSelectedChanged() {
                vmUpdateSelectAll();
            }

            function vmOnWorkstationFilterChange() {
                vmUpdateSelectAll();
            }

            function vmOnStatusFilterChange() {
                vmUpdateSelectAll();
            }

            function vmSelectedItems() {
                var visibleItems = vmGetFilteredItems();
                return visibleItems.length && visibleItems.some(function (i) { return i.$selected; });
            }

            function vmMarkAsCertified() {
                vmSetCertification(true);
            }

            function vmMarkAsNotCertified() {
                vmSetCertification(false);
            }

            function vmSetCertification(isCertified) {
                var visibleItems = vmGetFilteredItems();
                var selectedItems = visibleItems.filter(function (i) { return i.$selected; });
                var ids = selectedItems.map(function (w) { return w.workstation_id; });

                $rootScope.pageLoading = true;
                LMWordpress.workstations.updateCertificationStatus(ids, isCertified).then(function (response) {
                    response.data.forEach(function (itemResult) {
                        if (itemResult.error) {
                            console.error(itemResult);
                            return;
                        }

                        var workst = selectedItems.find(function (i) { return i.workstation_id == itemResult.workstationId; });
                        workst.is_certified = isCertified ? 1 : 0;
                    });
                }).catch(function (e) {
                    console.error(e);
                }).finally(function () {
                    $rootScope.pageLoading = false;
                });;
            }

            //  class editing functions
            $scope.onSchoolChange = vmOnSchoolChange;
            $scope.removeWorkstation = vmRemoveWorkstation;
            $scope.searchIsStatus = vmSearchIsStatus;
            $scope.selectAllChanged = vmSelectAllChanged;
            $scope.onSelectedChanged = vmOnSelectedChanged;
            $scope.onWorkstationFilterChange = vmOnWorkstationFilterChange;
            $scope.onStatusFilterChange = vmOnStatusFilterChange;
            $scope.selectedItems = vmSelectedItems;
            $scope.markAsCertified = vmMarkAsCertified;
            $scope.markAsNotCertified = vmMarkAsNotCertified;
        })
})();