(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.admin.school.cindex', {
                    url: '/index',
                    controller: 'adminClassesIndexCtrl',
                    templateUrl: '/assets/ng1/admin/classes/index.html',
                    deepStateRedirect: {
                        default: { state: 'app.admin.school.cindex.classes', params: { type: 'active' } },
                        params: true
                    },
                })
        }])

        .controller('adminClassesIndexCtrl', function ($scope, $state, $window, LMConfig, user, commonService, schools) {
          
            $scope.$emit('adminTopTabChange', 'cindex');

            $scope.$on('adminClassesHighlighted', function (event, data) {
                $scope.activeTab = data;
                angular.element('.mainWindow').scrollTop(0);
            });
        })
})();