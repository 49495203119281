(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.parent.manage.children', {
                    url: '/children',
                    controller: 'parentManageChildrenCtrl',
                    templateUrl: '/assets/ng1/parent/manage/children.html'
                })
                .state('app.parent.manage.add', {
                    url: '/children/add',
                    controller: 'parentAddChildCtrl',
                    templateUrl: '/assets/ng1/parent/manage/add.html'
                })
                .state('app.parent.manage.search', {
                    url: '/children/search/:page',
                    controller: 'parentSearchChildCtrl',
                    templateUrl: '/assets/ng1/parent/manage/request.html'
                })
        }])

        .controller('parentManageChildrenCtrl', function ($scope, children, LMWordpress, $state) {

            $scope.$emit('activeTabTop', 'parent');
            $scope.$emit('activeTabLeft', 'children');

            $scope.children = children;

            function vmRemoveChild(child) {

                if (confirm('Are you sure?')) {
                    LMWordpress.removeChild(child.token).then(function (response) {
                        children = children.filter(function (item) {
                            return item.token != child.token;
                        });
                        $state.go('app.parent.manage.children', {}, { reload: true });
                    })
                }
            }

            $scope.removeChild = vmRemoveChild;
        })
        .controller('parentAddChildCtrl', function ($scope, children, LMWordpress, commonService, $state, user, FRAPI) {

            $scope.$emit('activeTab', 'parent');
            $scope.$emit('activeTabLeft', 'children');
            //get all possible options languages
            $scope.languages = commonService.languages(user.project);
            $scope.selected_lang = $scope.languages.find((x) => x.key === 'en').key;

            function vmCheckUsername(str) {

                return commonService.checkUsername(str);
            }

            function vmSaveChild(username, password, firstname, lastname, selected_model, selected_lang) {

                LMWordpress.addchild(username, password, firstname, lastname, selected_model, selected_lang, null).then(function (response) {

                    $scope.error = false;
                    children.push({
                        username: username,
                        firstname: firstname,
                        lastname: lastname,
                        token: response.data
                    });
                    vmBack();

                }, function (error) {
                    $scope.error = true;
                })
            }
            function vmBack() {
                $state.go('app.parent.manage.children');
            }
            function vmCheck(str) {

                if (str && /\s/.test(str)) {
                    return true;
                } else {
                    return false;
                }
            }
            function vmUserExists(login) {

                $scope.exists = false;

                if (login) {
                    var space = vmCheck(login);

                    if (!space) {

                        FRAPI.userexists(login).then(function (res) {
                            $scope.exists = res.data;
                        })
                    }
                }
            }

            function vmIsPassword(password) {

                if (password && password.length >= 8 && !(/\s/.test(password))) {
                    $scope.available = true;
                } else {
                    $scope.available = false;
                }
            }

            $scope.saveChild = vmSaveChild;
            $scope.check = vmCheck;
            $scope.isPassword = vmIsPassword;
            $scope.userexists = vmUserExists;
            $scope.checkUsername = vmCheckUsername;

        })
        .controller('parentSearchChildCtrl', function ($scope, $window, FRAPI, LMWordpress, $rootScope, $state, $stateParams, token, $translate) {

            $scope.$emit('activeTabTop', 'parent');
            $scope.$emit('activeTabLeft', 'children');
            $scope.$emit('adminTabChange', 'teacher');
            $scope.$emit('adminClassesHighlighted', 'active');

            $scope.page = $stateParams.page;
            $scope.idschool = $stateParams.school;
            $scope.idclas = $stateParams.currentClass;
           
            function onBack() {          
                FRAPI.cleargetUsersFromCohortTeacherMode($scope.idschool, JSON.stringify(['teacher']));  
                $state.go('app.admin.school.users.teachers');
            }
            $scope.onBack = onBack;
        })
})();