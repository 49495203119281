(function () {
    'use strict';
  
   angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {
  
        $stateProvider
        .state('app.admin.school.cindex.import', {
          url: '/import/:currentClass',
          controller: 'importStudentCtrl',
          templateUrl: '/assets/ng1/admin/import/importStudent/importStudent.html',
          resolve: {

            licenses: function (FRAPI, $stateParams, $rootScope, commonService) {
              return FRAPI.licensesBySchool(commonService.getCurrentSchool()).then(function (data) {

                let today = moment($rootScope.date).unix(); // moment(new Date()).unix();


                let licenses = data.filter((x) => {
                  let date_start = moment(new Date(x.date_start)).unix();
                  let date_end = moment(new Date(x.date_end)).unix();
                  return (today <= date_end && today >= date_start) ||
                    (!x.date_end && today >= date_start);
                })

                return licenses;
              })
            },
          }
        })
        .state('app.admin.school.users.importstudents', {
          url: '/import',
          controller: 'importStudentCtrl',
          templateUrl:  '/assets/ng1/admin/import/importStudent/importStudent.html',
          resolve: {

            licenses: function (FRAPI, $stateParams, $rootScope, commonService) {
              return FRAPI.licensesBySchool(commonService.getCurrentSchool()).then(function (data) {

                let today = moment($rootScope.date).unix(); // moment(new Date()).unix();


                let licenses = data.filter((x) => {
                  let date_start = moment(new Date(x.date_start)).unix();
                  let date_end = moment(new Date(x.date_end)).unix();
                  return (today <= date_end && today >= date_start) ||
                    (!x.date_end && today >= date_start);
                })

                return licenses;
              })
            }
          }
        })
        .state('app.teacher.class.import', {
          url: '/:school/:currentClass/:teacher/:params/import',
          controller: 'importStudentCtrl',
          templateUrl: '/assets/ng1/admin/import/importStudent/importStudent.html',
          resolve: {
            licenses: function (FRAPI, $stateParams, $rootScope, commonService, credentials) {
              return FRAPI.teacherLicensesBySchool(commonService.getCurrentSchool()).then(function (data) {

                let today = moment($rootScope.date).unix(); // moment(new Date()).unix();

                let licenses = data.filter((x) => {
                  let date_start = moment(new Date(x.date_start)).unix();
                  let date_end = moment(new Date(x.date_end)).unix();
                  return (today <= date_end && today >= date_start) ||
                    (!x.date_end && today >= date_start);
                })

                return licenses;
              })
            },
          }
        })
    }])
  
    .controller('importStudentCtrl',  function (commonService, $state, $scope, user, $translate, importservice, LMWordpress, FRAPI, licenses, $rootScope, $q, $stateParams) {

      // watch for side tab changed
      $scope.$emit('adminTabChange', 'students');
      $scope.$emit('adminClassesHighlighted', 'active');
      $scope.$emit('sideTabChange', $stateParams.currentClass);

      $scope.disableBtn = true;
      $scope.disableAddStudents = false;
      $scope.isMessage = false;

      $scope.groups_id = [];

      $scope.licenses = licenses;

      function vmAddStudents(people) {

        FRAPI.addStudentBulk(people).then(function (response) {

          var resObjArr = response.data;

          $scope.importList.forEach(function (student) {

            student.state = $translate.instant('teacher.import.saved');

            var studResObj = resObjArr.find(function (ro) {
              return ro.username == student.username;
            });

            resObjArr = resObjArr.filter((x) => x.token !== studResObj.token);

            student.invalid = studResObj.result.addStudent || studResObj.result.addToClass || studResObj.result.addLicenses || studResObj.result.addDefLicenses;
            
            if (studResObj.result.addStudent) {
              student.state = $translate.instant('common.forms.errorserverschool');
           
            } else if (studResObj.result.addToClass) {
              student.state = $translate.instant('common.forms.errorserverclass');
           
            } else if (studResObj.result.addLicenses) {
              student.state = $translate.instant('common.forms.errorserverlicense');
            
            }  else if (studResObj.result.addDefLicenses) {
              student.state = $translate.instant('common.forms.notenoughtlicense');
            
            }     
          });
          FRAPI.cleargetUsersFromCohort();     
          $scope.testMessageAboveTable = response.data.some((x) => x.result.error !== 0);            
          $scope.isMessage = true;
          $rootScope.pageLoading = false;
          if (!$scope.$$phase) {
            $scope.$apply();
          }
          localStorage.setItem('import_old_angular', 'import');

        })
        .catch(function (error) {

          $scope.importList.forEach((student) => {

            student.invalid = true;
            student.state = $translate.instant('common.forms.errorserverschool');
          });

          $rootScope.pageLoading = false;

          if (!$scope.$$phase) {
            $scope.$apply();
          }
        });
    }

    function vmDownloadTemplate() {

      let list = [{
        username: null,
        sis_id: null,
        password: null,
        firstname: null,
        lastname: null,
        voice_model: null,
        lang: null,
        grade: null
      }];
      // export json to Worksheet of Excel
      let listWS = XLSX.utils.json_to_sheet(list);

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new();

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, listWS);

      let namefile = $translate.instant('teacher.import.template');

      // export Excel file
      XLSX.writeFile(wb, namefile);
    }
    function vmExportToExsel() {

      $rootScope.pageLoading = true;
      if (!$scope.$$phase) {
        $scope.$apply();
      }

      let list = $scope.importList.map((x) => {
        return {
          username: x.username,
          sis_id: x.sis_id,
          password: x.password,
          firstname: x.firstname,
          lastname: x.lastname,
          voice_model: x.age || x.voice_model,
          lang: x.lang,
          grade: x.grade
        }
      });
      // export json to Worksheet of Excel
      let listWS = XLSX.utils.json_to_sheet(list);

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new();

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, listWS);

      XLSX.writeFile(wb, 'Students.xlsx');
        
      $rootScope.pageLoading = false;
      if (!$scope.$$phase) {
        $scope.$apply();
      }
    }

    function vmCheckLicenses(item) {

      let arraylicenses = item.licenses.toString().split(',').map((x) => x.trim());

      item.getlicense = [];

      // check that all licenses exist for current school 
      // get group licenseWithOptionalCourse id for each case
      let ids = [];
      arraylicenses.forEach((licenseWithOptionalCourse) => {
        var licenseCourseArr = licenseWithOptionalCourse.split(':');
        var license = licenseCourseArr[0];
        var childCourse = licenseCourseArr.length > 1 ? licenseCourseArr[1] : null;
        let temp_license = $scope.licenses.filter((x) => x.shop_license_group_idnumber === license);

        if (temp_license.length === 0) {

          ids.push(license);

          item.invalid = true;
          item.state = $translate.instant('common.forms.errorotherlicense') + ' ' + ids.join();
        }


        temp_license.forEach((lic) => {

          item.getlicense.push({
            available_licenses: parseInt(lic.available_licenses),
            group_license_id: lic.group_license_id,
            course_idnumber: lic.course_idnumber,
            child_course_idnumber: childCourse,
            shop_license_group_idnumber: lic.shop_license_group_idnumber
          });
        })

      });

    }

    function vmLoadExcel(data, fileData) {

      $scope.disableAddStudents = false;
      $scope.isMessage = false;
      $rootScope.pageLoading = true;
      if (!$scope.$$phase) {
        $scope.$apply();
      }

      $scope.attachment = {
        data: fileData.data,
        name: fileData.name,
        type: fileData.type
      };

      // load workbook
      // and first sheet from it
      var workbook = XLSX.read(data, { type: 'binary' });
      var sheet = workbook.Sheets[workbook.SheetNames[0]];

      // convert sheet to json list
      $scope.importList = XLSX.utils.sheet_to_json(sheet).map(function (item) {
        // trim all fields from excel
        for (var property in item) {
          if (item.hasOwnProperty(property) && typeof item[property] === 'string')
            item[property] = item[property].trim();
        }

        item.password ? importservice.vmCheckPassword(item) : importservice.vmGeneratePassword(item);

        item.lang = item.lang ? importservice.vmCheckLang(item, user) : 'en';
        item.grade = item.grade ? vmCheckPhysicalGrade(item, user) : null;

        item.age || item.voice_model ? importservice.vmCheckAge(item, user) : importservice.vmAgeEmpty(item);

        item.def = item.username ? importservice.vmCheckUsername(item) : vmErrorEmptyUsername(item);

        item.licenses ? vmCheckLicenses(item) : null;

        !item.firstname ? importservice.vmCheckName(item, 'firstname') : null;
        !item.lastname ? importservice.vmCheckName(item, 'lastname') : null;

        item.sis_id ? item.sis_id : null;

        return item;
      });

      // check user names
      $q.all($scope.importList.map((x) => x.def))
        .then(() => {

          let usernames = $scope.importList.map((item) => { return item.username });

          let isUnique = importservice.onCheckUniqueUser(usernames, $scope.importList);

          if (!isUnique) {

            var usernamesToCheckOnServer = $scope.importList
              .filter(function (i) { return i.checkUsernameOnServer; })
              .map(function (i) { return i.username; });

            if (usernamesToCheckOnServer.length) {
              FRAPI.userexistsBulk(usernamesToCheckOnServer)
                .then(function (response) {
                  var existingUsers = response.data.filter(function (user) { return user.exists; })
                  existingUsers.forEach(function (eu) {
                    var item = $scope.importList.find(function (i) { return i.username === eu.login; });
                    item.invalid = true;
                    item.state = $translate.instant('common.forms.errorexists');
                  });

                  afterItemChecks().then().finally(vmOnChecksFinish);
                });
            } else {
              afterItemChecks().then().finally(vmOnChecksFinish);
            }
          } else {
            vmOnChecksFinish();
          }

        });

      function vmCheckLicenseCounts() {
        $scope.importList.forEach((student) => {
          if (student.getlicense) {
            let ids = [];
            student.getlicense.forEach((x) => {

              let array = $scope.importList.map((y) => y.licenses).join().split(',');
              let count = array.filter((licIdnumber) => x.shop_license_group_idnumber === licIdnumber.trim()).length; /// ??

              if (count > parseInt(x.available_licenses)) {
                ids.push(x.shop_license_group_idnumber);
                student.invalid = true;
                student.state = $translate.instant('common.forms.errorlicense') + ' ' + ids.join();
              }
            })
          }
        });
      }

      function vmOnChecksFinish() {
        $scope.disableBtn = $scope.importList.some((x) => !!x.invalid);

        $rootScope.pageLoading = false;
        if (!$scope.$$phase) {
          $scope.$apply();
        }
        // restructure header table to the correct position
        $('table.demo').floatThead('reflow');
      }

      function afterItemChecks() {
        var deferred = $q.defer();
        vmCheckLicenseCounts();
        vmCheckLicenseChildCourses().then(deferred.resolve);

        return deferred.promise;
      }

      function vmCheckLicenseChildCourses() {
        var deferred = $q.defer();
        var childCoursesToCheck = [];
        var itemsWithLicense = $scope.importList.filter(function (i) {
          return !i.invalid && i.getlicense;
        });
        itemsWithLicense.forEach(function (i) {
          i.getlicense.forEach(function (l) {
            if (l.child_course_idnumber && !childCoursesToCheck.includes(l.child_course_idnumber))
              childCoursesToCheck.push({ lic_course_idnumber: l.course_idnumber, child_course_idnumber: l.child_course_idnumber });
          });
        });

        if (childCoursesToCheck.length) {
          LMWordpress.getChildCourseIds(childCoursesToCheck).then(function (result) {
            itemsWithLicense.forEach(function (i) {
              i.getlicense.forEach(function (l) {
                if (i.invalid || !l.child_course_idnumber) return;
                var id = result.data[l.course_idnumber + ':' + l.child_course_idnumber];
                if (id) {
                  l.child_course_id = id;
                } else {
                  i.invalid = true;
                  i.state = $translate.instant('common.forms.errorserverlicense_childcourse');
                }
              });
            });
            deferred.resolve();
          });
        }
        else {
          deferred.resolve();
        }

        return deferred.promise;
      }
    };


    function vmCheckPhysicalGrade(item, user) {

      let listPhysicalGrades = commonService.listGrades(user.project);

      let isGrade = listPhysicalGrades.some((x) => x.key === item.grade);

      if (!isGrade) {
          item.invalid = true;
          item.state = $translate.instant('common.forms.errorgrade');
      }
      return item.grade;
    }

    function vmAccept() {

      $scope.disableAddStudents = true;
      // download file if password or username was generated and didnt have mistakes
      let download = $scope.importList.some((x) => !x.invalid && x.download);

      if (download) {
        vmExportToExsel();
      }

      const people = {
        addToClass: $stateParams.currentClass || null,
        people: []
      };

      $rootScope.pageLoading = $scope.importList.length;
      $scope.importList.forEach((student) => {
        
        const person = {
          school: $stateParams.school,
          username: student.username,
          student_sis_id: student.sis_id,
          password: student.password,
          firstname: student.firstname,
          lastname: student.lastname,
          age: student.age || student.voice_model,
          lang: student.lang,
          physical_grade: student.grade,
          addToLicense: []
        };

        if (student.getlicense) {
          student.getlicense.forEach((x) => {
            person.addToLicense.push({ group_license_id: x.group_license_id, child_course_id: x.child_course_id });

          })
        }

        people.people.push(person);
      });

      vmAddStudents(people);  

    }
    function vmVoiceModel(voice_model) {
      return voice_model;
    }
    function vmBack() {
      let mode = localStorage.getItem('mode');

      if (mode === 'schooladmin') {

        if ($stateParams.currentClass) {
          $state.go('app.admin.school.cindex.student', { currentClass: $stateParams.currentClass, type: 'active'  })
        } else {
          $state.go('app.admin.school.users.students');
        }
      } else if (mode === 'teacher') {
        window.history.back();
      }
    }

    $scope.exportToExsel = vmExportToExsel;
    $scope.loadExcel = vmLoadExcel;
    $scope.downloadTemplate = vmDownloadTemplate;
    $scope.vmAccept = vmAccept;
    $scope.back = vmBack;
 
    })
})();