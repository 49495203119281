(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.teacher.tasks.tupdate12.edit', {

            url: '/editquest',
            controller: 'teacherEditFormTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/editform/editform.html'
        })
        .state('app.teacher.tasks.dublicate.edit', {

            url: '/editquest',
            controller: 'teacherEditFormTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/editform/editform.html'
        })
    }])
    .controller('teacherEditFormTaskCtrl', function ($scope, tasks, $stateParams, commonService, LMAPI, credentials, user, school) {

        $scope.$emit('sideTabChange', $stateParams.state);
        $scope.$emit('editTaskTab', 'edit');
      
        let _tasks = angular.copy(tasks);

        $scope.task = _tasks.find((x) => x.idnumber == $stateParams.task);
        $scope.isDisabled = true;

        // for display dates on screen
        if ($scope.task.enddate) {
            let DataFromUnicx_End = moment.unix($scope.task.enddate).toDate();
            $scope.task.date_end = new Date(DataFromUnicx_End.getUTCFullYear(), DataFromUnicx_End.getUTCMonth(), DataFromUnicx_End.getUTCDate());
        } else {
            $scope.task.date_end = null;
        }
     
        if ($scope.task.startdate) {
            let DataFromUnicx_Begin = moment.unix($scope.task.startdate).toDate();
            $scope.task.date_begin = new Date(DataFromUnicx_Begin.getUTCFullYear(), DataFromUnicx_Begin.getUTCMonth(), DataFromUnicx_Begin.getUTCDate());
        } else {
            $scope.task.date_begin = null;
        }

        function vmCheckDates(begin, end){

            let $begin = begin ? commonService.convertLocalDateToUTCUnix(begin) : null;
            let $end = end ? commonService.convertLocalDateToUTCUnix(end) : null;

            if ($end < $begin) {
                $scope.errorDates = true;
            } else {
                $scope.errorDates = false;
            }
            $scope.$emit('EventEditFormErrorDates', $scope.errorDates);
        }
        function vmChangeCurrentTask(task) {
            
            $scope.$emit('EventEditFormTask', task);
            $scope.isDisabled = false;
        }
        function vmUpdateTask(title, start, end, description) {

            $scope.start = start ? commonService.convertLocalDateToUTCUnix(start) : null;
            $scope.end = end ? commonService.convertLocalDateToUTCUnix(end) : null;

            LMAPI.updatetask(credentials.session_token, $scope.task.id, $scope.task.idnumber, title, user.project, 'task', $scope.start, $scope.end, description, school.id, $scope.task.userid).then(function (res) {

                tasks.forEach(function (item) {
                    if ($stateParams.task == item.idnumber) {

                        item.title = title,
                        item.description = description,
                        item.startdate = $scope.start,
                        item.enddate = $scope.end

                        $scope.$emit('taskSave', item);
                    }
                })
                window.localStorage.removeItem('cacheQuest');
                $scope.isDisabled = true;
                $scope.msg = 'Saved';               
            }).catch(function (error) {
                $scope.msg = 'Error';
            })

        }

        $scope.updateTask = vmUpdateTask;
        $scope.checkDate = vmCheckDates;
        $scope.vmChangeCurrentTask = vmChangeCurrentTask;
    })
})();