(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.admin', {
                    url: '/schooladmin',
                    controller: 'adminCtrl',
                    resolve: {
                        schools: function(FRAPI, credentials, commonService, $stateParams, $state) {
                           return FRAPI.getSchoolsByUser()
                            .then(function (response) {
                                response.sort((a, b) => {
                                    if (a.title > b.title) {
                                        return 1;
                                    }
                                    if (a.title < b.title) {
                                        return -1;
                                    }
                                    return 0;
                                });
                               
                                return response;
                            });
                        },
                        school: function(commonService, $stateParams, schools) {
                            if (schools.length > 0) {
                                
                                let school_id = commonService.getCurrentSchool();
                           
                                if (!school_id){
                                    commonService.setCurrentSchool(schools[0].id);
                                    return schools[0];
                                }
                                if (school_id){                                       
                                    let $sh =  schools.find((x) => x.id === school_id);
                                    if (!$sh) {
                                        commonService.setCurrentSchool(schools[0].id);
                                        return schools[0];
                                    } else {
                                        return $sh;
                                    }
                                }                              
                            } 
                        },
                        gemstones: function(FRAPI, credentials){
                            
                            return FRAPI.getGemStoneList('student').then((response) => {
                                return response;
                            });
                        }
                    },
                    onExit: function (FRAPI) {
                       
                        localStorage.removeItem('vmLoadSchoolStudents');
                      
                    },
                    deepStateRedirect: {
                        default: { state: 'app.admin.school',
                                    params: { school: sessionStorage.getItem('current_school_idnumber') } 
                        },
                        params: true
                    },
                    templateUrl: '/assets/ng1/admin/index.html'
                })
                .state('app.admin.school', {
                    url: '/:school',
                    controller: 'adminSchoolCtrl',
                    templateUrl: '/assets/ng1/admin/school.html',
                    resolve: {
                        classes: function (FRAPI, commonService, school) {
                            return FRAPI.getSchoolAllClasses(commonService.getCurrentSchool())
                                .then(function (data) {
                                    // get list of teacher classes
                                    return data;
                                });
                        },
                        courses: function (LMAPI, token, user) {

                            return LMAPI.courses(token, user.project)
                                .then((courses) => courses.filter((course) => course.enrol == 1));
                        },
                        unreadNotifiCount: function(FRAPI, credentials) {
                            FRAPI.clearNewNotifications();        
                            return FRAPI.getNewNotifications().then((newNotifications) => {
                       
                                return newNotifications.length;
                            })
                        },
                        notifications: function(FRAPI, credentials) {
    
                            return FRAPI.getUserNotifications().then(function (response) {
                                    return response; 
                            });          
                        },
                        licenses: function (FRAPI, $stateParams, commonService, credentials) {

							return FRAPI.licensesBySchool(commonService.getCurrentSchool()).then(function (data) {

								data
									.forEach(function (x) {

										x.start = x.date_start ? moment.utc(new Date(x.date_start)).format("MM/DD/YYYY") : null;
										x.end = x.date_end ? moment.utc(new Date(x.date_end)).format("MM/DD/YYYY") : null;
										x.unixend = x.date_end ? moment(new Date(x.date_end)).startOf('day').unix() : Number.POSITIVE_INFINITY;
									});
								let licenses = data
									.filter(function (l) { return !l.is_expired; })
									.sort((a, b) => {
										if(a.unixend == b.unixend)
                                        {
                                            return (a.sort_order < b.sort_order) ? -1 : (a.sort_order > b.sort_order) ? 1 : 0;
                                        }
                                        else
                                        {
                                            return (a.unixend > b.unixend) ? -1 : 1;
                                        }
									});

								return licenses;
							});
						}
                    },

                    deepStateRedirect: {
                        default: { state: 'app.admin.school.reports', },
                        params: true
                    },
                })

        }])

        .controller('adminCtrl', function ($scope, $stateParams, $state, $window, LMConfig, FRAPI, user, commonService, schools) {

            // configuration
            $scope.config = LMConfig;
            window.localStorage.setItem('mode', 'schooladmin');

            // read user information
            $scope.user = user;
            $scope.locale = user.lang;
            $scope.list_school = schools;
            
            $scope.hasRolesStudent = vmHasRolesStudent;
            $scope.hasRolesTeacher = vmHasRolesTeacher;
            $scope.hideProfile = vmHideProfile;

            $scope.urlHelp = commonService.help(user.project);

            // update logo and page title
            var logoObj = commonService.logo(user.project, 'admin');
            $scope.logo = logoObj.logo;
            $scope.tm = logoObj.tm;

            $window.document.title = $scope.logo;

            function vmHideProfile(e) {
                $scope.profileUser = false;
            }

            function vmHasRolesStudent() {
                return $scope.user.roles.some(x => x == 'student' || x == 'user');
            }
            function vmHasRolesTeacher() {
                return $scope.user.roles.some(x => x == 'teacher');
            }

            function onSelect($event) {
                $state.go('app.admin.school', {school: $event});            
                $scope.activeSchoolTab = $event;
                FRAPI.clearNewNotifications();
            }          

            if (schools.length > 0) {
                $scope.msgNotSchool = false;
            } else {
                $scope.msgNotSchool = true;
            }
            $scope.$on('adminTopTabChange', function (event, data) {
                $scope.activeTab = data;
                angular.element('.mainWindow').scrollTop(0);
            });
            
            $scope.$on('schoolChange', function (event, hideSubscription) {
                $scope.hideSubscription = hideSubscription;
            });

            function vmTwiceClick() {
                if ($state.current.name.indexOf('app.admin.school.library.') != -1) { return; }
    
                $state.go('app.admin.school.library.curriculums');
            }
            function twiceClickTaskTab() {

                if ($state.current.name.indexOf('app.admin.school.tasks.') != -1) { return; }
    
                $state.go('app.admin.school.tasks', {mode: 'admin'});
            }

            $scope.twiceClickTaskTab = twiceClickTaskTab;
            $scope.twiceClick = vmTwiceClick;
            $scope.onSelect = onSelect;

        })
        .controller('adminSchoolCtrl', function ($scope, LMConfig, LMWordpress, $stateParams, user, $state, commonService, schools, licenses) {

            $scope.$emit('schoolChange', licenses.length === 0);

            $scope.user = user;
            
            $scope.teacherReports = commonService.teacherReports(user.project);

            $scope.school = schools.filter(x => x.id == commonService.getCurrentSchool())[0];    

            $scope.$on('adminTabChange', function (event, data) {
                $scope.activeTab = data;
                angular.element('.mainWindow').scrollTop(0);
            });
            if ($stateParams.school !== commonService.getCurrentSchool()) {
                $state.go('app.admin.school', {school: commonService.getCurrentSchool()});
            }

            $scope.showWorkstations = commonService.workstations(user.project, 'adminMode');
            $scope.certificatesTab = commonService.certificatesTab(user.project);
            $scope.hiringTab = commonService.hiringTab(user.project);
    
        });

})();