(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.root.districtadmin.district.curriculums', {
                    url: '/curriculums',
                    controller: 'districtadminCurriculumsCtrl',
                    templateUrl: '/assets/ng1/teacher/courses/assignments/assignments.html'
                })
                .state('app.root.districtadmin.district.reportcurriculums', {
                    url: '/curriculums/:id',
                    controller: 'districtAdminCurriculumReportCtrl',
                    templateUrl: '/assets/ng1/districtadmin/curriculum_reports/curriculum_report.html'
                })
        }])

        .controller('districtadminCurriculumsCtrl', function ($scope, $state, LMAPI, user, token) {

            $scope.$emit('modeChange', 'page');
            $scope.$emit('leftTabs', 'curriculum');

            LMAPI.courses(token, user.project)
                            .then((courses) => {                                
                                
                //courses = courses.filter((course) => course.enrol == 1);

                $scope.curriculum = courses.filter(function (x) {
                    return x.enrol == 1 && (x.curriculumtype == "categories" ||
                        x.curriculumtype == "cohorts" ||
                        x.curriculumtype == "levels" ||
                        x.curriculumtype == "external");
                }).filter((x) => {
                    return x.params ? JSON.parse(x.params).p_reports : null
                });
    
                $scope.curriculumGroup = vmGetChunks($scope.curriculum, 2); // need for table-cell view
            });

            function vmOpenCurriculum(course) {

                $state.go('app.root.districtadmin.district.reportcurriculums', { id: course.id });
            }

            $scope.openCurriculum = vmOpenCurriculum;

        })
        .controller('districtAdminCurriculumReportCtrl', function ($scope, $stateParams, districtsByUser, LMAPI, user, token) {
          
            $scope.$emit('modeChange', 'page');
            $scope.$emit('leftTabs', 'curriculum');

            LMAPI.courses(token, user.project)
            .then((courses) => { 
                $scope.currentcourse = courses.filter((course) => course.enrol == 1).find((item) => item.id == $stateParams.id);
            })                              
                
            $scope.district = sessionStorage.getItem("current_district_idnumber");

        })

})();