(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.admin.school.cindex.classes', {
                    url: '/classes/:type',
                    controller: 'adminClassesCtrl',
                    templateUrl: '/assets/ng1/admin/classes/class/list.html',
                })
        }])
        .controller('adminClassesCtrl', function ($q, $rootScope, schoolTypeService, FRAPI, $scope, $translate, LMWordpress, $state, classes, $stateParams, commonService, schools, credentials) {

         //   $scope.$emit('adminTabChange', $stateParams.type);
            $scope.$emit('adminTopTabChange', 'cindex');
            $scope.$emit('adminClassesHighlighted', $stateParams.type);

            $scope.school = schools.find((x) => x.id === commonService.getCurrentSchool());
            let _type = schools.find((x) => x.id === commonService.getCurrentSchool()).school_type;
            $scope.schoolType = _type ? _type : 'Languametrics';
        
            var _date = new Date();
            let today = commonService.convertLocalDateToUTCUnix(_date);
            $scope.allselect = false;
            $scope.oneselect = false;
            $scope.flagBtn = false;
  
            let arrayValuesSchool = $state.params.school.split('_');
            let tenantId = arrayValuesSchool[1];

            
            FRAPI.getUsersFromCohortTeacherMode(commonService.getCurrentSchool(), JSON.stringify(['teacher'])).then(function (response) {
            
                $scope.teachers = response;
                $scope.classes = angular.copy(classes);
             /*   $scope.classes.forEach((item) => {
                    let teacher = response.find((x) => x.token === item.teacher);
                    item.name_teacher =  teacher ? teacher.firstname + ' ' + teacher.lastname : null;
                });*/
                SeparateClasses();

            });

            function vmGetClassLinkClasses() {

                $rootScope.pageLoading = true;
 
                FRAPI.getclassesForClasslink($state.params.school, tenantId).then((response) => {
                      
                    $scope.listAllClassLinkClasses = response.filter((x) => !$scope.classes.some((y) => x.idnumber === y.idnumber)); 

                    $rootScope.pageLoading = false;
                    //restructure header table to the correct position
                    $('table.demo').floatThead('reflow');
                }).catch((error) =>{
                    $rootScope.pageLoading = false;
                })
            }           
            function vmGetCleverClasses() {

                $rootScope.pageLoading = true;

                schoolTypeService.cleverSession().then((response8) => {
                    FRAPI.getclasses($rootScope.cleverSession, $state.params.school).then((response) => {

                        $scope.listAllCleverClasses = response.filter((x) => !$scope.classes.some((y) => x.idnumber === y.idnumber)); 
                      
                        $rootScope.pageLoading = false;
                        //restructure header table to the correct position
                        $('table.demo').floatThead('reflow');
                    }).catch((error) =>{
                        $rootScope.pageLoading = false;
                    })
                });
            }
            function SeparateClasses() {

                if ($stateParams.type == 'active') {
                    //array of current classes
                    $scope.classes = $scope.classes.filter(function (item) {
                        return item.date_end >= today;
                    });

                } else if ($stateParams.type == 'closed') {
                    //array of closed classes
                    $scope.classes = $scope.classes.filter(function (item) {
                        return item.date_end < today;
                    });
                }

                $scope.classes.forEach(function (item) {
                 
                    item.dBegin = item.date_begin == 0 || item.date_begin == null ? 0 : commonService.getLocaleDate(item.date_begin); 
                    item.dEnd = item.date_end == 0 || item.date_end == null ? 0 :  commonService.getLocaleDate(item.date_end);

                    let properties = JSON.parse(item.properties);
                    if (properties) {
                        item.district = properties.district ? properties.district : null;
                        item.type = properties.class_type ? properties.class_type : null;
                    }

                    let teacher = $scope.teachers.find((x) => x.token === item.teacher);
                    item.name_teacher =  teacher ? teacher.firstname + ' ' + teacher.lastname : null;
                })
                //restructure header table to the correct position
                $('table.demo').floatThead('reflow');
            }
            function vmRemoveClass(myclass) {

                if (confirm('Are you sure?')) {
                    $rootScope.pageLoading = true;
                    FRAPI.deleteClass(myclass).then(function (response) {
                        classes.forEach(function (item, i) {
                            if (item.idnumber == myclass.idnumber) {
                              
                                classes.splice(i, 1);
                                let _cl = JSON.parse(item.properties);
                                if ($scope.listAllClassLinkClasses && _cl.class_type === 'Classlink') {
                                    
                                    $scope.listAllClassLinkClasses.push(item); 
                                }
                                if ($scope.listAllCleverClasses && _cl.class_type === 'Clever') {

                                    let mainteacher = $scope.teachers.find((x) => x.token === item.teacher);

                                    $scope.listAllCleverClasses.push({
                                        date_begin: item.date_begin,
                                        date_end: item.date_end,
                                        idnumber: item.idnumber,
                                        name: item.name,                                     
                                        teacher: item.teacher,
                                        teacher_first_name: mainteacher.firstname,
                                        teacher_last_name: mainteacher.lastname
                                    }); 
                                }
                            }
                        });
                        $scope.classes = angular.copy(classes);
                                                             
                        SeparateClasses();
                        $rootScope.pageLoading = false;
                    }).catch((error) =>{
                        $rootScope.pageLoading = false;
                    })
                }
            }
            //using recursion
            const vmSyncClass = (classes,processed=[]) =>
                        (classes.length!==0)
                        ? FRAPI.syncclass($rootScope.cleverSession, commonService.getCurrentSchool(), classes[0])
                            .then((resp)=> {
                                processed.push(resp.data);
                            })                            
                            .catch(err=>Promise.reject([err,processed]))
                            .then(_=>vmSyncClass(classes.slice(1),processed))
                        : processed;
        
            function vmAddArrayCleverClass(_listAllCleverClasses) {

                $rootScope.pageLoading = true;

                let $selectedCleverClasses = $scope.listAllCleverClasses.filter((x) => x.select);
                let selectedCleverClasses = $selectedCleverClasses.map((y) => y.idnumber);
               
                schoolTypeService.cleverSession().then((response8) => {
                
                vmSyncClass(selectedCleverClasses).then((processed) => {

                        FRAPI.getUsersFromCohort($state.params.school, JSON.stringify(['teacher'])).then(function (resTeachers) {
                         
                            $scope.listAllCleverClasses = $scope.listAllCleverClasses.filter((x) => !$selectedCleverClasses.some(y => x.idnumber === y.idnumber));
                     
                            $selectedCleverClasses.forEach((item) => {
                               
                                item.teacher = processed.find((x) => x.id === item.idnumber).teacher_token;
    
                                let main_teacher = resTeachers.find((x) => x.token === item.teacher);
    
                                let $teach = $scope.teachers.find((x) => x.token === item.teacher);
                                if (!$teach) {
                                    $scope.teachers.push(main_teacher);
                                }
                                FRAPI.cleargetUsersFromCohort();
    
                                item.properties = JSON.stringify({ class_type: "Clever" });
                                item.select = false;
                                $scope.classes.push(item);
                                classes.push(item);
                            });
                            vmSelectClever(false, null);
                            SeparateClasses();
                            
                            //restructure header table to the correct position
                            $('table.demo').floatThead('reflow');
    
                            $rootScope.pageLoading = false;

                        }).catch((error) =>{
                            $rootScope.pageLoading = false;
                        })
                    }).catch((error) =>{
                        $rootScope.pageLoading = false;
                    })     
                });
            }               
            function vmSelectAllAssigned(select) {

                $scope.listAllCleverClasses.forEach((x) => x.select = select);
                $scope.flagBtn = $scope.listAllCleverClasses.some((x) => x.select === true);
            }
            function vmAssignClever(clas) {

                $rootScope.pageLoading = true;               

                schoolTypeService.cleverSession().then((response8) => {
                   
                    FRAPI.syncclass($rootScope.cleverSession, commonService.getCurrentSchool(), clas.idnumber).then((response) => {
                        FRAPI.getUsersFromCohort(commonService.getCurrentSchool(), JSON.stringify(['teacher'])).then(function (resTeachers) {
                            // get list of school teachers
                            $scope.listAllCleverClasses = $scope.listAllCleverClasses.filter((x) => x.idnumber != clas.idnumber);                  
                    
                            clas.teacher = response.data.teacher_token;                   
                            clas.properties = JSON.stringify({ class_type: "Clever" });
    
                            let main_teacher = resTeachers.find((x) => x.token === clas.teacher);
                            let $teach = $scope.teachers.find((x) => x.token === clas.teacher);
                            if (!$teach) {
                                $scope.teachers.push(main_teacher);
                            }
                            FRAPI.cleargetUsersFromCohort();
    
                            clas.select = false;
                            $scope.classes.push(clas);
                            classes.push(clas);                            

                            SeparateClasses();
                            //restructure header table to the correct position
                            $('table.demo').floatThead('reflow');
                                     
                            $rootScope.pageLoading = false;

                        }).catch((error) =>{
                            $rootScope.pageLoading = false;
                        })
                    }).catch((error) =>{
                        $rootScope.pageLoading = false;
                    })

                });
              
            }
            function vmSyncAll() {

                $rootScope.pageLoading = true;

                schoolTypeService.cleverSession().then((response1) => {
                    FRAPI.syncinfo($rootScope.cleverSession, $state.params.school).then((responce2) => {

                        FRAPI.syncadmins($rootScope.cleverSession,  $scope.school.district, $state.params.school).then((responseAdmins) => {
                            
                            let assignclasses = $scope.classes.map((y) => y.idnumber);
                           
                            $rootScope.pageLoading = true;

                            vmSyncClass(assignclasses).then((resp) => {

                                $rootScope.pageLoading = true;

                                $q.all([FRAPI.getUsersFromCohort(commonService.getCurrentSchool(), JSON.stringify(['teacher'])),
                                    FRAPI.getSchoolAllClasses(commonService.getCurrentSchool())]).then((response) => {

                                        // assign new values to the global data array (teachers and classes) without changing the link
                                        let length1 = $scope.teachers.length;

                                        for(let i=0; i <= length1; i++) {
                                            $scope.teachers.splice(0, 1);
                                        }                                                                   
                                        response[0].forEach((y) => {
                                            $scope.teachers.push(y);
                                        });                                   
                                        FRAPI.cleargetUsersFromCohort();
    
                                        let length2 = classes.length;

                                        for(let i=0; i <= length2; i++) {
                                            classes.splice(0, 1);
                                        }                                                                   
                                        response[1].forEach((y) => {
                                            classes.push(y);
                                        });                                           

                                        SeparateClasses();
                                        FRAPI.clearlistClassesClever();
                                        if (!$scope.$$phase) {
                                            $scope.$apply();
                                        }
                
                                        $rootScope.pageLoading = false;
                                });
                            })
               
                        })
                    })
                });
            }
            const vmSyncClassClassLink = (classes,processed=[]) =>
                                            (classes.length!==0)
                                            ? FRAPI.syncclassLink(commonService.getCurrentSchool(), classes[0], tenantId)
                                                .then((resp)=> {
                                                    processed.push(resp.data);
                                                })                            
                                                .catch(err=>Promise.reject([err,processed]))
                                                .then(_=>vmSyncClassClassLink(classes.slice(1),processed))
                                            : processed;
            function vmGetClasses() {
                if ($scope.schoolType === 'Clever') {
                    vmGetCleverClasses();
                } else if ($scope.schoolType === 'Classlink') {
                    vmGetClassLinkClasses();
                }
            }
            function vmAssignClass(clas) {
                if ($scope.schoolType === 'Clever') {
                    vmAssignClever(clas);
                } else if ($scope.schoolType === 'Classlink') {
                    vmAssignClassLink(clas);
                }
            }
            function vmSyncAllclasslink(){

                $rootScope.pageLoading = true;
                
                FRAPI.syncadminsClassLink(commonService.getCurrentSchool(), $scope.school.district, tenantId).then((responseAdmins) => {
                            
                   // FRAPI.clearSchoolAdminsByProject();
                   // FRAPI.clearSchoolAdminsBySchool();
                    FRAPI.clearListClassesClassLink();
                    
                    let assignclasses = $scope.classes.map((y) => y.idnumber);
                   
                    $rootScope.pageLoading = true;

                    vmSyncClassClassLink(assignclasses).then((resp) => {

                        $rootScope.pageLoading = true;

                        $q.all([FRAPI.getUsersFromCohort(commonService.getCurrentSchool(), JSON.stringify(['teacher'])),
                            FRAPI.getSchoolAllClasses(commonService.getCurrentSchool())]).then((response) => {

                                // assign new values to the global data array (teachers and classes) without changing the link
                                let length1 = $scope.teachers.length;

                                for(let i=0; i <= length1; i++) {
                                    $scope.teachers.splice(0, 1);
                                }                                                                   
                                response[0].forEach((y) => {
                                    $scope.teachers.push(y);
                                });                                   

                                let length2 = classes.length;

                                for(let i=0; i <= length2; i++) {
                                    classes.splice(0, 1);
                                }                                                                   
                                response[1].forEach((y) => {
                                    classes.push(y);
                                });                                           

                                SeparateClasses();
                                FRAPI.clearListClassesClassLink();
                                if (!$scope.$$phase) {
                                    $scope.$apply();
                                }
        
                                $rootScope.pageLoading = false;
                        });
                    })
       
                })
            }
            function vmAssignClassLink(clas) {

                $rootScope.pageLoading = true;

                FRAPI.syncclassLink($state.params.school, clas.idnumber, tenantId).then((response) => {
                    FRAPI.getUsersFromCohort($state.params.school, JSON.stringify(['teacher'])).then(function (resTeachers) {
                
                        $scope.listAllClassLinkClasses = $scope.listAllClassLinkClasses.filter((x) => x.idnumber != clas.idnumber);                  
                    
                        clas.teacher = response.data.teacher_token;                   
                        clas.properties = JSON.stringify({ class_type: "Classlink" });

                        let main_teacher = resTeachers.find((x) => x.token === clas.teacher);

                        let $teach = $scope.teachers.find((x) => x.token === clas.teacher);
                        if (!$teach) {
                            $scope.teachers.push(main_teacher);
                        }
                        FRAPI.cleargetUsersFromCohort();
    
                        clas.properties = JSON.stringify({ class_type: "Classlink" });
                        clas.select = false;
                        $scope.classes.push(clas);
                        classes.push(clas);
                        SeparateClasses();

                        $rootScope.pageLoading = false;
                    })
                })
            }
            function vmSelectClassClasslink(select, _class) {
                if (_class) {
                    $scope.listAllClassLinkClasses.forEach((x) => {
                        let $clas = _class.find((y) => y.idnumber === x.idnumber);
                        if ($clas) {
                            x.select = select;
                        }
                    })
                } else {
                    $scope.listAllClassLinkClasses.forEach((x) => {                   
                        x.select = select;                    
                    })
                }          
            }
            function vmSelectClever(select, _class){
                if (_class) {
                    $scope.listAllCleverClasses.forEach((x) => {
                        let $clas = _class.find((y) => y.idnumber === x.idnumber);
                        if ($clas) {
                            x.select = select;
                        }
                    })
                } else {
                    $scope.listAllCleverClasses.forEach((x) => {                   
                        x.select = select;                    
                    })
                }      
            }
            function vmAddArrayClasslinkClass(_listAllClassLinkClasses) {

                $rootScope.pageLoading = true;
                let $selectedClassLinkClasses = $scope.listAllClassLinkClasses.filter((x) => x.select);
                let selectedClassLinkClasses = $selectedClassLinkClasses.map((y) => y.idnumber);

                vmSyncClassClassLink(selectedClassLinkClasses).then((processed) => {

                    FRAPI.getUsersFromCohort($state.params.school, JSON.stringify(['teacher'])).then(function (resTeachers) {

                        $scope.listAllClassLinkClasses = $scope.listAllClassLinkClasses.filter((x) => !$selectedClassLinkClasses.some(y => x.idnumber === y.idnumber));
                     
                        $selectedClassLinkClasses.forEach((item) => {
                           
                            item.teacher = processed.find((x) => x.id === item.idnumber).teacher_token;

                            let main_teacher = resTeachers.find((x) => x.token === item.teacher);

                            let $teach = $scope.teachers.find((x) => x.token === item.teacher);
                            if (!$teach) {
                                $scope.teachers.push(main_teacher);
                            }

                            item.properties = JSON.stringify({ class_type: "Classlink" });
                            item.select = false;
                            $scope.classes.push(item);
                            classes.push(item);
                        })   
                        vmSelectClassClasslink(false, null);
                        SeparateClasses();
                        $scope.selectClass = false;
                        
                        //restructure header table to the correct position
                        $('table.demo').floatThead('reflow');

                        $rootScope.pageLoading = false;

                    }).catch((error) =>{
                        $rootScope.pageLoading = false;
                    })
                }).catch((error) =>{
                    $rootScope.pageLoading = false;
                }) 
            }
            function isDisabledSelectedBtnClasslink(){
                return $scope.listAllClassLinkClasses.every((x) => !x.select);
            }
            function isDisabledSelectedBtnClever(){
                return $scope.listAllCleverClasses.every((x) => !x.select);
            }
            function vmGetClassType(clas) {
                let properties = JSON.parse(clas.properties);
                return vmGetNameClassType(properties.class_type);
            }
            function vmGetNameClassType(type) {
                switch (type) {
                    case 'Clever':                       
                        return 'Clever';      
                    case 'Classlink':                       
                        return 'ClassLink';
                    case 'Languametrics':
                        return 'Languametrics';
                }
            }

            //  class editing functions
            $scope.removeClass = vmRemoveClass;
            $scope.type = $stateParams.type;
            $scope.selectAllAssigned = vmSelectAllAssigned;
            $scope.syncAll = vmSyncAll;           
            $scope.syncAllclasslink = vmSyncAllclasslink;
            $scope.vmAssignClass = vmAssignClass; 
            $scope.vmGetClasses = vmGetClasses;
            $scope.vmSelectClassClasslink = vmSelectClassClasslink;
            $scope.vmAddArrayClasslinkClass = vmAddArrayClasslinkClass;
            $scope.vmAddArrayCleverClass = vmAddArrayCleverClass;
            $scope.isDisabledSelectedBtnClasslink = isDisabledSelectedBtnClasslink;
            $scope.isDisabledSelectedBtnClever = isDisabledSelectedBtnClever;
            $scope.vmSelectClever = vmSelectClever;
            $scope.vmGetClassType = vmGetClassType;
            $scope.vmGetNameClassType = vmGetNameClassType;
        })
})();