(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.projectadmin.page.curriculums', {
                    url: '/curriculums',
                    controller: 'projectadminCurriculumsCtrl',
                    templateUrl: '/assets/ng1/teacher/courses/assignments/assignments.html'
                })
                .state('app.projectadmin.page.reportcurriculums', {
                    url: '/curriculums/:id',
                    resolve: {
                        schoolsProjectAdmin: function(FRAPI, credentials) {

                            return FRAPI.getSchools().then((response) => {
                                return response.data;
                            })
                        },

                        districts: function(FRAPI, schoolTypeService, credentials) {

                            return FRAPI.getlistDistrict().then((response) => {
                
                                response.forEach((x) => {                 
                                    x.districtType = x.properties.district_type ? schoolTypeService.types.find((y) => y.key === x.properties.district_type).name : 'Languametrics';                                    
                                });
                                return response;
                            });
                        }
                    },
                    controller: 'curriculumReportCtrl',
                    templateUrl: '/assets/ng1/projectadmin/page/curriculum_report/report.html'
                })
        }])

        .controller('projectadminCurriculumsCtrl', function ($scope, $state, courses) {

            $scope.$emit('modeChange', 'page');
            $scope.$emit('leftTabs', 'curriculum');

            $scope.curriculum = courses.filter(function (x) {
                return x.enrol == 1 && (x.curriculumtype == "categories" ||
                    x.curriculumtype == "cohorts" ||
                    x.curriculumtype == "levels" ||
                    x.curriculumtype == "external");
            }).filter((x) => {
                return x.params ? JSON.parse(x.params).p_reports : null
            });

            $scope.curriculumGroup = vmGetChunks($scope.curriculum, 2); // need for table-cell view

            function vmOpenCurriculum(course) {

                $state.go('app.projectadmin.page.reportcurriculums', { id: course.id });
            }

            $scope.openCurriculum = vmOpenCurriculum;

        })
        .controller('curriculumReportCtrl', function ($scope, $rootScope, $stateParams, commonService, $http, $q, credentials, dates, LMConfig, districts, user, LMAPI, schoolsProjectAdmin, courses, $translate) {
            
            $scope.$emit('modeChange', 'page');
            $scope.$emit('leftTabs', 'curriculum');

            $scope.districts = angular.copy(districts);
            $scope.list = angular.copy(schoolsProjectAdmin);

            $scope.list.sort(function (a, b) { return ('' + a.name).localeCompare(b.name); })
                        .unshift({
                            name: $translate.instant('projectAdmin.curriculum.name'),
                            idnumber: user.project
                        });
            $scope.districts.sort(function (a, b) { return ('' + a.name).localeCompare(b.name); })
                        .unshift({
                            name: $translate.instant('projectAdmin.curriculum.name'),
                            idnumber: user.project
                        });
            // default values
            $scope.district = $scope.districts.find((x) => x.idnumber === user.project);           
            $scope.school = $scope.list.find((x) => x.idnumber === user.project);         
            $scope.cohortID = $scope.district.idnumber;   

            vmGetCurriculumReport();
            vmChangeDate(moment(localStorage.getItem('report.datebegin')).toDate(), moment(localStorage.getItem('report.dateend')).toDate());

            function vmChangeCohortDistrict(cohortObj) {

                $scope.cohortID = cohortObj.idnumber ? cohortObj.idnumber : user.project;

                if (cohortObj.idnumber !== user.project) {
                    $scope.list = schoolsProjectAdmin.filter((x) => x.properties.district === cohortObj.idnumber);
                } else {
                    $scope.list = angular.copy(schoolsProjectAdmin);                  
                }

                $scope.list.sort(function (a, b) { return ('' + a.name).localeCompare(b.name); })
                            .unshift({
                                name: $translate.instant('projectAdmin.curriculum.name'),
                                idnumber: user.project
                            });
                $scope.school = $scope.list.find((x) => x.idnumber === user.project);
            }

            function vmChangeCohortSchool(cohortObj) {

                if (!cohortObj) { return;}

                if (cohortObj.idnumber && cohortObj.idnumber === user.project) {

                    $scope.cohortID = $scope.district.idnumber ? $scope.district.idnumber : user.project;
                } else {
                    $scope.cohortID = cohortObj.idnumber ? cohortObj.idnumber : user.project;
                }
            }

            function vmRunReport(idnumber, dateBegin, dateEnd) {

                $scope.loadReport = false;

                var datebegin = dates.dateFormat(dateBegin.setHours(0, 0, 0, 0));
                var dateend = dates.dateFormat(dateEnd.setHours(23, 59, 59, 999));
                idnumber = idnumber ? idnumber : null;

                var curriculum = LMAPI.getcurriculum(credentials.session_token, idnumber, $scope.cohortID, datebegin, dateend, 'JSON', $stateParams.id, 'projectadministrator');
                var template = $http.get($scope.report.report.path, { withCredentials: true });

                $q.all({ template: template, curriculum: curriculum })
                  .then(function (response) {

                      $scope.loadReport = true;
                      $scope.datas = response.curriculum.data;
                      $scope.headers = response.template.data;

                  }).catch(function (reject) {

                      console.log(reject);
                  });
            }

            function vmDownloadFile(type) {

                $scope.loadReport = false;

                var DateBegin = dates.dateFormat($scope.report.datebegin);
                var DateEnd = dates.dateFormat($scope.report.dateend);
                var idnumber = $scope.report.report.idnumber ? $scope.report.report.idnumber : null;

                LMAPI.getcurriculumDownload(credentials.session_token, idnumber, $scope.cohortID, DateBegin, DateEnd, type, $stateParams.id, 'projectadministrator')
                    .then(function (response) {

                        let applicationType = type === 'PDF' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

                        var file = new Blob([response.data], { type: applicationType });

                        const link = document.createElement('a');
                        // create a blobURI pointing to our Blob
                        link.href = URL.createObjectURL(file);
                        link.download = $scope.report.report.title;

                        document.body.append(link);
                        link.click();
                        link.remove();
                        $scope.loadReport = true;
                    })

            }  
            function vmGetValue(field, value) {

                var parameters = $scope.headers.find((x) => x.key === field);

                if (parameters.min_width || parameters.align) {
                    return {
                        value: parameters.type === "date" ? commonService.getLocaleDate(value / 1000) : value,
                        align: parameters.align ? parameters.align : 'left',
                        min_width: parameters.min_width
                    }
                }
                return {
                    value: parameters.type === "date" ? commonService.getLocaleDate(value / 1000) : value,
                    align: 'left'
                };
            }
            function vmGetCurriculumReport() {

                $scope.course = courses.filter((course) => course.enrol == 1)
                                              .find((item) => item.id == $stateParams.id);

                // get list of report id's
                // assosiated with course
                // for each of them get report details
                var reports = $scope.course.params ? JSON.parse($scope.course.params).p_reports : null;
                if (reports) {

                let ids = reports.join();

                LMAPI.getcurriculumreports(credentials.session_token, ids, 'projectadministrator').then(function (response) {

                        $scope.listReports = response.data;

                        $scope.listReports.forEach(function (item) {
                            item.path = LMConfig.storage + '/report-templates/' + item.excel_template_name + '.json';
                        });

                        if ($scope.listReports.length === 1) {
                            $scope.selectReport = $scope.listReports[0];
                        }
                    });
                };
            }

            function vmChangeDate(datebegin, dateend) {
        
                var unixDatebegin = datebegin ? moment(datebegin.setHours(0, 0, 0, 0)).unix() : null;
                var unixDateend = dateend ? moment(dateend.setHours(23, 59, 59, 999)).unix() : null;
        
                // one of the dates may be empty (null) - this is not a mistake
                // show mistake only when date end < date begin
                if ((unixDateend < unixDatebegin) && unixDatebegin != null && unixDateend != null) {
                    $scope.errorIntervalDate = true;
                } else {
                    $scope.errorIntervalDate = false;
                }
            }

            $scope.downloadFile = vmDownloadFile;
            $scope.runReport = vmRunReport;
            $scope.getValue = vmGetValue;
            $scope.changeCohortDistrict = vmChangeCohortDistrict;
            $scope.changeCohortSchool = vmChangeCohortSchool;
            $scope.changeDate = vmChangeDate;
        })

})();