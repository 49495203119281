(function () {
    'use strict';

angular.module('languabooksApp')
.controller('teacherReportBookCtrl', function ($scope, $state, $stateParams, FRAPI, LMWordpress, school, classes, dates, commonService, $rootScope) {

    $scope.$emit('studentReportsTabChange', 'breport');

    // if dates are in localStorage
    // check dates, must be date end >= date begin
    vmChangeDate(null, null, true);
    var course = localStorage.getItem('course');
    $scope.course = course && course != 'null' && course != 'undefined' ? course : '';

    if ($stateParams.clas && $stateParams.student && $scope.unix_datebegin && $scope.unix_dateend) {
        vmUserResult($stateParams.student);
    }

    function vmUserResult(token) {

        $rootScope.pageLoading = true;

        var course = $scope.course ? $scope.course : null;
        var token = token ? token : null;
        var tokens = [];

        if (token == null) {

            $scope.studentsList.forEach(function (item, i) {
                if (item.token != null) {
                    tokens.push(item.token);
                }
            });

            if (tokens.length === 0) { $rootScope.pageLoading = false; return; };

            $scope.students_token = tokens.join(',');
            $scope.selectedStudent = $scope.studentsList.find(x => x.token == $stateParams.student);
        } else {
            $scope.students_token = token;
        }

        FRAPI.userresult($scope.students_token, course, $scope.unix_datebegin, $scope.unix_dateend).then(function (response) {

            $scope.results = response.data;

            $scope.results.forEach(function (item) {
                item.course_scores = JSON.parse(item.course_scores);
                if (item.course_scores) {
                    item.$read = item.course_scores ? Math.round(item.course_scores.read) : null;
                    item.$quiz = item.course_scores ? Math.round(item.course_scores.quiz) : null;
                    item.$raw_quiz = item.course_scores.raw_quiz ? Math.round(item.course_scores.raw_quiz) : 0;
                    item.$raw_read = item.course_scores.state == 'in_progress' ? null : item.course_scores.raw_read ? Math.round(item.course_scores.raw_read) : 0;
                    item.$raw_first_quiz = item.course_scores.state == 'in_progress' ? null : item.course_scores.raw_first_quiz == null ? 'N/A' : Math.round(item.course_scores.raw_first_quiz);
                    item.$coins_earned = item.course_scores.state == 'in_progress' ? null : item.coins_earned;
                }

                item.$attempt_start_date = item.attempt_start_date ? commonService.getLocaleDate(item.attempt_start_date) : 0;
                item.$lastuse = item.lastuse ? commonService.getLocaleDate(item.lastuse) : 0;
                item.$lastuseOnlyDate = item.lastuse ? commonService.removeTime(item.lastuse) : 0;
                
                let Lmin = item.time_listening ? Math.trunc(parseFloat(item.time_listening) / 60) : 0;
                let Rmin = item.time_reading ? Math.trunc(parseFloat(item.time_reading) / 60) : 0;
                let Lsec; let Rsec;
                if (Lmin*60 < parseInt(item.time_listening)) {
                    Lsec = item.time_listening - Lmin*60;
                } else {
                    Lsec = 0;
                }
                if (Rmin*60 < parseInt(item.time_reading)) {
                    Rsec = item.time_reading - Rmin*60;
                } else {
                    Rsec = 0;
                }

                item.$time_listening_min  = commonService.vmAddZero(Lmin) + ":" + commonService.vmAddZero(Lsec);
                item.$time_reading_min = commonService.vmAddZero(Rmin) + ":" + commonService.vmAddZero(Rsec);
            });

            $scope.results = $scope.results.sort(function (x, y) {
                return y.$lastuseOnlyDate - x.$lastuseOnlyDate || y.attempt_start_date - x.attempt_start_date;
            });
            $rootScope.pageLoading = false;
        })
    };

    function vmOpenResult(cohort, result) {
        if ($scope.mode === 'schooladmin') {
            $state.go('app.admin.school.reports.bookresults', { idnumber: result.idnumber, token: result.token, title: result.title, showAllClasses: $stateParams.showAllClasses });
        } else {
            $state.go('app.teacher.report.bookresults', { idnumber: result.idnumber, token: result.token, title: result.title });
        }
    };
    // method call in template if date change
    // then check dates which got
    // else check dates which are in localStorage
    // NOT READ all from local storage, when change template, storage see changes later
    function vmChangeDate(datebegin, dateend, storage) {

        if (storage) {

            var storageBegin = localStorage.getItem('datebegin');
            var storageEnd = localStorage.getItem('dateend');

            $scope.unix_datebegin = storageBegin && storageBegin != 'null' && storageBegin != 'undefined' ? moment((new Date(storageBegin)).setHours(0, 0, 0, 0)).unix() : null;
            $scope.unix_dateend = storageEnd && storageEnd != 'null' && storageEnd != 'undefined' ? moment((new Date(storageEnd)).setHours(23, 59, 59, 999)).unix() : null;

        } else {
            $scope.unix_datebegin = datebegin ? moment(datebegin.setHours(0, 0, 0, 0)).unix() : null;
            $scope.unix_dateend = dateend ? moment(dateend.setHours(23, 59, 59, 999)).unix() : null;
        }

        // one of the dates may be empty (null) - this is not a mistake
        // show mistake only when date end < date begin
        if (($scope.unix_dateend < $scope.unix_datebegin) && $scope.unix_datebegin != null && $scope.unix_dateend != null) {

            $scope.errorIntervalDate = true;

        } else {
            $scope.errorIntervalDate = false;
        }
    }

    $scope.userresult = vmUserResult;
    $scope.openResult = vmOpenResult;
    $scope.changeDate = vmChangeDate;
})
})();
