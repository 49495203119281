(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
            .state('app.admin.school.reports.breport', {
        
                url: '/book',
                /*params:{
                    token: null,
                    back: null,
                },*/
                controller: 'adminReportBookCtrl',
                templateUrl: '/assets/ng1/admin/reports/breport/breport.html'
            })
            
        }])

        .controller('adminReportBookCtrl', function ($scope, $state, $stateParams, classes, $rootScope, commonService, FRAPI) {

            $scope.$emit('adminTabChange', 'breport');

            var _date = new Date();
            let today = commonService.convertLocalDateToUTCUnix(_date);
            
            $scope.showClas = false;
            // if dates are in localStorage
            // check dates, must be date end >= date begin
            vmChangeDate(null, null, true);
            vmBooleanShowAllClasses();

            if ($stateParams.clas) {
                FRAPI.getUsersFromCohortTeacherMode($stateParams.clas, JSON.stringify(['student'])).then(function (response) {
        
                    $scope.students = response.unique(x => x.token);
                    $scope.students.push({
                        firstname: ' All',
                        lastname: 'Students',
                        token: null
                    });
                    $scope.selectStudent = $scope.students.find(x => x.token == $stateParams.student);
                    // try to set default student
                    // if found than read the result
                    $scope.select = $scope.students.find(x => x.token == $stateParams.student);
        
                    //show results when back from  state: bookresults
                    if ($stateParams.student && $stateParams.back) {
                        vmUserResult($stateParams.student);
                    } else if (!$stateParams.student && $stateParams.back) {
                        vmUserResult(null);
                    } else if ($stateParams.student && !$stateParams.back) {
                        vmUserResult($stateParams.student);
                    }
                });
            }
                  
        
            // try to set default class
            // if found than get list of students
            $scope.selectedClass = $scope.classList.find(x => x.idnumber == $stateParams.clas);        
        
            function vmChangeClass(data) {
        
                $state.go($state.current.name, { clas: data.idnumber, student: null, back: null });
        
            };
            function vmBooleanShowAllClasses() {

                let show = window.localStorage.getItem('showAllClasses');
                if (show && show != 'null' && show != 'undefined') {
                    if (typeof show === "string") {
                        show = JSON.parse(show);
                        vmShowClasses(show);
                    }
                } else {
                    vmShowClasses(false);
                }              
            }
            
            function vmChangeStudent(data) {
                   
                $state.go($state.current.name, { clas: $stateParams.clas, student: data.token, back: null });
            }
            function vmGetStateName(state) {

                switch (state) {
                    case 'in_progress': {
                        return 'In Progress';
                    }
                    case 'completed': {
                         return 'Completed';
                    }           
                    case 'satisfactory_completed': {
                        return 'Satisfactory Completed';
                    }
                }      
             }
            function vmUserResult(token) {
        
                $rootScope.pageLoading = true;
                $scope.show = true;
        
                var course = $scope.course ? $scope.course : null;
                var token = token ? token : null;
                var tokens = [];
        
                if (token == null) {
        
                    $scope.students.forEach(function (item, i) {
                        if (item.token != null) {
                            tokens.push(item.token);
                        }
                    });

                    if (tokens.length === 0) { $rootScope.pageLoading = false; return; };

                    $scope.students_token = tokens.join(',');
                    $scope.selectStudent = $scope.students.find(x => x.token == $stateParams.student);
        
                    // try to set default student
                    // if found than read the result
                    $scope.select = $scope.students.find(x => x.token == $stateParams.student);
                        
                } else {
                    $scope.students_token = token;
                }
        
                FRAPI.userresult($scope.students_token, course, $scope.unix_datebegin, $scope.unix_dateend).then(function (response) {
        
                    $scope.results = response.data.sort(function (x, y) {
                        return y.lastuse - x.lastuse;
                    }).sort((a,b) => (a.attempt_start_date < b.attempt_start_date) ? 1 : ((b.attempt_start_date < a.attempt_start_date) ? -1 : 0));
        
                    $scope.results.forEach(function (item) {
                        item.course_scores = JSON.parse(item.course_scores);
                        if (item.course_scores) {
                            item.$read = item.course_scores ? Math.round(item.course_scores.read) : null;
                            item.$quiz = item.course_scores ? Math.round(item.course_scores.quiz) : null;
                            //item.$state = item.course_scores ? vmGetStateName(item.course_scores.state) : null;
                            item.$raw_quiz = item.course_scores.raw_quiz ? Math.round(item.course_scores.raw_quiz) : 0;
                            item.$raw_read = item.course_scores.raw_read ? Math.round(item.course_scores.raw_read) : 0;
                            item.$raw_first_quiz = item.course_scores.raw_first_quiz == null ? 'N/A' : Math.round(item.course_scores.raw_first_quiz);
                        }
                       
                        item.$attempt_start_date = item.attempt_start_date ? commonService.getLocaleDate(item.attempt_start_date) : 0;
                        item.$lastuse = item.lastuse ? commonService.getLocaleDate(item.lastuse) : 0;

                        let Lmin = item.time_listening ? Math.trunc(parseFloat(item.time_listening) / 60) : 0;
                        let Rmin = item.time_reading ? Math.trunc(parseFloat(item.time_reading) / 60) : 0;
                        let Lsec; let Rsec;
                        if (Lmin*60 < parseInt(item.time_listening)) {
                            Lsec = item.time_listening - Lmin*60;
                        } else {
                            Lsec = 0;
                        }
                        if (Rmin*60 < parseInt(item.time_reading)) {
                            Rsec = item.time_reading - Rmin*60;
                        } else {
                            Rsec = 0;
                        }
        
                        item.$time_listening_min  = commonService.vmAddZero(Lmin) + ":" + commonService.vmAddZero(Lsec);
                        item.$time_reading_min = commonService.vmAddZero(Rmin) + ":" + commonService.vmAddZero(Rsec);
        
                        item.lastuse = item.lastuse == 0 || item.lastuse == null ? 0 : moment.unix(item.lastuse).format("MM/DD/YYYY");
                    });
                    $rootScope.pageLoading = false;
                })
            };
        
            function vmOpenResult(cohort, result) {
                $state.go('app.admin.school.reports.bookresults', {  
                    clas:  $stateParams.clas, 
                    student: $stateParams.student,
                    token: result.token,
                    idnumber: result.idnumber,
                    title: result.title
                 });
            };
            // method call in template if date change
            // then check dates which got
            // else check dates which are in localStorage
            // NOT READ all from local storage, when change template, storage see changes later
            function vmChangeDate(datebegin, dateend, storage) {
        
                if (storage) {
        
                    var storageBegin = localStorage.getItem('datebegin');
                    var storageEnd = localStorage.getItem('dateend');
        
                    $scope.unix_datebegin = storageBegin && storageBegin != 'null' && storageBegin != 'undefined' ? moment((new Date(storageBegin)).setHours(0, 0, 0, 0)).unix() : null;
                    $scope.unix_dateend = storageEnd && storageEnd != 'null' && storageEnd != 'undefined' ? moment((new Date(storageEnd)).setHours(23, 59, 59, 999)).unix() : null;
        
                } else {
                    $scope.unix_datebegin = datebegin ? moment(datebegin.setHours(0, 0, 0, 0)).unix() : null;
                    $scope.unix_dateend = dateend ? moment(dateend.setHours(23, 59, 59, 999)).unix() : null;
                }
        
                // one of the dates may be empty (null) - this is not a mistake
                // show mistake only when date end < date begin
                if (($scope.unix_dateend < $scope.unix_datebegin) && $scope.unix_datebegin != null && $scope.unix_dateend != null) {
        
                    $scope.errorIntervalDate = true;
        
                } else {
                    $scope.errorIntervalDate = false;
                }
            }
            function vmShowClasses(show) {

                window.localStorage.setItem('showAllClasses', show);
                
                $scope.showClas = show;
                           

                if (show) {
                    $scope.classList = classes;        
                } else {
                    $scope.classList = classes.filter(function (item) {
                        return item.date_end >= today;
                    });        
                }
            }
        
            $scope.getStudentInClass = vmChangeClass;
            $scope.getStudent = vmChangeStudent;
            $scope.userresult = vmUserResult;
            $scope.openResult = vmOpenResult;
            $scope.changeDate = vmChangeDate;
            $scope.showClasses = vmShowClasses;
            $scope.getClassName = commonService.vmsGetClassName;
        })
        .controller('adminResultsBookCtrl', function (credentials, $scope, commonService, $state, $stateParams, LMAPI, LMAPICache, LMConfig, $q, $translate, user) {
            $scope.$emit('adminTabChange', 'breport');
            $scope.back = vmBack;
            $scope.showActivityReport = vmShowActivityReport;
            $scope.loadReportActivity = vmLoadReportActivity;
            $scope.currentActivity = null;
            $scope.openAttepmt = vmOpenAttepmt;
            $scope.isSpeaking = vmIsSpeaking;
    
            //restructure header table to the correct position
            $('table.demo').floatThead('reflow');
    
            $scope.info = {idnumber: $stateParams.idnumber, token: $stateParams.token, title: $stateParams.title };
    
            function vmBack() {
    
                $state.go('app.admin.school.reports.breport', { back: 'back', clas:  $stateParams.clas, student: $stateParams.student });
            };
    
            function vmShowActivityReport(sco) {
    
                $scope.currentActivity = sco;
    
                $scope.currentActivity.date = sco.timemodified ? moment.unix(sco.timemodified).utc().format("MM/DD/YYYY") : null;
    
                var params = ['cmi.vms_absolute_score', 'cmi.core.lesson_status', 'cmi.core.total_time', 
                'cmi.core.score_coefficient', 'cmi.vms_quiz_xml', 'cmi.vms_first_score',
                'cmi.vms_audio_path', 'cmi.vms_text', 'cmi.vms_video_path', 
                'vms.chat_score', 'vms.chat_text', 'cmi.core.score.raw', 
                'cmi.vms_dialog_cache', 'cmi.vms_first_quiz_xml'];
    
                $q.all([LMAPICache.scos($scope.info.token, $scope.info.idnumber), LMAPI.track($scope.info.token, sco.id, params.join(','))]).then(function (responses) {
                    var scos = responses[0].scos;
                    var tracks = responses[1].data;
                    var reportData = {};
    
                    var activityParams = scos.find(x => x.id == sco.id).params;
                    activityParams = JSON.parse(activityParams);
                    //make type of dialog activity 'Text' to prevent building in report composition clouds
                    if (activityParams.idevice_type == 'DGR')
                        activityParams.type = 'Text';
    
                    tracks = tracks.filter(x => x.attempt == sco.attempt);
                    if (tracks.length > 0) {
    
                        reportData = tracks.reduce(function (prev, next) {
    
                            prev[next.key] = next.value;
                            return prev;
    
                        }, {});
                    };
    
                    vmLoadReportActivity(activityParams, reportData)
                });
            }
    
            function vmLoadReportActivity(activityParams, reportData) {
    
                var defaultStorage = {
                    type: 's3',
                    audio: 'speech-userfiles-dev'
                };
    
                var reportConfig = {
    
                    application_id: "contenttool_report_page",
                    bucket_root_url: LMConfig.storage,
                    bucket_script_url: LMConfig.sco_defaults.bucket_script_url,
                    idevice_mode: "report",
                    lang: user.lang || "en",
                    speech_service: LMConfig.sco_defaults.speech_service,
                    web_worker: LMConfig.sco_defaults.web_worker,
                    storage: LMConfig.sco_defaults.storage || defaultStorage,
                    mode: 'report'
                };
    
                reportConfig = $.extend(reportConfig, activityParams);
    
                var preload = vimas.loader.preload({
                    bucket: reportConfig.bucket_script_url || reportConfig.bucket_root_url,
                    skin: {
                        action: "lb_report",
                        html: "lb_report",
                        skin: "lb_report",
                        localization: "lb"
                    },
                    scos: [{ container: '#sco_container', type: reportConfig.idevice_type }],
                    lang: reportConfig.lang,
                    mode: reportConfig.mode
                });
    
                preload.done(function () {
    
                    Idevice.postinit('sco_container', reportConfig, reportData);
                });
            };
    
            LMAPI.activitiesprogress($scope.info.token, $scope.info.idnumber).then(function (response) {
               
                $scope.attempts = [];
                let numberAttempQuest = 10000;
    
                $scope.results = response.data.filter(x => x.type !== 'NSCO' && x.type !== 'FC');
    
                let uniqAttempts = [...new Set($scope.results.map(item => item.attempt))];
               
                let dataAttempts = [];
    
                uniqAttempts.forEach((attempt) => {
    
                    let dataByOneAttempt = $scope.results.filter((x) => x.attempt === attempt);
                    let isCompleted = dataByOneAttempt.every((x) => x.status === "completed" );
                    
                    dataByOneAttempt.sort((a,b) => (a.timemodified > b.timemodified) ? 1 : ((b.timemodified > a.timemodified) ? -1 : 0));
                                
                    let finishDate = isCompleted ? dataByOneAttempt[dataByOneAttempt.length - 1].timemodified : null;              
                    let arrayStartingDate = dataByOneAttempt.filter((x) => x.status === "completed")
                                            .sort((a,b) => (a.timemodified > b.timemodified) ? 1 : ((b.timemodified > a.timemodified) ? -1 : 0));
                    let startDate  = arrayStartingDate.length > 0 ? arrayStartingDate[0].timemodified : null;
     
                    dataAttempts.push({
                        attempt: attempt,
                        timemodified: startDate ? startDate : 9999999999,
                        finishDate: finishDate ? moment.unix(finishDate).utc().format("MM/DD/YYYY") : null,
                        startDate: startDate ? moment.unix(startDate).utc().format("MM/DD/YYYY") : null,
                        status: isCompleted ? 'completed' : null,
                        isQuest: attempt > numberAttempQuest ? Math.trunc(attempt / 10000) : null
                    });
                });
    
                dataAttempts.sort((a,b) => (a.timemodified > b.timemodified) ? 1 : ((b.timemodified > a.timemodified) ? -1 : 0));
    
                $scope.attempts = dataAttempts;
                //show always the first attempt by default
                if ($scope.attempts.length >= 1) {
                    vmOpenAttepmt($scope.attempts[$scope.attempts.length - 1]);
                }                       
    
                $scope.results.forEach(x => x.activityName = $translate.instant('teacher.report.book.results.activityTypes.{0}'.format(x.type)));
    
                //restructure header table to the correct position
                $('table.demo').floatThead('reflow');
               
            });
    
            function vmOpenAttepmt(attempt) {
                                    
                $scope.currentActivity = null;
                //add css-class if active attempt
                $scope.selectAttempt = attempt.attempt;
                $scope.selectCurrentAttempt = attempt;

                $scope.resultsAttempt = $scope.results.filter(function (item) {
                    return item.attempt == attempt.attempt;
                });
                if (attempt.isQuest) {
                    LMAPI.singletaskByID(credentials.session_token, attempt.isQuest, commonService.getCurrentSchool()).then(function (res) {
                        
                        $scope.selectCurrentAttempt.title = res.title;
                        if (!$scope.$$phase) {
                            $scope.$apply();
                        }
                    });
                }
                //restructure header table to the correct position
                $('table.demo').floatThead('reflow');
            };
    
            function vmIsSpeaking(type) {
                return ['SRS', 'SAS', 'FCR', 'FCA'].contains(type);
            };
        })

})();