(function () {
  'use strict';

  angular.module('languabooksApp')

    .directive('botResponse', function () {
      return {
        restrict: 'E',
        scope: {
          response: '=ngModel'
        },
        link: function ($scope, elem) {
          $scope.my = $scope.response;
        },
        template: '<div class="chatbot"><div class="avatar"></div><div class="chatbot_answer" ng-bind-html="my"></div></div>'
      }
    })

    .directive('botQuestion', function () {
      return {
        restrict: 'E',
        scope: {
          response: '@asr',
        },
        link: function ($scope, elem) {
          
          $scope.text = $scope.response;
         },
        template: '<div class="wrapperQuestion"><div class="chatbot_question">{{text}}</div></div>',
      }
    })

    // url_type = book
    .directive('botResponseBook', function ($state, coursesService) {
      return {
        restrict: 'E',
        scope: {
          _image: '=asImage',
          _book: '=asBook',
          _textSpeech: '=asText',
          _activity: '=asActivity',
          _titleBtn: '=asTitle',
          stopRecognition: "=asStopRecording"
        },
        link: function ($scope) {
          $scope.image = $scope._image;
          $scope.book = $scope._book;
          $scope.textSpeech = $scope._textSpeech;
          $scope.activity = $scope._activity;
          $scope.titleBtn = $scope._titleBtn;
          $scope.open = vmOpen;


          function vmOpen() {
            coursesService.runTest(null, $scope.book, $scope.activity);
          }

        },
        template: '<div class="chatbot">\
                    <div class="avatar"></div>\
                    <div class="chatbot_answer image book">\
                        <p ng-bind-html="textSpeech"></p>\
                        <a ng-click="stopRecognition(); open()" \
                          class="button"\
                          ng-if="!image">{{titleBtn}}</a>\
                        <a ng-click="stopRecognition(); open()" ng-if="image">\
                            <img draggable="false"\
                                ng-srcset="{{image}}" />\
                        </a>\
                    </div>\
                  </div>',
      }
    })
    // url_type = pdf
    .directive('botResponsePdf', function () {
      return {
        restrict: 'E',
        scope: {
          _url: '=asUrl',
          _textSpeech: '=asText',
          stopRecognition: "=asStopRecording"
        },
        link: function ($scope, elem) {
          $scope.url = $scope._url;
          $scope.textSpeech = $scope._textSpeech;

        },
        template: '<div class="chatbot">\
                      <div class="avatar"></div> \
                      <div class="chatbot_answer button"> \
                          <p ng-bind-html="textSpeech"></p> \
                          <a ng-href="{{url}}" target="_blank" ng-click="stopRecognition()">Open</a> \
                      </div> \
                  </div>',
      }
    })
    // url_type = youtube
    .directive('botResponseYoutube', function () {
      return {
        restrict: 'E',
        scope: {
          _url: '=asUrl',
          _textSpeech: '=asText',
          stopRecognition: "=asStopRecording"
        },
        link: function ($scope, elem) {
          $scope.url = $scope._url;
          $scope.textSpeech = $scope._textSpeech;

        },
        template: '<div class="chatbot">\
                    <div class="avatar"></div>\
                    <div class="chatbot_answer image youtube">\
                        <p ng-bind-html="textSpeech"></p>\
                        <a ng-href="{{url}}" target="_blank" class="youtube" ng-click="stopRecognition()">\
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\
                                viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">\
                                <path style="fill:#D7143A;" d="M492.044,106.769c-18.482-21.97-52.604-30.931-117.77-30.931H137.721\
                                  c-66.657,0-101.358,9.54-119.77,32.93C0,131.572,0,165.174,0,211.681v88.64c0,90.097,21.299,135.842,137.721,135.842h236.554\
                                  c56.512,0,87.826-7.908,108.085-27.296C503.136,388.985,512,356.522,512,300.321v-88.64\
                                  C512,162.636,510.611,128.836,492.044,106.769z M328.706,268.238l-107.418,56.14c-2.401,1.255-5.028,1.878-7.65,1.878\
                                  c-2.97,0-5.933-0.799-8.557-2.388c-4.942-2.994-7.959-8.351-7.959-14.128V197.82c0-5.767,3.009-11.119,7.941-14.115\
                                  c4.933-2.996,11.069-3.201,16.187-0.542l107.418,55.778c5.465,2.837,8.897,8.479,8.905,14.635\
                                  C337.58,259.738,334.163,265.388,328.706,268.238z" />\
                            </svg>\
                        </a>\
                    </div>\
                </div>',
      }
    })

    .directive('notes', function (LMAPI) {

      function vmController($scope, elem, attrs) {

        $scope.note = $scope._note;
        $scope.subject = 'Reminder from Kleo assistant';

        function vmEditNote(note) {

          $scope.editNote(note);
        }

        function vmSentToEmail(note) {

          LMAPI.sentToEmail($scope.user, 'Notes from Kleo assistant', note.text, $scope.session).then(function (response) {

            // it was send 
            $scope.audioSend();

          }).catch(function (reason) {
            console.error(reason);
          });
        }

        // appears/disappears dialog window for enter dates and time
        function vmReminder() {

          $scope.dialogReminder = $scope.closeReminder();

          // reset the date to current on open
          // get current date and round to minutes
          let date = new Date();
          date.setMilliseconds(0);
          date.setSeconds(0);

          $scope.date_begin = date;
        }

        function vmSentReminder(subject, date_start, note) {

          var start = moment(date_start).unix();
          // add 5 minutes 
          var end = start + 300;

          LMAPI.reminder($scope.user, start, end, subject, note.text, $scope.session).then(function (response) {

            $scope.dialogReminder = false;
            $scope.audioSend();
          }).catch(function (reason) {
            console.error(reason);
          });
        }

        $scope.editNotes = vmEditNote;
        $scope.email = vmSentToEmail;
        $scope.sentReminder = vmSentReminder;
        $scope.reminder = vmReminder;

      }

      return {
        restrict: 'E',
        scope: {
          _note: '=asNote',
          allNotes: '=asAllNotes',
          textArea: '=asTextArea',
          session: '=asSession',
          user: '=asMail',
          audioSend: '=audioSend',
          editNote: '=editNote',
          closeReminder: '=closeReminder'
        },
        link: vmController,
        templateUrl: "notes.html",
      }
    });

})();