angular.module('LMAPI')

.service('LMAPICache', ['$q', 'LMAPI', 'LMWebStorage', function ($q, LMAPI, LMWebStorage) {

    // 60 minutes
    var cachedTime = 60 * 60;
    // caching layer above courses API method
    // creates timed cache by different criterias
    // by category
    // by cohort
    // by single course
    this.courses = function (token, cohort, category, course) {

        var currentTime = new Date().getTime() / 1000;

        if (category) {

            // try to get data about category from cache
            return LMWebStorage.get('CategoriesCourseCache', null, category)
                        .then(function (cached) {

                            // if no cached data found
                            // or cache already expired
                            if (!cached || cached.updated + cachedTime < currentTime) {

                                // read fresh courses data from API
                                return LMAPI.courses(token, null, category)
                                            .then(function (courses) {

                                                // update categories cache record
                                                // set list of courses
                                                // which is included in category
                                                // set updated date as current
                                                LMWebStorage.update('CategoriesCourseCache', null, category, {
                                                    id: category,
                                                    courses: courses.map(x => x.id),
                                                    updated: currentTime
                                                });

                                                // update courses cache
                                                vmUpdateCourses(courses, currentTime);

                                                // return list of courses
                                                // as result
                                                return courses;
                                            });

                            } else {

                                // read all courses from database
                                // as list of deffered objects
                                var defs = cached.courses.map(x => LMWebStorage.get('CoursesCache', null, x));

                                // combine all deffereds into one
                                // return it as list of courses
                                return $q.all(defs);
                            }
                        })

        } else if (cohort) {

            // try to get data about cohort from cache
            return LMWebStorage.get('CohortsCourseCache', null, cohort)
                        .then(function (cached) {

                            // if no cached data found
                            // or cache already expired
                            if (!cached || cached.updated + cachedTime < currentTime) {

                                // read fresh courses data from API
                                return LMAPI.courses(token, cohort, null)
                                            .then(function (courses) {

                                                // update cohorts cache record
                                                // set list of courses
                                                // which is included in cohort
                                                // set updated date as current
                                                LMWebStorage.update('CohortsCourseCache', null, cohort, {
                                                    id: cohort,
                                                    courses: courses.map(x => x.id),
                                                    updated: currentTime
                                                });

                                                // update courses cache
                                                vmUpdateCourses(courses, currentTime);

                                                // return list of courses
                                                // as result
                                                return courses;
                                            });

                            } else {

                                // read all courses from database
                                // as list of deffered objects
                                var defs = cached.courses.map(x => LMWebStorage.get('CoursesCache', null, x));

                                // combine all deffereds into one
                                // return it as list of courses
                                return $q.all(defs);
                            }
                        })

        } else {

            // try to get data about course from cache
            return LMWebStorage.get('CoursesCache', null, course)
                        .then(function (cached) {

                            // if no cached data found
                            // or cache already expired
                            if (!cached || cached.updated + cachedTime < currentTime) {

                                // read fresh courses data from API
                                return LMAPI.courses(token, null, null, course)
                                            .then(function (courses) {

                                                // update courses cache
                                                vmUpdateCourses(courses, currentTime);

                                                // return response
                                                // as result
                                                return courses[0];
                                            });

                            } else {

                                // read course from cache
                                return LMWebStorage.get('CoursesCache', null, course)
                            }
                        });
        }
    };

    // caching layer above search API method
    // creates timed cache by search criteria
    this.searchCourses = function (token, limit, searchcriterias) {

        var currentTime = new Date().getTime() / 1000;

        var criterias = angular.toJson(searchcriterias);

        // try to get search data from cache
        return LMWebStorage.get('SearchCache', null, criterias)
                .then(function (cached) {

                    // if no cached data found
                    // or cache already expired
                    if (!cached || cached.updated + cachedTime < currentTime) {

                        return LMAPI.searchCourses(token, searchcriterias)
                                    .then(function (courses) {

                                        // update cohorts cache record
                                        // set list of courses
                                        // which is included in cohort
                                        // set updated date as current
                                        LMWebStorage.update('SearchCache', null, criterias, {
                                            criteria: criterias,
                                            courses: courses.map(x => x.id),
                                            updated: currentTime
                                        });

                                        // update courses cache
                                        vmUpdateCourses(courses, currentTime);

                                        // return list of courses
                                        // as result
                                        return courses;
                                    });

                    } else {

                        // read all courses from database
                        // as list of deffered objects
                        var defs = cached.courses.map(x => LMWebStorage.get('CoursesCache', null, x));

                        // combine all deffereds into one
                        // return it as list of courses
                        return $q.all(defs);
                    }

                });
    }

    // caching layer above activity list API method
    // creates timed cache by course
    this.scos = function (token, course) {

        let currentTime = new Date().getTime() / 1000;

        // try to get data about scos from cache
        return LMWebStorage.get('ScosCache', null, course)
                    .then(function (cached) {
                      
                        // check ss_model
                        var ss_model = window.localStorage.getItem('ss_model') || null;
                        // if no cached data found
                        // or cache already expired
                        if (!cached || cached.updated + cachedTime < currentTime || cached.scos.ss_model !== ss_model) {

                            // read fresh scos data from API
                            return LMAPI.scos(token, course)
                                        .then(function (scos) {

                                            // update categories cache record
                                            // set list of courses
                                            // which is included in category
                                            // set updated date as current
                                            LMWebStorage.update('ScosCache', null, course, {
                                                id: course,
                                                scos: scos,
                                                updated: currentTime
                                            });
                                            window.localStorage.setItem('ss_model', scos.ss_model);

                                            // return scos data as result
                                            return angular.copy(scos);
                                        });

                        } else {

                            // read all data from scos cache
                            return LMWebStorage.get('ScosCache', null, course)
                                        .then(x => angular.copy(x.scos));
                        };
                    });
    };

    // caching layer above categories list API method
    // creates timed cache
    this.categories = function () {

        let currentTime = new Date().getTime() / 1000;

        // try to get data about scos from cache
        return LMWebStorage.get('CategoriesCache')
                    .then(function (cached) {

                        var i = 1;

                        // if no cached data found
                        // or cache already expired
                        if (cached && cached.length > 0) {

                            return cached;

                        } else {

                            // read fresh scos data from API
                            return LMAPI.categories()
                                        .then(function (categories) {

                                            categories.forEach(function (category) {

                                                // update categories cache record
                                                LMWebStorage.update('CategoriesCache', null, category.idnumber, {
                                                    id: category.idnumber,
                                                    idnumber: category.idnumber,
                                                    parentidnumber: category.parentidnumber,
                                                    name: category.name
                                                });
                                            });

                                            return categories
                                        });
                        };
                    });

    };

    // caching layer above cohorts API method
    // creates timed cache by different criterias
    // by type
    this.cohorts = function (token, type) {

        let currentTime = new Date().getTime() / 1000;

        // try to get data about scos from cache
        return LMWebStorage.get('CohortsCache', null, type)
                    .then(function (cached) {

                        // if no cached data found
                        // or cache already expired
                        if (!cached || cached.updated + cachedTime < currentTime) {

                            // read fresh scos data from API
                            return LMAPI.listCohort(token, type)
                                        .then(function (cohorts) {

                                            // update categories cache record
                                            // set list of courses
                                            // which is included in category
                                            // set updated date as current
                                            LMWebStorage.update('CohortsCache', null, type, {
                                                id: type,
                                                cohorts: cohorts,
                                                updated: currentTime
                                            });

                                            // return scos data as result
                                            return angular.copy(cohorts);
                                        });

                        } else {

                            // read all data from scos cache
                            return LMWebStorage.get('CohortsCache', null, type)
                                        .then(x => angular.copy(x.cohorts));
                        };
                    });
    };

    // reset API cache
    this.reset = function () {

        LMWebStorage.delete('CategoriesCourseCache');
        LMWebStorage.delete('CohortsCourseCache');
        LMWebStorage.delete('CoursesCache');
        LMWebStorage.delete('ScosCache');
        LMWebStorage.delete('SearchCache');
        LMWebStorage.delete('CategoriesCache');
        LMWebStorage.delete('CohortsCache');
    };

    // update courses cache
    function vmUpdateCourses(courses, time) {

        // for each course read
        // set updated date as current
        // and update in database
        courses.forEach(function (course) {

            course.updated = time;
            LMWebStorage.update('CoursesCache', null, course.id, course);
        });
    };

}]);