(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', 'LMConfig', function ($stateProvider, LMConfig) {

        $stateProvider
            .state('app.course', {
                url: '/course/:course',
                abstract: true,
                template: '<ui-view />',
                params: {
                    back: null,
                    activity: null,
                    notDirect: null
                },
                resolve: {
                    course: function (LMAPICache, token, $stateParams) {
                        return LMAPICache.courses(token, null, null, $stateParams.course);
                    }
                }
            })            
    }])

})();