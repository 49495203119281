(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', 'LMConfig', function ($stateProvider, LMConfig) {

        $stateProvider
            .state('app.library.shelve.latest', {
                url: '/latest',
                controller: 'libraryMyLatestCtrl',
                template: '<vms-course-list vms-more-unread="showmsg" ng-model="courses" vms-token="token" vms-remove-sort="true" vms-user="user" vms-page="page"></vms-course-list>'
            })
    }])

    .controller('libraryMyLatestCtrl', function ($scope, LMAPI, token, latestCourses, user) {

        $scope.token = token;
        $scope.courses = null;
        $scope.page = 'latest';

        $scope.$emit('libraryTabChange', 'library'); 
        $scope.$emit('libraryActiveTab', 'latest');
        $scope.courses = latestCourses;
        let isStudent = user.roles.every(x => x == 'student');
        $scope.showmsg = latestCourses && latestCourses.length > 15 && isStudent ? true : false;

        if (!$scope.$$phase) {
            $scope.$apply();
        }

    })

})();