(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.admin.school.users', {
                    url: '/users',
                    controller: 'adminUsersCtrl',
                    templateUrl: '/assets/ng1/admin/users/index.html',
                    deepStateRedirect: {
                        default: { state: 'app.admin.school.users.teachers', },
                        params: true
                    },
                })
        }])

       .controller('adminUsersCtrl', function ($scope, $state, $window, LMConfig, user, commonService, schools) {
            
            $scope.$emit('adminTopTabChange', 'users');

        })

})();