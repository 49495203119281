(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.projectadmin.page.districtadmins', {
                url: '/districtadmins/:district',
                controller: 'pageDistrictsAdminsCtrl',
                templateUrl: '/assets/ng1/projectadmin/page/districts/admin/admin.html'
            })
            .state('app.projectadmin.page.createdisadmin', {
                url: '/createdisadmin/?username/?district',
                controller: 'pageCreateDistrictAdminCtrl',
                templateUrl: '/assets/ng1/projectadmin/page/districtadmins/createDistrictAdmin.html'
            })
            .state('app.projectadmin.page.invitedisadmin', {
                url: '/request/:page/?district',
                controller: 'pageInviteDistrictAdminCtrl',
                templateUrl: '/assets/ng1/projectadmin/page/districts/admin/request.html'
            })
    }])

    .controller('pageDistrictsAdminsCtrl', function ($scope, $state, $stateParams) {

        $scope.$emit('modeChange', 'page');
        $scope.$emit('leftTabs', 'districts');
        $scope.district = $stateParams.district;

        function onBack() {
            $state.go('app.projectadmin.page.districts');
        }
        function onCreateDistrictAdmin() {
            $state.go('app.projectadmin.page.createdisadmin', { district: $stateParams.district });
        }
        function onUpdateDistrictAdmin(admin) {
            $state.go('app.projectadmin.page.createdisadmin', { username: admin.username, district: $stateParams.district });
        }
        function onInviteDistrictAdmin() {
            $state.go('app.projectadmin.page.invitedisadmin', { page: 'districtadmin',  district: $stateParams.district});
        }

        $scope.onBack = onBack;
        $scope.onCreateDistrictAdmin = onCreateDistrictAdmin;
        $scope.onUpdateDistrictAdmin = onUpdateDistrictAdmin;
        $scope.onInviteDistrictAdmin = onInviteDistrictAdmin;
    })
    .controller('pageCreateDistrictAdminCtrl', function ($scope, $state, user, $stateParams, LMWordpress, FRAPI, $rootScope) {

        $scope.$emit('modeChange', 'page');

        $scope.district = $stateParams.district;
        $scope.username = $stateParams.username; 

        if ($stateParams.district) {
            $scope.$emit('leftTabs', 'districts');
        } else {
            $scope.$emit('leftTabs', 'availabledistrictadmins');
        }

        function onBack() {
            if ($stateParams.district ) {
                $state.go('app.projectadmin.page.districtadmins', { district: $stateParams.district });
            } else {
                $state.go('app.projectadmin.page.availabledistrictadmins');
            }         
        }
        $scope.onBack = onBack;
    })
    .controller('pageInviteDistrictAdminCtrl', function ($scope, $state, $stateParams) {
        
        $scope.$emit('modeChange', 'page');

        if ($stateParams.district ) {
            $scope.$emit('leftTabs', 'districts');
        } else {
            $scope.$emit('leftTabs', 'availabledistrictadmins');
        }

        $scope.page = $stateParams.page;
        $scope.district = $stateParams.district;

        function onBack() {
            if ($stateParams.district ) {
                $state.go('app.projectadmin.page.districtadmins', { district: $stateParams.district });
            } else {
                $state.go('app.projectadmin.page.availabledistrictadmins');
            }         
        }
        $scope.onBack = onBack;

    })

})();