(function () {
    'use strict';

angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider    
            .state('app.root.districtadmin.district.schools', {
                url: '/schools',
                controller: 'schoolsByDistrictCtrl',
                templateUrl: '/assets/ng1/districtadmin/schools/schools.html',
            })      
            .state('app.root.districtadmin.district.school', {
                url: '/createschool/?:school',
                controller: 'schoolCreateDistCtrl',
                templateUrl: '/assets/ng1/districtadmin/schools/createSchool.html'
            })     
            .state('app.root.districtadmin.district.assignschooladmin', {
                url: '/assignschooladmin/:school',
                controller: 'assignSchoolAdminDistCtrl',
                templateUrl: '/assets/ng1/districtadmin/schools/assignschooladmin.html'
            }) 
            .state('app.root.districtadmin.district.search', {
                url: '/request/:school/:page',
                controller: 'inviteSchoolAdminDSTRCtrl',
                templateUrl: '/assets/ng1/projectadmin/page/schools/admin/request.html'
            })
    }])

    .controller('schoolsByDistrictCtrl', function ($scope, $stateParams, $rootScope, $state, newNotifications, districtsByUser, FRAPI) {

        $scope.$emit('modeChange', 'page');
        $scope.$emit('leftTabs', 'schools');                  
        $scope.$emit('newNotifications', newNotifications.length);
      
        $scope.district = sessionStorage.getItem("current_district_idnumber");
        $scope.districts = districtsByUser;
        $scope.clearCashSchoolByDistrict = $rootScope.clearCashSchoolByDistrict;
        $scope.onEditSchool = onEditSchool;
        $scope.onAssignUsers = onAssignUsers;
        $scope.onCreateSchool = onCreateSchool;
        $scope.onClearCache = onClearCache;

        function onClearCache(value) {
            $rootScope.clearCashSchoolByDistrict = value;
        }

        function onEditSchool(school) {
            $state.go('app.root.districtadmin.district.school', { school: school.idnumber});
        }
        function onAssignUsers(school) {
            $state.go('app.root.districtadmin.district.assignschooladmin', { school: school.idnumber});
        }
        function onCreateSchool(e) {
            $state.go('app.root.districtadmin.district.school');
        }
    })
    .controller('schoolCreateDistCtrl', function ($scope, $state, $stateParams, districtsByUser) {

        $scope.$emit('modeChange', 'page');
        $scope.$emit('leftTabs', 'schools');

        $scope.idschool =  $stateParams.school ?  $stateParams.school : null;
        $scope.district = sessionStorage.getItem("current_district_idnumber");
        $scope.districts = districtsByUser;

        $scope.onBack = vmBack;

        function vmBack() {
            $state.go('app.root.districtadmin.district.schools');
        }

        $scope.back = vmBack;
    })
    .controller('assignSchoolAdminDistCtrl', function ($q, $scope, $rootScope, $state, $stateParams, districtsByUser) {

        $scope.$emit('modeChange', 'page');
        $scope.$emit('leftTabs', 'schools');

        $scope.idschool =  $stateParams.school ?  $stateParams.school : null;
        $scope.district = sessionStorage.getItem("current_district_idnumber");
        $scope.districts = districtsByUser;
        $scope.onBack = vmBack;
        $scope.onInvite = onInvite;
        $scope.onCreateSchoolAdmins = onCreateSchoolAdmins;
        $scope.onUpdateSchoolAdmins = onUpdateSchoolAdmins;

        function vmBack() {
            $state.go('app.root.districtadmin.district.schools');
        }
        function onCreateSchoolAdmins(school) {

            $state.go('app.root.districtadmin.district.schooladmin', { school: school } );
        }
        function onUpdateSchoolAdmins(datas) {
            $state.go('app.root.districtadmin.district.schooladmin', { school: datas.school, username: datas.username } );
        }
        function onInvite(school) {
            $state.go('app.root.districtadmin.district.search', { school: school, page: 'schooladmin' } );
        }
    })
    .controller('inviteSchoolAdminDSTRCtrl', function ($scope, $state, $stateParams) {

        $scope.$emit('modeChange', 'page');
        $scope.$emit('leftTabs', 'schools');

        $scope.idschool = $stateParams.school;
        $scope.page =  $stateParams.page;
        $scope.district = $stateParams.district;

        function onBack() {
            $state.go('app.root.districtadmin.district.assignschooladmin', { school: $stateParams.school });
        }

        $scope.onBack = onBack;
    })
})();