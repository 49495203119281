(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.projectadmin.page', {
                    url: '/page',
                    controller: 'pageCtrl',
                    templateUrl: '/assets/ng1/projectadmin/page/page.html',
                    deepStateRedirect: {
                        default: { state: 'app.projectadmin.page.districts' },
                        params: true
                    }
                })
                .state('app.projectadmin.page.availableadmins', {
                    url: '/availableadmins',
                    controller: 'availableAdminsCtrl',
                    templateUrl: '/assets/ng1/projectadmin/page/listSchoolAdmins.html'
                })
                .state('app.projectadmin.page.availabledit', {
                    url: '/availabledit/:username',
                    params: {
                        admin: null
                    },
                    controller: 'pageAvailablEditCtrl',
                    templateUrl: '/assets/ng1/projectadmin/page/schools/admin/createAdmin.html'
                })
        }])

        .controller('pageCtrl', function ($scope, $state, user, commonService, $window) {

            $scope.$emit('modeChange', 'page');
            $scope.$on('leftTabs', function (event, data) {
                $scope.activeTab = data;
            });
            $scope.teacherReports = commonService.teacherReports(user.project);
            $scope.notifications = commonService.notifications(user.project);
            $scope.certificatesTab = commonService.certificatesTab(user.project);
            $scope.showWorkstations = commonService.workstations(user.project, 'projectAdmin');
        })
        .controller('availableAdminsCtrl', function ($scope, $state) {

            $scope.$emit('modeChange', 'page');
            $scope.$emit('leftTabs', 'availableadmins');

            $scope.onEditSchoolAdmin = onEditSchoolAdmin;
            $scope.onCreateSchoolAdmin = onCreateSchoolAdmin;

            function onEditSchoolAdmin(admin) {
                $state.go('app.projectadmin.page.availabledit', { username: admin.username});
            }
            function onCreateSchoolAdmin() {
                $state.go('app.projectadmin.page.availabledit');
            }

        })
        .controller('pageAvailablEditCtrl', function ($scope, $state, FRAPI, $stateParams) {

            $scope.$emit('modeChange', 'page');
            $scope.$emit('leftTabs', 'availableadmins');

            $scope.username =  $stateParams.username ?  $stateParams.username : null;
            $scope.idschool = null;
    
            $scope.onBack = vmBack;
            $scope.onCreateAdmin = onCreateAdmin;
    
            function vmBack() {  
              
                $state.go('app.projectadmin.page.availableadmins');                       
            }
            function onCreateAdmin(user) {
                FRAPI.clearSchoolAdminsByProject();
                FRAPI.clearSchoolAdminsBySchool();
            }
            
        })


})();