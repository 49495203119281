(function () {
    'use strict';

    angular.module('languabooksApp')
        .directive('vmsCurriculum', function ($state, $stateParams, $q, $http, LMConfig, LMAPI, $translate, dates, commonService) {

            function vmController($scope, $state, $stateParams, $q, $http, LMConfig, LMAPI, $translate, dates, commonService) {

                $scope.course = $scope.courses.filter((course) => course.enrol == 1)
                    .find((item) => item.id == $stateParams.id);
                var _date = new Date();
                let today = commonService.convertLocalDateToUTCUnix(_date);
                // get list of report id's
                // assosiated with course
                // for each of them get report details
                var reports = $scope.course.params ? JSON.parse($scope.course.params).p_reports : null;
                if (reports) {

                    let ids = reports.join();
                    LMAPI.getcurriculumreports($scope.credentials.session_token, ids, $scope.mode, $scope.usage)
                        .then(function (response) {

                            $scope.listReports = response.data;

                            $scope.listReports.forEach(function (item) {
                                item.path = LMConfig.storage + '/report-templates/' + item.excel_template_name + '.json';
                                item.isPDFAllowed = item.allowed_exports.includes('PDF');
                                item.isExcelAllowed = item.allowed_exports.includes('EXCEL');
                            });

                            if ($scope.listReports.length === 1) {
                                $scope.selectReport = $scope.listReports[0];
                            }
                        });
                };
                vmChangeDate(moment(localStorage.getItem('report.datebegin')).toDate(), moment(localStorage.getItem('report.dateend')).toDate());

                function vmRunReport(idnumber, cohortID, dateBegin, dateEnd) {

                    $scope.loadReport = false;

                    var datebegin = dates.dateFormat(dateBegin.setHours(0, 0, 0, 0));
                    var dateend = dates.dateFormat(dateEnd.setHours(23, 59, 59, 999));
                    idnumber = idnumber ? idnumber : null;
                    cohortID = $scope.usage === 'school' ? $stateParams.school : cohortID;

                    var curriculum = LMAPI.getcurriculum($scope.credentials.session_token, idnumber, cohortID, datebegin, dateend, 'JSON', $stateParams.id, $scope.mode);
                    var template = $http.get($scope.report.report.path, { withCredentials: true });

                    $q.all({ template: template, curriculum: curriculum })
                        .then(function (response) {

                            $scope.loadReport = true;
                            $scope.datas = response.curriculum.data;
                            $scope.headers = response.template.data;

                        }).catch(function (reject) {

                            console.log(reject);
                        });
                }

                function vmGetValue(field, value) {

                    var parameters = $scope.headers.find((x) => x.key === field);

                    if (parameters.min_width || parameters.align) {
                        return {
                            value: parameters.type === "date" ? commonService.getLocaleDate(value / 1000) : value,
                            align: parameters.align ? parameters.align : 'left',
                            min_width: parameters.min_width
                        }
                    }
                    return {
                        value: parameters.type === "date" ? commonService.getLocaleDate(value / 1000) : value,
                        align: 'left'
                    };
                }

                function vmDownloadFile(type) {

                    $scope.loadReport = false;

                    var DateBegin = dates.dateFormat($scope.report.datebegin);
                    var DateEnd = dates.dateFormat($scope.report.dateend);
                    var idnumber = $scope.report.report.idnumber ? $scope.report.report.idnumber : null;
                    var cohortIdNumber = $scope.usage === 'school' ? $stateParams.school : $scope.report.cohort.idnumber;

                    LMAPI.getcurriculumDownload($scope.credentials.session_token, idnumber, cohortIdNumber, DateBegin, DateEnd, type, $stateParams.id, $scope.mode)
                        .then(function (response) {

                            let applicationType = type === 'PDF' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

                            var file = new Blob([response.data], { type: applicationType });

                            const link = document.createElement('a');
                            // create a blobURI pointing to our Blob
                            link.href = URL.createObjectURL(file);
                            link.download = $scope.report.report.title;

                            document.body.append(link);
                            link.click();
                            link.remove();
                            $scope.loadReport = true;
                        })

                }
                if ($scope.mode === 'schooladmin' && $scope.usage != 'school') {
                    vmShowClasses(false);
                }

                function vmShowClasses(show) {

                    if (show) {
                        $scope.list = angular.copy($scope.classes);
                    } else {
                        $scope.list = $scope.classes.filter(function (item) {
                            return item.date_end >= today;
                        });
                    }
                    $scope.list.sort(function (a, b) {
                        return a.name.localeCompare(b.name);
                    });
                    if (!$scope.usage) {
                        $scope.list.unshift({
                            name: $translate.instant('admin.curriculum.name'),
                            idnumber: $stateParams.school
                        });
                    }
                }

                function vmChangeDate(datebegin, dateend) {

                    var unixDatebegin = datebegin ? moment(datebegin.setHours(0, 0, 0, 0)).unix() : null;
                    var unixDateend = dateend ? moment(dateend.setHours(23, 59, 59, 999)).unix() : null;

                    // one of the dates may be empty (null) - this is not a mistake
                    // show mistake only when date end < date begin
                    if ((unixDateend < unixDatebegin) && unixDatebegin != null && unixDateend != null) {
                        $scope.errorIntervalDate = true;
                    } else {
                        $scope.errorIntervalDate = false;
                    }
                }

                $scope.showClasses = vmShowClasses;
                $scope.downloadFile = vmDownloadFile;
                $scope.runReport = vmRunReport;
                $scope.getValue = vmGetValue;
                $scope.changeDate = vmChangeDate;
                $scope.getClassName = commonService.vmsGetClassName;
            }

            return {
                scope: {
                    mode: '=mode',
                    credentials: '=credentials',
                    user: '=user',
                    classes: '=classes',
                    list: '=list',
                    courses: '=courses',
                    flagIsAdmin: '=flagisadmin',
                    usage: '=usage',
                },
                controller: vmController,
                templateUrl: '/assets/ng1/teacher/curriculum/report.html'
            }
        })
})();