(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', 'LMConfig', function ($stateProvider, LMConfig) {

        $stateProvider
        .state('app.library.report', {
            url: '/report',
            controller: 'libraryReportCtrl',
            deepStateRedirect: {
                default: { state: 'app.library.report.calendar' },
                params: ['locale']
            },
            templateUrl: '/assets/ng1/library/report/report.html',
            resolve: {
                student: function ($stateParams) {
                    return null;
                }
            },
        })
        .state('app.library.report.calendar', {
            url: '/calendar',
            controller: 'libraryReportCalendarCtrl',
            templateUrl: '/assets/ng1/library/report/calendar.html'
        })
        .state('app.library.report.summary', {
            url: '/summary',
            controller: 'libraryReportSummaryCtrl',
            templateUrl: '/assets/ng1/library/report/summary.html'
        })
        .state('app.library.report.pronunciation', {
            url: '/pronunciation',
            controller: 'libraryReportPronunciationCtrl',
            templateUrl: '/assets/ng1/library/report/pronunciation.html'
        })
    }])

    .controller('libraryReportCtrl', function ($scope, student, $rootScope, $state, $stateParams, user) {
        
        // throw main tab change
        $scope.$emit('libraryTabChange', 'report');

        // watch for side tab changed
        $scope.$on('sideTabChange', function (event, data) {
            $scope.activeTab = data;
        });
        
        $scope.$on('EventCurrentTasks', function (event, data) {
            $rootScope.currentTasks = data;
            if ($rootScope.currentTasks && $rootScope.currentTasks.length > 0) {
                    $state.go('app.library.shelve.task');
                }
        });

    })
    .controller('libraryReportCalendarCtrl', function ($scope, LMAPI, LMConfig, token, $filter, $translate) {

        $scope.config = LMConfig;
        // throw side tab change
        $scope.$emit('sideTabChange', 'calendar');
        $scope.$emit('studentReportsTabChange', 'calendar');
        $scope.$emit('activeTabTop', token);

        $scope.token = token;
        $scope.date = new Date();

        $scope.getPrevious = vmGetPrevious;
        $scope.getNext = vmGetNext;
        

        $scope.days = ['library.report.calendar.days.sunday',
                        'library.report.calendar.days.monday',
                        'library.report.calendar.days.tuesday',
                        'library.report.calendar.days.wednesday',
                        'library.report.calendar.days.thursday',
                        'library.report.calendar.days.friday',
                        'library.report.calendar.days.saturday'];

        var monthNames = ['library.report.calendar.months.january',
                        'library.report.calendar.months.february',
                        'library.report.calendar.months.march',
                        'library.report.calendar.months.april',
                        'library.report.calendar.months.may',
                        'library.report.calendar.months.june',
                        'library.report.calendar.months.july',
                        'library.report.calendar.months.august',
                        'library.report.calendar.months.september',
                        'library.report.calendar.months.october',
                        'library.report.calendar.months.november',
                        'library.report.calendar.months.december'];

        var current = vmBuildCalendarMonth($scope.date, 0);
        vmCalendarEvents(current.from, current.to);


        function vmBuildCalendarMonth(date, counter) {

            date.setMonth(date.getMonth() + counter);

            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var dayNumber = new Date(year, month, 0).getDate();

            $scope.calendar = {

                mainInfo: {
                    year: year,
                    month: monthNames[month - 1],
                },

                days: []
            };

            for (var i = 1; i <= dayNumber; i++) {

                var iterationDate = new Date(year, month - 1, i);

                var calendarItem = {

                    id: year + '-' + formatDateItem(month) + '-' + formatDateItem(i),
                    date: iterationDate,
                    today: vmIsToday(iterationDate)
                };

                $scope.calendar.days.push(calendarItem);

            };

            var firstDay = $scope.calendar.days[0];

            firstDay.class = vmGetFirstDayClass(firstDay);



            var dates = {

                from: year + '-' + formatDateItem(month) + '-' + '01',
                to: year + '-' + formatDateItem(month) + '-' + formatDateItem(dayNumber)

            };

            return dates;
        };

        function vmCalendarEvents(from, to) {

            if (!token) return;

            LMAPI.calendar(token, from, to)
                     .then(function (responce) {

                         $scope.list = responce.data;

                         $scope.list.forEach(function (item) {

                             var attemptingDay = $scope.calendar.days.first('id', moment(item.date).format('YYYY-MM-DD'));

                             if (attemptingDay)
                                 attemptingDay.attempt = item;

                         });

                     });
        };

        function vmGetPrevious() {

            var previous = vmBuildCalendarMonth($scope.date, -1);

            vmCalendarEvents(previous.from, previous.to, $scope.coursesFilter);
        };

        function vmGetNext() {

            var next = vmBuildCalendarMonth($scope.date, 1);

            vmCalendarEvents(next.from, next.to, $scope.coursesFilter);
        };

        function vmIsToday(iterationDate) {

            var enterDate = $filter('date')(new Date(), 'yyyy-MM-dd');
            iterationDate = $filter('date')(iterationDate, 'yyyy-MM-dd');

            if (enterDate == iterationDate)
                return true;
            else
                return false;
        };

        function vmGetFirstDayClass(day) {

            //this metod returns css class nqame for correct building calendar from first day
            //now days of the weeks are written with translation keys like 'library.report.calendar.days.sunday'
            //our goal - word 'sunday'. So we split this string by '.' and get last item
            var firstMonthDayNumber = day.date.getDay();
            var firstDayNameTranslationKey = $scope.days[firstMonthDayNumber];
            var firstDayItems = firstDayNameTranslationKey.split('.');
            return firstDayItems[firstDayItems.length - 1];

        };

        function formatDateItem(date) {

            if (date < 10)
                date = '0' + date;

            return date;
        };


    })

    .controller('libraryReportSummaryCtrl', function ($scope, $filter, $timeout, LMConfig, LMAPI, FRAPI, LMAPICache, reportService, token, $rootScope, $stateParams, $translate, $q) {

        // throw side tab change
        $scope.$emit('sideTabChange', 'summary');
        $scope.$emit('studentReportsTabChange', 'summary');
        $scope.$emit('activeTabTop', token);
        $scope.config = LMConfig;

        // graph period changing
        $scope.getDayAgo = vmGetDayAgo;
        $scope.getMonthAgo = vmGetMonthAgo;

        $scope.token = token;
        var currentDate = new Date();
        var dateAgo = new Date();

        dateAgo = dateAgo.setMonth(dateAgo.getMonth() - 5);
        currentDate = $filter('date')(currentDate, 'yyyy-MM-dd');
        dateAgo = $filter('date')(dateAgo, 'yyyy-MM-dd');

        $q.all($translate.instant('library.report.summary.reading'), $translate.instant('library.report.summary.listening'))
               .then(function () {
                   $scope.readingListeningSeries = [$translate.instant('library.report.summary.reading'), $translate.instant('library.report.summary.listening')];
                   vmInit();
               });

        

        function vmInit() {

            // init graphs
            $scope.label = [];
            $scope.books = [[]];
            $scope.coins = [[]];
            $scope.readingAndListening = [[], []];
            
            vmGetDayAgo();
        };

        function vmGetDayAgo() {

            $scope.activeTab = 'day';

            var dateAgo = new Date(new Date().setDate(new Date().getDate() - 7));
            if (!token) return;

            vmSummary(dateAgo, $scope.activeTab)
                .then(function (data) {

                    $scope.data = data;

                    vmBuildChartData($scope.data, dateAgo, $scope.activeTab, 1);
                });
        };

        function vmGetMonthAgo() {

            $scope.activeTab = 'month';

            var dateAgo = new Date();
            dateAgo = new Date(dateAgo.setMonth(dateAgo.getMonth() - 7));

            vmSummary(dateAgo, $scope.activeTab)
                .then(function (data) {

                    $scope.data = data;

                    vmBuildChartData($scope.data, dateAgo, $scope.activeTab, 1);
                });

        };

        function vmSummary(from, periodtype) {

            // reset charts
            $scope.label = [];
            $scope.books = [[]];
            $scope.coins = [[]];
            $scope.readingAndListening = [[], []];

            // convert dates to correct format
            var to = $filter('date')(new Date(), 'yyyy-MM-dd');
            from = $filter('date')(from, 'yyyy-MM-dd');

            return FRAPI.userSummaryReport(token, from, to, periodtype);
        };

        function vmBuildChartData(data, dateAgo, type, iterator) {

            var iterationDate;
            var search;
            var iterationYear;

            for (var i = 0; i < 7; i++) {

                if (type != 'month') {
                    iterationDate = new Date(dateAgo.setDate(dateAgo.getDate() + iterator));
                } else {
                    iterationDate = new Date(dateAgo.setMonth(dateAgo.getMonth() + iterator)); 
                }                                  

                if (type == 'day') {

                    search = $filter('date')(iterationDate, 'yyyy-MM-dd');
                    $scope.label.push($filter('date')(iterationDate, 'MM/dd'));
                }
                else {

                    iterationYear = iterationDate.getFullYear();

                    if (type == 'week') {
                        search = iterationDate.getWeek();
                    }
                       
                    if (type == 'month') {
                        search = iterationDate.getMonth() + 1;
                    }                        

                    search = search.toString();

                    if (search.length == 1) {
                        var searchLabel = '0' + search;
                    } else {
                        var searchLabel = search;
                    }                        

                    $scope.label.push(searchLabel + '/' + iterationYear.toString().substr(2, 2));
                };

                var itemWithActions = data.first('date_part', search);

                pushAction(itemWithActions);
            };
        };


        function pushAction(itemWithActions) {

            if (itemWithActions) {

                $scope.books[0].push(itemWithActions.books);
                $scope.coins[0].push(itemWithActions.coins);
                $scope.readingAndListening[0].push(Math.ceil(parseFloat(itemWithActions.reading)/60));
                $scope.readingAndListening[1].push(Math.ceil(parseFloat(itemWithActions.listening)/60));
            }
            else {

                $scope.books[0].push(0);
                $scope.coins[0].push(0);
                $scope.readingAndListening[0].push(0);
                $scope.readingAndListening[1].push(0);
            };
        };

        LMAPICache.categories().then(function (response) {

            $scope.categories = response;

        });

    })

    .controller('libraryReportPronunciationCtrl', function ($scope, LMAPI, LMConfig, token, $filter, $translate, $q) {

        // throw side tab change
        $scope.$emit('sideTabChange', 'pronunciation');
        $scope.$emit('activeTabTop', token);

        $scope.label = [];
        $scope.pronunciation = [[]];

        var currentDate = new Date();
        var dateAgo = new Date();

        dateAgo = dateAgo.setMonth(dateAgo.getMonth() - 10);
        currentDate = $filter('date')(currentDate, 'yyyy-MM-dd');
        dateAgo = $filter('date')(dateAgo, 'yyyy-MM-dd');

        $q.all($translate.instant('library.report.pronunciation.accuracy')).then(function () {

            $scope.series = [$translate.instant('library.report.pronunciation.accuracy')];
            vmInit();
        });

        

        function vmInit() {
            var date = new Date();
            
            if (!token) return;

            LMAPI.pronunciation(token, dateAgo, currentDate).then(function (responce) {

                $scope.pronoun = responce.data;
                // if no datas
                // show empty nice graph, than add default label
                if (responce.data.length > 0) {

                    vmBuidPronunciation($scope.pronoun);
                } else {
                    for (var i = 0; i < 10; i++) {
                        var iterationDate = new Date(new Date().setMonth(new Date().getMonth() - i));
                        $scope.label.unshift($filter('date')(iterationDate, 'yyyy/MM'));
                        $scope.pronunciation[0].push(0);
                      //  $scope.pronunciation[1].push(0);
                    }
                }
            });

            LMAPI.worstresults(token).then(function (responce) {

                $scope.worstresult = responce.data;
              
            });

           $scope.$on('create', function (event, chart) {

                $scope.loaded = true;
            });
        }

        function vmBuidPronunciation(pronoun) {

            for (var i = 0; i < pronoun.length; i++) {

                $scope.pronunciation[0].push(pronoun[i].acc);
                // change null on nativ or other value
              //  $scope.pronunciation[1].push(null);

                if (pronoun[i].month > 9) {

                    $scope.label.push(pronoun[i].year.toString() + '/' + pronoun[i].month.toString());
                } else {

                    $scope.label.push(pronoun[i].year.toString() + '/' + '0' + pronoun[i].month.toString());
                }
            }
        }

    })

    .service('reportService', function ($http, $q, LMAPICache) {


        this.getReportData = function (token) {

            return LMAPICache.cohorts(token, 'curriculum_level')
                         .then(function (cohortsList) {

                             var queries = [];

                             cohortsList = cohortsList.map(x=>x.id);

                             cohortsList.forEach(function (cohortid) {

                                 queries.push(LMAPI.courses(token, cohortid))
                             });

                             return $q.all(queries);
                         });
        }
    })

})();