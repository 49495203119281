(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.admin.school.licenses.license', {
                    url: '/license',
                    controller: 'adminLicenseCtrl',
                    template: '<ui-view>',
                    deepStateRedirect: {
                        default: { state: 'app.admin.school.licenses.license.list' },
                        params: true
                    },
                })
                .state('app.admin.school.licenses.license.teacher', {
                    url: '/teacher/:license',
                    controller: 'adminLicenseTeacherCtrl',
                    templateUrl: '/assets/ng1/admin/licenses/license/licenseteacher.html',
                })
                .state('app.admin.school.licenses.license.list', {
                    url: '/list',
                    controller: 'adminLicenseListCtrl',
                    templateUrl: '/assets/ng1/admin/licenses/license/license.html',
                })
        }])

        .controller('adminLicenseCtrl', function ($scope) {
            // empty
        })
        .controller('adminLicenseListCtrl', function ($scope, LMConfig, LMWordpress, LMAPI, $stateParams, $state, licenses) {
           
            $scope.page = 'teacherlicenses';
            // watch for side tab changed
            $scope.$emit('adminTabChange', 'license');
            // configuration
            $scope.config = LMConfig;
            //get id school
            $scope.school = $stateParams.school;
            let _licenses = licenses.filter((x) => x.license_type != 'student');
            // need for table-cell view
            $scope.licenses = vmGetChunks(_licenses, 2);

            function vmOpenLicense(license) {

                $state.go('app.admin.school.licenses.license.teacher', { license: license.group_license_id });
            }

            $scope.openLicense = vmOpenLicense;

        })

        .controller('adminLicenseTeacherCtrl', function ($q, schools, LMAPICache, $scope, LMWordpress, $stateParams, commonService, classes, credentials, licenses, user, $translate, FRAPI, $rootScope) {

            $scope.$emit('adminTabChange', 'license');

            $scope.classes = classes;

            $scope.arraySelectedAssigned = [];
            $scope.arraySelectedAvailable = [];

            $scope.license = licenses.find((x) => x.group_license_id == $stateParams.license);
            $scope.days = $scope.license.date_end ? Math.round((moment(new Date($scope.license.date_end)).unix() - moment(new Date($scope.license.date_start)).unix()) / 60 / 60 / 24) : null;
            let district = schools.find((x) => x.id === commonService.getCurrentSchool()).district;

            var getUsersFromCohort = FRAPI.getUsersFromCohort(commonService.getCurrentSchool(), JSON.stringify(['teacher', 'schooladmin']));
            var getUsersFromCohort2 = district ? FRAPI.getUsersFromCohort(district, JSON.stringify(['districtadmin'])) : null;

            var getlicenseteacher = FRAPI.licenseGetTeachers($scope.license.group_license_id);

            $q.all({ getUsersFromCohort: getUsersFromCohort, 
                     getUsersFromCohort2: getUsersFromCohort2, 
                     getlicenseteacher: getlicenseteacher })
                .then(function (response) {
                    let districtadmins = response.getUsersFromCohort2 ? response.getUsersFromCohort2.unique(x => x.token) : [];
                    let teachers = response.getUsersFromCohort.unique(x => x.token);

                    let _users = [...districtadmins, ...teachers];
                    $scope.teachers = _users.unique(x => x.token);

                    $scope.licenseteacher = response.getlicenseteacher;

                    if ($scope.licenseteacher.length > 0) {
                        $scope.licenseteacher.forEach((x) => {

                            let teacher = $scope.teachers.find((item) => item.token === x.teacher_token);

                            if (teacher) {
                                x.firstname = teacher.firstname;
                                x.lastname = teacher.lastname;
                                x.username = teacher.username;
                                x.classes = teacher.classes;
                                x.token = teacher.token;

                                $scope.teachers = $scope.teachers.filter(function (elem) {
                                    return elem.token != teacher.token;
                                });
                            }
                        })
                    }
                    //restructure header table to the correct position
                    $('table.demo').floatThead('reflow');
                });


            function vmLicenseAddUser(teacher) {
                vmLicenseAddUsers([teacher]);
            }

            function vmLicenseAddUsers(teachers) {
                if (parseInt($scope.license.available_licenses) === 0) {
                    alert($translate.instant('teacher.error.notEnough'));
                    return;
                }

                if (!$scope.license.is_expired && parseInt($scope.license.available_licenses) >= teachers.length) {
                    $rootScope.pageLoading = true;

                    let teacherTokens = teachers.map(x => x.token);
                    FRAPI.licenseAssignTeachers($scope.license.group_license_id, teacherTokens).then(function (responce) {
                        $rootScope.pageLoading = false;

                        $scope.licenseteacher = $scope.licenseteacher.concat(teachers);
                        $scope.teachers = $scope.teachers.filter(function (elem) {
                            return !teachers.some(teach => teach.token == elem.token);
                        });
                        $scope.license.available_licenses = parseInt($scope.license.available_licenses) - teachers.length;

                        teachers.forEach(x => x.select = false);                        
                        $scope.arraySelectedAvailable = [];
                        //restructure header table to the correct position
                        $('table.demo').floatThead('reflow');

                        //Clear courses cache
                        LMAPICache.reset();
                    }).catch(function (error) {
                        $rootScope.pageLoading = false;
                        if (error && error.data) {
                            if (error.status == 504) {
                                alert(error.data.message);
                            } else if (error.status == 403) {
                                if (error.data.code === 'U0203') {
                                    alert($translate.instant('teacher.error.notEnough'));
                                } else if (error.data.code === 'U0204') {
                                    if (teachers.length == 1) {
                                        alert($translate.instant('teacher.error.isLicense', { username: teachers[0].username }));
                                    } else {
                                        alert($translate.instant('teacher.error.isLicense'));
                                    }                                    
                                } else {
                                    alert($translate.instant('teacher.error.error'));
                                }
                            }
                            else {
                                alert($translate.instant('teacher.error.error'));
                            }
                        } else {
                            alert($translate.instant('teacher.error.error'));
                        }

                    })
                } else {
                    alert($translate.instant('teacher.error.notEnough'));
                }
            }

            function vmLicenseDeleteUser(teacher) {
                vmLicenseDeleteUsers([teacher]);
            }

            function vmLicenseDeleteUsers(teachers) {
                $rootScope.pageLoading = true;

                let teacherTokens = teachers.map(x => x.token);
                FRAPI.licenseRemoveTeachers($scope.license.group_license_id, teacherTokens).then(function (responce) {
                    $rootScope.pageLoading = false;

                    $scope.teachers = $scope.teachers.concat(teachers);
                    $scope.licenseteacher = $scope.licenseteacher.filter(function (elem) {
                        return !teachers.some(teach => teach.token == elem.token);
                    });
                    $scope.license.available_licenses = parseInt($scope.license.available_licenses) + teachers.length;
                    teachers.forEach(x => x.select = false);
                    $scope.arraySelectedAssigned = [];
                    //restructure header table to the correct position
                    $('table.demo').floatThead('reflow');

                    //Clear courses cache
                    LMAPICache.reset();
                }).catch(function (error) {
                    $rootScope.pageLoading = false;
                    console.log(error);
                })
            }

            function vmAssignAllToLicense(teachers) {
                vmLicenseAddUsers(teachers);
            }

            function vmSelectAllAssigned(select) {

                if (select) {
                    $scope.listselectedteacher.forEach((x) => x.select = true);
                } else {
                    $scope.listselectedteacher.forEach((x) => x.select = false);
                }
            }
            function vmSelectAllAvailable(select) {

                if (select) {
                    $scope.list.forEach((x) => x.select = true);
                } else {
                    $scope.list.forEach((x) => x.select = false);
                }
            }
            function vmRemoveSelected() {

                $scope.selectassigned = false;
                let list = $scope.licenseteacher.filter((x) => x.select === true);

                if (list.length > 0) {
                    vmLicenseDeleteUsers(list);
                }
            }

            function vmAssignSelected() {

                $scope.selectavailable = false;

                let list = $scope.teachers.filter((x) => x.select === true);

                if (list.length > 0) {
                    vmLicenseAddUsers(list);
                }
            }

            function vmFlagRemoveSelected(select, username) {

                if (select) {
                    $scope.arraySelectedAssigned.push({ select: select, username: username });
                } else {
                    $scope.arraySelectedAssigned = $scope.arraySelectedAssigned.filter((x) => x.username !== username);
                    if ($scope.arraySelectedAssigned.length === 0) {
                        $scope.selectassigned = false;
                    }
                }

            }
            function vmFlagAllRemoveSelected(selectassigned) {

                if (selectassigned) {
                    $scope.listselectedteacher.forEach((x) => {
                        $scope.arraySelectedAssigned.push({ select: true, username: x.username });
                    })
                } else {
                    $scope.arraySelectedAssigned = [];
                }

            }


            function vmFlagAssignedSelected(select, username) {

                if (select) {
                    $scope.arraySelectedAvailable.push({ select: select, username: username });
                } else {
                    $scope.arraySelectedAvailable = $scope.arraySelectedAvailable.filter((x) => x.username !== username);
                    if ($scope.arraySelectedAvailable.length === 0) {
                        $scope.selectavailable = false;
                    }
                }

            }
            function vmFlagAllAssignedSelected(selectavailable) {

                if (selectavailable) {
                    $scope.list.forEach((x) => {
                        $scope.arraySelectedAvailable.push({ select: true, username: x.username });
                    })
                } else {
                    $scope.arraySelectedAvailable = [];
                }

            }

            $scope.licensedeleteuser = vmLicenseDeleteUser;
            $scope.licenseadduser = vmLicenseAddUser;
            $scope.assignAllToLicense = vmAssignAllToLicense;
            $scope.selectAllAvailable = vmSelectAllAvailable;
            $scope.selectAllAssigned = vmSelectAllAssigned;
            $scope.removeSelected = vmRemoveSelected;
            $scope.assignselected = vmAssignSelected;
            $scope.vmFlagRemoveSelected = vmFlagRemoveSelected;
            $scope.vmFlagAllRemoveSelected = vmFlagAllRemoveSelected;
            $scope.vmFlagAssignedSelected = vmFlagAssignedSelected;
            $scope.vmFlagAllAssignedSelected = vmFlagAllAssignedSelected;
        })
})();