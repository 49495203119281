(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', 'LMConfig', function ($stateProvider, LMConfig) {

        $stateProvider
            .state('app.course.exit', {
                url: '/exit',
                controller: 'courseExitCtrl',
                params: {
                    back: null
                }
            })
    }])

    .controller('courseExitCtrl', function ($state, $stateParams, $location) {

        //unsubscribe from events
        window.onbeforeunload = null;

        // show footer
        $(".mainWindow").removeClass("blackZone");

        // remove leading '#' symbol if there are any
        // navigate back
        if ($stateParams.back) {

            $location.path($stateParams.back.replace(/^#/, ''));

        } else {
           let mode = localStorage.getItem('mode');
           switch (mode) {
               case 'teacher':
                    $state.go('app.teacher.library');
                    break;
               case 'schooladmin':
                    $state.go('app.admin.school.library');
                    break;
                default:
                    $state.go('app.library.shelve');
                    break;
            }
           
        };
                           
    })

})();