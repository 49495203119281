(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.admin.school.reports.sreport', {
                url: '/sreport?showAllClasses',
                controller: 'adminReportStudentCtrl',
                templateUrl: '/assets/ng1/teacher/report/student/student.html',
                deepStateRedirect: {
                    default: { state: 'app.admin.school.reports.sreport.book' },
                    params: true
                }
            })
            .state('app.admin.school.reports.sreport.book', {
                url: '/book',
                controller: 'teacherReportBookCtrl',
                templateUrl: '/assets/ng1/teacher/report/book/book.html'
            })
            .state('app.admin.school.reports.sreport.calendar', {

                url: '/calendar',
                controller: 'libraryReportCalendarCtrl',
                templateUrl: '/assets/ng1/library/report/calendar.html',
                params: {
                    token: null
                },
                resolve: {
                    token: function ($stateParams) {

                        return $stateParams.student;
                    }
                }
            })
            .state('app.admin.school.reports.sreport.summary', {

                url: '/summary',
                controller: 'libraryReportSummaryCtrl',
                templateUrl: '/assets/ng1/library/report/summary.html',
                params: {
                    token: null
                },
                resolve: {
                    token: function ($stateParams) {

                        return $stateParams.student;
                    }
                }
            })
    }])

        .controller('adminReportStudentCtrl', function ($scope, FRAPI, $state, $stateParams, $translate, classes, commonService) {

            $scope.mode = 'schooladmin';
            $scope.$emit('adminTabChange', 'sreport');
            $scope.$on('studentReportsTabChange', function (event, data) {
                $scope.activeTab = data;
            });
            $scope.getStudentInClassList = vmChangeClass;
            $scope.changeStudent = vmChangeStudent;
            $scope.getClassName = commonService.vmsGetClassName;
            $scope.changeTab = vmChangeTab;
            $scope.showClasses = vmShowClasses;

            var _date = new Date();
            let today = commonService.convertLocalDateToUTCUnix(_date);
            
            $scope.show = $stateParams.showAllClasses === 'true';
            if ($scope.show) {
                $scope.classList = classes;
            } else {
                $scope.classList = classes.filter(function (item) {
                    return item.date_end >= today;
                });
            }

            if ($stateParams.clas) {

                $scope.selectedClass = $scope.classList.find(x => x.idnumber == $stateParams.clas);

                if ($scope.selectedClass) {
                    FRAPI.getUsersFromCohortTeacherMode($stateParams.clas, JSON.stringify(['student'])).then(function (response) {

                        $scope.studentsList = response.unique(x => x.token);
                        $scope.selectedStudent = $scope.studentsList.find(x => x.token == $stateParams.student);
                    });
                }                
            }

            function vmChangeTab(page) {
                switch (page) {
                    case 'breport': {
                        $state.go('app.admin.school.reports.sreport.book');
                        return;
                    }
                    case 'calendar': {
                        $state.go('app.admin.school.reports.sreport.calendar');
                        return;
                    }
                    case 'summary': {
                        $state.go('app.admin.school.reports.sreport.summary');
                        return;
                    }
                }
            }

            function vmChangeClass(data) {
                $state.go($state.current.name, { clas: data.idnumber, student: null, showAllClasses: $scope.show });
            };

            function vmChangeStudent(token) {
                $state.go($state.current.name, { clas: $stateParams.clas, student: token, showAllClasses: $scope.show });
            }

            function vmShowClasses(show) {
                $state.go($state.current.name, { clas: null, student: null, showAllClasses: show });
            }
        })
})();