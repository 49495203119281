(function () {
  'use strict';

  angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

      $stateProvider
        .state('app.teacher.license', {
          url: '/license',
          controller: 'teacherLicenseCtrl',
          templateUrl: '/assets/ng1/teacher/license/license.html',
          resolve: {
            licenses: function (FRAPI, school) {

              return FRAPI.teacherLicensesBySchool(school.id).then(function (data) {

                data.forEach(function (x) {

                  x.start = x.date_start ? moment(new Date(x.date_start)).format("MM/DD/YYYY") : null;
                  x.end = x.date_end ? moment(new Date(x.date_end)).format("MM/DD/YYYY") : null;
                  x.unixend = x.date_end ? moment(new Date(x.date_end)).unix() : null;
                });
                let licenses = data
                  .filter(function (l) { return !l.is_expired; })
                  .sort((a, b) => {
                    if(a.unixend == b.unixend)
                    {
                        return (a.sort_order < b.sort_order) ? -1 : (a.sort_order > b.sort_order) ? 1 : 0;
                    }
                    else
                    {
                        return (a.unixend > b.unixend) ? -1 : 1;
                    }
                  });

                return licenses;
              });
            },
            students: function (FRAPI, classes, credentials) {
            
              if (classes.length > 0) {
                  // get list of available classes id's
                  var classIds = classes.map((item) => item.idnumber).join();

                  return FRAPI.getUsersFromCohortTeacherMode(classIds, JSON.stringify(['student']))
                      .then(function (response) {

                          let uniqStudents = response.unique(x => x.token);
                          return uniqStudents.map((student) => {

                            student.classes = response.filter((x) => x.token == student.token)
                                                      .map((y) => y.idnumber);

                            return student;
                          });
                      })
              } else {
                  return [];
              }
            },
          },
          deepStateRedirect: {
            default: { state: 'app.teacher.license.list', params: { state: "current" } },
            params: true
          },
        })
        .state('app.teacher.license.list', {
          url: '/:state',
          controller: 'teacherLicenseActiveCtrl',
          templateUrl: '/assets/ng1/teacher/license/licenseteacher.html'
        })
        .state('app.teacher.license.students', {
          url: '/students/:state/:group_license_id?childCourse',
          controller: 'teacherLicenseStudentsCtrl',
          templateUrl: '/assets/ng1/teacher/license/licensestudents.html'
        })
        .state('app.teacher.license.childCourses', {
          url: '/courses/:state/:group_license_id',
          controller: 'teacherLicenseChildCoursesCtrl',
          templateUrl: '/assets/ng1/teacher/license/licenseChildCourses.html'
        })
    }])

    .controller('teacherLicenseCtrl', function ($scope, $state, schools, school) {

      $scope.$emit('teacherModeChange', 'license');
      // watch for side tab changed
      $scope.$on('sideTabChange', function (event, data) {
        $scope.selected = data;
      });

      $scope.open = vmOpen;

      function vmOpen(state) {
        //restructure header table to the correct position
        $('table.demo').floatThead('reflow');

        $state.go('app.teacher.license.list', { state: state });
      }

    })
    .controller('teacherLicenseActiveCtrl', function ($scope, $state, LMWordpress, $stateParams, school, licenses, LMConfig) {

      $scope.$emit('sideTabChange', $stateParams.state);

      // configuration
      $scope.config = LMConfig;
      $scope.license = licenses;
      vmConvertDate();

      function vmConvertDate() {

        if ($scope.license.length > 0) {

          $scope.groupLicenses = vmGetChunks($scope.license, 2); // need for table-cell view
        }
      }

      function vmOpenLicense(license) {
        if (license.vms_execution_type == 'license') {
          $state.go('app.teacher.license.childCourses', {
            group_license_id: license.group_license_id,
            state: $stateParams.state
          });
        }
        else {
          $state.go('app.teacher.license.students', {
            group_license_id: license.group_license_id,
            state: $stateParams.state
          });
        }
      }
      //restructure header table to the correct position
      $('table.demo').floatThead('reflow');

      $scope.openLicense = vmOpenLicense;
    })
    .controller('teacherLicenseStudentsCtrl', function ($rootScope, LMWordpress, FRAPI, $scope, $state, $stateParams, user, commonService, classes, licenses, credentials, students, $translate) {

      $scope.$emit('sideTabChange', $stateParams.state);
      $scope.back = vmBack;
      $scope.showAssigned = commonService.showAssignedLicensesWithUTR(user.project);

      $scope.arraySelectedAssigned = [];
      $scope.arraySelectedAvailable = [];

      $scope.license = licenses.find((x) => x.group_license_id == $stateParams.group_license_id);
      $scope.students = angular.copy(students);
      $scope.classes = classes;
      $scope.classes2 = classes;
      $scope.days = $scope.license.date_end ? Math.round((moment(new Date($scope.license.date_end)).unix() - moment(new Date($scope.license.date_start)).unix()) / 60 / 60 / 24) : null;
      $scope.childCourse = null;

      LMWordpress.licenseteacher.getlicensestudent($scope.license.group_license_id, $scope.license.group_cohort_id, $scope.license.course_id, $stateParams.childCourse)
        .then(function (responce) {

          $scope.licensestudent = responce.data;
          if ($scope.licensestudent.length > 0) {
            $scope.licensestudent.forEach((x) => {
              let student = students.find((item) => item.token === x.student_token);
              if (student) {
                x.firstname = student.firstname;
                x.lastname = student.lastname;
                x.username = student.username;
                x.classes = student.classes;
                x.token = student.token;

                $scope.students = $scope.students.filter(function (elem) {
                  return elem.token != student.token;
                });
              }
              x.$dateAssigned = x.date_assigned ? moment.unix(x.date_assigned).format('MM/DD/YYYY') : null;
            })
            $scope.licensestudent = $scope.licensestudent.filter((x) => x.classes);
          }
          //restructure header table to the correct position
          $('table.demo').floatThead('reflow');
        })

      if ($stateParams.childCourse) {
        if ($scope.$parent.currentChildCourse && $scope.$parent.currentChildCourse.id == $stateParams.childCourse) {
          $scope.childCourse = $scope.$parent.currentChildCourse;
        } else {
          LMWordpress.license.getChildCourses($scope.license.course_idnumber, $stateParams.childCourse).then(function (response) {
            $scope.childCourse = response.data[0];
          });
        }
      }

      function vmLicenseAddUsers(studentArr) {

        if (parseInt($scope.license.available_licenses) === 0) {
          alert($translate.instant('teacher.error.notEnough'));
          return;
        }

        if (!$scope.license.is_expired && parseInt($scope.license.available_licenses) > 0) {

          $rootScope.pageLoading = true;

          FRAPI.licenseAssignStudents($scope.license.group_license_id, studentArr.map(function (st) { return st.token }), $stateParams.childCourse).then(function (responce) {
              $rootScope.pageLoading = false;

              studentArr.forEach(function (student) {
                student.comment = $scope.license.comment;
                student.$dateAssigned = moment().format("MM/DD/YYYY");
                $scope.licensestudent.push(student);
                $scope.students = $scope.students.filter(function (elem) {
                  return elem.token != student.token;
                });
                $scope.license.available_licenses = parseInt($scope.license.available_licenses) - 1;
                if ($scope.showAssigned) {
                  $scope.license.assigned_licenses = parseInt($scope.license.assigned_licenses) + 1;
                }

                student.select = false;
              });
              $scope.arraySelectedAvailable = [];
              //restructure header table to the correct position
              $('table.demo').floatThead('reflow');

            }).catch(function (error) {
              $rootScope.pageLoading = false;

              if (error && error.data) {
                if (error.status == 500) {
									alert(error.data.message);
								} else if (error.status == 403) {
									if (error.data.code === 'U0203') {
										alert($translate.instant('teacher.error.notEnough'));
									} else if (error.data.code === 'U0204') {
										if (studentArr.length == 1) {
											alert($translate.instant('teacher.error.isLicense', { username: studentArr[0].username }));
										} else {
											alert($translate.instant('teacher.error.isLicense'));
										}                                    
									} else {
										alert($translate.instant('teacher.error.error'));
									}
								}
								else {
									alert($translate.instant('teacher.error.error'));
								}
              } else {
                alert($translate.instant('teacher.error.error'));
              }

            })
        } else {
          alert($translate.instant('teacher.error.notEnough'));
        }
      }


      function vmLicenseDeleteUsers(studentArr) {

        $rootScope.pageLoading = true;

        FRAPI.licenseRemoveStudents($scope.license.group_license_id, studentArr.map(function (st) { return st.token }),	$stateParams.childCourse).then(function (responce) {
          $rootScope.pageLoading = false;
          studentArr.forEach(function (student) {
            $scope.students.push(student);
            $scope.licensestudent = $scope.licensestudent.filter(function (elem) {
              return elem.token != student.token;
            });

            var license = $scope.license;
            if (student.group_license_id && student.group_license_id != license.group_license_id) {
              license = licenses.find((x) => x.group_license_id == student.group_license_id);
            }
            license.available_licenses = parseInt(license.available_licenses) + 1;
            if ($scope.showAssigned) {
              license.assigned_licenses = parseInt(license.assigned_licenses) - 1;
            }

            $scope.arraySelectedAssigned = [];
            student.select = false;
          });

          //restructure header table to the correct position
          $('table.demo').floatThead('reflow');
        }).catch(function (error) {
          $rootScope.pageLoading = false;
          console.log(error);
          if (error && error.data) {
						if (error.status == 500) {
							alert(error.data.message);
						} else if (error.status == 403) {
							if (error.data.code === 'U0010') {
								if (studentArr.length == 1) {
									alert($translate.instant('teacher.error.licenseCantBeDeleted', { logins: studentArr[0].username }));
								} else {
									alert($translate.instant('teacher.error.licenseCantBeDeleted'));
								}                                    
							} else {
								alert($translate.instant('teacher.error.error'));
							}
						}
						else {
							alert($translate.instant('teacher.error.error'));
						}
					} else {
						alert($translate.instant('teacher.error.error'));
					}
        })
      }

      function vmAssignAllToLicense(students) {
        vmLicenseAddUsers(students);
      }

      function vmSelectAllAssigned(select) {

        if (select) {
          $scope.listselectedstudent.forEach((x) => x.select = true);
        } else {
          $scope.listselectedstudent.forEach((x) => x.select = false);
        }
      }
      function vmSelectAllAvailable(select) {

        if (select) {
          $scope.list.forEach((x) => x.select = true);
        } else {
          $scope.list.forEach((x) => x.select = false);
        }
      }
      function vmRemoveSelected() {

        $scope.selectassigned = false;
        let selectedStudents = $scope.licensestudent.filter((x) => x.select === true);

        if (selectedStudents.length > 0) {
          vmLicenseDeleteUsers(selectedStudents);
        }
      }

      function vmAssignSelected() {

        $scope.selectavailable = false;

        let selectedStudents = $scope.list.filter((x) => x.select === true);

        if (selectedStudents.length > 0) {
          vmLicenseAddUsers(selectedStudents);
        }
      }

      function vmFlagRemoveSelected(select, username) {

        if (select) {
          $scope.arraySelectedAssigned.push({ select: select, username: username });
        } else {
          $scope.arraySelectedAssigned = $scope.arraySelectedAssigned.filter((x) => x.username !== username);
          if ($scope.arraySelectedAssigned.length === 0) {
            $scope.selectassigned = false;
          }
        }

      }
      function vmFlagAllRemoveSelected(selectassigned) {

        if (selectassigned) {
          $scope.listselectedstudent.forEach((x) => {
            $scope.arraySelectedAssigned.push({ select: true, username: x.username });
          })
        } else {
          $scope.arraySelectedAssigned = [];
        }

      }


      function vmFlagAssignedSelected(select, username) {

        if (select) {
          $scope.arraySelectedAvailable.push({ select: select, username: username });
        } else {
          $scope.arraySelectedAvailable = $scope.arraySelectedAvailable.filter((x) => x.username !== username);
          if ($scope.arraySelectedAvailable.length === 0) {
            $scope.selectavailable = false;
          }
        }

      }
      function vmFlagAllAssignedSelected(selectavailable) {

        if (selectavailable) {
          $scope.list.forEach((x) => {
            $scope.arraySelectedAvailable.push({ select: true, username: x.username });
          })
        } else {
          $scope.arraySelectedAvailable = [];
        }

      }

      function vmBack() {
        var nextState = 'app.teacher.license';
        var params = {};
        if ($stateParams.childCourse) {
          nextState = 'app.teacher.license.childCourses';
          params = {
            group_license_id: $scope.license.group_license_id,
            state: $stateParams.state
          }
        }
        $state.go(nextState, params);
      }

      $scope.limitAssign = 30;
      $scope.limitAvailable = 30;
      $scope.loadMoreAssign = function () {
        $scope.limitAssign += 20;
      };
      $scope.loadMoreAvailable = function () {
        $scope.limitAvailable += 20;
      };
      $scope.licensedeleteusers = vmLicenseDeleteUsers;
      $scope.licenseaddusers = vmLicenseAddUsers;
      $scope.assignAllToLicense = vmAssignAllToLicense;
      $scope.selectAllAvailable = vmSelectAllAvailable;
      $scope.selectAllAssigned = vmSelectAllAssigned;
      $scope.removeSelected = vmRemoveSelected;
      $scope.assignselected = vmAssignSelected;
      $scope.vmFlagRemoveSelected = vmFlagRemoveSelected;
      $scope.vmFlagAllRemoveSelected = vmFlagAllRemoveSelected;
      $scope.vmFlagAssignedSelected = vmFlagAssignedSelected;
      $scope.vmFlagAllAssignedSelected = vmFlagAllAssignedSelected;

    })
    .controller('teacherLicenseChildCoursesCtrl', function (LMConfig, $scope, $state, $stateParams, LMWordpress, licenses) {
      $scope.$emit('sideTabChange', $stateParams.state);
      $scope.back = vmBack;
      $scope.openLicenseCourse = vmOpenLicenseCourse;
      // configuration
      $scope.config = LMConfig;
      // $scope.childCourses = [];
      var license = licenses.find(function (l) { return l.group_license_id == $stateParams.group_license_id; });

      LMWordpress.license.getChildCourses(license.course_idnumber).then(function (response) {
        $scope.childCourses = response.data;
        $scope.childCourseGroups = vmGetChunks($scope.childCourses, 2);
      });

      function vmBack() {
        $state.go('app.teacher.license');
      }

      function vmOpenLicenseCourse(course) {
        $scope.$parent.currentChildCourse = course;
        $state.go('app.teacher.license.students', {
          group_license_id: license.group_license_id,
          childCourse: course.id,
          state: $stateParams.state
        });
      }
    })
    .directive('vmsStudentLicenses', function (LMWordpress, LMAPI, FRAPI, $translate) {

      function vmController($scope, LMWordpress, LMAPI, FRAPI, $translate) {
        $scope.limitAssign = 30;
        $scope.limitAvailable = 30;
        $scope.assignedLicenses = [];
        $scope.availableLicenses = [];
        $scope.loadMoreAssign = function () {
          $scope.limitAssign += 20;
        };
        $scope.loadMoreAvailable = function () {
          $scope.limitAvailable += 20;
        };

        $scope.deleteLicense = function (license) {
          LMWordpress.licenseteacher.deletelicensestudent(license.group_license_id, $scope.studentToken,
            $scope.credentials.token, license.child_course_idnumber).then(function (responce) {
              $scope.availableLicenses.push(license);
              $scope.assignedLicenses = $scope.assignedLicenses.filter(function (elem) {
                return elem.group_license_id != license.group_license_id || elem.child_course_idnumber != license.child_course_idnumber;
              });
            }).catch(function (error) {

              console.log(error);
            })
        }

        $scope.addlicense = function (license) {
          if (parseInt(license.available_licenses) === 0) {
            alert($translate.instant('teacher.error.notEnough'));
            return;
          }

          if (!license.is_expired && parseInt(license.available_licenses) > 0) {

            LMWordpress.licenseteacher.postlicensestudent(license.group_license_id, $scope.studentToken,
              $scope.credentials.token, license.child_course_idnumber).then(function (responce) {

                $scope.assignedLicenses.push(license);
                $scope.availableLicenses = $scope.availableLicenses.filter(function (elem) {
                  return elem.group_license_id != license.group_license_id || elem.child_course_idnumber != license.child_course_idnumber;
                });
              }).catch(function (error) {

                if (error && error.data) {
                  if (error.data.errorInfo) {
                    if (error.data.errorInfo[0] === 'U0203') {
                      alert($translate.instant('teacher.error.notEnough'));
                    } else if (error.data.errorInfo[0] === 'U0204') {
                      alert($translate.instant('teacher.error.isLicense', { username: $scope.student.username }));
                    } else {
                      alert($translate.instant('teacher.error.error'));
                    }
                  } else {
                    alert($translate.instant('teacher.error.error'));
                  }
                } else {
                  alert($translate.instant('teacher.error.error'));
                }

              })
          } else {
            alert($translate.instant('teacher.error.notEnough'));
          }
        }

        $scope.assignAll = function () {
          throw new Error('Not Implemented');
        }

        FRAPI.getUsersFromCohort($scope.classId, JSON.stringify(['student'])).then(function (response) {
          //get field username student
          $scope.student = response.filter(function (item) {
            return item.token == $scope.studentToken;
          })[0];
          //get info about student
          FRAPI.userinfo($scope.studentToken).then(function (response) {
            $scope.studentInfo = response.data;
          });
        });

        LMWordpress.licenseteacher.getStudentLicenses($scope.schoolId, $scope.studentToken).then(
          function (response) {
            const assignedLicenses = response.data;

            FRAPI.teacherLicensesBySchool($scope.schoolId, true).then(function (data) {
              let schoolLicenses = data.sort((a, b) => {
                if (a.unixend > b.unixend) {
                  return 1;
                }
                if (a.unixend < b.unixend) {
                  return -1;
                }
                return 0;
              });

              schoolLicenses.forEach(function (x) {
                if (x.is_expired)
                  return;

                x.start = x.date_start ? moment(new Date(x.date_start)).format("MM/DD/YYYY") : null;
                x.end = x.date_end ? moment(new Date(x.date_end)).format("MM/DD/YYYY") : null;
                x.unixend = x.date_end ? moment(new Date(x.date_end)).unix() : null;

                if (assignedLicenses.some(al => al.group_license_id == x.group_license_id && al.child_course_id == x.child_course_id)) {
                  $scope.assignedLicenses.push(x);
                } else {
                  if (parseInt(x.available_licenses) > 0) {
                    $scope.availableLicenses.push(x);
                  }
                }
              });
            });
          }
        );
      }

      return {
        scope: {
          back: '=back',
          schoolId: '=schoolId',
          classId: '=cls',
          studentToken: '=studentToken',
          credentials: '=credentials'
          // user: '=user',
          // classes: '=classes',
          // list: '=list',
          // courses: '=courses'
        },
        controller: vmController,
        templateUrl: '/assets/ng1/teacher/license/studentLicenses.html'
      }
    })
})();