(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.teacher', {
                    url: '/teacher?schoolid',
                    controller: 'teacherCtrl',
                    templateUrl: '/assets/ng1/teacher/index.html',
                    resolve: {
                        schools: function (FRAPI, credentials) {

                            return FRAPI.getSchoolsByUser()
                                .then((x) => x
                                    .sort((a, b) => {
                                        if (a.title > b.title) {
                                            return 1;
                                        }
                                        if (a.title < b.title) {
                                            return -1;
                                        }
                                        return 0;
                                    }));
                        },
                        school: function ($stateParams, schools, commonService) {

                            if (schools.length === 0) {
                                return null;
                            } else {
                                var schoolId = $stateParams.schoolid || commonService.getCurrentSchool();
                                if (schoolId) {
                                    let $sh =  schools.find((x) => x.id === schoolId);
                                    if (!$sh) {
                                        commonService.setCurrentSchool(schools[0].id);
                                        return schools[0];
                                    } else {
                                        commonService.setCurrentSchool($sh.id);
                                        return $sh;
                                    }
                                } else {
                                    commonService.setCurrentSchool(schools[0].id);
                                    return schools[0];
                                }
                            }
                        },
                        courses: function (LMAPI, token, user) {

                            return LMAPI.courses(token, user.project)
                                .then((courses) => courses.filter((course) => course.enrol == 1));
                        },
                        // all school classes regardless closed or not
                        allClasses: function (FRAPI, school) {
                            if (school) {
                                return FRAPI.getSchoolAllClasses(school.id)
                                    .then(function (data) {
                                        return data;
                                    });
                            }
                            else {
                                return [];
                            }
                        },
                        // closedClasses
                        closedClasses: function (allClasses, commonService) {
                         //   let currentDate = moment().startOf('day').format('X');
                            var _date = new Date();
                            let currentDate = commonService.convertLocalDateToUTCUnix(_date);

                            return allClasses.filter((item) => currentDate > (item.date_end || 1924905600));
                        },
                        // available classes
                        classes: function ($q, LMAPI, school, allClasses, token, commonService) {

                            if (school) {

                                let classes = allClasses;
                                let cohorts = LMAPI.listCohort(token, 'class');

                              //  let currentDate = moment().startOf('day').format('X');
                                var _date = new Date();
                                let currentDate = commonService.convertLocalDateToUTCUnix(_date);

                                return cohorts
                                    .then(function (cohorts) {
                                        let res = classes.filter((item) => cohorts.some((cohort) => cohort.id === item.idnumber))
                                            .filter((item) => currentDate <= (item.date_end || 1924905600));

                                        return res;
                                    })
                            } else {

                                return [];
                            }
                        },
                        gemstones: function(FRAPI, credentials){
                            
                            return FRAPI.getGemStoneList('student').then((response) => {
                                return response;
                            });
                        },
                        newNotifications: function(FRAPI, credentials){
                            FRAPI.clearNewNotifications();        
                            return FRAPI.getNewNotifications().then((newNotifications) => {
                               
                               return newNotifications;
                            })
                        },  
                        notifications: function(FRAPI, credentials) {
        
                            return FRAPI.getUserNotifications().then(function (response) {
                                    return response; 
                            });          
                        } 

                    },
                    deepStateRedirect: {
                        default: { state: 'app.teacher.report' },
                        params: true
                    },
                })

                .state('app.teacher.error', {

                    url: '/error',
                    controller: 'teacherErrorCtrl',
                    templateUrl: '/assets/ng1/teacher/error.html'
                })
        }])

        .controller('teacherCtrl', function ($scope, $state, $location, FRAPI, $window, schools, user, LMConfig, school, commonService, classes) {

            window.localStorage.setItem('mode', 'teacher');
            // configuration
            $scope.config = LMConfig;

            // read user information
            $scope.user = user;
            $scope.locale = user.lang;
            $scope.schools = schools;
            $scope.school = school;

            $scope.urlHelp = commonService.help();
            // update logo and page title
            var logoObj = commonService.logo(user.project, 'teacher');
            $scope.logo = logoObj.logo;
            $scope.tm = logoObj.tm;

            $scope.teacherMode = commonService.teacherMode(user.project);
            $window.document.title = $scope.logo;

            $scope.stateSchool = schools.length;
            $scope.showWorkstations = commonService.workstations(user.project, 'teacherMode');

            $scope.hasRolesAdmin = vmHasRolesAdmin;
            $scope.hasRolesStudent = vmHasRolesStudent;
            $scope.hideProfile = vmHideProfile;

            function vmHideProfile(e) {
                $scope.profileUser = false;
            }

            function vmHasRolesAdmin() {
                return $scope.user.roles.some(x => x == 'schooladmin');
            }
            function vmHasRolesStudent() {
                return $scope.user.roles.some(x => x == 'student' || x == 'user');
            }

            $scope.$on('teacherModeChange', function (event, data) {
                $scope.activeTab = data;
            });

            if ($scope.stateSchool < 1) {
                $state.go('app.teacher.error');
            }
            function vmGoToClass() {

                if (classes.length > 0) {
                    $state.go('app.teacher.class.liststudent',
                        { currentClass: classes[0].idnumber, teacher: classes[0].teacher, school: school.id });
                } else {
                    $state.go('app.teacher.class');
                }

            }
            function vmGoToWorkstations() {
                $state.go('app.teacher.workstations', { selectFirstClass: true });
            }

            function onSelect($event) {
                $state.go('app.teacher', { schoolid: $event });    
                FRAPI.clearNewNotifications();          
            }
            function vmTwiceClick() {
                if ($state.current.name.indexOf('app.teacher.library.') != -1) { return; }
    
                $state.go('app.teacher.library.curriculums');
            }

            function twiceClickTaskTab() {

                if ($state.current.name.indexOf('app.teacher.tasks.') != -1) { return; }
    
                $state.go('app.teacher.tasks');
            }

            $scope.twiceClickTaskTab = twiceClickTaskTab;
            $scope.twiceClick = vmTwiceClick;

            $scope.onSelect = onSelect;
            $scope.goToClass = vmGoToClass;
            $scope.goToWorkstations = vmGoToWorkstations;

        })

        .controller('teacherErrorCtrl', function ($scope, $state, LMConfig, user, schools) {

            $scope.config = LMConfig;
            $scope.locale = user.lang;

            $scope.schoolLength = schools.length;
            $scope.$emit('teacherModeChange', 'error');

        })

        .service('dates', function () {

            this.dateFormat = function (date) {
                var dateformat = date ? moment(date).format('X') : null;
                return dateformat;
            };
        });
})();