(function () {
  'use strict';

  angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

      $stateProvider
        .state('app.teacher.report.certificates', {
          url: '/certificates',
          controller: 'teacherCertificatesCtrl',
          templateUrl: '/assets/ng1/teacher/report/certificates/index.html'
        })
        .state('app.teacher.report.cert_details', {
          url: '/certificate_details/:usertoken',
          controller: 'teacherCertificateDetailsCtrl',
          templateUrl: '/assets/ng1/teacher/report/certificates/details.html',
        });
    }])
    .controller('teacherCertificatesCtrl', function ($scope, classes, school) {
      $scope.$emit('teacherModeChange', 'report');
      $scope.$emit('activeTabLeft', 'certificates');

      $scope.schoolId = school.id;
      $scope.classes = classes;
    })
    .controller('teacherCertificateDetailsCtrl', function ($scope, $stateParams) {
      $scope.usertoken = $stateParams.usertoken;
    })
})();