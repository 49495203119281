(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.teacher.tasks.tupdate.report', {

            url: '/:idnumber',
            controller: 'teacherReportTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/report.html'
        })
    }])
    .controller('teacherReportTaskCtrl', function ($scope, $state, LMAPI, FRAPI, token, tasks, $stateParams, credentials, $rootScope) {
        $scope.show = true;
        $scope.back = vmBack;
        
        let promises = []; 
        $rootScope.pageLoading = true;

        LMAPI.getstudentstasks(credentials.session_token, $stateParams.idnumber, null, null).then(function (response) {

            $scope.results = [];
           
            let  array = [];

            var uniqueTokens = response.data.map((x) => x.token).filter(onlyUnique);

            uniqueTokens.forEach((token) => {
                promises.push(
                    FRAPI.userinfo(token).then(function (info) {
                      
                        if (info.data.roles.some(x => x == 'student' || x == 'user')) { 
                            array.push({
                                firstname: info.data.firstname,
                                lastname: info.data.lastname,
                                token: token
                            });
                        }
                    }));
            });

            Promise.all(promises).then((we) => {
 
                response.data.forEach((item) => {
    
                    let user = array.find((x) => x.token === item.token);
                    console.log('user', user);

                    $scope.results.push({
                            firstname: user.firstname,
                            lastname: user.lastname,
                            login: item.username,
                            courses: item.courses,
                            passed: item.passed,
                            read: item.read,
                            quiz: item.quiz,
                            token: item.token
                    });
                })
             
                if (!$scope.$$phase) {
                    $scope.$apply();
                  }
                  // restructure header table to the correct position
                  $('table.demo').floatThead('reflow');
                  $rootScope.pageLoading = false;
            });

        })

        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }

        function vmBack() {
            $state.go('app.teacher.tasks.list');
        }

    })
})();