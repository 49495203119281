(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.admin.school.tasks.tupdate12.edit', {

            url: '/editquest',
            controller: 'teacherEditFormTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/editform/editform.html'
        })
        .state('app.admin.school.tasks.dublicate.edit', {

            url: '/editquest',
            controller: 'teacherEditFormTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/editform/editform.html'
        })
    }])
})();