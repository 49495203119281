angular.module('LMConfig')

    .constant('LMProjectConfig', {

        get properties() {

            return {
                fr: {
                    localization: [{ key: 'en', title: 'English', file: 'en' }],
                    languaCards: false,
                    agent_key: null,
                    logo: 'Rev Learning Suite',
                    notifications: true,
                    fingerprintMandatory: false,
                    checkIndividualLicense: false,
                    searchGroup: { topic: ['er', 'fr'], genre: 'school', level: 'cefr' },
                    help_link: 'mailto:support@rev-learn.com',
                    tabs: { community: false, score: true }
                },
                tr: {
                    localization: [{ key: 'en', title: 'English', file: 'en' }],
                    languaCards: false,
                    agent_key: null,
                    logo: 'Rev Learning Suite',
                    notifications: true,
                    fingerprintMandatory: false,
                    checkIndividualLicense: false,
                    searchGroup: { topic: ['er', 'fr'], genre: 'school', level: 'cefr' },
                    help_link: 'https://tutorrev.helpscoutdocs.com/',
                    tabs: { community: true, score: true }
                },
                uonline: {
                    localization: [{ key: 'en', title: 'English', file: 'en' }],
                    languaCards: false,
                    agent_key: null,
                    logo: 'Rev Learning Suite',
                    notifications: true,
                    fingerprintMandatory: false,
                    checkIndividualLicense: false,
                    searchGroup: { topic: ['er', 'fr'], genre: 'school', level: 'cefr' },
                    help_link: 'mailto:support@rev-learn.com',
                    tabs: { community: false, score: true }
                }
            }
        },
    });