(function () {
  'use strict';

  angular.module('languabooksApp')

    .config(['$stateProvider', 'LMConfig', function ($stateProvider, LMConfig) {

      $stateProvider
        .state('app.course.start', {
          url: '/start/:taskId',
          // abstract: true,
          controller: 'startCtrl',
          templateUrl: '/assets/ng1/course/start.html'
        })
    }])
    .controller('startCtrl', function ($stateParams, $state, $scope, coursesService, token) {

      $scope.start = function () {
        coursesService.runTest(token, $stateParams.course, null, $stateParams.back, true, $stateParams.taskId);
      }

    })

})();