(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', 'LMConfig', function ($stateProvider, LMConfig) {

            $stateProvider
                .state('app.profile.edit.edit', {
                    url: '/editting',
                    controller: 'profileEditCtrl',
                    templateUrl: '/assets/ng1/profile/edit/edit.html',
                    params: {
                        saved: null
                    }
                })
        }])
        .controller('profileEditCtrl', function ($scope, $state, token, LMWordpress, user, username, commonService, $stateParams, $translate, LMConfig, LMAPI) {

            $scope.$emit('sideTabChange', 'edit');
            $scope.user = user;
            $scope.username = username.username || username;
            $scope.pswdObj = { password: null, confirm_password: null };
            $scope.parent = $scope.user.roles.some(x => x == 'parent');
            $scope.role_student = $scope.user.roles.every(x => x == 'student');
           
            LMAPI.user_properties(token).then(function (response) {
                $scope.prop = response.data;
                $scope.prop.birthdate = new Date(moment.unix($scope.prop.birthdate));

            })

            $scope.full = LMConfig.rights.profile_full($scope.user.project, $scope.user.roles);
            $scope.email = LMConfig.rights.profile_email($scope.user.project, $scope.user.roles);
    
            $scope.languages = commonService.languages(user.project);

            $scope.user.lang = $scope.languages.some((x) => x.key === user.lang) ? user.lang : 'en';
        
            $scope.available = true;
            if ($stateParams.saved) {
                $scope.msg = true;
            }

            function vmUpdateProfile(username, password, confirm_password, firstname, lastname, email, age, lang, parent, gender, birthdate, parentFirstName, parentLastName, phone_number) {

                var date = moment(birthdate).unix();
                LMWordpress.edit_profile(username, password, email, firstname, lastname, age, lang, user.project, parent, gender, date, parentFirstName, parentLastName, phone_number)
                    .then(function (response) {
                        // for check cache for scos
                        window.localStorage.setItem('ss_model', age);

                        $state.go('app.profile.edit.edit', { saved: true }, { reload: true });
                    })
            }

            function vmIsPassword(password, confirm_password) {

                $scope.available = true;
                $scope.errorPassword = null;

                // if the user changed his mind to change the password
                // errors should not be displayed 
                if ((password == '' && confirm_password == '') ||
                    (password == '' && !confirm_password) ||
                    (!password && confirm_password == '')) {

                    $scope.available = true;
                    $scope.errorPassword = null;
                } else if (password !== confirm_password) {
                    $scope.available = false;
                    $scope.errorPassword = $translate.instant('common.user_menu.errormatch');
                }

                if ((password && password.length < 8) || (/\s/.test(password))) {
                    $scope.available = false;
                    $scope.errorPassword = $translate.instant('common.forms.placeholder');
                }

            }

            $scope.updateProfile = vmUpdateProfile;
            $scope.isPassword = vmIsPassword;
        })
})();