(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.admin.school.cindex.teacher', {
                url: '/:currentClass/:type/teacher',
                controller: 'adminAssignTeacherCtrl',
                templateUrl: '/assets/ng1/admin/classes/class/teacher/teacher.html',
            })
    }])
    .controller('adminAssignTeacherCtrl', function ($scope, FRAPI, LMWordpress, schools, $q, $state, classes, $stateParams, commonService) {

       // $scope.$emit('adminTabChange', $stateParams.type);     
        $scope.$emit('adminClassesHighlighted', $stateParams.type);

        let _school = schools.find((x) => x.id === $state.params.school);
         
        let currentClass = $stateParams.currentClass ? classes.find((x) => x.idnumber === $stateParams.currentClass) : null;
        let classType = currentClass ? JSON.parse(currentClass.properties).class_type : null;
        $scope.titleCurrentClass = currentClass ? currentClass.name : null;

        switch (_school.school_type) {
            case 'Clever':
                if (classType === 'Clever') {
                    vmGetUsersInSchool();
                } else if (classType === 'Languametrics') {
                    vmGetUsersInSchool();
                }
                break;
            case 'Classlink':
                vmGetUsersInSchool()
                break;
            case 'Languametrics':
                vmGetUsersInSchool()
                break;
        }
        function vmGetUsersInSchool(){
            
            $q.all([FRAPI.getUsersFromCohort(commonService.getCurrentSchool(), JSON.stringify(['teacher'])),
                    FRAPI.getUsersFromCohort($stateParams.currentClass, JSON.stringify(['teacher']))]).then(function (response) {
                        // set list of school teachers
                        $scope.all_teachers = response[0];
                        $scope.remainder_teachers = response[1];

                        $scope.remainder_teachers.forEach(function (item) {
                            $scope.all_teachers.forEach(function (elem, i) {
                                if (item.token == elem.token) {
                                    $scope.all_teachers.splice(i, 1);
                                    //restructure header table to the correct position
                                    $('table.demo').floatThead('reflow');
                                }
                            })
                        })            
                    });
        }

        function vmCohortAddUser(teacher) {

            FRAPI.cohortaddusers($stateParams.currentClass, [teacher.username]).then(function (responce) {

                $scope.remainder_teachers.push(teacher);

                $scope.all_teachers = $scope.all_teachers.filter(function (elem) {
                    return elem.token != teacher.token;
                });
                //restructure header table to the correct position
                $('table.demo').floatThead('reflow');
            });

        };
        function vmCohortRemoveUser(teacher) {

            FRAPI.cohortremoveusers($stateParams.currentClass, [teacher.username]).then(function (responce) {

                $scope.all_teachers.push(teacher);
                $scope.remainder_teachers = $scope.remainder_teachers.filter(function (elem) {
                    return elem.token != teacher.token;
                });
                //restructure header table to the correct position
                $('table.demo').floatThead('reflow');
            })
        }

        function vmSelectAll(allselect) {

            if (allselect) {
                $scope.remainder_teachers.forEach((x) => x.select = true);
            } else {
                $scope.remainder_teachers.forEach((x) => x.select = false);
            }
        }
        function vmOpenSendEmail() {

            let recipients = $scope.remainder_teachers.filter((y) => y.select);

            $state.go('app.admin.school.cindex.email', { recipients: recipients, people: $scope.remainder_teachers });
        }

        function vmBack() {
            $state.go('app.admin.school.cindex.classes', {type: $stateParams.type});
        }
        $scope.back = vmBack;
        $scope.cohortadd = vmCohortAddUser;
        $scope.cohortremove = vmCohortRemoveUser;
        $scope.type = $stateParams.type;
        $scope.selectAll = vmSelectAll;
        $scope.openSendEmail = vmOpenSendEmail;
    })
})();