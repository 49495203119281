(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.projectadmin.page.admins', {
                    url: '/admins/:school?district',
                    controller: 'pageAdminsCtrl',
                    templateUrl: '/assets/ng1/projectadmin/page/schools/admin/listAdmin.html'
                })
                .state('app.projectadmin.page.add', {
                    url: '/edit/:school/:username?district',
                    params: {
                        admin: null
                    },
                    controller: 'pageAddCtrl',
                    templateUrl: '/assets/ng1/projectadmin/page/schools/admin/createAdmin.html'
                })
                .state('app.projectadmin.page.search', {
                    url: '/request/:school/:page?district',
                    controller: 'pageRequestCtrl',
                    templateUrl: '/assets/ng1/projectadmin/page/schools/admin/request.html'
                })
        }])

        .controller('pageAdminsCtrl', function ($scope, $state, $stateParams) {

            $scope.$emit('modeChange', 'page');
            $scope.$emit('leftTabs', 'schools');
            $scope.idschool = $stateParams.school;

            $scope.onBack = vmBack;
            $scope.onCreateSchoolAdmins = onCreateSchoolAdmins;
            $scope.onUpdateSchoolAdmins = onUpdateSchoolAdmins;
            $scope.onInvite = onInvite;
    
            function vmBack() {
                $state.go('app.projectadmin.page.schools');
            }
            function onCreateSchoolAdmins(school) {
    
                $state.go('app.projectadmin.page.add', { school: school, district: $stateParams.district } );
            }
            function onUpdateSchoolAdmins(datas) {
                $state.go('app.projectadmin.page.add', { school: datas.school, username: datas.username, district: $stateParams.district } );
            }
            function onInvite(school) {
                $state.go('app.projectadmin.page.search', { school: school, page: 'schooladmin', district: $stateParams.district } );
            }
        })
        .controller('pageAddCtrl', function ($scope, $state, $stateParams) {

            $scope.$emit('modeChange', 'page');
            $scope.$emit('leftTabs', 'schools');

            $scope.username =  $stateParams.username ?  $stateParams.username : null;
            $scope.idschool = $stateParams.school ? $stateParams.school : null;
            $scope.district = $stateParams.district;
            
            $scope.onBack = vmBack;
    
            function vmBack() {  
                $state.go('app.projectadmin.page.admins', { school: $stateParams.school, district: $stateParams.district });                       
            }
        })

        .controller('pageRequestCtrl', function ($scope, $state, $stateParams) {

            $scope.$emit('modeChange', 'page');
            $scope.$emit('leftTabs', 'schools');

            $scope.idschool = $stateParams.school;
            $scope.page =  $stateParams.page;
            $scope.district = $stateParams.district;

            function onBack() {
                $state.go('app.projectadmin.page.admins', { school: $stateParams.school, district: $stateParams.district });
            }

            $scope.onBack = onBack;
        })
})();