(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.admin.school.cindex.editstudent', {
                    url: '/:currentClass/:type/editstudent/:student',
                    params: {
                        student: null,
                        currentClass: null
                    },
                    controller: 'studentEditCtrl',
                    templateUrl: '/assets/ng1/admin/classes/class/student/editstudent.html',
                })
        }])
    .controller('studentEditCtrl', function ($scope, schools, LMWordpress, gemstones, FRAPI, LMAPI, $state, $stateParams, commonService, user, LMConfig, $rootScope) {

        // $scope.$emit('adminTabChange', $stateParams.type);
        $scope.$emit('adminClassesHighlighted', $stateParams.type);
        $scope.listgrades = commonService.listGrades();
        $scope.listgemstones = gemstones;
        
        $scope.schoolType = schools.find((x) => x.id === commonService.getCurrentSchool()).school_type;

        $scope.username = $stateParams.student;
        $scope.idclass = $stateParams.currentClass;    
        $scope.idschool = commonService.getCurrentSchool();    

        //method call when add/edit student
        function vmBack() {
            FRAPI.cleargetUsersFromCohort();
            FRAPI.cleargetUsersFromCohortTeacherMode($stateParams.currentClass, JSON.stringify(['student']));
            $state.go('app.admin.school.cindex.student', { type: $stateParams.type, currentClass: $stateParams.currentClass });
        }
        $scope.onBack = vmBack;
    })

})();