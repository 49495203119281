(function () {
    'use strict';
    
    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider       
        .state('app.admin.school.tasks.tupdate12.progressreport', {

            url: '/progressreport',
            controller: 'teacherProgressReportTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/progressreport/progressreport.html'
        })     
        .state('app.admin.school.tasks.dublicate.progressreport', {

            url: '/progressreport',
            controller: 'teacherProgressReportTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/progressreport/progressreport.html'
        })     
    }])
})();