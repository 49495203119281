(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.admin.school.tasks.tupdate12', {

            url: '/edit/:task?:page?:state',
            resolve: {
                school: function (schools, commonService) {
                    return schools.find((x) => x.id === commonService.getCurrentSchool());                  
                }
            },
            controller: 'teacherUpdateTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/edit.html',
            deepStateRedirect: {
                default: { state: 'app.admin.school.tasks.tupdate12.edit' },
                params: true
            }
        })
    }])

})();