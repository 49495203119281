(function () {
    'use strict';

    angular.module('languabooksApp')

    .filter('vmsFilterUsername', function () {

        // filter the list of students by list of fields
        // input: list of students
        // value: filter value
        // fields: array with fields to filter by
        return function (input, value, fields) {

            // all fields should be specified
            if (!!value && !!input && !!fields && fields.length > 0) {

                // break filter value by space
                // each of values should be matched
                // to one of fields in student
                let values = value.toLowerCase().split(' ');
                
                return input.filter((item) => {

                    return values.every((value) => {

                        return fields.some((field) => {

                            return !!item[field] && item[field].toLowerCase().indexOf(value) > -1;
                        });                        
                    })
                })

            } else {

                return input;
            }
        }

    })
    .filter('vmsFilterUniqUser', function () {

        // filter the list of students by list of fields
        // input: list of students
        // value: filter value
        // fields: array with fields to filter by
        return function (input, value, field) {

            // all fields should be specified
            if (!!value && !!input && !!field) {

                return input.filter((item) => {                   

                   return !!item[field] && item[field] === value;                                         
                   
                })

            } else {

                return input;
            }
        }

    })
    .filter('vmsFilterByList', function () {

        // filter the list of students by list of fields
        // input: list of students
        // value: filter value
        // fields: array with fields to filter by
        return function (input, value, fields) {

            // all fields should be specified
            if (!!value && !!input && !!fields && fields.length > 0) {

                // break filter value by space
                // each of values should be matched
                // to one of fields in student
                let values = value.replace(/,+|\s+|\t+|\n+/g, " ");
                values = values.trim().toLowerCase().split(/\s+/g);
                
                return input.filter((item) => {

                    return values.some((value) => {

                        return fields.some((field) => {

                            return !!item[field] && item[field].toLowerCase().indexOf(value) > -1;
                        });                        
                    })
                })

            } else {

                return input;
            }
        }

    })
    .filter('vmsFilterClass', function () {

        // filter list of students by class
        // input: list of students
        // value: filter value
        // field: name of class array field
        return function (input, value, field) {

            // all fields should be specified
            if (!!value && !!input && !!field) {

                // return students
                // which are assigned to provided class
                return input.filter((item) => {

                    return item[field].some((clas) => clas == value);
                })

            } else {

                return input;
            }
        }

    })
    .filter('vmsFilterTested', function () {
        return function (input, value, field) {

            // all fields should be specified
            if (!!value && !!input && !!field) {

                // return students
                // which are assigned to provided class
                return input.filter((item) => {

                    if(!value)
                        return true;
                    if(value == 'tested')
                        return !!item[field];
                    if(value == 'not-tested')
                        return !item[field];
                })

            } else {

                return input;
            }
        }
    })
          // input: list of students
        // value: filter value
        // field: name of class array field
    .filter('vmsFilterByBooleanValue', function () {
        return function (input, value, field) {

            // all fields should be specified
            if (!!input && !!field) {

                // return students
                // which are assigned to provided class
                return input.filter((item) => {

                    if (!value) {
                        return !item[field];
                    }
                    if (value) {
                        return item;
                    }                    
                })

            } else {

                return input;
            }
        }
    });
    

})();