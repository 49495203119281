(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.teacher.report.bookresults', {

                url: '/bookresults?:idnumber?:token?:title',
                controller: 'teacherReportBookResultsCtrl',
                templateUrl: '/assets/ng1/teacher/report/book/results.html'
            })
            .state('app.admin.school.reports.bookresults', {

                url: '/bookresults?:idnumber?:token?:title?:showAllClasses',
                controller: 'teacherReportBookResultsCtrl',
                templateUrl: '/assets/ng1/teacher/report/book/results.html'
            })
    }])

    .controller('teacherReportBookResultsCtrl', function (school, credentials, $scope, $state, $stateParams, LMAPI, LMAPICache, LMConfig, LMWordpress, $q, $translate, user) {

        $scope.mode = localStorage.getItem('mode');
        if ($scope.mode === 'schooladmin') {
            $scope.$emit('adminTabChange', 'sreport');
        } else {
            $scope.$emit('activeTabLeft', 'sreport');
        }
        
        if ($stateParams.state) {
            $scope.$emit('sideTabChange', $stateParams.state);
        }

        if ($stateParams.extendedParams) {
            localStorage.setItem('bookResultsExtendedParams', JSON.stringify($stateParams.extendedParams));
        } else {
            $stateParams.extendedParams = JSON.parse(localStorage.getItem('bookResultsExtendedParams'));
        }

        $scope.back = vmBack;
        $scope.showActivityReport = vmShowActivityReport;
        $scope.loadReportActivity = vmLoadReportActivity;
        $scope.currentActivity = null;
        $scope.openAttepmt = vmOpenAttepmt;
        $scope.isSpeaking = vmIsSpeaking;
        
        //restructure header table to the correct position
        $('table.demo').floatThead('reflow');

        $scope.info = { idnumber: $stateParams.idnumber, token: $stateParams.token, title: $stateParams.title };

        function vmBack() {

            if ($scope._activity){
                $scope._activity.stop();
            }
           
            if ($stateParams.state && $stateParams.extendedParams) {
                if ($stateParams.extendedParams.statename.includes('tasks.tupdate12.detailreport')) {
                    $state.go($stateParams.extendedParams.statename, { token: $stateParams.extendedParams.token, page: $stateParams.extendedParams.page, task: $stateParams.extendedParams.task, state: $stateParams.extendedParams.state });
                }
            } else {
                if ($scope.mode === 'schooladmin') {
                    $state.go('app.admin.school.reports.sreport.book', { showAllClasses:  $stateParams.showAllClasses });
                } else {
                    $state.go('app.teacher.report.sreport.book');
                }
            }
        };

        function vmShowActivityReport(sco) {

            vmStopAudio();
            
            $scope.currentActivity = sco;

            $scope.currentActivity.date = sco.timemodified ? moment.unix(sco.timemodified).utc().format("MM/DD/YYYY") : null;

            var params = ['cmi.vms_absolute_score', 'cmi.core.lesson_status', 'cmi.core.total_time', 'cmi.vms_first_score',
            'cmi.core.score_coefficient', 'cmi.vms_quiz_xml', 'cmi.vms_audio_path', 'cmi.vms_text', 
            'cmi.vms_video_path', 'vms.chat_score', 'vms.chat_text', 'cmi.core.score.raw', 'cmi.vms_dialog_cache', 
            'cmi.vms_first_quiz_xml'];

            $q.all([LMAPICache.scos($scope.info.token, $scope.info.idnumber), LMAPI.track($scope.info.token, sco.id, params.join(','))]).then(function (responses) {
                var scos = responses[0].scos;
                var tracks = responses[1].data;
                var reportData = {};

                var activityParams = scos.find(x => x.id == sco.id).params;
                activityParams = JSON.parse(activityParams);
                //make type of dialog activity 'Text' to prevent building in report composition clouds
                if (activityParams.idevice_type == 'DGR')
                    activityParams.type = 'Text';

                tracks = tracks.filter(x => x.attempt == sco.attempt);
                if (tracks.length > 0) {

                    reportData = tracks.reduce(function (prev, next) {

                        prev[next.key] = next.value;
                        return prev;

                    }, {});
                };

                vmLoadReportActivity(activityParams, reportData)
            });
        }

        function vmLoadReportActivity(activityParams, reportData) {

            var defaultStorage = {
                type: 's3',
                audio: 'speech-userfiles-dev'
            };

            var reportConfig = {

                application_id: "contenttool_report_page",
                bucket_root_url: LMConfig.storage,
                bucket_script_url: LMConfig.sco_defaults.bucket_script_url,
                idevice_mode: "report",
                lang: user.lang || "en",
                speech_service: LMConfig.sco_defaults.speech_service,
                web_worker: LMConfig.sco_defaults.web_worker,
                storage: LMConfig.sco_defaults.storage || defaultStorage,
                mode: 'report'
            };

            reportConfig = $.extend(reportConfig, activityParams);

            var preload = vimas.loader.preload({
                bucket: reportConfig.bucket_script_url || reportConfig.bucket_root_url,
                skin: {
                    action: "lb_report",
                    html: "lb_report",
                    skin: "lb_report",
                    localization: "lb"
                },
                scos: [{ container: '#sco_container', type: reportConfig.idevice_type }],
                lang: reportConfig.lang,
                mode: reportConfig.mode
            });

            preload.done(function () {

               $scope._activity = Idevice.postinit('sco_container', reportConfig, reportData);
            });
        };
        function vmTransformationResult(results){

            results.forEach(result => {
                
                result.raw_score = null;
                
                if (Array.isArray(result.cmi_params) && result.cmi_params.length > 0) {
                  
                    const rawScoreParam = result.cmi_params.find(param => param.element === "cmi.core.score.raw");
            
                    if (rawScoreParam) {
                        result.raw_score = rawScoreParam.value;
                    }
                }
            });
            return results.filter(x => x.type !== 'NSCO' && x.type !== 'FC');
        }

        LMAPI.activitiesprogress($scope.info.token, $scope.info.idnumber).then(function (response) {

            $scope.attempts = [];
            let numberAttempQuest = 10000;

            $scope.results = vmTransformationResult(response.data);
            
            let uniqAttempts = [...new Set($scope.results.map(item => item.attempt))];
           
            let dataAttempts = [];

            uniqAttempts.forEach((attempt) => {

                let dataByOneAttempt = $scope.results.filter((x) => x.attempt === attempt);
                let isCompleted = dataByOneAttempt.every((x) => x.status === "completed" );
                
                dataByOneAttempt.sort((a,b) => (a.timemodified > b.timemodified) ? 1 : ((b.timemodified > a.timemodified) ? -1 : 0));
                            
                let finishDate = isCompleted ? dataByOneAttempt[dataByOneAttempt.length - 1].timemodified : null;              
                let arrayStartingDate = dataByOneAttempt.filter((x) => x.status === "completed")
                                        .sort((a,b) => (a.timemodified > b.timemodified) ? 1 : ((b.timemodified > a.timemodified) ? -1 : 0));
                let startDate  = arrayStartingDate.length > 0 ? arrayStartingDate[0].timemodified : null;
 
                dataAttempts.push({
                    attempt: attempt,
                    timemodified: startDate ? startDate : 9999999999,
                    finishDate: finishDate ? moment.unix(finishDate).utc().format("MM/DD/YYYY") : null,
                    startDate: startDate ? moment.unix(startDate).utc().format("MM/DD/YYYY") : null,
                    status: isCompleted ? 'completed' : null,
                    isQuest: attempt > numberAttempQuest ? Math.trunc(attempt / 10000) : null
                });
            });

            dataAttempts.sort((a,b) => (a.timemodified > b.timemodified) ? 1 : ((b.timemodified > a.timemodified) ? -1 : 0));

            $scope.attempts = dataAttempts;

            //show always the first attempt by default
            if ($scope.attempts.length >= 1) {
                vmOpenAttepmt($scope.attempts[$scope.attempts.length - 1]);
            }                       

            $scope.results.forEach(x => x.activityName = $translate.instant('teacher.report.book.results.activityTypes.{0}'.format(x.type)));

            //restructure header table to the correct position
            $('table.demo').floatThead('reflow');
           
        });

        function vmOpenAttepmt(attempt) {

            vmStopAudio();
            $scope.currentActivity = null;
            //add css-class if active attempt
            $scope.selectAttempt = attempt.attempt;
            $scope.selectCurrentAttempt = attempt;

            $scope.resultsAttempt = $scope.results.filter(function (item) {
                return item.attempt == attempt.attempt;
            });
            if (attempt.isQuest) {
                LMAPI.singletaskByID(credentials.session_token, attempt.isQuest, school.id).then(function (res) {
                    
                    $scope.selectCurrentAttempt.title = res.title;

                    if (!$scope.$$phase) {
                        $scope.$apply();
                    }
                });
            }

            //restructure header table to the correct position
            $('table.demo').floatThead('reflow');
        };

        function vmIsSpeaking(type) {
            return ['SRS', 'SAS', 'FCR', 'FCA'].contains(type);
        };

        function vmStopAudio() {
            if ($scope._activity){
                $scope._activity.stop();
            }
        }
         // Listen for the '$destroy' event on the scope
        $scope.$on('$destroy', function() {
            // This function will be called when the controller is being destroyed
            vmStopAudio();
        });

    })
})();