angular.module('LMConfig', [])

  .constant('LMConfig', {

    session: {
      checkInterval: 20000 // 20 sec
    },
    task: {
      attemptMultiplier: 10000,
    },
    get api() {

      var api = this.lmApi;
      var api_curriculum = this.api_curriculum;

      return {
        login: api + '/login',
        getCDNAccess: api + '/aws/cdn/signCookies',
        categories: api + '/categories',
        courses: api + '/courses',
        activitiesprogress: api + '/activity/progress',
        scos: api + '/activity/list',
        track: api + '/activity/track',
        log: api + '/activity/log',
        calendar: api + '/report/calendar',
        summary: api + '/report/summary',
        achievments_nuts: api + '/achievements/nuts',
        courses_search: api + '/courses/search',
        courses_scores: api + '/report/coursescores',
        levelsummary: api + '/report/levelsummary',
        pronunciation: api + '/report/pronounciation',
        bestpronounciation: api + '/report/bestpronounciation',
        worstresults: api + '/report/worstresults',
        latest: api + '/report/books_in_progress',
        schools: api + '/teacher/schools',
        classes: api + '/teacher/classes',
        tasks: api + '/user/tasks',
        students: api + '/teacher/students',
        listCohort: api + '/cohort/list',
        cohortInfo: api + '/cohort/info',
        teachertasks: api + '/teacher/tasks',
        user_properties: api + '/user/properties',
        getcurriculumreports: api_curriculum + '/report/curriculum/list',
        getcurriculum: api_curriculum + '/report/curriculum',
        getcurriculumuserreports: api_curriculum + '/report/user/list',
        getcurriculumuser: api_curriculum + '/report/user',

        gettask: api + '/task',
        singletask: api + '/task/{0}',
        singletaskByID: api + '/task/{0}/get',
        taskCourses: {
          courses: api + '/task/{0}/courses'
        },
        taskStudents: {
          students: api + '/task/{0}/students'
        },
        getstudentstasks: api + '/task/{0}/state',
        levels: api + '/level/state',
        levelCourses: api + '/level/{0}/courses',
        levelStudents: api + '/level/{0}/students'
      }
    },
    get frapi() {

      var frApi = this.frApi;
      return {
        auth: frApi + '/clever/auth/token',
        loadSchool: frApi + '/clever/school/{0}',
        createSchool: frApi + '/clever/school/{0}?project={1}',
        syncadmins: frApi + '/clever/school/{0}/sync-admins?district={1}&project={2}',
        syncclasses: frApi + '/clever/school/{0}/sync-classes?project={1}',
        syncclass: frApi + '/clever/school/{0}/sync-class/{1}?project={2}',
        getclasses: frApi + '/clever/school/{0}/classes',
        updateClever: frApi + '/clever/school/{0}',
        getUsersClever: frApi + '/clever/school/{0}/users?districtNumber={1}&role={2}',
        assignUserClever: frApi + '/clever/school/user?role={0}',

        syncinfo: frApi + '/clever/school/{0}/sync-info?project={1}',
        getSchools: frApi + '/district/school?project=fr',
        
        createcleverDistrict: frApi + '/clever/district/{0}?project=fr',
        cleverSchools: frApi + '/clever/district/{0}/schools',

        class: frApi + '/class',
        
        getTeachersClasslinkByDistrict: frApi + '/classlink/district/{0}/teachers',
        getTeachersCleverByDistrict: frApi + '/clever/district/{0}/teachers',
        classlinkSchools: frApi + '/classlink/district/{0}/schools',
        getUsersClasslink: frApi + '/classlink/school/{0}/users?role={1}',
        assignUserClasslink: frApi + '/classlink/school/user?role={0}',
        createclasslinkDistrict: frApi + '/classlink/district/{0}?tenantId={1}&project=fr',
        classlinkSchool: frApi + '/classlink/school/{0}?tenantId={1}',
        syncinfoClassLinkSchool: frApi + '/classlink/school/{0}/sync-info?tenantId={1}&project={2}',
        syncadminsClassLink: frApi + '/classlink/school/{0}/sync-admins?district={1}&tenantId={2}&project={3}',
        getclassesForClasslink: frApi + '/classlink/school/{0}/classes?tenantId={1}',
        syncclassLink: frApi + '/classlink/school/{0}/sync-class/{1}?tenantId={2}&project={3}',
        getGemStoneList: frApi +  '/gemstone/list',
        userinfo: frApi + '/user/info',
        student: frApi + '/student',
        addStudentBulk: frApi + '/student/addBulk',
        teacher: frApi + '/teacher',
        addTeacherBulk: frApi + '/teacher/addBulk',
        taskCourseAttempt: frApi + '/task/courseattempt',
        taskGetAttemptMultiplier: frApi + '/task/attemptMultiplier',
        tasksstudents: frApi + '/task/state',
        taskDetailReport: frApi + '/task/detailreport',
        taskProgressReport: frApi + '/task/progressreport',
        statusCourses: frApi + '/task/{0}/courseattempts',
        taskCourseSortOrder: frApi + '/task/{0}/coursesortorder',
        getUsersFromCohort: frApi + '/user/info-by-cohort',
        classreport: frApi + '/class/report',
        setGemstoneLevel: frApi + '/student/set-gemstone-level',
        userresult: frApi + '/user/result-report',
        userSummaryReport: frApi + '/user/summary-report',

        getAttemptTask: frApi + '/task/courseattempt?course_idnumber={0}&task_id={1}',
        getAttemptLibrary: frApi + '/course/attempt?course_idnumber={0}',

        getSchoolsByUser: frApi + '/school/admin/schools',
        getSchoolAllClasses: frApi + '/school/allclasses?school={0}',
        getlistDistrict: frApi + '/district',
        getDistrictsAdmins: frApi + '/district/admin?project={0}',
        getDistrictsAdminsByDistrict: frApi + '/district/admin?project={0}&district={1}',
        getDistrictsByUser: frApi + '/district/admin/districts',
        getNewNotifications: frApi + '/notification/unread',
        setLastRead: frApi + '/notification/setlastread',        
        getUserNotifications: frApi + '/notification?applyRoles=true',
             
        userexists: frApi + '/user/exists',
        levelStudents: frApi + '/level/{0}/students',

        licensesBySchool: frApi + '/license/licensesBySchool?school={0}',
        teacherLicensesBySchool: frApi + '/license/teacherLicensesBySchool',
        licenseStudents: frApi + '/license/students',
        licenseTeachers: frApi + '/license/teachers',
        cohortaddusers: frApi + '/cohort/add-users',
        cohortremoveusers: frApi + '/cohort/remove-users',
        checksession: frApi + '/session/check',
        prolongsession: frApi + '/session/prolong'
      }

    },

    get wpapi() {

      return {
        user_logout: '/logout/',
        privacy: '/privacy/',
        user_profile: '/{0}/profile/',
        edit_profile: '/userupdate',
        addTeacher: '/schooladmin/addteacher',
        updateTeacher: '/schooladmin/updateteacher',       
        editClass: '/schooladmin/updateclass',
        checkSchoolForCertification: '/library/checkSchoolForCertification',
        teacher_page: '/teacher/#/teacher',
        schooladmin_page: '/schooladmin/#/schooladmin',
        library_page: '/library/#/library/shelve',
        useraddcourses: '/teacher/useraddcourses',
        userremovecourses: '/teacher/userremovecourses',
        addcohortcourse: '/teacher/addcohortcourse',
        removecohortcourse: '/teacher/removecohortcourse',
        tasklist: '/teacher/tasklist',
        addtask: '/teacher/addtask',
        updatetask: '/teacher/updatetask',
        deletetask: '/teacher/deletetask',
        getcredentials: '/getcredentials',
        removeChild: '/parent/removechild',
        addchild: '/parent/addchild',
        getchildren: '/parent/getchildren',
        searchuser: '/parent/searchuser',
        requestchild: '/parent/requestchild',
        requeststudent: '/teacher/requeststudent',
        requestteacher: '/schooladmin/requestteacher',
        collectionmarket: '/languamarket/getcollections',
        booksmarket: '/languamarket/getbooks',
        testsmarket: '/languamarket/gettests',
        othermarket: '/languamarket/getextproducts',
        productdetails: '/languamarket/product/productdetail',
        editproductproperties: '/languamarket/cart/editproductproperties',
        translation: '/languamarket/product/translation',
        getlicensetypes: '/languamarket/cart/getlicensetypes',
        addproduct: '/languamarket/cart/addproduct',
        productnumber: '/languamarket/cart/productnumber',
        cart: '/languamarket/cart',
        deleteproduct: '/languamarket/cart/deleteproduct',
        addorder: '/languamarket/orders/addorder',
        addlicense: '/languamarket/addlicense',
        getorders: '/languamarket/orders/getorders',
        getordersstatus: '/languamarket/orders/getordersstatus',
        subscriptions: '/languamarket/subscriptions/getsubscriptions',
        editlicensetype: '/languamarket/subscriptions/editlicensetype',
        getCourseIds: '/teacher/courseids',
        sendemail: '/sendemail',
        certificates: '/user/certificates',
        candidate_certifcates: '/user/candidateCertificates',
        getHiringPeople: '/hiredstatus/list',
        importHiringPeople: '/hiredstatus/import',
        updateHiringPeople: '/hiredstatus/item',
        checkusersHired: '/hiredstatus/checkusers',
        checkCSCcode: '/hiredstatus/check_csc_code',
        admin: {
          notification: {
            get: '/notifications/getSchoolNotifications',
          }
        },
        teacher: {
          courses: {
            cohortsLevel: '/teacher/cohorts/{0}/level/{1}/{2}'
          }
        },
        license: {
          getChildCourses: '/schooladmin/licenseChildCourses'
        },
        licenseteacher: {
          licensestudent: '/teacher/licensestudent',
          studentlicenses: '/teacher/studentlicenses'
        },
        projectadmin: {
          admin: '/projectadmin/admin',
          license: '/projectadmin/grouplicense',
          editLicense: '/projectadmin/editgrouplicense',
          courses: '/projectadmin/projectcourses',
          requestschooladmin: '/projectadmin/requestschooladmin',
          notification: {
            get: '/notifications/getNotifications',
            add: '/notifications/addNotification',
            update: '/notifications/updateNotification',
            delete: '/notifications/deleteNotification',
          },
          updateLicensePO: '/projectadmin/updateLicensePO',
          getLastLicensePO: '/projectadmin/getLastLicensePO',
          getLicensePOs: '/projectadmin/getLicensePOs',
          deleteLicensePO: '/projectadmin/deleteLicensePO'
        },
        workstations: {
          checkWorkstationIdExist: '/workstations/checkWorkstationIdExist',
          updateWorkstations: '/workstations/updateworkstations',
          updateCertificationStatus: '/workstations/certificationstatus',
          restoreRegistration: '/workstations/restoreRegistration',
          getList: '/workstations/getworkstations',
          isRegistered: '/workstations/isregistered',
          delete: '/workstations/delete',
          getFreeNumbers: '/workstations/getFreeNumbers',
          getRegisteredNumbers: '/workstations/getRegisteredNumbers',
          canRestore: '/workstations/canRestore',
        },
        notifications: {
          getNotifications: '/notifications/getNotifications',
          addNotification: '/notifications/addNotification',
          updateNotification: '/notifications/updateNotification',
        }
      }
    },
    get bapi() {
      return {
        dialogflowQuery: 'https://4beg6xf3g7.execute-api.eu-central-1.amazonaws.com/DEV/LMDEV-dialogflow',
        query: this.dlgFlwApi + '/query?v=20150910',
        email: this.lmApi + '/aws/SentEmail',
        reminder: this.lmApi + '/aws/SentAppointment'
      }
    },

    get sco_defaults() {

      return {
        lang: 'en',
        bucket_root_url: this.storage,
        bucket_script_url: this.storage + '/js/fr-idevice/1.49',
        web_worker: '/assets/worker/',
        lmapi: this.lmApiEndPoint,
        frapi: this.frApi,
        speech_service: this.speechService,
        speech_socket: this.speechSocket,
        optimize_tracks: true,
        storage: {
          type: 'cloudfront',
          region: 'us-east-1',
          path: this.storage + '/'
        }
      }
    },

    get rights() {

      return {
        profile_full: function (project, roles) {
          return project != 'odis' && !roles.some(x => x == 'facebook') && !roles.some(x => x == 'google');
        },
        profile_email: function (project, roles) {
          return project != 'odis' && !roles.some(x => x == 'google');
        },
        profile_lela: function (project) {
          return project == 'lela';
        },
        profile_ext_user: function (project) {
          return project == 'universal';
        }
      }
    },

    get payment() {
      return {
        default:/*lb*/ {
          url: 'https://demo.globalgatewaye4.firstdata.com/payment',
          pageLogin: 'HCO-VMS_T-499',
          transactionKey: 'tNqGywPmqU5vf3uCiUYb',
          currencyCode: 'USD',
          maxRandomNumber: 32767
        },
        'lela': {
          url: 'https://demo.globalgatewaye4.firstdata.com/payment',
          pageLogin: 'WSP-VMS_T-HMXATwB2Dg',
          transactionKey: 'xi5WP~xjBDZ_f1u3kjIj',
          currencyCode: 'USD',
          maxRandomNumber: 32767
        }
      };
    },

    server_utc_offset: 0
  });