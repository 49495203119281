(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.projectadmin', {
                    url: '/projectadmin',
                    controller: 'projectadminCtrl',
                    templateUrl: '/assets/ng1/projectadmin/index.html',
                    onExit: function (FRAPI) {
                    
                        FRAPI.clearNewNotifications();                       
                      
                    },
                    resolve: {
                        courses: function (LMAPI, token, user) {

                            return LMAPI.courses(token, user.project)
                                .then((courses) => courses.filter((course) => course.enrol == 1));
                        },
                    },
                    deepStateRedirect: {
                        default: { state: 'app.projectadmin.page' },
                        params: true
                    },
                })
        }])

        .controller('projectadminCtrl', function ($scope, $state, user, LMConfig, commonService, $window) {

            window.localStorage.setItem('mode', 'projectadministrator');
            // configuration
            $scope.config = LMConfig;

            // read user information
            $scope.user = user;
            $scope.locale = user.lang;
            // update logo and page title
            var logoObj = commonService.logo(user.project, 'projectadmin');
            $scope.logo = logoObj.logo;
            $scope.tm = logoObj.tm;

            $window.document.title = $scope.logo;
            $scope.$on('modeChange', function (event, data) {
                $scope.activeTab = data;
            });
        })
        .factory('projectAdminSvc', function () {
            return {
                getActiveSchools: function (schools) {
                    return schools.filter(function (school) {
                        try {
                            var props = school.properties;
                            return props.is_active == 1;
                        }
                        catch (e) {
                            console.log('Can\'t get is_active for school \'' + school.idnumber + '\'');
                            return false;
                        }
                    });
                }

            };
        })
})();