(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.admin.school.cindex.edit', {
                url: '/edit/:type/?:currentClass',
                params: {
                    currentClass: null,
                    school: null
                },
                resolve: {
                    teachers: function($stateParams, FRAPI, credentials, commonService){
                        return FRAPI.getUsersFromCohortTeacherMode(commonService.getCurrentSchool(), JSON.stringify(['teacher'])).then(function (response) {
                            return response;
                        });
                    }
                },
                controller: 'adminClassesEditCtrl',
                templateUrl: '/assets/ng1/admin/classes/class/edit.html',
            })
    }])
    .controller('adminClassesEditCtrl', function ($scope, FRAPI, LMWordpress, $rootScope, teachers, schools, commonService, $state, $stateParams, classes, $translate) {

        $scope.$emit('adminClassesHighlighted', $stateParams.type);
        $scope.type = $stateParams.type;
 
        $scope.newClass = {};
        let _school = schools.find((x) => x.id === commonService.getCurrentSchool());
        $scope.editFlag = $stateParams.currentClass ? true : false;

        $scope.errors = {
            errorBegin: null,
            errorEnd: null
        };
        $scope.teachers = teachers;

        //restructure header table to the correct position
        $('table.demo').floatThead('reflow');

        if ($stateParams.currentClass) {

            $scope.current_class = classes.find((x) => x.idnumber === $stateParams.currentClass);

            $scope.titleCurrentClass = angular.copy($scope.current_class.name);
            $scope.classname = $scope.current_class.name;
            $scope.description = $scope.current_class.description;

            let properties = JSON.parse($scope.current_class.properties);

            $scope.classType = properties.class_type || 'Languametrics';

            if ($scope.current_class.date_end) {
                let DataFromUnicx_End = moment.unix($scope.current_class.date_end).toDate();
                $scope.dateEnd = new Date(DataFromUnicx_End.getUTCFullYear(), DataFromUnicx_End.getUTCMonth(), DataFromUnicx_End.getUTCDate());
            } else {
                $scope.dateEnd = null;
            }
         
            if ($scope.current_class.date_begin) {
                let DataFromUnicx_Begin = moment.unix($scope.current_class.date_begin).toDate();
                $scope.dateBegin = new Date(DataFromUnicx_Begin.getUTCFullYear(), DataFromUnicx_Begin.getUTCMonth(), DataFromUnicx_Begin.getUTCDate());
            } else {
                $scope.dateBegin = null;
            }
          
            if (properties) {
                $scope.district = properties.district ? properties.district : null;
            }


            $scope.newClass.teacher = $scope.teachers.find(function (item) {
                return ($scope.current_class.teacher == item.token);
            });

            $scope.all_teachers = $scope.teachers.filter(function (item) {
                return item.token != $scope.current_class.teacher;
            });

            vmCheckDate($scope.current_class.date_begin, $scope.current_class.date_end);
        }


        function vmUpdateClass(name, info_teacher, dateBegin, dateEnd, description, district) {

            if ($stateParams.currentClass) {

            let dateEnd1 = dateEnd ? commonService.convertLocalDateToUTCUnix(dateEnd) : null;
            let dateBegin1 = dateBegin ? commonService.convertLocalDateToUTCUnix(dateBegin) : null;
            let clas = {
                idnumber: $stateParams.currentClass,
                name: name,
                school: $stateParams.school,
                teacher: info_teacher.username,
                district: _school.district || null,
                date_begin: dateBegin1,
                date_end: dateEnd1,
                description: description
            }
            $rootScope.pageLoading = true;

            FRAPI.updateClass(clas).then(function (data) {

                let properties = { district: _school.district, class_type:  $scope.classType || 'Languametrics' };

                classes.forEach(function (item) {
                    if (item.idnumber == $scope.current_class.idnumber) {
                        item.name = name;
                        item.teacher = info_teacher.token;
                        item.teacherFirstname = info_teacher.firstname,
                        item.teacherLastname = info_teacher.lastname,
                        item.date_begin = dateBegin1;
                        item.date_end = dateEnd1;
                        item.description = description;
                        item.properties = JSON.stringify(properties);
                    }
                });
                               //assign main teacher to class 
                FRAPI.cohortaddusers($stateParams.currentClass, [info_teacher.username]).then(function (resp) {
                   
                    $scope.saveClassMsg = 'Saved';
                    $rootScope.pageLoading = false;
    
                    vmBack();
                });
              

                }, function (error) {
                    $scope.saveClassMsg = 'Error!';
                    $rootScope.pageLoading = false;
                })

            } else {
                vmSaveClass(name, info_teacher, dateBegin, dateEnd, description, district);
            }
        }

        //method call when add/update class
        function vmBack() {
            $state.go('app.admin.school.cindex.classes', { type: $stateParams.type });
        }
        function vmSaveClass(name, teacher, dateBegin, dateEnd, description) {

           let dateEnd1 = dateEnd ? commonService.convertLocalDateToUTCUnix(dateEnd) : null;
           let dateBegin1 = dateBegin ? commonService.convertLocalDateToUTCUnix(dateBegin) : null;
           let clas = {
               name: name,
               school: $stateParams.school,
               teacher: teacher.username,
               district: _school.district || null,
               date_begin: dateBegin1,
               date_end: dateEnd1,
               class_type: "Languametrics",
               description: description
           }
           $rootScope.pageLoading = true;

            FRAPI.createClass(clas).then(function (response) {

                let properties = { district: _school.district || null,  class_type: 'Languametrics' };

                classes.push({
                    name: name,
                    school_id: $stateParams.school,
                    teacher: teacher.token,
                    teacherFirstname: teacher.firstname,
                    teacherLastname: teacher.lastname,
                    cohort_type: 'class',
                    idnumber: response.data.idnumber,
                    date_begin: dateBegin1,
                    date_end: dateEnd1,
                    description: description,
                    properties: JSON.stringify(properties)
                });
               
                FRAPI.cohortaddusers(response.data.idnumber, [teacher.username]).then(function (response) {
                    $scope.saveClassMsg = 'Saved';
                    $rootScope.pageLoading = false;
                    vmBack();
                });

            }, function (error) {
                $scope.saveClassMsg = 'Error!';
                $rootScope.pageLoading = false;
            })
        }
        function vmCheckDate(begin, end) {

            $scope.errors = commonService.checkDate(begin, end);
        }
        function vmChangeField(classname, teacher, dateBegin, dateEnd, description){

            if (!$scope.editFlag) {  $scope.checkChange = true; return;}

            if ($scope.current_class.description === null) { $scope.current_class.description = "";}

            if (classname !== $scope.titleCurrentClass ||
                teacher.token !== $scope.current_class.teacher || 
                commonService.convertLocalDateToUTCUnix(dateBegin) !== $scope.current_class.date_begin ||
                commonService.convertLocalDateToUTCUnix(dateEnd) !== $scope.current_class.date_end ||
                description !== $scope.current_class.description) {

                $scope.checkChange = true;
            } else{
                $scope.checkChange = false;
            }
            
        }
        $scope.vmChangeField = vmChangeField;
        $scope.updateClass = vmUpdateClass;
        $scope.back = vmBack;
        $scope.type = $stateParams.type;
        $scope.saveClass = vmSaveClass;
        $scope.checkDate = vmCheckDate;
    })

})();