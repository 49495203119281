(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.admin.school.tasks.dublicate', {

            url: '/copy/:task?:page?:state',
            params:{
                dublicateBooks: null,
                dublicateStudents: null,
                originalIdnumber: null,
                taskEnd: null,
                taskBegin: null,
                description: null,
                title: null
            },
            resolve: {
                school: function (schools, commonService) {
                    return schools.find((x) => x.id === commonService.getCurrentSchool());                  
                }
            },
            controller: 'teacheDublicateTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/edit.html'
        })
    }])

})();