(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('app.admin.school.tasks', {
                url: '/quests',
                controller: 'adminQuestsCtrl',
                resolve: {
                    tasks: function (LMAPI, credentials, commonService) {

                        return LMAPI.gettasks(credentials.session_token, commonService.getCurrentSchool()).then(function (res) {
    
                            res.data.forEach((x) => {
                                x.teach_firstname = x.teacher.firstname;
                                x.teach_lastname = x.teacher.lastname;
                                x.teach_username = x.teacher.username;                           
                            });
                            return res.data;    
                        });
                    },
                    curriculum: function(courses) {

                        return courses.filter(function (item) {
    
                            let params = JSON.parse(item.params);
                            item.p_sortorder = params && params.p_sortorder ? params.p_sortorder : -999;
                            item.p_collection = params && params.p_collection ? params.p_collection : null;
                
                            return item.enrol == 1 && item.curriculumtype == 'assignments';
                        });
                    }
                },
                templateUrl: '/assets/ng1/teacher/tasks/tasks.html',
            })
    }])
    .controller('adminQuestsCtrl', function ($scope, $state, tasks, $stateParams, schools, curriculum) {

        $scope.$emit('adminTopTabChange', 'tasks');

        // watch for side tab changed
        $scope.$on('sideTabChange', function (event, data) {
            $scope.selected = data;
        });
        $scope.curriculum = curriculum;
        $scope.open = vmOpen;
        $scope.vmOpenAssignments = vmOpenAssignments;

        if ($state.params.state) { return; }
        if (curriculum && curriculum.length > 0) {
            vmOpenAssignments();
        } else {
            vmOpen('current');
        }

        function vmOpen(state) {
            //restructure header table to the correct position
            $('table.demo').floatThead('reflow');

            $state.go('app.admin.school.tasks.list', { state: state });
        }
        function vmOpenAssignments() {
            $state.go('app.admin.school.tasks.assignments');
        }
    })

})();