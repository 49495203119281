(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider

        .state('app.admin.school.tasks.tadd', {
            url: '/tadd?:state',
            resolve: {
                school: function (schools, commonService) {
                    return schools.find((x) => x.id === commonService.getCurrentSchool());                  
                }
            },
            controller: 'teacherAddTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/addtask/add.html'
        })
    }])

})();