(function () {
  'use strict';

  angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

      $stateProvider
        .state('app.projectadmin.page.wrkstedit', {

          url: '/wrkstedit/:id',
          // params: {
          //   id: null
          // },
          controller: 'projAdminEditWorkstationCtrl',
          templateUrl: '/assets/ng1/projectadmin/page/workstations/edit.html'
        })
    }])
    .controller('projAdminEditWorkstationCtrl', function ($scope, $translate, LMWordpress, workstationSvc, $state, $stateParams) {
      $scope.$emit('modeChange', 'page');
      $scope.$emit('leftTabs', 'workstations');

      LMWordpress.workstations.getList(null, null, $stateParams.id).then(function (response) {
        $scope.workstation = response.data[0];
        $scope.workstation.$is_certified = '' + $scope.workstation.is_certified;
      });

      function vmSave() {

        LMWordpress.workstations.updateWorkstations($scope.workstation.test_center_id, null, $stateParams.id, null, null, $scope.workstation.specification, $scope.workstation.comments, $scope.workstation.$is_certified == '1')
          .then(function (response) {
            vmBack(true);
            $scope.error = false;
          }).catch(function (response) {
            $scope.error = true;
          })
      }


      function vmBack(reload) {
        $state.go('app.projectadmin.page.workstations'/*, {reload: reload}*/);
      }

      $scope.save = vmSave;
      $scope.back = vmBack;
    })
})();