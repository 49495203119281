(function () {

    'use strict';

    angular.module('LMLanguaCards')

    .directive('vmsFlashCard', function (LMConfig, languacardsService, $q, $http, $translate) {

        function vmController($scope) {

            $scope.courseErrorMsg = $translate.instant('library.shelve.shelve.cards.noBook');

            var scanSuccessSoundConfig = {
                url: 'resourses/audio/beep-07.wav'
            };

            $scope.$on('$destroy', function () {
                audioContext.close();
                $scope.data = null;

            })

            var audioContext = new (window.AudioContext || webkitAudioContext)();

            $scope.course = null;
            $scope.dictionary = null;
            $scope.firstScanned = false;
            $scope.dictionaryLoaded = false;
            $scope.rCardWordLoaded = false;
            $scope.courseLoaded = false;
            $scope.showLoadingIndicator = false;

            $scope.dataDisplayReady = false;

            $scope.openCourse = vmOpenCourse;
            $scope.playAudio = vmPlayAudio;

            $scope.$watch('data', function () {                

                $scope.dictionaryLoaded = false;
                $scope.rCardWordLoaded = false;

                $scope.dataDisplayReady = false;

                if ($scope.data) {

                    vmPlayAudio(scanSuccessSoundConfig);

                    $scope.firstScanned = true;

                    $scope.rCardInfo = null;
                    $scope.dictionary = null;
                    $scope.course = null;

                    if ($scope.rCardInfo) {

                        if ($scope.rCardInfo.img) {
                            $scope.rCardInfo.img = null;
                        }
                    }

                    if ($scope.dictionary) {

                        $scope.dictionary = null;
                    }
                    
                    if ($scope.data.rCard) {

                        $scope.showLoadingIndicator = true;

                        $http({
                            method: 'get',
                            url: LMConfig.storage + '/dictionary/en/{0}.json'.format($scope.data.rCard)
                        }).then(function (response) {

                            var data = response.data;
                            //load audio for rCard key(like dictionary)
                            if (data.audio) {

                                if (data.audio.length > 0) {
                                    $scope.rCardAudioBufferLoad = languacardsService.loadAudio({
                                        url: '{0}/dictionary/{1}/{2}'.format(LMConfig.storage, 'en', data.audio[0].value)
                                    })
                                }
                            }

                            //then init search and playing random audio

                            vmProcessFlashCard();
                        });
                    } else {
                        vmOpenDictionary();
                    }

                    
                }
            });

            function vmProcessFlashCard() {

                $scope.rCardWordLoaded = false;

                languacardsService.searchCourse($scope.token, $scope.data.course).then(function (courses) {

                    if (courses.length > 0) {                       

                        //api returns list (usually 1 item, but if more then 1, take first)
                        $scope.course = courses[0];
                        var course = courses[0];

                        if ($scope.course.enrol == '1') {

                            languacardsService.getScosList($scope.token, course).then(function (scos) {
                                if (scos) {
                                    if (scos.length > 0) {

                                        var randomizedSco = languacardsService.getRandomSco(scos);
                                        if (randomizedSco.params) {

                                            var randomizedContentKey = JSON.parse(randomizedSco.params).title_id;

                                            languacardsService.getRandomScoWordInfo(randomizedContentKey).then(function (data) {

                                                if (data) {
                                                    $scope.rCardWordLoaded = true;
                                                    $scope.rCardInfo = data;
                                                }

                                            })

                                            $scope.randomAudioLoad = languacardsService.loadAudio({
                                                url: languacardsService.getAudioUrl(randomizedContentKey)
                                            });

                                            $q.all([$scope.rCardAudioBufferLoad, $scope.randomAudioLoad]).then(function (responses) {

                                                var rCardBuffer = responses[0];
                                                var randomAudioBuffer = responses[1];

                                                $scope.randomAudioBuffer = randomAudioBuffer;

                                                languacardsService.processAudio(audioContext, rCardBuffer).then(function () {
                                                    languacardsService.processAudio(audioContext, randomAudioBuffer);
                                                });

                                                $scope.dataDisplayReady = true;
                                                $scope.courseLoaded = true;
                                                $scope.courseLoadError = false;

                                                $scope.showLoadingIndicator = false;

                                                //callBack for outer behavior handling
                                                $scope.dataLoadedCallBack({
                                                    data: {
                                                        timeout: 5000,
                                                        playScannerSound: true
                                                    }
                                                });
                                            });

                                        };
                                    };
                                };
                            });
                        } else {
                            vmProcessCourseError();
                        }
                    }
                    else {
                        vmProcessCourseError();
                    }
                }).catch(function () {
                    vmProcessCourseError();
                });
            }

            function vmProcessCourseError() {

                $scope.courseErrorMsg = $scope.courseErrorMsg.format($scope.data.course);

                $scope.courseLoaded = false;
                $scope.courseLoadError = true;
                $scope.showLoadingIndicator = false;

                $scope.dataLoadedCallBack({
                    data: {
                        timeout: 5000,
                        playScannerSound: true
                    }
                });
            }

            function vmOpenDictionary() {

                $scope.dictionaryLoaded = false;

                $scope.dictionary = {
                    display: null,
                    img: null,
                    audio: {
                        url: null,
                        buffer: null
                    }
                };

                $http({
                    method: 'get',
                    url: LMConfig.storage + '/dictionary/en/{0}.json'.format($scope.data.dictionary)
                }).then(function (response) {

                    $scope.dictionaryLoaded = true;
                    $scope.showLoadingIndicator = false;

                    var data = response.data;

                    $scope.dictionary.display = data.display;

                    if (data.audio) {

                        if (data.audio.length > 0) {

                            $scope.dictionary.audio.url = '{0}/dictionary/{1}/{2}'.format(LMConfig.storage, 'en', data.audio[0].value);

                            vmPlayAudio($scope.dictionary.audio);
                        }
                    }

                    if (data.image) {
                        $scope.dictionary.img = '{0}/dictionary/img/{1}'.format(LMConfig.storage, data.image[0].value);
                    }

                    $scope.dataDisplayReady = true;

                    $scope.dataLoadedCallBack({
                        data: {
                            timeout: 5000,
                            playScannerSound: true
                        }
                    });

                }).catch(function () {

                    $scope.showLoadingIndicator = false;

                    $scope.dataLoadedCallBack({
                        data: {
                            timeout: 5000,
                            playScannerSound: true
                        }
                    });
                });

                if ($scope.data.course) {

                    languacardsService.searchCourse($scope.token, $scope.data.course).then(function (courses) {

                        if (courses.length > 0) {

                            $scope.courseLoadError = false;

                            $scope.course = courses[0];
                            var course = courses[0];
                        }
                        //else {
                        //    $scope.courseLoadError = true;
                        //}
                    }).catch(function () {
                        $scope.courseLoadError = true;
                    });
                }            
            }

            function vmOpenCourse(course) {

                $scope.goToCourseCallBack({
                    data: {
                        course: course
                    }
                })

            };

            function vmPlayAudio(audioConfig) {

                var playing = languacardsService.playAudio(audioContext, audioConfig);

                if (playing) {

                    $scope.play = true;
                    playing.then(function () {

                        $scope.play = false;
                    });
                }
            }
        }

        return {
            scope: {
                data: '=vmsData',
                token: '=vmsToken',
                goToCourseCallBack: '&vmsGotoCourseCallback',
                dataLoadedCallBack: '&vmsDataLoadedCallback'
            },
            controller: vmController,
            templateUrl: '/assets/ng1/languacards/flashcard.html'
        }
    })

})();