(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.admin.school.workstations', {
                    url: '/workstations',
                    controller: 'adminWorkstationsCtrl',
                    templateUrl: '/assets/ng1/admin/workstations/list.html',
                })
                .state('app.admin.school.workstation_register', {
                    url: '/workst_register',
                    params: {
                        restore: false
                    },
                    controller: 'registerWorkstationCtrl',
                    templateUrl: '/assets/ng1/teacher/workstations/register.html',
                    resolve: {
                        school: function () { return {}; }
                    }
                })
        }])
        .controller('adminWorkstationsCtrl', function ($scope, $translate, LMWordpress, $stateParams, commonService, classes, user) {

            $scope.$emit('adminTabChange', 'workstations');
            $scope.notRegistered = false;
            $scope.showRestore = false;
            $scope.workstations = [];
            $scope.statuses = [
                { key: '1', name: $translate.instant('admin.workstation.list.Certified') },
                { key: '0', name: $translate.instant('admin.workstation.list.notCertified') }];

            vmLoad();

            function vmLoad() {
                commonService.getClientFingerprint(user.project).then(function (fingerprintObj) {
                    if (fingerprintObj.msg) alert(fingerprintObj.msg);
                    if (!fingerprintObj.value) return;
                    LMWordpress.workstations.isRegistered(fingerprintObj.value).then(function (response) {
                        $scope.notRegistered = !response.data.workstation;
                        $scope.isRegistered = !!response.data.workstation;
                        if ($scope.isRegistered) {
                            $scope.registrationNumber = response.data.workstation.workstation_id;
                            $scope.isCertified = response.data.workstation.is_certified == true;
                            $scope.testSite = response.data.workstation.test_site;
                        } else {
                            $scope.registrationNumber = null;
                            $scope.isCertified = false;
                            $scope.testSite = null;
                            if (response.data.was_deleted) {
                                commonService.resetClientFingerprint();
                            }
                        }
                    });
                });

                LMWordpress.workstations.canRestore($stateParams.school, classes.map(function (c) { return c.idnumber; })).then(function (response) {
                    $scope.showRestore = response.data;
                });

                LMWordpress.workstations.getList(commonService.getCurrentSchool())
                    .then(function (response) {
                        $scope.workstations = response.data;
                        //restructure header table to the correct position
                        $('table.demo').floatThead('reflow');
                        $scope.workstations.forEach(function (item) {
                            item.$date_creation = item.date_creation ? moment.unix(item.date_creation).utc().format("MM/DD/YYYY") : '';
                        });
                    });
            }

            function vmRemoveWorkstation(workstation) {

                if (confirm($translate.instant('admin.workstation.list.deleteConfirmation'))) {

                    LMWordpress.workstations.deleteWorkstation(workstation.workstation_id).then(function () {
                        // $scope.workstations = $scope.workstations.filter(function (w) {
                        //     return w.workstation_id != workstation.workstation_id;
                        // });
                        vmLoad();
                    })
                }
            }

            function vmSearchIsStatus(value, index, array) {
                if (!$scope.status) return true;

                var filter = $scope.status;

                return value.is_certified == filter;
            }
            //  class editing functions
            $scope.removeWorkstation = vmRemoveWorkstation;
            $scope.searchIsStatus = vmSearchIsStatus;
        })
})();