(function () {
  'use strict';

  angular.module('languabooksApp')
    .directive('vmsCandidateCertificates', function () {
      return {
        restrict: 'E',
        scope: {
          schools: '=?',
          schoolId: '=?',
          classes: '=?'
        },
        controller: vmController,
        templateUrl: '/assets/ng1/common/certificates/index.html'
      };

      function vmController($rootScope, $scope, $state, $translate, LMWordpress, FRAPI, $q) {
        $scope.userFilter = '';
        $rootScope.pageLoading = 1;

        setTimeout(function () {
          if ($scope.classes) {
            $scope.classList = angular.copy($scope.classes);
            onClassListReady();
            vmLoadRouteData();
            $rootScope.pageLoading--;
            if (!$rootScope.$$phase) {
              $rootScope.$apply();
            }
          } else {
            $scope.schools = angular.copy($scope.schools);
            $scope.schools.sort(function (a, b) { return ('' + a.title).localeCompare(b.title); });
            $scope.selectedSchool = { value: $scope.schools[0] };
            vmLoadSelectedSchool();
            vmOnSchoolChange().then(vmLoadRouteData).finally(function () {
              $rootScope.pageLoading--;
              if (!$rootScope.$$phase) {
                $rootScope.$apply();
              }
            });
          }
        }, 500);

        $scope.onSchoolChange = vmOnSchoolChange;
        $scope.runQuery = vmRunquery;
        $scope.openCertificate = vmOpenCertificate;

        function vmLoadSelectedSchool() {
          if ($state.current.data && $state.current.data.selectedSchool) {
            var selectedSchool = $scope.schools.find(function (s) { return s.id == $state.current.data.selectedSchool.id });
            if (selectedSchool) {
              $scope.selectedSchool.value = angular.copy(selectedSchool);
            }
          }
        }

        function vmLoadRouteData() {
          if (!$state.current.data) {
            return;
          }

          if ($state.current.data.selectedClass) {
            var selectedClass = $scope.classList.find(function (c) { return c.idnumber == $state.current.data.selectedClass.idnumber });
            if (selectedClass) {
              $scope.selectedClass = selectedClass;
            }
          }
          $scope.userFilter = $state.current.data.userFilter;

          if ($rootScope.fromState.url.startsWith('/certificate_details'))
            vmRunquery();
        }

        function vmOnSchoolChange() {
          var deferred = $q.defer();
          $scope.certificates = [];
          $rootScope.pageLoading++;
          FRAPI.getSchoolAllClasses($scope.selectedSchool.value.id)
            .then(function (data) {
              $scope.classes = data;
              $scope.classList = angular.copy($scope.classes);
              onClassListReady();
              deferred.resolve();
            })
            .finally(function () {
              $rootScope.pageLoading--;
              if (!$rootScope.$$phase) {
                $rootScope.$apply();
              }
            });;

          return deferred.promise;
        }

        function vmRunquery() {
          // break filter value by space
          var userSearchValues = $scope.userFilter.replace(/,+|\s+|\t+|\n+/g, " ");
          userSearchValues = userSearchValues.trim().toLowerCase().split(/\s+/g);
          var selectedClassIds = $scope.selectedClass.idnumber == 'all' ?
            $scope.classes.map(function (c) { return c.idnumber }) :
            [$scope.selectedClass.idnumber];

          var dateBegin = $scope.filterDateBegin ? moment($scope.filterDateBegin).unix() : null;
          var dateEnd = $scope.filterDateEnd ? moment($scope.filterDateEnd).endOf('day').unix() : null;

          $rootScope.pageLoading++;
          LMWordpress.getCandidateCertificates(selectedClassIds, userSearchValues, dateBegin, dateEnd)
            .then(function (response) {
              $scope.certificates = response.data;

              $scope.certificates.forEach(function (item) {
                item.$date = new Date(item.timemodified * 1000);
                item.$certificate_raw = item.certificate_raw ? JSON.parse(item.certificate_raw) : {};
              });

              $state.current.data =
              {
                selectedClass: angular.copy($scope.selectedClass),
                userFilter: angular.copy($scope.userFilter)
              };
              if ($scope.selectedSchool) {
                $state.current.data.selectedSchool = angular.copy($scope.selectedSchool.value);
              }
            })
            .finally(function () {
              $rootScope.pageLoading--;
              if (!$rootScope.$$phase) {
                $rootScope.$apply();
              }
            });
        }

        function onClassListReady() {
          const allClassesItem = {
            name: $translate.instant('common.forms.certificates.allClasses'),
            idnumber: 'all'
          };
          $scope.classList.sort(function (a, b) { return ('' + a.name).localeCompare(b.name); });
          $scope.classList.unshift(allClassesItem);
          $scope.selectedClass = allClassesItem;
        }
      }

      function vmOpenCertificate(url) {
        let secureUrl = vmGetSignedURL(url); // secure url from field 'External Content'
        window.open(secureUrl, '_blank');
      }

      function vmGetSignedURL(url) {

        // find Amazon S3 bucket from URL
        // check two possible positions for it
        var buckets = url.match('https://([a-z\-]*).?s3[\.a-z0-9\-]*.amazonaws.com/([a-z\-]*)');
        var bucket = buckets[1] || buckets[2];

        // find key portion in Amazon S3 URL
        // exclude root and bucket
        var key = url.match('amazonaws.com(/' + bucket + ')?/([A-Za-z0-9\-\/\._ ]*)')[2];

        // get signed URL
        var s3 = new AWS.S3({ signatureVersion: "v4" });
        return s3.getSignedUrl('getObject', { Bucket: bucket, Key: key });
      }
    })
    .directive('vmsCandidateCertificateDetails', function () {
      return {
        restrict: 'E',
        scope: {
          usertoken: '='
        },
        controller: vmController,
        templateUrl: '/assets/ng1/common/certificates/details.html'
      };

      function vmController($rootScope, $scope, $translate, LMWordpress) {
        $rootScope.pageLoading = true;
        LMWordpress.certificates($scope.usertoken)
          .then(function (response) {
            $scope.certificates = response.data;
            $scope.certificates.forEach(function (item) {
              item.$date = new Date(item.timemodified * 1000);
              item.$certificate_raw = item.certificate_raw ? JSON.parse(item.certificate_raw) : {};
            });

          })
          .finally(function () {
            $rootScope.pageLoading = false;
          });
      }
    });
})();