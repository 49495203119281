(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.teacher.tasks.assignments', {

            url: '/courses',
            controller: 'teacherCohortsCtrl',
            templateUrl: '/assets/ng1/teacher/courses/assignments/modulesV2.html'
        })
        .state('app.admin.school.tasks.assignments', {

            url: '/courses',
            controller: 'teacherCohortsCtrl',
            templateUrl: '/assets/ng1/teacher/courses/assignments/modulesV2.html'
        })

    }])
    .controller('teacherCohortsCtrl', function ($scope, $state, school, user, token, LMAPI, curriculum, commonService) {

        $scope.$emit('sideTabChange', 'assigments');
        $scope.$emit('teacherModeChange', 'modules');

        $scope.curriculum = curriculum;
        
        $scope.curriculum.sort((a, b) => {          
               if (a.p_sortorder === b.p_sortorder) {

                  return b.name - a.name;
               }
               return a.p_sortorder > b.p_sortorder ? 1 : -1;
        });

        $scope._products = $scope.curriculum.map((x) => x.p_collection).unique((y) => y);

        if ($scope._products.length >= 2) {

            $scope.products = [];
            let _products = commonService.products();

            $scope._products.forEach((x, i) => {
                
                $scope.products[i] = { key: x, name: _products.find((y) => y.key === x) ? _products.find((y) => y.key === x).name : x };
            });
            $scope.products.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                            .unshift({ key: null, name: 'All'});
            $scope.currentproduct = $scope.products.find((x) => x.key === null).key;
        }

        function vmOpenCurriculum(course) {
           
            if (localStorage.getItem('mode') === 'schooladmin') {
                $state.go('app.admin.school.levels', { course: course.id});
            } else {
                $state.go('app.teacher.levels', { course: course.id});
            }
           
        }
        function vmChangeProduct(product) {
            $scope.currentproduct = product;
        }

        $scope.openCurriculum = vmOpenCurriculum;
        $scope.vmChangeProduct = vmChangeProduct;
    })

})();