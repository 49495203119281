(function () {
    'use strict';
    
    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider       
        .state('app.teacher.tasks.tupdate12.book', {

            url: '/book',
            controller: 'teacherBookTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/assignbook/book.html'
        })     
        .state('app.teacher.tasks.dublicate.book', {

            url: '/book',
            controller: 'teacherBookTaskCtrl',
            templateUrl: '/assets/ng1/teacher/tasks/edittask/assignbook/book.html'
        })     
    }])
  .controller('teacherBookTaskCtrl', function ($scope, $timeout, $rootScope, token, LMAPI, $stateParams, $state, tasks, FRAPI, LMAPICache, credentials, $q) {

      $scope.$emit('sideTabChange', $stateParams.state);
      $scope.$emit('editTaskTab', 'book');
    
      $scope.criterias = {
        gemstone_level: null,
        courseId: null,
        courseTitle: null
      }

      $scope.courses = [];
      $scope.allCourses = [];
      $scope.pageSize = 14;
      $scope.currentPage = 0;
      $scope.busy = false;
      $scope.loadMore = loadMore;
      
      let _tasks = angular.copy(tasks);
      $scope.task = _tasks.find((x) => x.idnumber == $stateParams.task);

      $q.all([FRAPI.getGemStoneList(), 
              LMAPI.taskCourses.allcourses(credentials.session_token, $stateParams.task)]).then((response) => {
        /////// getGemStoneList
        $scope.gemstones = response[0];
        $scope.gemstones.unshift({ idnumber: null, name: 'All Gem Levels' });

        $scope.criterias.gemstone_level = $scope.gemstones.find((x) => !x.idnumber).idnumber;
        
        /////////LMAPI.taskCourses.allcourses
        response[1].data.forEach((x) => { 
            let params = JSON.parse(x.params);
            x.gemstone_level = params && params.gemstone_level ? params.gemstone_level : null;
            x.name_gemstone_level = x.gemstone_level ? vmGetGemLevel(x.gemstone_level) : null;
        })
        $scope.coursesTask = response[1].data;
        vmRefreshCoursesTask();
        $rootScope.pageLoading = false;
        vmCopyBooksId();
      })
      function vmGetGemLevel(gemstone_level) {

        if (!gemstone_level) return;

        let gemstone = $scope.gemstones.find((x) => x.idnumber === gemstone_level);

        return gemstone ? gemstone.name : null;
        
      }
      function vmSearchInCoursesTask(o, i, a) {

          if (!$scope.searchCoursesTask) return true;

          var filter = $scope.searchCoursesTask.toLowerCase().split(' ');
          var keys = ['name', 'id'];

          return filter.every(function (f) {

              return keys.some(function (key) {

                  return this[key].toLowerCase().indexOf(f) > -1;
              }, o);
          });
      };
      function vmAssignCourseToTask(course) {

        $rootScope.pageLoading = true;

          var idcourse = [course.id];

          LMAPI.taskCourses.addcourses(credentials.session_token, $stateParams.task, idcourse).then(function (response) {

              $scope.error = false;

              course.sort_order = response.data.find(x => x.id == course.id).sort_order;
              $scope.coursesTask.push(course);
              $scope.courses = $scope.courses.filter(function (item) {
                  return item.id != course.id;
              });
              vmCopyBooksId();
              vmRefreshCoursesTask();

              tasks.forEach(function (item) {
                if ($stateParams.task == item.idnumber) {

                    item.assignment_status = vmSetAssignmentsStatus(item.assignment_status, $scope.coursesTask);
                    $scope.$emit('changeAssignmentStatus', item.assignment_status);
                    return;
                }
              })

              $rootScope.pageLoading = false;

          }).catch(function () {

              $scope.error = true;
          });
      }
      function vmRemoveCourseFromTask(course) {

          $rootScope.pageLoading = true;
          var idcourse = [course.id];

          LMAPI.taskCourses.deletecourses(credentials.session_token, $stateParams.task, idcourse).then(function () {

              $scope.error = false;

              if ($scope.courses) {
                $scope.courses.push(course);
              } else {
                $scope.courses = [course];
                $scope.searchCourse = true;
                $scope.show = true;
              }
              $scope.coursesTask = $scope.coursesTask.filter(function (item) {
                  return item.id != course.id;
              })
              vmCopyBooksId();
              vmRefreshCoursesTask();

              tasks.forEach(function (item) {
                if ($stateParams.task == item.idnumber) {

                    item.assignment_status = vmSetAssignmentsStatus(item.assignment_status, $scope.coursesTask);
                    $scope.$emit('changeAssignmentStatus', item.assignment_status);
                    return;
                }
              })

              $rootScope.pageLoading = false;

          }).catch(function () {

              $scope.error = true;
          });
      }
      function vmGetCourseId(courseId) {

        if (!courseId) { return undefined;}

        if (courseId) {
            let arrayIds;
            
            if (courseId.indexOf('\n') !== -1) {
                arrayIds = courseId.trim().split('\n');
            } else {
                arrayIds = courseId.trim().split(' ');
            }
            
            return arrayIds.length > 1 ? arrayIds : '%' + courseId + '%';
        }
      
      }
      function vmSearch() {

          $rootScope.pageLoading = true;

          $scope.searchCourse = true;

          var criterias = $scope.criterias;          

          // prepare search criterias
          var search = {
              id: vmGetCourseId(criterias.courseId),
              name: criterias.courseTitle ? '%' + replaceSymbol(criterias.courseTitle) + '%' : undefined,
              gemstone_level: criterias.gemstone_level ? criterias.gemstone_level : undefined
          };

          // save last search criterias
          window.sessionStorage.setItem('languabooks.com/search', angular.toJson(criterias));

          // run search API
          // there is no limiter here
          // because we added client side filter
          LMAPICache.searchCourses(token, null, search)
                      .then(function (courses) {

                            $scope.currentPage = 0;
                            $scope.courses = [];

                            $scope.allCourses = courses.filter(x => x.enrol == '1' && (x.executiontype == 'curriculum_unit' || x.executiontype == 'standalone_course'));
                            // Process courses in chunks to avoid blocking the UI thread
                            processCoursesInChunks($scope.allCourses, 100, function() {
                                $scope.allCourses = $scope.allCourses.filter((y) => !y.is_external_link);

                                loadMore();

                                // Hide loading indicator
                                $scope.show = true;
                                $rootScope.pageLoading = false;
                                $('table.demo').floatThead('reflow');
                            });
                      });
                    
      }

    function loadMore() {
        if ($scope.busy) return;
        $scope.busy = true;
    
        // Calculate start and end index for the next page
        let start = $scope.currentPage * $scope.pageSize;
        let end = start + $scope.pageSize;
    
        // Ensure end does not exceed the total number of records
        if (start < $scope.allCourses.length) {
            let nextPage = $scope.allCourses.slice(start, end);
            $scope.courses = $scope.courses.concat(nextPage);
            $scope.currentPage++;
            
        } 
    
        $scope.busy = false;
        $scope.show = true;
        $rootScope.pageLoading = false;
        $('table.demo').floatThead('reflow');
    };
    function processCoursesInChunks(courses, chunkSize, callback) {
        let index = 0;
        function processChunk() {
            let endIndex = Math.min(index + chunkSize, courses.length);
            for (; index < endIndex; index++) {
                let item = courses[index];
                if (item.scores && !(item.scores instanceof Object)) {
                    item.scores = JSON.parse(item.scores);
                }
                let isAssigned = $scope.coursesTask.find((x) => x.id === item.id);
                item.isAssigned = isAssigned ? true : false;    
                let params = JSON.parse(item.params);
                item.is_external_link = params && params.p_external_link ? true : false;
                item.gemstone_level = params && params.gemstone_level ? params.gemstone_level : null;
                item.name_gemstone_level = item.gemstone_level ? vmGetGemLevel(item.gemstone_level) : null;
            }
            if (index < courses.length) {
                $timeout(processChunk, 0);
            } else {
                callback();
            }
        }
        processChunk();
    }    
    function replaceSymbol(title) {
        if (title.indexOf('"') > -1 || title.indexOf("'") > -1) {
            
            let _title = title.replace(/["']/g, '%');
            return _title;

        }
        return title;
    }
    function vmBack() {
        if (localStorage.getItem('mode') === 'schooladmin') {
            $state.go('app.admin.school.tasks.list', { state: $stateParams.state });
        }
        else {
            $state.go('app.teacher.tasks.list', { state: $stateParams.state });
        }
    }
      function changeCourseTitle(criterias) {

        if (!criterias || criterias === '') {
            $scope.exists = false;
            $scope.isSymbol = false;
            return;
         }
        // Check for invalid characters " and '
        if (criterias.indexOf('"') > -1 || criterias.indexOf("'") > -1) {
            $scope.isSymbol = true;
            $scope.exists = true;
            return;
        }

        // Check length of input (must be 2 or more characters)
        if (criterias.trim().length >= 2) {
            // Check for spaces inside the string
            if (criterias.trim().indexOf(' ') === -1) {
                $scope.exists = false;
                $scope.isSymbol = false;
            } else {
                $scope.exists = true;
                $scope.isSymbol = false;
            }
        } else {
            $scope.exists = true;    
            $scope.isSymbol = false;      
        }          
      }
      function vmCopyBooksId() {
        $scope.copybooks = $scope.coursesTask.map((x) => {return x.id })
                                                 .join('\n');
      }
      function vmCopyInBuffer(){

        navigator.clipboard.writeText($scope.copybooks).then(function() { 
            console.log('Async: Copying to clipboard was successful!'); 
        }, function(err) { 
            console.error('Async: Could not copy text: ', err); 
        });
      
      }
      function vmGotoLibrary() {

        localStorage.setItem("current_quest_id", $stateParams.task);
        localStorage.removeItem("current_books");
        
        if (localStorage.getItem('mode') === 'schooladmin') {

            localStorage.setItem('schooladmin_quest_teacher', JSON.stringify($scope.task.teacher));
            if (credentials.username === $scope.task.teacher.username) {
                localStorage.setItem('schooladmin_myquest_checkmark', '1');
            } else {
                localStorage.setItem('schooladmin_myquest_checkmark', '0');
            }

            $state.go('app.admin.school.library');
        }
        else {
            $state.go('app.teacher.library');
        }
      }
     
      function vmSetAssignmentsStatus(assignment_status, coursesInTask) {

        switch (assignment_status) {
            case 'missing_books_students':       

                   if (coursesInTask.length > 0) {
                    return 'missing_students';
                   } else {
                    return 'missing_books_students';
                   }
            case 'missing_books': 

                if (coursesInTask.length > 0) {
                    return 'ready';
                } else {
                    return 'missing_books';
                }                      
               
            case 'missing_students':

                if (coursesInTask.length > 0) {
                    return 'missing_students';
                } else {
                    return 'missing_books_students';
                }    
               
            case 'ready':  
                if (coursesInTask.length > 0) {
                    return 'ready';
                } else {
                    return 'missing_books';
                }                     
              
        }
      
      }

      function vmMoveDown(course) {
        let curSortOrder = course.sort_order;
        let nextCourse = $scope.coursesTask.find(x => x.id == course.nextId);
        course.sort_order = nextCourse.sort_order;
        nextCourse.sort_order = curSortOrder;

        vmRefreshCoursesTask();
        FRAPI.taskUpdateCourseSortOrder($scope.task.id, [ {idnumber: course.id, sort_order: course.sort_order}, {idnumber: nextCourse.id, sort_order: nextCourse.sort_order} ]);
      }

      function vmMoveUp(course) {
        let curSortOrder = course.sort_order;
        let prevCourse = $scope.coursesTask.find(x => x.id == course.prevId);
        course.sort_order = prevCourse.sort_order;
        prevCourse.sort_order = curSortOrder;

        vmRefreshCoursesTask();
        FRAPI.taskUpdateCourseSortOrder($scope.task.id, [ {idnumber: course.id, sort_order: course.sort_order}, {idnumber: prevCourse.id, sort_order: prevCourse.sort_order} ]);
      }

      function vmRefreshCoursesTask() {
        $scope.coursesTask.sort((a, b) => a.sort_order - b.sort_order).forEach((item, index, array) => {
            item.prevId = index === 0 ? null : array[index-1].id;
            item.nextId = index === array.length - 1 ? null : array[index+1].id;
        });
      }

      $scope.searchInCoursesTask = vmSearchInCoursesTask;
      $scope.selected = $stateParams.id;
   //   $scope.task = $stateParams.task;
      $scope.search = vmSearch;       
      $scope.assignCourseToTask = vmAssignCourseToTask;
      $scope.removeCourseFromTask = vmRemoveCourseFromTask;       
      $scope.back = vmBack;      
      $scope.changeCourseTitle = changeCourseTitle;
      $scope.copyInBuffer = vmCopyInBuffer;
      $scope.vmGotoLibrary = vmGotoLibrary;
      $scope.moveDown = vmMoveDown;
      $scope.moveUp = vmMoveUp;
  })
})();