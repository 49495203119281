(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.teacher.class.add', {

                    url: '/:school/:currentClass/:teacher/add',
                    controller: 'studentAddClassCtrl',
                    templateUrl: '/assets/ng1/admin/classes/class/student/editstudent.html'
                })
                .state('app.teacher.class.request', {

                    url: '/:school/:currentClass/:teacher/request/:page',
                    controller: 'parentSearchChildCtrl',
                    templateUrl: '/assets/ng1/parent/manage/request.html'
                })
                .state('app.teacher.class.edit', {

                    url: '/:school/:currentClass/:teacher/editstudent/:student',
                    controller: 'studentEditClassCtrl',
                    templateUrl: '/assets/ng1/admin/classes/class/student/editstudent.html'
                })
                .state('app.teacher.class.licenses', {

                    url: '/:currentClass/:teacher/licenses/:student',
                    params: {
                        school: null
                    },
                    controller: 'licenseEditClassCtrl',
                    templateUrl: '/assets/ng1/teacher/class/editLicenses.html'
                })
        }])

        .controller('studentEditClassCtrl', function ($scope, FRAPI, school, $state, $stateParams, gemstones, commonService) {

            $scope.$emit('sideTabChange', $stateParams.currentClass);
            $scope.listgrades = commonService.listGrades();
            $scope.listgemstones = gemstones;

            $scope.username = $stateParams.student;
            $scope.idclass = $stateParams.currentClass;    
            $scope.idschool =  sessionStorage.getItem('current_school_idnumber'); 
          
            function vmBack() {
                FRAPI.cleargetUsersFromCohort();
                FRAPI.cleargetUsersFromCohortTeacherMode($stateParams.currentClass, JSON.stringify(['student']));
             
                $state.go('app.teacher.class.liststudent', { currentClass: $stateParams.currentClass, school: school.id, teacher: $stateParams.teacher });
            }
            $scope.onBack = vmBack;
        })

        .controller('licenseEditClassCtrl', function ($scope, LMWordpress, credentials, school, $state, $stateParams) {
            $scope.$emit('sideTabChange', $stateParams.currentClass);
            $scope.back = function () {
                $state.go('app.teacher.class.liststudent', { currentClass: $stateParams.currentClass, school: school.id, teacher: $stateParams.teacher });
            }
            $scope.cls = $stateParams.currentClass;
            $scope.schoolId = school.id;
            $scope.studentToken = $stateParams.student;
            $scope.credentials = credentials;
        })
        .controller('studentAddClassCtrl', function ($scope, classes, LMWordpress, gemstones, FRAPI, school, $state, $stateParams, commonService, user, $rootScope) {

            $scope.$emit('sideTabChange', $stateParams.currentClass);
            $scope.listgrades = commonService.listGrades();
            $scope.listgemstones = gemstones;

            $scope.username = $stateParams.student;
            $scope.idclass = $stateParams.currentClass;    
            $scope.idschool =  sessionStorage.getItem('current_school_idnumber'); 
          
            function vmBack() {
                FRAPI.cleargetUsersFromCohort();
                FRAPI.cleargetUsersFromCohortTeacherMode($stateParams.currentClass, JSON.stringify(['student']));
                
                $state.go('app.teacher.class.liststudent', { currentClass: $stateParams.currentClass, school: school.id, teacher: $stateParams.teacher });
            }

            $scope.onBack = vmBack;
           
        })
})();