(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.admin.school.cindex.student', {
                    url: '/class/:currentClass/:type',
                    params: {
                        teacher: null
                    },

                    controller: 'adminAssignStudentCtrl',
                    templateUrl: '/assets/ng1/admin/classes/class/student/student.html',
                })
                .state('app.admin.school.cindex.request', {
                    url: '/request/:currentClass/:type/:page',
                    params: {
                        currentClass: null,
                        school: null,
                        type: null
                    },
                    controller: 'parentSearchChildCtrl',
                    templateUrl: '/assets/ng1/parent/manage/request.html'
                })
        }])

        .controller('adminAssignStudentCtrl', function ($rootScope, gemstones, $q, $scope, $translate, LMConfig, LMWordpress, schools, user, token, $state, classes, $stateParams, commonService, FRAPI) {

           // $scope.$emit('adminTabChange', $stateParams.type);
            $scope.$emit('sideTabChange', $stateParams.currentClass);
            $scope.$emit('adminClassesHighlighted', $stateParams.type);

            $scope.arraySelectedAssigned = [];
            $scope.arraySelectedAvailable = [];
            $scope.type = $stateParams.type;
            $scope.classes = angular.copy(classes);
            $scope.classes = $scope.classes.filter((x) => x.idnumber !== $stateParams.currentClass);
            $scope.classes.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).unshift({
                idnumber: null,
                name: 'All'
            });
            $scope.gemstones = gemstones;
            $scope.gemstones_filter = angular.copy(gemstones);
            $scope.gemstones_filter.unshift({
                idnumber: null,
                name: 'All'
            })
            $scope.selectedGem = $scope.gemstones_filter.find((x) => !x.idnumber).idnumber;       

            $scope.ext_user = LMConfig.rights.profile_ext_user(user.project);
            $scope.btnHide = commonService.btnHide(user.project);

            if ($stateParams.teacher == token || user.roles.some(x => x == 'schooladmin')) {
                $scope.enabled = true;
            } else {
                $scope.enabled = false;
            };
            
           $scope.clas = $stateParams.currentClass;

           let _school = schools.find((x) => x.id === commonService.getCurrentSchool());
           $scope.schoolType = _school.school_type;

           let currentClass = $stateParams.currentClass ? classes.find((x) => x.idnumber === $stateParams.currentClass) : null;
           $scope.classType = currentClass ? JSON.parse(currentClass.properties).class_type : null;
           $scope.titleCurrentClass = currentClass ? currentClass.name : null;
   

            switch (_school.school_type) {
                case 'Clever':
                    if ($scope.classType === 'Clever') {
                        vmGetUsersInClass();
                    } else if ($scope.classType === 'Languametrics') {
                        vmGetUsersInClass();
                    }
                    break;
                case 'Classlink':
                    vmGetUsersInClass()
                    break;
                case 'Languametrics':
                    vmGetUsersInClass()
                    break;
            }

            function vmGetUsersInClass(){
            
                FRAPI.getUsersFromCohortTeacherMode($stateParams.currentClass, JSON.stringify(['student'])).then(function (response) {
                   
                    $scope.usersInClassList = angular.copy(response);
                    
                    $scope.usersInClassList.forEach((x) => { 
                        x.gemstone_level = x.properties ? x.properties.gemstone_level : null;
                        x.name_gemstone_level = x.properties ? vmGetGemLevel(x.properties.gemstone_level) : null;
                    })                   
                    // restructure header table to the correct position
                    $('table.demo').floatThead('reflow');
                });
            }
            //add student to current class
            function vmAssignStudentToClass(usersInSchoolArr) {
                if (commonService.checkIndividualLicense(user.project)) {
                    var studentsWithoutLic = usersInSchoolArr.filter(function (u) { return !u.has_license; });
                    if (studentsWithoutLic.length) {
                        var msg = $translate.instant('admin.class.edit.userWithoutLicCantBeAdded',
                            { users: studentsWithoutLic.slice(0, 3).map(function (st) { return "'" + st.username + "'" }).join(',') });
                        alert(msg);
                        return false;
                    }
                }
                $rootScope.pageLoading = true;

                FRAPI.cohortaddusers($stateParams.currentClass,
                    usersInSchoolArr.map(function (s) {
                        return s.username
                    }))
                    .then(function () {
                        $rootScope.pageLoading = false;

                        $scope.error = false;
                        usersInSchoolArr.forEach(function (x) {
                            //add student to array of class
                            $scope.usersInClassList.push({
                                email: x.email,
                                firstname:  x.firstname,
                                idnumber: $stateParams.currentClass,
                                lastname: x.lastname,
                                roles: x.roles,
                                token: x.token,
                                username: x.username,
                                gemstone_level: x.properties ? x.properties.gemstone_level : null,
                                properties: {
                                    gemstone_level: x.properties ? x.properties.gemstone_level : null,
                                    physical_grade: x.properties ? x.properties.physical_grade : null
                                }
                            });
                      
                            //remove student from array of school
                            $scope.usersList = $scope.usersList.filter(function (item) {
                                return item.username != x.username;
                            });

                            x.select = false;
                        });
                        FRAPI.cleargetUsersFromCohort();
                        //restructure header table to the correct position
                        $('table.demo').floatThead('reflow');

                    }).catch(function () {
                        $rootScope.pageLoading = false;
                        $scope.error = true;
                    });
                return true;
            };
            //remove student from current class
            function vmRemoveStudentFromClass(usersInClassArr) {
                $rootScope.pageLoading = true;

                FRAPI.cohortremoveusers($stateParams.currentClass, usersInClassArr.map(s => s.username)).then(function (response) {
                   
                    $rootScope.pageLoading = false;
                    $scope.error = false;
                    usersInClassArr.forEach(function (y) {
                        y.select = false;
                        //add student to array of school 
                        if ($scope.usersList){
                            $scope.usersList.push(y);
                        }
                      
                        //remove student from array of class
                        $scope.usersInClassList = $scope.usersInClassList.filter(function (item) {
                            return item.username != y.username;
                        });
                 
                    });
                    FRAPI.cleargetUsersFromCohort();
                    //restructure header table to the correct position
                    $('table.demo').floatThead('reflow');

                   /* if (notRemovedUsers.length) {
                        var className = classes.find(function (cls) { return cls.idnumber == $stateParams.currentClass }).name;
                        alert($translate.instant('admin.class.edit.userCantBeDeleted',
                            {
                                users: notRemovedUsers.join(),
                                class: className
                            }));
                    }*/
                }).catch(function () {
                    $scope.error = true;
                    $rootScope.pageLoading = false;
                });
            };
            //assign all student to class
            function vmAssignAllStudentToClass(list) {

                if (list.length > 0) {
                    vmAssignStudentToClass(list);
                }
            }
            function vmSelectAllAssigned(select) {

                if (select) {
                    $scope.listUsersClass.forEach((x) => x.select = true);
                } else {
                    $scope.listUsersClass.forEach((x) => x.select = false);
                }
            }
            function vmSelectAllAvailable(select) {

                if (select) {
                    $scope.usersList.forEach((x) => x.select = true);
                } else {
                    $scope.usersList.forEach((x) => x.select = false);
                }
            }
            function vmRemoveSelected() {

                let selectedStudents = $scope.usersInClassList.filter((x) => x.select === true);

                if (selectedStudents.length > 0) {
                    vmRemoveStudentFromClass(selectedStudents);
                    vmFlagAllRemoveSelected(false, selectedStudents);
                }
            }

            function vmAssignSelected() {

                $scope.selectavailable = false;

                let selectedStudents = $scope.usersList.filter((x) => x.select === true);

                if (selectedStudents.length > 0) {
                    if (vmAssignStudentToClass(selectedStudents))
                        selectedStudents.forEach(function (item) {
                            vmFlagAssignedSelected(false, item.username);
                        })
                }
            }

            function vmOpenSendEmail() {

                let recipients = $scope.usersInClassList.filter((y) => y.select);

                $state.go('app.admin.school.cindex.email', { recipients: recipients, people: $scope.usersInClassList });
            }

            function vmFlagRemoveSelected(select) {
                $scope.isSeleted = $scope.usersInClassList.some((x) => x.select);
            }

            function vmFlagAllRemoveSelected(select, listUsersClass) {
               
                $scope.isSeleted = select;
                $scope.selectassigned = select;

                listUsersClass.forEach((x) => {                   
                   
                    let item = $scope.usersInClassList.find((y) => y.username === x.username);
                    item.select = select;
                })
            }
            function vmFlagAssignedSelected(select, username) {

                if (select) {
                    $scope.arraySelectedAvailable.push({ select: select, username: username });
                } else {
                    $scope.arraySelectedAvailable = $scope.arraySelectedAvailable.filter((x) => x.username !== username);
                    if ($scope.arraySelectedAvailable.length === 0) {
                        $scope.selectavailable = false;
                    }
                }

            }
            function vmFlagAllAssignedSelected(selectavailable) {

                if (selectavailable) {
                    $scope.usersList.forEach((x) => {
                        $scope.arraySelectedAvailable.push({ select: true, username: x.username });
                    })
                } else {
                    $scope.arraySelectedAvailable = [];
                }
            }
            function vmAssignGemLevel(level) {

                let _students1 = $scope.usersInClassList.filter((x) => x.select === true);
                let _students =_students1.map((y) => y.username);

                FRAPI.setGemstoneLevel(level, _students).then((response) => {

                   $scope.isSeleted = false;

                    vmOpenPopup();
                    FRAPI.cleargetUsersFromCohort();
                    vmGetUsersInClass();  
                    vmFlagAllRemoveSelected(false, _students1);       
                    //restructure header table to the correct position
                     $('table.demo').floatThead('reflow');
                    if (!$scope.$$phase) {
                        $scope.$apply();
                    }        
                   
                })
            }
            function vmGetGemLevel(gemstone_level) {

                if (!gemstone_level) return;
        
                let gemstone =  gemstones.find((x) => x.idnumber === gemstone_level);
        
                return gemstone ? gemstone.name : null;                
            }
            function vmOpenPopup() {

                $scope.popup = !$scope.popup;
    
            }

            function vmStudentInSchool() {

                FRAPI.getUsersFromCohortTeacherMode(commonService.getCurrentSchool(), JSON.stringify(['student']), commonService.checkIndividualLicense(user.project), 1).then((response) => {
                  
                    $scope.usersList = angular.copy(response).filter((x)=> !$scope.usersInClassList.some((y) => x.username === y.username));
                    $scope.usersList.forEach((x) => { 
                        x.gemstone_level = x.properties ? x.properties.gemstone_level : null;
                        x.name_gemstone_level = x.properties ? vmGetGemLevel(x.properties.gemstone_level) : null;
                    });
                    vmFlagAllAssignedSelected(false);
                })
            }

            $scope.vmStudentInSchool = vmStudentInSchool;
            $scope.assignGemLevel = vmAssignGemLevel;
            $scope.OpenPopup = vmOpenPopup;
            $scope.assignStudentToClass = vmAssignStudentToClass;
            $scope.removeStudentFromClass = vmRemoveStudentFromClass;
            $scope.assignAllStudentToClass = vmAssignAllStudentToClass;
            $scope.teacher = $stateParams.teacher;
            $scope.school = $stateParams.school;
            $scope.type = $stateParams.type;
            $scope.openSendEmail = vmOpenSendEmail;
            $scope.selectAllAvailable = vmSelectAllAvailable;
            $scope.selectAllAssigned = vmSelectAllAssigned;
            $scope.removeSelected = vmRemoveSelected;
            $scope.assignselected = vmAssignSelected;
            $scope.vmFlagRemoveSelected = vmFlagRemoveSelected;
            $scope.vmFlagAllRemoveSelected = vmFlagAllRemoveSelected;
            $scope.vmFlagAssignedSelected = vmFlagAssignedSelected;
            $scope.vmFlagAllAssignedSelected = vmFlagAllAssignedSelected;
            $scope.vmGetGemLevel = vmGetGemLevel;
        })
})();