angular.module('LMWordpress', ['LMConfig'])

  .service('LMWordpress', function ($http, $q, LMConfig) {

    this.sendemail = function (users, subject, text, attachments) {

      var params = {
        'users': users,
        'subject': subject,
        'text': text,
        'attachments': attachments
      };

      return $http({

        method: 'post',
        url: LMConfig.wpapi.sendemail,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    }

    this.getHiringPeople = function(usernames, hired_status, school_id, cseid, dateBegin, dateEnd, test_result) {

      let params = {
        usernames: usernames, 
        hired_status: hired_status, 
        school_id: school_id, 
        cseid: cseid,
        datebegin: dateBegin,
        dateend: dateEnd,
        test_result: test_result
      }

      return $http.post(LMConfig.wpapi.getHiringPeople, JSON.stringify(params));
    }

    this.checkusersHired = function(school_number, usernames, csc_codes) {

      let params = {
        school_number: school_number, 
        usernames: usernames,
        csc_codes: csc_codes 
      }

      return $http.post(LMConfig.wpapi.checkusersHired, JSON.stringify(params));
    }
    this.checkCSCcode = function(username, csc_code) {

      return $http.get(LMConfig.wpapi.checkCSCcode, { params: {username: username, csc_code: csc_code } });
    }

    this.importHiringPeople = function(people) {

      return $http.put(LMConfig.wpapi.importHiringPeople, JSON.stringify(people));
    }

    this.updateHiringPeople = function(people) {

      let params = {
        username: people.username, 
        hired_status: people.status, 
        ext_id: people.cseid,
        first: people.firstname, 
        last: people.lastname
      }

      return $http.put(LMConfig.wpapi.updateHiringPeople, JSON.stringify(params));
    }


    this.getCourseIds = function (childCourseArr) {
      return $http.post(LMConfig.wpapi.getCourseIds, childCourseArr);
    }

    this.cart = function () {

      return $http({
        method: "get",
        url: LMConfig.wpapi.cart
      });
    };
    this.subscriptions = function () {

      return $http({
        method: "get",
        url: LMConfig.wpapi.subscriptions
      })
        .then(function (response) {

          return response.data;
        });
    };

    this.productnumber = function () {

      return $http({
        method: "get",
        url: LMConfig.wpapi.productnumber
      });
    };

    this.addTeacher = function (school, username, password, firstname, lastname, age, language, email) {

      var params = {
        'school': school,
        'username': username,
        'password': password,
        'firstname': firstname,
        'lastname': lastname,
        'age': age,
        'language': language,
        'email': email
      };

      return $http({

        method: 'post',
        url: LMConfig.wpapi.addTeacher,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });

    };

    this.addStudent = function (grade, physical_grade, school, username, password, firstname, lastname, age, language, student_sis_id) {

      var params = {
        'grade': grade,
        'physical_grade': physical_grade,
        'school': school,
        'username': username,
        'password': password,
        'firstname': firstname,
        'lastname': lastname,
        'age': age,
        'language': language,
        'student_sis_id': student_sis_id
      };

      return $http({

        method: 'post',
        url: LMConfig.wpapi.addStudent,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });

    };

    this.addStudentBulk = function (studentsObj) {

      const students = {
        addToClass: studentsObj.addToClass,
        students: studentsObj.people
      };

      return $http.post(LMConfig.wpapi.addStudentBulk, students);
    };

    this.updateTeacher = function (school, username, password, firstname, lastname, age, language, email) {

      var params = {
        'school': school,
        'username': username,
        'password': password,
        'firstname': firstname,
        'lastname': lastname,
        'age': age,
        'language': language,
        'email': email
      };

      return $http({

        method: 'post',
        url: LMConfig.wpapi.updateTeacher,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });

    };

    this.userresult = function (course, token, date_from, date_to) {

      return $http({
        method: "get",
        url: LMConfig.wpapi.userresult,
        params: {
          course: course,
          token: token,
          date_from: date_from,
          date_to: date_to
        }
      });
    };
    this.deleteproduct = function (product_variant_id) {

      var params = {
        'product_variant_id': product_variant_id
      };

      return $http({

        method: 'post',
        url: LMConfig.wpapi.deleteproduct,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    }

    this.checkSchoolForCertification = function () {
      return $http.get(LMConfig.wpapi.checkSchoolForCertification, { params: {} });
    }

    this.useraddcourses = function (token, courses) {

      var params = {
        'token': token,
        'courses': courses
      };

      return $http({

        method: 'post',
        url: LMConfig.wpapi.useraddcourses,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    }

    this.userremovecourses = function (token, courses) {

      var params = {
        'token': token,
        'courses': courses
      };

      return $http({

        method: 'post',
        url: LMConfig.wpapi.userremovecourses,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    }
    
    this.addcohortcourse = function (idnumber, courses) {

      var params = {
        'idnumber': idnumber,
        'courses': courses
      };

      return $http({

        method: 'post',
        url: LMConfig.wpapi.addcohortcourse,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    }
    this.removecohortcourse = function (idnumber, courses) {

      var params = {
        'idnumber': idnumber,
        'courses': courses
      };

      return $http({

        method: 'post',
        url: LMConfig.wpapi.removecohortcourse,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    }

    this.tasklist = function (project, cohort, teacher) {

      return $http({
        method: "get",
        url: LMConfig.wpapi.tasklist,
        params: {
          project: project,
          cohort: cohort,
          teacher: teacher
        }
      });
    };

    this.addtask = function (title, date_begin, date_end, description, school) {

      var params = {
        'title': title,
        'date_begin': date_begin,
        'date_end': date_end,
        'description': description,
        'school': school
      };

      return $http({

        method: 'post',
        url: LMConfig.wpapi.addtask,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    }

    // PHP avoided
    this.getcredentials = function () {

      let session = sessionStorage.getItem('session');
      let token = sessionStorage.getItem('token');
      let username = sessionStorage.getItem('username');

      session = session ? JSON.parse(session).value : null;
      token = token ? JSON.parse(token).value : null;
      username = username ? JSON.parse(username).value : null;

      return Promise.resolve({
          session_token: session,
          token,
          username
      });
    }

    this.getchildren = function (token) {

      return $http({
        method: "GET",
        url: LMConfig.wpapi.getchildren,
        params: {
          token: token
        }
      });
    }

    this.removeChild = function (token) {

      var params = {
        'token': token
      };

      return $http({

        method: 'POST',
        url: LMConfig.wpapi.removeChild,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    }
    this.addchild = function (username, password, firstname, lastname, age, language, email) {

      var params = {
        'username': username,
        'password': password,
        'firstname': firstname,
        'lastname': lastname,
        'age': age,
        'language': language,
        'email': email
      };

      return $http({

        method: 'POST',
        url: LMConfig.wpapi.addchild,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    }
    this.searchuser = function (searchtext) {

      return $http({
        method: "GET",
        url: LMConfig.wpapi.searchuser,
        params: {
          searchtext: searchtext
        }
      });
    }

    this.collectionmarket = function () {

      return $http({
        method: "GET",
        url: LMConfig.wpapi.collectionmarket
      })
        .then(x => x.data);
    }

    this.booksmarket = function () {

      return $http({
        method: "get",
        url: LMConfig.wpapi.booksmarket
      })
        .then(x => x.data);
    };

    this.testsmarket = function () {

      return $http({
        method: "get",
        url: LMConfig.wpapi.testsmarket
      })
        .then(x => x.data);
    };

    this.othermarket = function () {

      return $http({
        method: "get",
        url: LMConfig.wpapi.othermarket
      })
        .then(x => x.data);
    };

    this.productdetails = function (product_id) {

      var params = {
        'product_id': product_id
      };

      return $http({

        method: 'POST',
        url: LMConfig.wpapi.productdetails,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    };


    this.requestchild = function (username) {
      var params = {
        'username': username
      };

      return $http({

        method: 'POST',
        url: LMConfig.wpapi.requestchild,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    }
    this.requeststudent = function (username, school, clas) {
      var params = {
        'username': username,
        'school': school,
        'clas': clas
      };

      return $http({

        method: 'POST',
        url: LMConfig.wpapi.requeststudent,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    }
    this.requestteacher = function (username, school, clas) {
      var params = {
        'username': username,
        'school': school,
        'clas': clas
      };

      return $http({

        method: 'POST',
        url: LMConfig.wpapi.requestteacher,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    }


    this.translation = function (description_id, lang) {
      var params = {
        'description_id': description_id,
        'lang': lang
      };

      return $http({

        method: 'POST',
        url: LMConfig.wpapi.translation,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    }

    this.getlicensetypes = function () {

      return $http({
        method: 'GET',
        url: LMConfig.wpapi.getlicensetypes,
      })
        .then(x => x.data);
    }

    this.addproduct = function (product_variant_id, licensetype, datestart, quantity) {

      var params = {
        'product_variant_id': product_variant_id,
        'licensetype': licensetype,
        'datestart': datestart,
        'quantity': quantity
      };

      return $http({

        method: 'POST',
        url: LMConfig.wpapi.addproduct,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    }

    this.addlicense = function (purchaseOrderId) {
      var params = {
        'purchaseId': purchaseOrderId
      };

      return $http({
        method: 'post',
        url: LMConfig.wpapi.addlicense,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    }

    this.editproductproperties = function (json) {

      var params = {
        properties: json
      };

      return $http({

        method: 'POST',
        url: LMConfig.wpapi.editproductproperties,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    }

    this.edit_profile = function (username, password, email, firstname, lastname, age, lang, project, parent, gender, birthdate, parentFirstName, parentLastName, phone_number) {

      var params = {
        'username': username,
        'password': password,
        'email': email,
        'firstname': firstname,
        'lastname': lastname,
        'age': age,
        'lang': lang,
        'project': project,
        'parent': parent,
        'gender': gender,
        'birthdate': birthdate,
        'parentFirstName': parentFirstName,
        'parentLastName': parentLastName,
        'phone_number': phone_number
      };

      return $http({

        method: 'post',
        url: LMConfig.wpapi.edit_profile,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    };

    this.addorder = function () {

      return $http({
        method: 'post',
        url: LMConfig.wpapi.addorder
      });
    }

    this.getorders = function () {

      return $http({
        method: 'post',
        url: LMConfig.wpapi.getorders,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
      }).then(x => x.data);
      /* return $http({
           method: 'get',
           url: LMConfig.wpapi.getorders            
       })
       .then(x => x.data);*/
    }
    this.getordersstatus = function () {
      return $http({
        method: 'get',
        url: LMConfig.wpapi.getordersstatus
      })
        .then(x => x.data);
    }

    this.editlicensetype = function (params) {
      return $http({

        method: 'post',
        url: LMConfig.wpapi.editlicensetype,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: $.param(params)
      });
    }

    this.teacher = {
      courses: {
        cohortsAssignLevel: function (curriculum, level, username) {

          var url = LMConfig.wpapi.teacher.courses.cohortsLevel.format(curriculum, level, username);
          return $http({
            method: 'POST',
            url: url
          });
        },
        cohortsUnassignLevel: function (curriculum, level, username) {

          var url = LMConfig.wpapi.teacher.courses.cohortsLevel.format(curriculum, level, username);
          return $http({
            method: 'DELETE',
            url: url
          });
        }
      }
    }

    this.license = {
      getChildCourses: function (licenseCohort, childCourse) {
        return $http.get(LMConfig.wpapi.license.getChildCourses, { params: { licenseCohort: licenseCohort, childCourse: childCourse } });
      }
    }
    this.licenseteacher = {

      getlicensestudent: function (license, groupCohortId, courseId, childCourse) {

        return $http({
          method: 'GET',
          url: LMConfig.wpapi.licenseteacher.licensestudent,
          params: {
            license: license,
            groupCohortId: groupCohortId,
            courseId: courseId,
            childCourse: childCourse
          }
        });
      },
      getStudentLicenses: function (school, student) {
        return $http({
          method: 'GET',
          url: LMConfig.wpapi.licenseteacher.studentlicenses,
          params: {
            school: school,
            student: student
          }
        });
      },
      postlicensestudent: function (license, student, token, childCourse) {

        return $http({
          method: 'POST',
          url: LMConfig.wpapi.licenseteacher.licensestudent,
          params: {
            license: license,
            student: student,
            token: token,
            childCourse: childCourse
          }
        });
      },
      deletelicensestudent: function (license, student, token, childCourse) {

        return $http({
          method: 'DELETE',
          url: LMConfig.wpapi.licenseteacher.licensestudent,
          params: {
            license: license,
            student: student,
            token: token,
            childCourse: childCourse
          }
        });
      },
    }
    this.certificates = function (token) {
      return $http({

        method: 'GET',
        url: LMConfig.wpapi.certificates,
        params: {
          token: token
        }
      });
    }

    this.getCandidateCertificates = function (classIds, searchNames, dateBegin, dateEnd) {
      return $http.post(
        LMConfig.wpapi.candidate_certifcates,
        JSON.stringify({
          classIds: classIds,
          searchNames: searchNames,
          dateBegin: dateBegin,
          dateEnd: dateEnd
        })
      );
    }
    this.admin = {
      notification: {
        get: function (id) {
          return $http.get(LMConfig.wpapi.admin.notification.get, { params: { schoolId: id } });
        },
      }
    }
    this.projectadmin = {
 
      getLicense: function () {
        return $http({

          method: 'GET',
          url: LMConfig.wpapi.projectadmin.license
        });
      },
      createLicense: function (school_id_number, course_id_number, date_start, date_end, max_users, comment) {

        return $http({
          method: 'POST',
          url: LMConfig.wpapi.projectadmin.license,
          params: {
            school_id_number: school_id_number,
            course_id_number: course_id_number,
            date_start: date_start,
            date_end: date_end,
            max_users: max_users
          },
          data: comment
        });
      },
      deleteLicense: function (groupLicenseId) {

        return $http({
          method: 'DELETE',
          url: LMConfig.wpapi.projectadmin.license,
          params: {
            groupLicenseId: groupLicenseId
          }
        });
      },
      editLicense: function (group_license_id, max_users, comment, deleted) {

        return $http({
          method: 'POST',
          url: LMConfig.wpapi.projectadmin.editLicense,
          params: {
            group_license_id: group_license_id,
            max_users: max_users,
            deleted: deleted
          },
          data: comment
        });
      },
      getCourses: function () {
        return $http({

          method: 'GET',
          url: LMConfig.wpapi.projectadmin.courses
        });
      },

      requestschooladmin: function (idnumber, username) {
        return $http({
          method: 'POST',
          url: LMConfig.wpapi.projectadmin.requestschooladmin,
          params: {
            idnumber: idnumber,
            username: username
          }
        })
      },
      notification: {
        get: function (id, applyRoles) {
          return $http.get(LMConfig.wpapi.projectadmin.notification.get, { params: { id: id, applyRoles: applyRoles } });
        },
        add: function (notification) {
          return $http.post(LMConfig.wpapi.projectadmin.notification.add, JSON.stringify(notification));
        },
        update: function (notification) {
          return $http.post(LMConfig.wpapi.projectadmin.notification.update, JSON.stringify(notification));
        },
        delete: function (id) {
          return $http.delete(LMConfig.wpapi.projectadmin.notification.delete, { params: { id: id } });
        },
      },
      updateLicensePO: function (orderId, licenseId, quantity, comment) {
        return $http.post(LMConfig.wpapi.projectadmin.updateLicensePO, {
          orderId: orderId,
          licenseId: licenseId,
          quantity: quantity,
          comment: comment
        })
      },
      getLastLicensePO: function (licenseId) {
        return $http.get(LMConfig.wpapi.projectadmin.getLastLicensePO, {
          params: {
            licenseId: licenseId
          }
        });
      },
      getLicensePOs: function (licenseId, orderId) {
        return $http.get(LMConfig.wpapi.projectadmin.getLicensePOs, {
          params: {
            licenseId: licenseId,
            orderId: orderId
          }
        });
      },
      deleteLicensePO: function (orderId) {
        return $http.delete(LMConfig.wpapi.projectadmin.deleteLicensePO, {
          params: {
            orderId: orderId
          }
        })
      }
    }

    this.workstations = {
      checkWorkstationIdExist: function (id) {
        return $http({
          method: 'GET',
          url: LMConfig.wpapi.workstations.checkWorkstationIdExist,
          params: {
            id: id
          }
        });
      },
      updateWorkstations: function (testCenterId, testSite, workstationId, workstationData, clientFingerprint, specification, comments, isCertified) {
        if (workstationData) {
          workstationData.push({ key: 'specification', value: specification });
          workstationData.push({ key: 'comments', value: comments });
        }

        var workstation =
        {
          testCenterId: testCenterId,
          testSite: testSite,
          workstationId: workstationId,
          data: workstationData,
          fingerprint: clientFingerprint,
          isCertified: isCertified ? 1 : 0,
          isAdmin: isCertified != null,
          specification: specification,
          comments: comments
        };

        return $http.post(
          LMConfig.wpapi.workstations.updateWorkstations,
          JSON.stringify(workstation));
      },
      updateCertificationStatus: function (workstationIdArr, cert_status) {
        var body = {
          workstationIds: workstationIdArr,
          isCertified: cert_status
        }
        return $http.put(LMConfig.wpapi.workstations.updateCertificationStatus,
          JSON.stringify(body));
      },
      restoreRegistration: function (testCenterId, testSite, workstationId, workstationData, clientFingerprint, specification, comments) {
        if (workstationData) {
          workstationData.push({ key: 'specification', value: specification });
          workstationData.push({ key: 'comments', value: comments });
        }

        var workstation = {
          testCenterId: testCenterId,
          testSite: testSite,
          workstationId: workstationId,
          data: workstationData,
          fingerprint: clientFingerprint,
          specification: specification,
          comments: comments
        }
        return $http.post(
          LMConfig.wpapi.workstations.restoreRegistration,
          JSON.stringify(workstation));
      },
      getRegisteredNumbers: function (testCenter, testSite) {
        return $http({
          method: 'GET',
          url: LMConfig.wpapi.workstations.getRegisteredNumbers,
          params: {
            testCenter: testCenter,
            testSite: testSite
          }
        });
      },
      getFreeNumbers: function (testCenter, testSite) {
        return $http({
          method: 'GET',
          url: LMConfig.wpapi.workstations.getFreeNumbers,
          params: {
            testCenter: testCenter,
            testSite: testSite
          }
        });
      },
      canRestore: function (testCenterId, classes) {
        return $http.post(
          LMConfig.wpapi.workstations.canRestore,
          JSON.stringify(classes),
          {
            params: {
              testCenterId: testCenterId,
            }
          });
      },
      getList: function (schoolId, classId, workstationId) {
        return $http({
          method: 'GET',
          url: LMConfig.wpapi.workstations.getList,
          params: {
            id: workstationId,
            classId: classId,
            schoolId: schoolId
          }
        });
      },
      isRegistered: function (fingerprint) {
        return $http({
          method: 'GET',
          url: LMConfig.wpapi.workstations.isRegistered,
          params: {
            fingerprint: fingerprint
          }
        });
      },
      deleteWorkstation: function (workstationId) {
        return $http({
          method: 'DELETE',
          url: LMConfig.wpapi.workstations.delete,
          params: {
            workstationId: workstationId
          }
        });
      }
    }
  });