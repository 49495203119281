angular.module('languabooksApp')

    .service('commonService', function (LMConfig, LMProjectConfig, $translate, $q, uuid) {
        this.$project = null;

        this.languages = function (project) {

            return LMProjectConfig.properties[project].localization;
        };
        this.languaCards = function (project) {

            return LMProjectConfig.properties[project].languaCards;
        }
        this.agent_key = function (project) {

            return LMProjectConfig.properties[project].agent_key;
        }
        this.logo = function (project, mode) {
            var translId;
            switch (mode) {
                case 'teacher':
                    translId = 'teacher.logo';
                    break;
                case 'admin':
                    translId = 'admin.logo';
                    break;
                case 'parent':
                    translId = 'parent.logo';
                    break;
                case 'projectadmin':
                    translId = 'projectAdmin.logo';
                    break;
            }

            if (translId) {
                var text = $translate.instant(translId);
                if (text) {
                    return {
                        logo: text,
                        tm: false
                    }
                }
            }

            return {
                logo: LMProjectConfig.properties[project].logo,
                tm: project != 'lela' && project != 'lelcurr' && project != 'tkc'
            }
        }
        this.tabs = function (project) {
            return LMProjectConfig.properties[project].tabs || null;
        }
        this.reports = function (project) {
            if (typeof LMProjectConfig.properties[project].reports == 'boolean')
                return LMProjectConfig.properties[project].reports;

            return LMProjectConfig.properties[project].reports || null;
        }

        this.certificatesTab = function (project) {
            return LMProjectConfig.properties[project].certificatesTab || null;
        }

        this.hiringTab = function (project) {
            return LMProjectConfig.properties[project].hiringTab || null;
        }

        this.teacherReports = function (project) {
            return LMProjectConfig.properties[project].teacherReports || null;
        }
        this.btnHide = function (project) {
            return LMProjectConfig.properties[project].btnHide || null;
        }
        this.notifications = function (project) {

            return LMProjectConfig.properties[project].notifications;
        }
        this.fingerPrintMandatory = function (project) {
            return LMProjectConfig.properties[project].fingerprintMandatory;
        }

        this.checkIndividualLicense = function (project) {
            return LMProjectConfig.properties[project].checkIndividualLicense;
        }

        this.teacherMode = function (project) {

            return LMProjectConfig.properties[project].teacherMode || null;
        }

        this.workstations = function (project, mode) {
            return LMProjectConfig.properties[project].workstations
                && LMProjectConfig.properties[project].workstations[mode]
                || false;
        }
        this.voiceModels = function (project) {
            return LMProjectConfig.properties[project].voiceModels || ['adult', 'kid'];
        }
        this.showAssignedLicensesWithUTR = function (project) {
            return !!LMProjectConfig.properties[project].showAssignedLicensesWithUTR;
        }
        this.voices = function () {
            return [{ key: 'kid', title: $translate.instant('common.user_menu.kid') },
            { key: 'kid_nnl', title: $translate.instant('common.user_menu.kid_nnl') },
            { key: 'adult', title: $translate.instant('common.user_menu.adult') },
            { key: 'adult_nnl', title: $translate.instant('common.user_menu.adult_nnl') },
            { key: 'adult_ph', title: $translate.instant('common.user_menu.adult_ph') },
            { key: 'adult_uk', title: $translate.instant('common.user_menu.adult_uk') }]
        }
        this.products = function() {
            return [{ key: 'phonicsrev', name: 'PhonicsRev' }, { key: 'englishrev', name: 'EnglishRev' }]
        }

        this.listReadingGrades = function() {
            let _grades = [{ key: 'prekindergarten', name: 'prekindergarten'},
                            { key: 'kindergarten', name: 'kindergarten'},
                            { key: 'grade1', name: 'grade1'},
                            { key: 'grade2', name: 'grade2'},
                            { key: 'grade3', name: 'grade3'},
                            { key: 'grade4', name: 'grade4'}, 
                            { key: 'grade5', name: 'grade5'},
                            { key: 'grade6', name: 'grade6'},
                            { key: 'grade7', name: 'grade7'},
                            { key: 'grade8', name: 'grade8'},
                            { key: 'grade9', name: 'grade9'},
                            { key: 'grade10', name: 'grade10'},
                            { key: 'grade11', name: 'grade11'},
                            { key: 'grade12', name: 'grade12'} ]
            return _grades;
        }
        this.listGrades = function() {
            let _grades = [{ key: 'prekindergarten', name: 'prekindergarten'},
                            { key: 'kindergarten', name: 'kindergarten'},
                            { key: 'grade1', name: 'grade1'},
                            { key: 'grade2', name: 'grade2'},
                            { key: 'grade3', name: 'grade3'},
                            { key: 'grade4', name: 'grade4'}, 
                            { key: 'grade5', name: 'grade5'},
                            { key: 'grade6', name: 'grade6'},
                            { key: 'grade7', name: 'grade7'},
                            { key: 'grade8', name: 'grade8'},
                            { key: 'grade9', name: 'grade9'},
                            { key: 'grade10', name: 'grade10'},
                            { key: 'grade11', name: 'grade11'},
                            { key: 'grade12', name: 'grade12'} ]
            return _grades;
        }
        this.searchGroup = function (project) {

            let group = LMProjectConfig.properties[project].searchGroup;
            return group;
        }
        this.searchKeysTopic = function (group_id_topic) {

            let keys = [
                { group_id_topic: 'fr', keys: ['sel', 'animals', 'family', 'sport', 'fantasy', 'mystery']},
                { group_id_topic: 'er', keys: ['people', 'food', 'money', 'shelter', 'work', 'school', 'feelings', 'country', 'ask', 'arts', 'health', 'technology', 'energy', 'ecosystems', 'news', 'american_stories', 'grammar', 'words']}
            ]

            return keys.find((x) => x.group_id_topic === group_id_topic).keys;
        }
        this.searchKeysGenre = function (group_id_genre) {

            let keys = [
                { group_id_genre: 'school', keys: ['fiction', 'non_fiction', 'flash_cards', 'comics', 
                'picture_book', 'speakers_theatre'] }
            ]

            return keys.find((x) => x.group_id_genre === group_id_genre).keys;
        }

        this.checkUsername = function (str) {

            let reg = /^[A-Za-z0-9@_.-]*$/;

            return reg.test(str);
        }

        this.convertLocalDateToServerTime = function (date) {

            var momentDate = moment(date);
            var utcOffset = momentDate.utcOffset();

            utcOffset /= 60;
            utcOffset += LMConfig.server_utc_offset;

            var serverTimestamp = momentDate.add(utcOffset, 'hours').format('X');

            return serverTimestamp;
        }

        this.convertServerTimeToLocalDate = function (timestamp) {

            var utcOffset = moment().utcOffset();

            utcOffset /= 60;
            utcOffset += LMConfig.server_utc_offset;

            var localDate = moment(timestamp, 'X').add((-1) * utcOffset, 'hours').toDate();

            return localDate;
        }

        this.convertLocalDateToUTCUnix = function(date) {
            let unixDate = Math.floor(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) / 1000);
            
            return unixDate;
        }

        this.checkDate = function (begin, end, isInterval) {

            var errors = {
                errorBegin: null,
                errorEnd: null
            }

            var unixbegin = begin ? moment(begin).format('X') : null;
            var unixend = end ? moment(end).format('X') : null;

            if (unixbegin != null && unixend != null) {

                if (unixbegin > unixend) {
                    errors.errorBegin = $translate.instant('teacher.tasks.tasks.errorDate');
                }
            }

            return errors;
        }

        this.setLanguage = function ($translateProvider, project, language) {

            // get the language settings
            // based on project and user defiend language
            let settings = this.languages(project).find((x) => x.key == language) || { key: 'en', title: 'English', file: 'en' };

            let url = '/assets/ng1/localization/locale-{0}.json'.format(settings.file);
            let urlDefault = '/assets/ng1/localization/locale-en.json';

            // read the required language
            return $.ajax(url, { dataType: "json" })
                .then(function (localization) {

                    // if localization is not the default one
                    // than load default localization
                    // and extend missing values
                    if (url != urlDefault) {

                        return $.ajax(urlDefault, { dataType: "json" })
                            .then(function (defaultLocalization) {

                                vmExtendLocalization(localization, defaultLocalization);
                                vmApplyLocalization(language, localization);
                            });

                    } else {

                        // localization is default
                        // so just apply it
                        vmApplyLocalization(language, localization);
                    }
                });

            function vmExtendLocalization(orig, def) {

                // for each key in default localization
                // check if same key exists in original
                // if not than create new key
                Object.keys(def).forEach(function (key) {

                    if (!orig.hasOwnProperty(key)) {

                        if (typeof def[key] == 'object') {

                            // create missing property
                            orig[key] = {};

                        } else if (typeof def[key] == 'string') {

                            // update missing property text
                            orig[key] = def[key]
                        }
                    }

                    // if current key is an object
                    // than start recursive processing
                    if (typeof def[key] == 'object') {

                        vmExtendLocalization(orig[key], def[key]);
                    }

                });
            }

            function vmApplyLocalization(language, localization) {

                $translateProvider.translations(language, localization);
                $translateProvider.useSanitizeValueStrategy('sanitizeParameters');
            }

        };

        this.getClientFingerprint = function (project) {
            var deferred = $q.defer();
            var that = this;

            if (window.requestIdleCallback) {
                requestIdleCallback(__getFingerPrint);
            } else {
                setTimeout(__getFingerPrint, 1000);
            }

            return deferred.promise;

            function __getFingerPrint() {
                if (typeof Fingerprint2 == 'undefined') {
                    if (that.fingerPrintMandatory(project || that.$project)) {
                        var fingerprntMandatoryMsg = $translate.instant('common.msg.cantgenerateFingerprintMandatory');
                        alert(fingerprntMandatoryMsg);
                        deferred.reject(fingerprntMandatoryMsg);
                        return;
                    } else {
                        var fingerprntNotMandatoryMsg = $translate.instant('common.msg.cantgenerateFingerprintNotMandatory');
                        // alert(fingerprntNotMandatoryMsg);
                        deferred.resolve({ value: null, msg: fingerprntNotMandatoryMsg });
                        return;
                    }
                }
                Fingerprint2.get({
                    preprocessor: function (key, value) {
                        // remove any versions from user agent string
                        if (key == "userAgent") {
                            var parser = new UAParser(value); // https://github.com/faisalman/ua-parser-js
                            var result = parser.getResult();
                            var userAgentMinusVersion = result.os.name + ' ' +
                                result.cpu.architecture + ' ' +
                                // result.engine.name + ' ' +
                                result.browser.name;
                            return userAgentMinusVersion
                        }
                        return value
                    },
                    excludes: {
                        'userAgent': false,
                        'webdriver': true,
                        'language': true,
                        'colorDepth': true,
                        'deviceMemory': false,
                        'pixelRatio': true,
                        'hardwareConcurrency': false,
                        'screenResolution': true,
                        'availableScreenResolution': true,
                        'timezoneOffset': true,
                        'timezone': true,
                        'sessionStorage': true,
                        'localStorage': true,
                        'indexedDb': true,
                        'addBehavior': true,
                        'openDatabase': true,
                        'cpuClass': true,
                        'platform': true,
                        'doNotTrack': true,
                        'plugins': true,
                        'canvas': true,
                        'webgl': true,
                        'webglVendorAndRenderer': true,
                        'adBlock': true,
                        'hasLiedLanguages': true,
                        'hasLiedResolution': true,
                        'hasLiedOs': true,
                        'hasLiedBrowser': true,
                        'touchSupport': true,
                        'fonts': true,
                        'fontsFlash': true,
                        'audio': true,
                        'enumerateDevices': true
                    },
                    extraComponents: [
                        {
                            key: 'client_guid', getData: function (done, options) {
                                var clntGuid = localStorage.getItem('client_guid');
                                if (!clntGuid) {
                                    clntGuid = uuid.newguid();
                                    localStorage.setItem('client_guid', clntGuid);
                                }
                                done(clntGuid);
                            }
                        }
                    ]

                }, function (components) {
                    // console.log(components) // an array of components: {key: ..., value: ...}
                    var values = components.map(function (component) { return component.value })
                    var murmur = Fingerprint2.x64hash128(values.join(''), 31)
                    // console.log('murmur: ' + murmur);
                    deferred.resolve({
                        components: components,
                        value: murmur
                    });
                });
            }
        };

        this.resetClientFingerprint = function () {
            localStorage.removeItem('client_guid');
        };

        this.setCurrentSchool = function (idnumber) {
            sessionStorage.setItem('current_school_idnumber', idnumber);
        };

        this.getCurrentSchool = function () {
            return sessionStorage.getItem('current_school_idnumber');
        }
        this.setCurrentDistrict = function (idnumber) {
            sessionStorage.setItem('current_district_idnumber', idnumber);
        };

        this.getCurrentDistrict = function () {
            return sessionStorage.getItem('current_district_idnumber');
        }
        this.getLocaleDate = function(date, isTimeZone) {

            let options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            if (!isTimeZone) {
                options.timeZone =  'UTC';
            }

            let _date = new Date(date * 1000);

            return  _date.toLocaleDateString('en-US', options);
        }
        this.removeTime = function(date) {

            return new Date(
                new Date(date * 1000).getFullYear(),
                new Date(date * 1000).getMonth(),
                new Date(date * 1000).getDate()
              ) / 1000;
        }
        this.changesource = function (key) {

            let changeSource = [
                { key: 'DA', name: 'Default assignment'},
                { key: 'MA', name: 'Manually by Teacher'},
                { key: 'GF', name: 'Gem Level Finder' },
                { key: 'PM', name: 'Progress Monitoring'},
                { key: 'CT', name: 'Content Tool'}
            ]

            return changeSource.find((x) => x.key === key).name;
        }
        
        this.help = function(project) {
            return LMProjectConfig.properties[project].help_link;
        }
        this.vmAddZero = function(value) {
            if (value < 10) {
                return '0' + value;
            } else {
                return value;
            }
        }
        this.vmsGetClassName = function(class_item) {
            return class_item.teacher_firstname ? `${class_item.name} [${class_item.teacher_firstname} ${class_item.teacher_lastname}]` : class_item.name;
        }
    })
    .factory('schoolTypeService', function ($rootScope, FRAPI) {

         function vmGetCleverAuth() {
          
            return  FRAPI.auth().then((response) => {
 
               return  $rootScope.cleverSession = response.data.access_token;
             })
         }

         var promise = new Promise((resolve, reject) => {
            resolve(0);     
          });

         function vmRootGetCleverAuth() {

            return promise.then((response) => {
                return $rootScope.cleverSession;
            });
         }

        return {
            types: [{ key: 'Clever', name: 'Clever' }, { key: 'Languametrics', name: 'Languametrics'}, { key: 'Classlink', name: 'ClassLink'}],

            cleverSession: function() {
    
                return $rootScope.cleverSession ? vmRootGetCleverAuth() :  vmGetCleverAuth();
            } 
        }
    

    })
    .factory('importservice', function(commonService, $q, $translate, FRAPI) {
        return {
            vmCheckAge: function(item, user) {
            
                let age = item.age ? item.age : item.voice_model;
                if (age === 'child') { age = 'kid'; }
                let isAge = commonService.voiceModels(user.project).some((x) => x === age);
                if (!isAge) {
                  item.invalid = true;
                  item.state = $translate.instant('common.forms.errorage');
                }
            },
            vmCheckLang: function(item, user) {
                let languages = commonService.languages(user.project);
                let islang = languages.some((x) => x.key === item.lang);
                if (!islang) {
                    item.invalid = true;
                    item.state = $translate.instant('common.forms.errorlang');
                }
                return item.lang;
            },
      
            vmGeneratePassword: function(item) {
                item.password = Math.random().toString(36).slice(-8);
                item.download = true;
            },
      
            vmGenerateUsername: function(item) {
                var deferred = $q.defer();
                let login = Math.random().toString(36).slice(-10);
        
                FRAPI.userexists(login).then(function (res) {
                  if (res.data) {
                    vmGenerateUsername(item).then(function (item) {
                      deferred.resolve(item);
                    });
                  } else {
                    item.username = login;
                    item.download = true;
        
                    deferred.resolve(item);
                  }
                });
        
                return deferred.promise;
            },
      
            onCheckUniqueUser: function(valueArr, datas) {
                let _isInArray = false;
        
                valueArr.forEach(function (item, idx) {
        
                  let isInArray = valueArr.indexOf(item) != idx;
        
                  if (isInArray) {
        
                    let _array = datas.filter((x) => x.username === item);
        
                    _array.forEach((y) => {
        
                      y.invalid = true;
                      y.state = $translate.instant('common.forms.errorusernameduplicated');
        
                    });
                    _isInArray = true;
                  }
                });
        
                return _isInArray;
            },
      
            vmCheckUsername: function(item) {
      
                var deferred = $q.defer();
                if (!commonService.checkUsername(item.username)) {
                item.invalid = true;
                item.state = $translate.instant('common.forms.errorusername');
                deferred.resolve(item);
                }
                else {
      
                item.checkUsernameOnServer = true;
                deferred.resolve(item);
                }
      
                return deferred.promise;
            },
            vmCheckPassword: function(item) {
                if (item.password.toString().length < 8) {
                    item.invalid = true;
                    item.state = $translate.instant('common.forms.errorpassword');
                  }
            },
            vmAgeEmpty: function(item) {
                item.invalid = true;
                item.state = $translate.instant('common.forms.errorageempty');
            },
      
            vmCheckName: function(item, name) {
      
                item.invalid = true;
      
                if (name === 'firstname') {
                    item.state = $translate.instant('common.forms.errorfirstname');
                }
                if (name === 'lastname') {
                    item.state = $translate.instant('common.forms.errorlastname');
                }
            },
        
            vmCheckEmail: function(item) {
      
                if (!item.email) {
      
                    item.invalid = true;
                    item.state = $translate.instant('common.forms.erroremail');
                } else {
      
                    var reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      
                    if (!reg.test(item.email)) {
                    item.invalid = true;
                    item.state = $translate.instant('common.forms.erroremailinvalid');
                    }
                }
            },
            
            vmCheckChangePassword: function(item) {
                item.payment_status = (parseInt(item.change_password) === 1) ? 'card' : 'paid';
            }

        }
    })

    //directive for fixed header table when is scroll
    .directive('thead', function () {
        return {
            restrict: 'A',
            scope: {
                ngModel: '='
            },
            link: function (scope, elem) {
                elem.floatThead('reflow');
                elem.floatThead({
                    bottom: 45,
                    copyTableClass: true,
                    scrollContainer: function ($table) {
                        return $table.closest('.scroll');
                    },
                    position: 'absolute'
                });
                elem.floatThead('reflow');
            }
        };
    })
    .directive('whenScrolled', function () {
        return {


            restrict: 'A',
            link: function (scope, elm, attr) {
                var raw = elm[0];

                elm.bind('scroll', function () {
                    if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight) {
                        scope.$apply(attr.whenScrolled);
                    }
                });
            }
        };
    })

    // read local file
    .directive("ngFileRead", [function () {
        return {
            scope: {
                callback: '&ngFileLoaded',
                ngFileReadAs: '@'
            },
            link: function (scope, element, attributes) {

                element.bind("change", function (changeEvent) {

                    var files = changeEvent.target.files;
                    for (var i = 0; i < files.length; i++)
                        vmReadFile(files[i]);

                    element.val('');
                    element.attr('value', '');
                });

                function vmReadFile(file) {

                    var reader = new FileReader();
                    reader.onload = function (loadEvent) {
                        scope.callback({ file: loadEvent.target.result, name: file.name, type: file.type });
                    };

                    reader[scope.ngFileReadAs](file);
                };
            }
        }
    }])
    .directive("lbPassword", [function () {
        return {
            restrict: 'A',
            link: function link(scope, element, attrs, ngModel, transcludeFn) {
                element.addClass('lb-password');
                var input = element[0];
                var style = window.getComputedStyle(input);
                if (style.hasOwnProperty('webkitTextSecurity')) {
                    //do nothing
                } else {
                    attrs.$set('type', 'password');
                    attrs.$set('autocomplete', 'new-password');
                }
            }
        }
    }])
    .directive('validateNoSpace', [function () {
        return {
            require: '?ngModel',
            link: function (scope, elm, attrs, ctrl) {
                ctrl.$validators.noSpace = function (modelValue) {
                    return ctrl.$isEmpty(modelValue) || ! /\s/.test(modelValue);
                };
            }
        };
    }])
    .directive('validateNameChars', [function () {
        return {
            require: '?ngModel',
            link: function (scope, elm, attrs, ctrl) {
                ctrl.$validators.nameChars = function (modelValue) {
                    return ctrl.$isEmpty(modelValue) || /^[A-Za-z0-9@_.-]*$/.test(modelValue);
                };
            }
        };
    }])

