// string format
String.prototype.format = function () {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != 'undefined'
      ? args[number]
      : match
        ;
    });
};

// get first item from array
// by key/value
Array.prototype.first = function (key, value) {

    return this.filter(function (el) {
        return el[key] == value;
    })[0];
};

// returns element which satisfies predicate condition
if (!Array.prototype.find) {
    Array.prototype.find = function (predicate) {
        if (this == null) {
            throw new TypeError('Array.prototype.find called on null or undefined');
        }
        if (typeof predicate !== 'function') {
            throw new TypeError('predicate must be a function');
        }
        var list = Object(this);
        var length = list.length >>> 0;
        var thisArg = arguments[1];
        var value;

        for (var i = 0; i < length; i++) {
            value = list[i];
            if (predicate.call(thisArg, value, i, list)) {
                return value;
            }
        }
        return undefined;
    };
};

// returns index of element which satisfies predicate condition
if (!Array.prototype.findIndex) {
    Array.prototype.findIndex = function (predicate) {
        if (this == null) {
            throw new TypeError('Array.prototype.findIndex called on null or undefined');
        }
        if (typeof predicate !== 'function') {
            throw new TypeError('predicate must be a function');
        }
        var list = Object(this);
        var length = list.length >>> 0;
        var thisArg = arguments[1];
        var value;

        for (var i = 0; i < length; i++) {
            value = list[i];
            if (predicate.call(thisArg, value, i, list)) {
                return i;
            }
        }
        return -1;
    };
};

Array.prototype.contains = function (item) {

    if (this.indexOf(item) > -1)
        return true;
    else
        return false;
};

// get unique elements from array
Array.prototype.unique = function (selector) {

    var u = {}, a = [];
    for (var i = 0, l = this.length; i < l; ++i) {

        var key = selector ? selector(this[i]) : this[i];

        if (u.hasOwnProperty(key))
            continue;

        a.push(this[i]);
        u[key] = 1;
    };
    return a;
};

function isEmptyObject(obj) {
    for (var i in obj) {
        return false;
    };
    return true;
};

Date.prototype.getWeek = function () {
    var onejan = new Date(this.getFullYear(), 0, 1);
    return Math.ceil((((this - onejan) / 86400000) + onejan.getDay() + 1) / 7);
};

function vmGetChunks(arr, chunkSize) {
    var groups = [], i;
    for (i = 0; i < arr.length; i += chunkSize) {
        groups.push(arr.slice(i, i + chunkSize));
    }
    return groups;
}

function vmFixedHeader() {

    $(".headerBlock").css("position", "fixed");
    $("<div class='insteadHeader'></div>").insertAfter(".headerBlock");
    $(".insteadHeader").css("height", "52");

    /*var mainWindow = $('.mainWindow'), header = mainWindow.find('.headerBlock'),
        pseudoHeader = $('<div />'), scrollTop,
        is_iPad = navigator.userAgent.match(/iPad/i) != null;


    mainWindow.on('scroll', function () {

        if (!parseInt(pseudoHeader.css('height')))
            pseudoHeader.css('height', header[0].offsetHeight + 'px');

        scrollTop = mainWindow[0].scrollTop;

        if (scrollTop > (header[0].offsetHeight) * 2) {
                               
            header.css('top', (-1) * header[0].offsetHeight + 'px');

            setTimeout(function () {
                header.addClass('fixed');
                mainWindow.find('.headerBlock').after(pseudoHeader);
            }, 0);
                
        }
        else {

            vmClearScroll();
                             
        }
    })


    $(window).on('hashchange', function () {
        vmClearScroll();
        mainWindow[0].scrollTop = 0;

    })

    function vmClearScroll() {

        header.css('top', '0px');
        header.removeClass('fixed');
        pseudoHeader.detach();
    }    */
}

function vmHandleNavbarMenu() {

    var navMain = $("#navbar");

    navMain.on("click", "a", null, function (e) {

        if (!$(e.target).hasClass('profile'))
            navMain.collapse('hide');        

    });
}

// add full screen request / exit functions
Element.prototype.requestFullscreen = Element.prototype.requestFullscreen || Element.prototype.mozRequestFullScreen || Element.prototype.webkitRequestFullscreen;
document.exitFullScreen = document.exitFullscreen || document.mozCancelFullScreen || document.webkitExitFullscreen;