(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.admin.school.reports', {
                    url: '/reports?clas&student',
                    controller: 'adminReportsCtrl',
                    templateUrl: '/assets/ng1/admin/reports/index.html',
                    resolve: {
                        newNotifications: function(FRAPI, credentials){
                                  
                            return FRAPI.getNewNotifications().then((newNotifications) => {
                               
                               return newNotifications;
                            })
                        }, 
                    }, 
                    deepStateRedirect: {
                        default: { state: 'app.admin.school.reports.creport' },
                        params: true
                    }
                })
                .state('app.admin.school.reports.notifications', {
                    url: '/notifications',
                    controller: 'districtNotificationsCtrl',
                    templateUrl: '/assets/ng1/districtadmin/notifications/notifications.html'
                })
        }])

        .controller('adminReportsCtrl', function ($scope, $state, newNotifications, notifications, FRAPI) {
     
           $scope.$emit('adminTopTabChange', 'reports');

           $scope.unreadNotifiCount = newNotifications.length;
           $scope.notifications = notifications;
   
           $scope.$on('unreadNotifiCount', function (event, data) {
               $scope.unreadNotifiCount = data;
               FRAPI.clearNewNotifications(); 

               if (!$scope.$$phase) {
                   $scope.$apply();
               }
           });
           if ($scope.unreadNotifiCount && $scope.unreadNotifiCount > 0){
                $state.go('app.admin.school.reports.notifications');
            } 
        })
})();