(function () {
    'use strict';

    angular.module('languabooksApp')

    .directive('vmsHelperPopup', function ($http, $location) {

        function vmContoller($scope) {

            $scope.datas = {};
            
            $scope.name = $scope.user.firstname + ' ' + $scope.user.lastname;

            function closeWindowFunction() {
                $scope.help = false;
                
            }
           
            $scope.closeWindowFunction = closeWindowFunction;

        };

        return {
            scope: {
                help: '=help',
                user: '=user',
                absurl: '=absurl'             
            },
            controller: vmContoller,
            templateUrl: '/assets/ng1/helpWindow/helpWindow.html'
        };
    })

})();