angular.module('languabooksApp')

    .service('FRAPI', function ($http, $q, LMConfig, $rootScope) {

    let session;
    let listDistrictAdmins = [];
    let listDistrictAdminsByDistrict = [];
    let listDistrictsByUser = [];
    let listClassesClever = [];
    let listUsersClever = [];
    let listUsersClasslink = [];
    let listTeachersCleverByDistrict = [];
    let listTeachersClasslinkByDistrict = [];
    let listClassesClassLink = [];
    let listUsersByCohort = [];
    let listNewNotifications = [];
    let listUserNotifications = [];
    let IsCall = false;

    this.setSession = function (_session) {
        session = _session;
    }

    this.auth = function () {

        var url = LMConfig.frapi.auth;

        return $http({
            method: 'GET',
            url: url,
            headers: {
                'x-vms-session': session
            }
        });
    };
    this.loadSchool = function (cleverSession, id) {

        var url = LMConfig.frapi.loadSchool.format(id);

        return $http({
            method: 'GET',
            url: url,
            headers: {
                'x-vms-session': session,
                'clvr-access-token': cleverSession
            }
        });
    }
    this.clearlistClassesClever = function() {
        listClassesClever = [];
    }
    this.getclasses = function (cleverSession, id) {

        let _listUsers = listClassesClever.find((x) => x.key === id);

        if (_listUsers) { return Promise.resolve(_listUsers.data) };

        var url = LMConfig.frapi.getclasses.format(id);

        let request = $http({
            method: 'GET',
            url: url,
            headers: {
                'x-vms-session': session,
                'clvr-access-token': cleverSession
            }
        });
        return request.then((response) => {

            listClassesClever.push({
                key: id,
                data: response.data
            }); 

            return response.data;
        })
    }
    this.clearUsersClever = function(){
        listUsersClever = [];
    }
    this.getUsersClever = function(idnumber, districtNumber, role) {

        let _listUsers = listUsersClever.find((x) => x.key === idnumber + '_' + districtNumber + '_' + role);

        if (_listUsers) { return Promise.resolve(_listUsers.data) };

        var url = LMConfig.frapi.getUsersClever.format(idnumber, districtNumber, role);

        let request = $http({
            method: 'GET',
            url: url,
            headers: {
                'x-vms-session': session
            }
        });
        return request.then((response) => {

            listUsersClever.push({
                key: idnumber + '_' + districtNumber + '_' + role,
                data: response.data
            }); 

            return response.data;
        })
    }
    this.clearUsersClasslink = function(){
        listUsersClasslink = [];
    }
    this.getUsersClasslink = function(idnumber, role) {

        let _listUsers = listUsersClasslink.find((x) => x.key === idnumber + '_' + role);

        if (_listUsers) { return Promise.resolve(_listUsers.data) };

        var url = LMConfig.frapi.getUsersClasslink.format(idnumber, role);

        let request = $http({
            method: 'GET',
            url: url,
            headers: {
                'x-vms-session': session
            }
        });
        return request.then((response) => {

            listUsersClasslink.push({
                key: idnumber + '_' + role,
                data: response.data
            }); 

            return response.data;
        })
    }
    this.assignUserClasslink = function(sourceIds, role, district, schoolNumber, schoolNumberClasslink) {

        var url = LMConfig.frapi.assignUserClasslink.format(role);
        var body = {
            "userSourcedIdArr": sourceIds,
            "districtNumber": district,
            "schoolNumber": schoolNumber,
            "schoolNumberClasslink": schoolNumberClasslink,
            "project": "fr"
        };

        return $http({
            method: 'POST',
            url: url,
            headers: {
                'x-vms-session': session
            },
            data: body
        });
    }
    this.assignUserClever = function(users, role, district, schoolNumber, schoolNumberClever) {
        
        var url = LMConfig.frapi.assignUserClever.format(role);
        var body = {
            "usernameArr": users,
            "districtNumber": district,
            "schoolNumber": schoolNumber,
            "schoolNumberClever": schoolNumberClever,
            "project": "fr"
        };

        return $http({
            method: 'POST',
            url: url,
            headers: {
                'x-vms-session': session
            },
            data: body
        });
    }
    this.cleverSchools = function(districtNumber) {

        var url = LMConfig.frapi.cleverSchools.format(districtNumber);

        let request = $http({
            method: 'GET',
            url: url,
            headers: {
                'x-vms-session': session
            }
        });
        return request.then((response) => {

            response.data.sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            });

            return response.data;
        })
    }
    this.getTeachersClasslinkByDistrict = function(districtNumber) {

        let _listUsers = listTeachersClasslinkByDistrict.find((x) => x.key === districtNumber);

        if (_listUsers) { return Promise.resolve(_listUsers.data) };

        var url = LMConfig.frapi.getTeachersClasslinkByDistrict.format(districtNumber);

        let request = $http({
            method: 'GET',
            url: url,
            headers: {
                'x-vms-session': session
            }
        });
        return request.then((response) => {

            listTeachersClasslinkByDistrict.push({
                key: districtNumber,
                data: response.data
            }); 

            return response.data;
        })
    }
    this.clearTeachersClasslinkByDistrict = function() {
        listTeachersClasslinkByDistrict = [];
    }
    this.clearTeachersCleverByDistrict = function() {
        listTeachersCleverByDistrict = [];
    }
    this.getTeachersCleverByDistrict = function(districtNumber) {

        let _listUsers = listTeachersCleverByDistrict.find((x) => x.key === districtNumber);

        if (_listUsers) { return Promise.resolve(_listUsers.data) };

        var url = LMConfig.frapi.getTeachersCleverByDistrict.format(districtNumber);

        let request = $http({
            method: 'GET',
            url: url,
            headers: {
                'x-vms-session': session
            }
        });
        return request.then((response) => {

            listTeachersCleverByDistrict.push({
                key: districtNumber,
                data: response.data
            }); 

            return response.data;
        })
    }
    this.createSchool = function (cleverSession, id, official_partner, state) {

        var url = LMConfig.frapi.createSchool.format(id, 'fr');
        var body = {
            'official_partner': official_partner,
            'state': state
        };

        return $http({
            method: 'POST',
            url: url,
            headers: {
                'x-vms-session': session,
                'clvr-access-token': cleverSession
            },
            data: body
        });
    }
    this.syncadmins = function (cleverSession, district, id) {

        var url = LMConfig.frapi.syncadmins.format(id, district, 'fr');

        return $http({
            method: 'POST',
            url: url,
            headers: {
                'x-vms-session': session,
                'clvr-access-token': cleverSession
            }
        });
    }
    this.syncclasses = function (cleverSession, id, class_ids) {

        var url = LMConfig.frapi.syncclasses.format(id, 'fr');
        var body = {
            'class_ids': class_ids,
        };

        return $http({
            method: 'POST',
            url: url,
            headers: {
                'x-vms-session': session,
                'clvr-access-token': cleverSession
            },
            data: body
        });
    }
    this.syncclass = function (cleverSession, idschool, idclass) {

        var url = LMConfig.frapi.syncclass.format(idschool, idclass, 'fr');

        return $http({
            method: 'POST',
            url: url,
            headers: {
                'x-vms-session': session,
                'clvr-access-token': cleverSession
            }
        });
    }
    this.updateClever = function (is_active, id, official_partner, state) {

        var url = LMConfig.frapi.updateClever.format(id);
        var body = {
            'is_active': is_active,
            'official_partner': official_partner,
            'state': state
        };

        return $http({
            method: 'PUT',
            url: url,
            headers: {
                'x-vms-session': session
            },
            data: body
        });
    }
    this.syncinfo = function (cleverSession, id) {

        var url = LMConfig.frapi.syncinfo.format(id, 'fr');

        return $http({
            method: 'POST',
            url: url,
            headers: {
                'x-vms-session': session,
                'clvr-access-token': cleverSession
            }
        });
    }

    this.classlinkSchool = function (identifier, tenantId) {

        var url = LMConfig.frapi.classlinkSchool.format(identifier, tenantId);

        return $http({
            method: 'GET',
            url: url,
            headers: {
                'x-vms-session': session
            }
        });
    }
    this.classlinkSchools = function (districtNimber) {

        var url = LMConfig.frapi.classlinkSchools.format(districtNimber);

        let request = $http({
            method: 'GET',
            url: url,
            headers: {
                'x-vms-session': session
            }
        });
        return request.then((response) => {

            response.data.sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            });

            return response.data;
        })
      
    }

    this.createclasslinkDistrict = function(district) {

        var url = LMConfig.frapi.createclasslinkDistrict.format(district.classlinkdistrict.sourcedId, district.tenant.tenant_id);
        return $http({
            method: 'POST',
            url: url,
            headers: {
                'x-vms-session': session
            }
        });
    }

    this.syncadminsClassLink = function (idnumber, district, tenantId) {

        var url = LMConfig.frapi.syncadminsClassLink.format(idnumber, district, tenantId, 'fr');

        return $http({
            method: 'POST',
            url: url,
            headers: {
                'x-vms-session': session
            }
        });
    }
    this.clearListClassesClassLink = function() {
        listClassesClassLink = [];
    }
    this.getclassesForClasslink = function (schoolNumber, tenantId) {

        let _listUsers = listClassesClassLink.find((x) => x.key === schoolNumber + '_' + tenantId);

        if (_listUsers) { return Promise.resolve(_listUsers.data) };

        var url = LMConfig.frapi.getclassesForClasslink.format(schoolNumber, tenantId);

        let request = $http({
            method: 'GET',
            url: url,
            headers: {
                'x-vms-session': session
            }
        });
        return request.then((response) => {

            listClassesClassLink.push({
                key: schoolNumber + '_' + tenantId,
                data: response.data
            }); 

            return response.data;
        })
    }
    this.clearListUserNOtifications = function() {
        listUserNotifications = [];
    }
    this.getUserNotifications = function() {

        if (listUserNotifications.length > 0) { return Promise.resolve(listUserNotifications); }

        var url = LMConfig.frapi.getUserNotifications;

        let request = $http({
            method: 'GET',
            url: url,
            headers: {
                'x-vms-session': session
            }
        });
        return request.then((response) => {

            listUserNotifications = response.data;

            return response.data;
        })
    }

    this.syncclassLink = function (schoolNumber, classNumber, tenantId) {

        var url = LMConfig.frapi.syncclassLink.format(schoolNumber, classNumber, tenantId, 'fr');

        return $http({
            method: 'POST',
            url: url,
            headers: {
                'x-vms-session': session
            }
        });
    }
    this.getGemStoneList = function (type) {

        let gemstones = null;

        if (type) {
            gemstones = window.localStorage.getItem('gemstonesStudents');
        } else {
            gemstones = window.localStorage.getItem('gemstones');
        }      
        if (gemstones) {
            return Promise.resolve(JSON.parse(gemstones));
        }

        var url = type ? LMConfig.frapi.getGemStoneList + '?type=student' : LMConfig.frapi.getGemStoneList;

        let request = $http({
            method: 'GET',
            url: url,
            headers: {
                'x-vms-session': session
            }
        });
        return request.then((response) => {

            response.data.sort((a, b) => (a.sortorder > b.sortorder) ? 1 : ((b.sortorder > a.sortorder) ? -1 : 0));

            if (type) {
                window.localStorage.setItem('gemstonesStudents', JSON.stringify(response.data));
            } else {
                window.localStorage.setItem('gemstones', JSON.stringify(response.data));
            }          

            return response.data;
        })
    }    

    this.userinfo = function (token) {

        return $http({
            method: "get",
            url: LMConfig.frapi.userinfo,
            headers: {
                'x-vms-session': session,
            },
            params: {
                token: token
            }
        });
    };
    this.deleteTeacher = function (token, school) {

        var body = {
            token,
            school
        };

        return $http({
            method: 'DELETE',
            url: LMConfig.frapi.teacher,
            headers: {
                'x-vms-session': session
            },
            data: body
        });
    }
    this.addTeacherBulk = function (teachersObj) {
      
        teachersObj.people.forEach((x) => {
          if (x.age === 'child') {
              x.age = 'kid';
          }
        })
        var body = {
            'addToClass': teachersObj.addToClass,
            'teachers': teachersObj.people
        };

        return $http({
            method: 'POST',
            url: LMConfig.frapi.addTeacherBulk,
            headers: {
                'x-vms-session': session
            },
            data: body
        });
      };
      this.deleteStudent = function (token, school) {

        var body = {
            token,
            school
        };

        return $http({
            method: 'DELETE',
            url: LMConfig.frapi.student,
            headers: {
                'x-vms-session': session
            },
            data: body
        });
    }
    this.addStudentBulk = function (studentsObj) {

        studentsObj.people.forEach((x) => {
            if (x.age === 'child') {
                x.age = 'kid';
            }
        })

        var url = LMConfig.frapi.addStudentBulk;
        var body = {
            'addToClass': studentsObj.addToClass,
            'students': studentsObj.people
        };

        return $http({
            method: 'POST',
            url: url,
            headers: {
                'x-vms-session': session
            },
            data: body
        });
    }
    this.taskCourseAttempt = function (courseIdNumber, taskId, taskAttemptId) {
        var url = LMConfig.frapi.taskCourseAttempt;

        var body = {
            course_idnumber: courseIdNumber,
            task_id: taskId,
            task_attemptId: taskAttemptId
        };

        return $http({
            method: 'POST',
            url: url,
            headers: {
                'x-vms-session': session
            },
            data: body
        });
    }
    this.taskGetAttemptMultiplier = function (taskIdNumber) {
        var url = LMConfig.frapi.taskGetAttemptMultiplier;

        return $http({
            method: 'GET',
            url: url,
            headers: {
                'x-vms-session': session
            },
            params: {
                idnumber: taskIdNumber
            }
        });
    }
    this.tasksstudents = function (fromdate, todate) {

        return $http({
            method: 'GET',
            url: LMConfig.frapi.tasksstudents,
            headers: {
                'x-vms-session': session,
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            params: {
                fromdate: fromdate,
                todate: todate
            }
        });
    }
    this.getAttemptTask = function (course_idnumber, task_id) {

        let request = $http({
            method: 'GET',
            url: LMConfig.frapi.getAttemptTask.format(course_idnumber, task_id),
            headers: {
                'x-vms-session': session,
                'Content-Type': 'application/json'
            }
        });
        return request.then((response) => {
       
            return response.data;
        })
    }
    this.getAttemptLibrary = function (course_idnumber) {

        let request = $http({
            method: 'GET',
            url: LMConfig.frapi.getAttemptLibrary.format(course_idnumber),
            headers: {
                'x-vms-session': session,
                'Content-Type': 'application/json'
            }
        });
        return request.then((response) => {
       
            return response.data;
        })
    }
    this.levelStudents = function (level, classes) {

        return $http({
            method: 'GET',
            url: LMConfig.frapi.levelStudents.format(level),
            headers: {
                'x-vms-session': session,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            params: {
                classes: JSON.stringify(classes)
            }
        });
    }
    this.addStudentsToLevel = function (level, students) {

        return $http({
            method: 'POST',
            url: LMConfig.frapi.levelStudents.format(level),
            headers: {
                'x-vms-session': session,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            data:  JSON.stringify(students)
        });
    }
    this.removeStudentsToLevel = function (level, students) {

        return $http({
            method: 'DELETE',
            url: LMConfig.frapi.levelStudents.format(level),
            headers: {
                'x-vms-session': session,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            data: JSON.stringify(students)
        });
    }
    this.statusCourses = function (taskID) {

        let request = $http({
            method: "get",
            url: LMConfig.frapi.statusCourses.format(taskID),
            headers: {
                'x-vms-session': session,
            }
        });
        return request.then((response) => {


            return response.data;
        })
    }

    this.getUsersFromCohort = function (cohort, role, showHasLicense, is_active) {

        let has_lic = showHasLicense ? 1 : 0;   
        
        let request = $http({
            method: "get",
            url: LMConfig.frapi.getUsersFromCohort,
            params: {
                cohort: cohort,
                roles: role,
                has_lic: has_lic,
                is_active: is_active
              },
            headers: {
                'x-vms-session': session,
            }
        });
        return request.then((response) => {
       
            return response.data;
        })
    };
    this.cleargetUsersFromCohort = function ()  {
      
        listUsersByCohort = [];
    }
    this.cleargetUsersFromCohortTeacherMode = function (cohort, role, is_active)  {

        listUsersByCohort = listUsersByCohort.filter((x) => x.key !== role + '_' + cohort + '_' + is_active);
    }
    this.getUsersFromCohortTeacherMode = function (cohort, role, showHasLicense, is_active) {

        let has_lic = showHasLicense ? 1 : 0;     

       let _listUsersByCohort = listUsersByCohort.find((x) => x.key === role + '_' + cohort + '_' + is_active);

        if (_listUsersByCohort) { return Promise.resolve(_listUsersByCohort.value) };   

        let request = $http({
            method: "get",
            url: LMConfig.frapi.getUsersFromCohort,
            params: {
                cohort: cohort,
                roles: role,
                has_lic: has_lic,
                is_active: is_active
              },
            headers: {
                'x-vms-session': session,
            }
        });
        return request.then((response) => {

            listUsersByCohort.push({
                key: role + '_' + cohort + '_' + is_active,
                value: response.data
            });

            return response.data;
        })
    };
    this.setGemstoneLevel = function(level, students) {
       
        var body = {
            'gemstone_level': level, 
            'students': students
        };

        return $http({
            method: 'PUT',
            url: LMConfig.frapi.setGemstoneLevel,
            headers: {
                'x-vms-session': session
            },
            data: body
        });
    }    
    this.userresult = function (token, course, date_from, date_to) {

        return $http({
          method: "get",
          url: LMConfig.frapi.userresult,
          params: {
            course: course,
            token: token,
            date_from: date_from,
            date_to: date_to
          },
          headers: {
            'x-vms-session': session,
        }
        });
    };
    this.classreport = function (cohort, date_from, date_to) {

        let request = $http({
            method: "get",
            url: LMConfig.frapi.classreport,
            params: {
                cohort: cohort,
                date_from: date_from,
                date_to: date_to
            },
            headers: {
                'x-vms-session': session,
            }
        });
        return request.then((response) => {
          
            return response.data;
        })

    };
    this.clearListDistrict = function() {
        listDistricts = [];
    }
    this.getlistDistrict = function() {
        let request = $http({
            method: "GET",
            url: LMConfig.frapi.getlistDistrict,
            headers: {
                'x-vms-session': session,
            }
        });
        return request.then((response) => {

            return response.data;
        })
    }     
    this.getSchools = function() {
        let request = $http({
            method: "GET",
            url: LMConfig.frapi.getSchools,
            headers: {
                'x-vms-session': session,
            }
        });
        return request.then((response) => {

            return response;
        })
    }      

    this.clearSchoolAdminsByProject = function() {
      //  listAdmins = [];
    }

    this.clearSchoolAdminsBySchool = function() {
      //  listAdminsBySchool = [];
    }

    this.getSchoolsByUser = function() {
        
        let request = $http({
            method: "GET",
            url: LMConfig.frapi.getSchoolsByUser,
            headers: {
                'x-vms-session': session,
            }
        });
        return request.then((response) => {

            response.data.forEach((x) => {
                x.school_type = x.school_type ? x.school_type : 'Languametrics';
                x.district_type = x.district_type ? x.district_type : 'Languametrics';
            })

            return response.data;
        })
    }
    this.getDistrictsAdmins = function() {

        if (listDistrictAdmins.length > 0) { return Promise.resolve(listDistrictAdmins) };

        let request = $http({
            method: "GET",
            url: LMConfig.frapi.getDistrictsAdmins.format('fr'),
            headers: {
                'x-vms-session': session,
            }
        });
        return request.then((response) => {

            listDistrictAdmins.push(...response.data);

            return response.data;
        })
    }
    this.getDistrictsAdminsByDistrict = function(district){

        let _listDistrictAdminsByDistrict = listDistrictAdminsByDistrict.find((x) => x.district === district);

        if (_listDistrictAdminsByDistrict) { return Promise.resolve(_listDistrictAdminsByDistrict.data) };

        let request = $http({
            method: "GET",
            url: LMConfig.frapi.getDistrictsAdminsByDistrict.format('fr', district),
            headers: {
                'x-vms-session': session,
            }
        });
        return request.then((response) => {

            listDistrictAdminsByDistrict.push({
                district: district,
                data: response.data
            });

            return response.data;
        })
    }
    this.clearDistrictsAdminsByDistrict = function() {
        listDistrictAdminsByDistrict = [];
    }
    this.clearDistrictsAdmins = function() {
        listDistrictAdmins = [];
    }

    this.deleteSchoolAdmin = function(username) {

        return $http({
            method: 'DELETE',
            url: LMConfig.frapi.deleteSchoolAdmin.format(username, 'fr'),
            headers: {
                'x-vms-session': session
            }
        });
    }

    this.userexists = function(username) {

        return $http({
            method: "GET",
            url: LMConfig.frapi.userexists,
            headers: {
                'x-vms-session': session,
            },
            params: {
                username: username
            },
        });
    }
    this.userexistsBulk = function (loginArr) {

        return $http({
            method: "POST",
            url: LMConfig.frapi.userexists,
            headers: {
                'x-vms-session': session,
            },
            data: JSON.stringify(loginArr)
        });
    }
    this.clearListDistrictsByUser = function() {
        listDistrictsByUser = [];
    }
    this.getDistrictsByUser = function() {

        if (listDistrictsByUser.length > 0) { return Promise.resolve(listDistrictsByUser) };

        let request = $http({
            method: "GET",
            url: LMConfig.frapi.getDistrictsByUser,
            headers: {
                'x-vms-session': session,
            }
        });
        return request.then((response) => {

            listDistrictsByUser.push(...response.data);

            return response.data;
        })
    }

    this.clearNewNotifications = function() {
        listNewNotifications = [];
        IsCall = false;
    } 

    this.getNewNotifications = function() {

        if (listNewNotifications.length > 0 || IsCall) { return Promise.resolve(listNewNotifications) };

        let request = $http({
            method: "GET",
            url: LMConfig.frapi.getNewNotifications,
            headers: {
                'x-vms-session': session,
            }
        });
        return request.then((response) => {
          
            listNewNotifications = response.data;
            IsCall = true;
           
            return response.data;
        })
    }
    this.setLastRead = function(notification) {

        let body = {
            'id': notification.id,
            'userId': notification.userId
        }

        return $http({
            method: 'POST',
            url: LMConfig.frapi.setLastRead,
            headers: {
                'x-vms-session': session
            },
            data: body
        });
    }
    this.createcleverDistrict = function(identifier) {

        return $http({
            method: 'POST',
            url: LMConfig.frapi.createcleverDistrict.format(identifier),
            headers: {
                'x-vms-session': session
            }
        });
    }
    this.createClass = function(clas) {

        let body =  {
            "name": clas.name,
            "school": clas.school,
            "teacher": clas.teacher,
            "district": clas.district,
            "date_begin": clas.date_begin,
            "date_end": clas.date_end,
            "class_type": clas.class_type,
            "description": clas.description
          }
  
          return $http({
              method: 'POST',
              url: LMConfig.frapi.class,
              headers: {
                  'x-vms-session': session
              },
              data: body
          });
    }
    this.updateClass = function(clas) {

        let body =  {
            "idnumber": clas.idnumber,
            "name": clas.name,
            "school": clas.school,
            "teacher": clas.teacher,
            "district": clas.district,
            "date_begin": clas.date_begin,
            "date_end": clas.date_end,
            "description": clas.description
          }
  
          return $http({
              method: 'PUT',
              url: LMConfig.frapi.class,
              headers: {
                  'x-vms-session': session
              },
              data: body
          });
    }
    this.deleteClass = function(clas) {

        let body =  {
            "idnumber": clas.idnumber
          }
  
          return $http({
              method: 'DELETE',
              url: LMConfig.frapi.class,
              headers: {
                  'x-vms-session': session
              },
              data: body
          });
    }
    this.licenseGetTeachers = function (license) {

        let request = $http({
            method: "get",
            url: LMConfig.frapi.licenseTeachers,
            params: {
                license: license
            },
            headers: {
                'x-vms-session': session
            }
        });
        return request.then((response) => {
          
            return response.data;
        })
    };
    this.licenseAssignTeachers = function(license, teachers) {
       
        var body = {
            'license': license, 
            'teachers': teachers
        };

        return $http({
            method: 'POST',
            url: LMConfig.frapi.licenseTeachers,
            headers: {
                'x-vms-session': session
            },
            data: body
        });
    }
    this.licenseRemoveTeachers = function(license, teachers) {
       
        var body = {
            'license': license, 
            'teachers': teachers
        };

        return $http({
            method: 'DELETE',
            url: LMConfig.frapi.licenseTeachers,
            headers: {
                'x-vms-session': session
            },
            data: body
        });
    }
    this.taskDetailReport = function (taskIdNumber) {

        let request = $http({
            method: "get",
            url: LMConfig.frapi.taskDetailReport,
            params: {
                idnumber: taskIdNumber
            },
            headers: {
                'x-vms-session': session,
            }
        });
        return request.then((response) => {
          
            return response.data;
        })

    };
    this.taskProgressReport = function (taskIdNumber) {

        let request = $http({
            method: "get",
            url: LMConfig.frapi.taskProgressReport,
            params: {
                idnumber: taskIdNumber
            },
            headers: {
                'x-vms-session': session,
            }
        });
        return request.then((response) => {
          
            return response.data;
        })

    };
    this.taskUpdateCourseSortOrder = function (taskId, coursesSortorder) {
        return $http({
            method: 'PUT',
            url: LMConfig.frapi.taskCourseSortOrder.format(taskId),
            headers: {
                'x-vms-session': session
            },
            data: coursesSortorder
        });
    };
    this.userSummaryReport = function (token, dateFrom, dateTo, periodType) {

        let request = $http({
            method: "get",
            url: LMConfig.frapi.userSummaryReport,
            params: {
                token: token,
                from: dateFrom,
                to: dateTo,
                periodtype: periodType
            },
            headers: {
                'x-vms-session': session,
            }
        });
        return request.then((response) => {
          
            return response.data;
        })

    };
    this.getSchoolAllClasses = function (schoolIdNumber) {

        let request = $http({
            method: "get",
            url: LMConfig.frapi.getSchoolAllClasses.format(schoolIdNumber),
            headers: {
                'x-vms-session': session
            }
        });
        return request.then((response) => {
          
            return response.data;
        })

    };
    this.cohortaddusers = function(cohort, usernames) {
       
        var body = {
            'cohort': cohort, 
            'usernames': usernames
        };

        return $http({
            method: 'POST',
            url: LMConfig.frapi.cohortaddusers,
            headers: {
                'x-vms-session': session
            },
            data: body
        });
    };
    this.cohortremoveusers = function(cohort, usernames) {
       
        var body = {
            'cohort': cohort, 
            'usernames': usernames
        };

        return $http({
            method: 'POST',
            url: LMConfig.frapi.cohortremoveusers,
            headers: {
                'x-vms-session': session
            },
            data: body
        });
    };
    this.licensesBySchool = function (schoolIdNumber) {

        let request = $http({
            method: "get",
            url: LMConfig.frapi.licensesBySchool.format(schoolIdNumber),
            headers: {
                'x-vms-session': session
            }
        });
        return request.then((response) => {
          
            return response.data;
        })

    };
    this.teacherLicensesBySchool = function (schoolIdNumber, childCourse) {

        let request = $http({
            method: "get",
            url: LMConfig.frapi.teacherLicensesBySchool,
            params: {
                school: schoolIdNumber,
                childCourse
            },
            headers: {
                'x-vms-session': session
            }
        });
        return request.then((response) => {
          
            return response.data;
        })
    };
    this.licenseGetStudents = function (license, childCourse) {

        let request = $http({
            method: "get",
            url: LMConfig.frapi.licenseStudents,
            params: {
                license: license,
                childCourse
            },
            headers: {
                'x-vms-session': session
            }
        });
        return request.then((response) => {
          
            return response.data;
        })
    };
    this.licenseAssignStudents = function(license, students, childCourse) {
       
        var body = {
            'license': license, 
            'students': students,
            childCourse
        };

        return $http({
            method: 'POST',
            url: LMConfig.frapi.licenseStudents,
            headers: {
                'x-vms-session': session
            },
            data: body
        });
    }
    this.licenseRemoveStudents = function(license, students, childCourse) {
       
        var body = {
            'license': license, 
            'students': students,
            childCourse
        };

        return $http({
            method: 'DELETE',
            url: LMConfig.frapi.licenseStudents,
            headers: {
                'x-vms-session': session
            },
            data: body
        });
    }
    this.checksession = function () {

        return $http({
            method: "get",
            url: LMConfig.frapi.checksession,
            headers: {
                'x-vms-session': session
            }
        });
    };
    this.prolongsession = function() {
       
        return $http({
            method: 'PUT',
            url: LMConfig.frapi.prolongsession,
            headers: {
                'x-vms-session': session
            }
        });
    };
    this.updateUser = function(username, password, firstname, lastname, email, age, lang, properties) {

        let body =  {
            'username': username,
            'password': password,
            'firstname': firstname,
            'lastname': lastname,
            'email': email,
            'age': age,
            'lang': lang,
            'properties': properties
          }

          return $http({
              method: 'PUT',
              url: LMConfig.frapi.user,
              headers: {
                  'x-vms-session': session
              },
              data: body
          });
    }
});
