(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app.teacher.workstations', {

                    url: '/workstations',
                    controller: 'teacherWorkstationsCtrl',
                    templateUrl: '/assets/ng1/teacher/workstations/index.html',
                    deepStateRedirect: {
                        params: true
                    },
                    params: {
                        selectFirstClass: null,
                    },

                })
        }])

        .controller('teacherWorkstationsCtrl', function ($scope, $state, $stateParams, LMWordpress, commonService, school, token, classes, user, schools) {

            $scope.$emit('teacherModeChange', 'workstations');

            // watch for side tab changed
            $scope.$on('sideTabChange', function (event, data) {
                $scope.selectedClass = data;
            });

            $scope.token = token;
            $scope.school = school;
            $scope.isRegistered = false;
            $scope.isCertified = false;
            $scope.registrationNumber = '';
            $scope.testSite = '';

            // get all class which is in school
            // filter classes where current teacher is assigned 
            // and are not expired
            var classList = classes;

            classList.sort(function (a, b) {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            });
            $scope.classList = classList;
            $scope.selectClass = vmSelectClass;

            vmLoad();

            function vmSelectClass(clas) {

                $state.go('app.teacher.workstations.list', { currentClass: clas.idnumber });

            }

            function vmLoad() {
                commonService.getClientFingerprint(user.project).then(function (fingerprintObj) {
                    if (fingerprintObj.msg) alert(fingerprintObj.msg);
                    if (!fingerprintObj.value) return;
                    LMWordpress.workstations.isRegistered(fingerprintObj.value).then(function (response) {
                        $scope.isRegistered = !!response.data.workstation;
                        if ($scope.isRegistered) {
                            $scope.registrationNumber = response.data.workstation.workstation_id;
                            $scope.isCertified = response.data.workstation.is_certified == true;
                            $scope.testSite = response.data.workstation.test_site;
                        } else {
                            if (response.data.was_deleted) {
                                commonService.resetClientFingerprint();
                            }
                        }
                    });
                })

                if ($stateParams.selectFirstClass && classList.length) {
                    vmSelectClass(classList[0]);
                    $stateParams.selectFirstClass = null;
                }
            }
        })
})();