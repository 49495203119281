(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.teacher.report.curriculums', {
            url: '/curriculums',
            controller: 'teacherCurriculumsCtrl',
            templateUrl: '/assets/ng1/teacher/courses/assignments/modules.html'
        })

    }])
    .controller('teacherCurriculumsCtrl', function ($scope, $state, courses) {

        $scope.$emit('activeTabLeft', 'curriculum');
        $scope.$emit('teacherModeChange', 'report');
  
        $scope.curriculum = courses.filter(function (x) {
            return x.enrol == 1 && (x.curriculumtype == "categories" ||
                                   x.curriculumtype == "cohorts" ||
                                   x.curriculumtype == "levels" ||
                                   x.curriculumtype == "external");
        }).filter((x) => {
            return x.params ? JSON.parse(x.params).p_reports : null
        });

        $scope.curriculumGroup = vmGetChunks($scope.curriculum, 2); // need for table-cell view

        function vmOpenCurriculum(course) {

            $state.go('app.teacher.report.curriculum', { id: course.id });
        }

        $scope.openCurriculum = vmOpenCurriculum;

    })
})();