(function () {
    'use strict';

    angular.module('languabooksApp')

        .directive('vmsShowDetails', function ($state, $filter, LMConfig, LMAPI, LMAPICache, $q, $http) {

            function vmController($scope, $state, $filter, LMConfig, LMAPI) {

                $scope.openAttempt = vmOpenAttepmt;
                $scope.showActivityReport = vmShowActivityReport;
                $scope.config = LMConfig;
                $scope.scos = null;

                $scope.results = [];
                $scope.attemptsArr = [];
                $scope.resultsAttempt = [];
                $scope.loaded = false;

                $q.all([LMAPICache.scos($scope.token, $scope.course.id), LMAPI.activitiesprogress($scope.token, $scope.course.id)])
                    .then(function (responses) {

                        $scope.scos = responses[0].scos;
                        $scope.results = responses[1].data.filter(x => !['NSCO', 'FC'].contains(x.type));

                        //for correct display thumbnail we check this thumbnail is available in activity content folder
                        //so we must parse xml info of every activity
                        //for this we initiate 'get' query for every activity using method vmGetXMLInfo
                        $scope.results.forEach(x => {
                            x.isReading = ['SRS', 'SAS', 'FCR', 'FCA'].contains(x.type);
                            x.xmlInfo = vmGetXMLInfo(x.content);
                            x.thumbnailCaption = x.name.slice(0, 10);
                            x.stars = vmGetScoreStars(x);
                        });

                        //then we get all 'get' queries of activities 
                        var xmlInfo = $scope.results.map(x => x.xmlInfo);

                        //wait all queries will done
                        $q.all(xmlInfo).then(function (responses) {

                            //parse each data(xml file with info about activity)
                            responses.forEach(function (x) {
                                var xml = $.parseXML(x.data);

                                //try to find in xml document info about image
                                var res = $(xml).find('entry[key="title_image"]');

                                //if image info available, find corresponding activity object and set property img
                                //this property will be handle in view to display image from content
                                //if no img property in view will be used default image
                                if (res) {
                                    var img = res.text();
                                    $scope.results.forEach(x => {

                                        if (img.indexOf(x.content) > -1)
                                            x.img = img;
                                    })
                                }
                            });
                            $scope.loaded = true;
                            $scope.results;
                        })

                        //method returning 'get' query with activity content folder url
                        function vmGetXMLInfo(content) {

                            return $http.get('{0}/Library/{1}/info.xml'.format(LMConfig.storage, content), { withCredentials: true });
                        }

                        function vmGetScoreStars(activity) {

                            var score = 0;


                            if (activity.score != null && activity.score != 'undefined') {

                                var activityScore = parseInt(activity.score);
                                if (activity.isReading) {


                                    if (activity && (activity.type === 'FCR' || activity.type === 'FCA')) {
                                        if (activityScore == 0) { score = 1; }
                                        else if (activityScore == 50) { score = 3; }
                                        else if (activityScore == 100) { score = 5; }

                                    } else {
                                        if (activityScore >= 91)
                                            score = 5;
                                        else if (activityScore >= 81)
                                            score = 4;
                                        else if (activityScore >= 68)
                                            score = 3;
                                        else if (activityScore >= 51)
                                            score = 2;
                                        else if (activityScore >= 0)
                                            score = 1;

                                    }

                                } else {
                                    if (activityScore >= 100)
                                        score = 5;
                                }

                            }

                            return score;
                        }

                        var maxAttempt = Math.max.apply(Math, $scope.results.map(x => x.attempt));

                        if (maxAttempt > 1) {

                            $scope.selectAttempt = maxAttempt;
                            $scope.resultsAttempt = $scope.results.filter(x => x.attempt == $scope.selectAttempt);

                            for (var i = 1; i <= $scope.attempts; i++) {
                                $scope.attemptsArr.push(i);
                            }

                            vmOpenAttepmt(maxAttempt);

                        } else {
                            $scope.resultsAttempt = $scope.results;

                            if ($scope.resultsAttempt.length > 0)
                                vmShowActivityReport($scope.resultsAttempt[0]);
                        }

                    });

                function vmOpenAttepmt(attempt) {

                    $scope.selectAttempt = attempt;
                    $scope.resultsAttempt = $scope.results.filter(x => x.attempt == $scope.selectAttempt);

                    if ($scope.resultsAttempt.length > 0)
                        vmShowActivityReport($scope.resultsAttempt[0]);
                }

                function vmShowActivityReport(sco) {

                    $scope.currentActivity = sco;

                    var params = ['cmi.vms_absolute_score', 'cmi.core.lesson_status', 'cmi.core.total_time', 
                    'cmi.core.score_coefficient', 'cmi.vms_quiz_xml', 'cmi.vms_audio_path', 'cmi.vms_first_score', 'cmi.vms_text', 'cmi.vms_first_quiz_xml'];

                    LMAPI.track($scope.token, sco.id, params.join(',')).then(function (response) {

                        var tracks = response.data;
                        var reportData = {};

                        var activityParams = $scope.scos.find(x => x.id == sco.id).params;

                        tracks = tracks.filter(x => x.attempt == sco.attempt);
                        if (tracks.length > 0) {

                            reportData = tracks.reduce(function (prev, next) {

                                prev[next.key] = next.value;
                                return prev;

                            }, {});
                        } else {
                            reportData = {
                                "cmi.core.lesson_status": "incomplete"
                            };
                        }

                        if (!$scope.currentActivity.isReading) {
                            activityParams = JSON.parse(activityParams);
                            activityParams.layout += ' student';

                            activityParams = JSON.stringify(activityParams);
                        }

                        vmLoadReportActivity(activityParams, reportData)

                    });
                }

                function vmLoadReportActivity(activityParams, reportData) {

                    $("#sco_container").removeClass();

                    var defaultStorage = {
                        type: 's3',
                        audio: 'speech-userfiles-dev'
                    };

                    var reportConfig = {

                        application_id: "contenttool_report_page",
                        bucket_root_url: LMConfig.storage,
                        bucket_script_url: LMConfig.sco_defaults.bucket_script_url,
                        idevice_mode: "report",
                        lang: $scope.lang || "en",
                        speech_service: LMConfig.sco_defaults.speech_service,
                        web_worker: LMConfig.sco_defaults.web_worker,
                        storage: LMConfig.sco_defaults.storage || defaultStorage,
                        mode: 'report'
                    };

                    reportConfig = $.extend(reportConfig, JSON.parse(activityParams));

                    var preload = vimas.loader.preload({
                        bucket: reportConfig.bucket_script_url || reportConfig.bucket_root_url,
                        skin: {
                            action: "lb_report",
                            html: "lb_report",
                            skin: "lb_report",
                            localization: "lb"
                        },
                        scos: [{ container: '#sco_container', type: reportConfig.idevice_type }],
                        lang: reportConfig.lang,
                        mode: reportConfig.mode
                    });

                    preload.done(function () {

                        Idevice.postinit('sco_container', reportConfig, reportData);
                    });
                };


            };

            return {
                scope: {
                    course: '=',
                    token: '=',
                    attempts: '=',
                    lang: '='
                },
                controller: vmController,
                templateUrl: '/assets/ng1/course/details.html'
            }

        })

})();