(function () {
  'use strict';

  angular.module('languabooksApp')

    .directive('vmsCourseList', function ($state, FRAPI, $stateParams, $filter, LMConfig, LMAPI, LMAPICache, $rootScope, coursesService, commonService, openCourseService, $translate) {

      function vmController($scope, $state, $stateParams, $filter, LMConfig, LMAPI, LMAPICache, $rootScope) {

        // configuration
        $scope.config = LMConfig;
        $scope.details = false;
        $scope.isStudent = $scope.user.roles.every(x => x === 'student');
        $scope.mode = localStorage.getItem('mode');
        $scope.quest = localStorage.getItem('current_quest_id');
        let _books = localStorage.getItem('current_books');
        $scope.books = _books ? JSON.parse(_books) : [];

        $scope.sortValues = [{ key: 'default', title: $translate.instant('course.list.sorting.Default') },
        { key: 'p_wordcount', title: $translate.instant('course.list.sorting.WordCounts') },
        { key: 'p_lexile', title: $translate.instant('course.list.sorting.Complexity') },
        { key: 'p_CEFR', title: $translate.instant('course.list.sorting.CEFR') },
        { key: 'name', title: $translate.instant('course.list.sorting.Title') },
        { key: 'p_series', title: $translate.instant('course.list.sorting.Series') }];


        // get last used order by option
        // restore it if found
        var keyOrderBy = window.localStorage.getItem('languabooks.com/orderBy');
        if (!$scope.removeSort) {

          vmOrderBy(keyOrderBy || 'default');

          $scope.enrolby = vmOrderEnrol;
        };
        
        $scope.$on('change_quest', function(event, data) {
               
          $scope.quest = data.quest;
          let _books = localStorage.getItem('current_books');
          $scope.books = _books ? JSON.parse(_books) : [];

          if (!$scope.$$phase) {
            $scope.$apply();
          } 
        });

        function vmOrderBy(name) {

          $scope.key = name;

          // save last used
          // order by options
          window.localStorage.setItem('languabooks.com/orderBy', $scope.key);

          if (name == 'p_lexile') {

            $scope.orderby = vmOrderLexile;

          } else if (name == 'p_wordcount') {

            $scope.orderby = vmOrderWordCount;

          } else if (name == 'p_CEFR') {

            $scope.orderby = vmOrderCEFR;

          } else if (name == 'name') {

            $scope.orderby = vmOrderTitle;

          } else if (name == 'p_series') {

            $scope.orderby = vmOrderSeries;

          } else if (name == 'default') {

            $scope.orderby = vmOrderDefault;
          }

          function vmOrderDefault(course) {

            return course.default || 9999;
          }

          function vmOrderLexile(course) {

            if (course.params) {

              var params = JSON.parse(course.params);
              if (params.p_lexile) {
                var lexile = params.p_lexile.slice(0, params.p_lexile.length - 1);
                return parseInt(lexile) || 0;
              } else {
                return 0;
              }
            } else {

              return 99999;
            }
          }
          function vmOrderSeries(course) {

            if (course.params) {

              var params = JSON.parse(course.params);
              return params.p_series || 'ZZZZZ';

            } else {

              return 'ZZZZZ';
            }
          }

          function vmOrderCEFR(course) {

            if (course.params) {

              var params = JSON.parse(course.params);
              return params.p_CEFR || 'ZZZZZ';

            } else {

              return 'ZZZZZ';
            }
          }

          function vmOrderTitle(course) {

            return course.name || 'ZZZZZ';
          }

          function vmOrderWordCount(course) {

            if (course.params) {

              var params = JSON.parse(course.params);
              if (params.p_wordcount) {
                return parseInt(params.p_wordcount);
              } else {
                return 99999;
              }
            } else {

              return 99999;
            }
          }
        };

        function vmOrderEnrol(course) {

          if (parseInt(course.enrol) == 1) {
            return 1;
          } else {
            return 9999;
          };
        };

        function vmOpenCourse(course) {


          // try to get external link from a course
          // if found than open new window
          // otherwise open course normally
          let link = vmExternalLink(course);
          if (link) {

            window.open(link);

          } else {

            openCourseService.get(null, course);
          };
        };

        function vmAssignBookToQuest(course, isAssign) {

          if ($scope.quest === 'null') { return; }

          if (isAssign) {
            vmAssignCourseToTask(course, $scope.quest);
          } else {
            vmRemoveCourseFromTask(course, $scope.quest);
          }
        }

        function vmAssignCourseToTask(course, quest) {

          $rootScope.pageLoading = true;
  
            var idcourse = [course.id];
  
            LMAPI.taskCourses.addcourses(null, quest, idcourse).then(function () {

              let _books = localStorage.getItem("current_books");
              let books = JSON.parse(_books);
              books.push(course.id);

              $scope.books = books;

              localStorage.setItem("current_books", JSON.stringify(books));

              $scope.$emit('limits_books', $scope.books);

              $rootScope.pageLoading = false;
  
            }).catch(function () {
  
                $scope.error = true;
            });
        }
  
        function vmRemoveCourseFromTask(course, quest) {
  
            $rootScope.pageLoading = true;
            var idcourse = [course.id];
  
            LMAPI.taskCourses.deletecourses(null, quest, idcourse).then(function () {
  
                let _books = localStorage.getItem("current_books");
                let books = JSON.parse(_books);
                books = books.filter((x) => x !== course.id);

                $scope.books = books;

                localStorage.setItem("current_books", JSON.stringify(books));

                $scope.$emit('limits_books', $scope.books);

                $rootScope.pageLoading = false;
  
            }).catch(function () {
  
                $scope.error = true;
            });
        }
  
        function vmOpenAudioCourse(course, event) {

          event.stopPropagation();

          $state.go('app.course.audio', { course: course.id, back: $state.href($state.current.name, $state.current.params) });
        };

        function vmGetInfo(course, courses, event) {

          // assign course params
          $scope.info = JSON.parse(course.params) || {};
          $scope.info.title = course.name;
          $scope.info.content = course.content;
          $scope.info.desc = course.description;
          $scope.info.shortdesc = course.shortdesc;
          $scope.info.id = course.id;
          $scope.course = course;
          $scope.courses = courses;
          course.openMoreDialog = false;

          if ($scope.info.p_atopic instanceof Array) {
            $scope.array_atopic = $scope.info.p_atopic.join(", ");
          }

          if ($scope.info.p_collection instanceof Array) {
            $scope.info.p_collection = $scope.info.p_collection.join(", ");
          }

          vmCriterias();

          $scope.bookSummary = course.shortdesc;

          if ($scope.info.p_keywords) {

            $scope.info.p_keywords.sort();

            var keywords = $scope.info.p_keywords.join(', ');
            $scope.bookSummary = $scope.info.shortdesc + '<p> Vocabulary: ' + keywords + '</p>';
          }

          ///REMOVE category course from Content tool
          /// p_category - new property
          // find category which course belong to
          // and display it's title
         /* LMAPICache.categories()
            .then(function (categories) {

              let category = categories.find(item => item.idnumber == course.categoryidnumber);
              $scope.info.categories = category.name;
            });*/

          // assign course scores
          $scope.info.scores = course.scores;

          return false;
        };

        function vmCriterias() {

          $scope.groups = commonService.searchGroup($scope.user.project);
          $scope.criterias = { topic: [], genre: [], gemstone: [] };

          for (let key in $scope.info) {
            if (key === ('gemstone_level')) {
              $scope.criterias.gemstone = $scope.info[key] instanceof Array ? $scope.info[key] : $scope.info[key] ? [$scope.info[key]] : [];
            }
          }
          for (let key in $scope.info) {
            if (key === ('p_genre_' + $scope.groups.genre)) {
              $scope.criterias.genre = $scope.info[key] instanceof Array ? $scope.info[key] : $scope.info[key] ? [$scope.info[key]] : [];
            }
          }

          let foundKeys = [];

          for (let key in $scope.info) {
            // Перевіряємо кожне закінчення в масиві $scope.groups.topic
            $scope.groups.topic.forEach((suffix) => {

                if (key.startsWith('p_topic_') && key.endsWith(suffix)) {
                  
                    foundKeys.push(suffix);
                }
            });
          }

          FRAPI.getGemStoneList().then((response) => {

            $scope.criterias.$gemstone = $scope.criterias.gemstone.map((x) => {

              let gemstone = response.find((y) => y.idnumber === x);

              return gemstone.name;
            }).join(", ");

          })


          foundKeys.forEach((activetopic) => {

            let _names_topic = $scope.info['p_topic_' + activetopic].map((key) => {

              let name = 'search.topic.' + activetopic + '.' + key;
  
              return $translate.instant(name);
            }).join(', ');

            
            if ($scope.criterias.topic && $scope.criterias.topic.length > 0) {
                $scope.criterias.topic += ', ' + _names_topic;
            } else {
               
                $scope.criterias.topic = _names_topic;
            }

          })
 
          // for each genre get related translation
          $scope.criterias.genre = $scope.criterias.genre.map((key) => {

            let name = 'search.genre.' + $scope.groups.genre + '.' + key;

            return $translate.instant(name);
          }).join(', ');

        }

        function vmShowDetails(course, courses) {
          vmGetInfo(course, courses, event);
          $scope.details = true;
          course.openMoreDialog = false;
        };

        function vmCloseInfo() {

          delete $scope.info;
          delete $scope.details;
        };

        $scope.$watch('courses', function(newVal, oldVal) {
          if ($scope.flagcloseinfobook && $scope.page === 'search') {
            vmCloseInfo(); // Виклик методу при оновленні courses
            $scope.flagcloseinfobook = false;
          }
        });

        function vmNextInfoCourse(course, courses, event) {

          //current course
          var course = course;

          for (var i = 0; i < courses.length; i++) {
            if (course.id == courses[i].id) {

              if (i != courses.length - 1) {
                course = courses[i + 1];
                break;
              } else {
                //if the last element of the array  then to come back the first elment of the array
                course = courses[0];
                break;
              }

            };
          }
          //show info about course
          vmGetInfo(course, courses, event);
        };

        function vmPrevInfoCourse(course, courses, event) {

          //current course
          var course = course;

          for (var i = 0; i < courses.length; i++) {
            if (course.id == courses[i].id) {
              if (i != 0) {
                course = courses[i - 1];
                break;

              } else {
                //if the first element of the array  then to come back the last elment of the array
                course = courses[courses.length - 1];
                break;
              }

            };
          }
          //show info about course
          vmGetInfo(course, courses, event);
        };

        function vmNextLevel(level, levels, event) {

          for (var i = 0; i < levels.length; i++) {
            if (level.idnumber == levels[i].idnumber) {

              if (i != levels.length - 1) {
                level = levels[i + 1];
                break;
              } else {
                //if the last element of the array  then to come back the first elment of the array
                level = levels[0];
                break;
              }

            };
            
          }
          let mode = window.localStorage.getItem('mode');
          if (mode === 'library') {
            // open levels curriculum
            $state.go('app.library.curriculum.levels.shelves', { level: level.idnumber });
          
          } else if (mode === 'teacher') {
            $state.go('app.teacher.curriculum.levels.shelves', { level: level.idnumber });
          
          } else if (mode === 'schooladmin') {
            $state.go('app.admin.school.curriculum.levels.shelves', { level: level.idnumber });
           
          }
        };

        function vmPrevLevel(level, levels, event) {

          for (var i = 0; i < levels.length; i++) {
            if (level.idnumber == levels[i].idnumber) {
              if (i != 0) {
                level = levels[i - 1];
                break;

              } else {
                //if the first element of the array  then to come back the last elment of the array
                level = levels[levels.length - 1];
                break;
              }
            };
          }
          let mode = window.localStorage.getItem('mode');
          if (mode === 'library') {
            // open levels curriculum
            $state.go('app.library.curriculum.levels.shelves', { level: level.idnumber });
          
          } else if (mode === 'teacher') {
            $state.go('app.teacher.curriculum.levels.shelves', { level: level.idnumber });
          
          } else if (mode === 'schooladmin') {
            $state.go('app.admin.school.curriculum.levels.shelves', { level: level.idnumber });
           
          }
        };

        function vmGetElementByCourse(course) {

          if (!(course.params instanceof Object)) {
            course.p_shelf_label = course.params ? JSON.parse(course.params).p_shelf_label : "";
          }
        };

        function vmExternalLink(course) {

          // try to parse external link or secure external link
          // from course parameters
          const params = JSON.parse(course.params) || {};
          const link = params.p_external_link;
          const secureLink = params.p_aws_secure_link;

          if (link) {

            return link;

          } else if (secureLink) {

            // find Amazon S3 bucket from URL
            // check two possible positions for it
            var buckets = secureLink.match('https://([a-z\-]*).?s3[\.a-z0-9\-]*.amazonaws.com/([a-z\-]*)');
            var bucket = buckets[1] || buckets[2];

            // find key portion in Amazon S3 URL
            // exclude root and bucket
            var key = secureLink.match('amazonaws.com(/' + bucket + ')?/([A-Za-z0-9\-\/\._ ]*)')[2];

            // get signed URL
            var s3 = new AWS.S3({ signatureVersion: "v4" });
            return s3.getSignedUrl('getObject', { Bucket: bucket, Key: key });

          } else {

            return null;
          }
        };

        function vmOpenDialog(course, event) {

          course.openMoreDialog = !course.openMoreDialog;

          if (course.openMoreDialog) {

            $scope.infoDetails = JSON.parse(course.params) || {};
            $scope.infoDetails.scores = course.scores;
          }
        }

        function vmShowButton(curriculumtype, btn) {
    
          switch (curriculumtype) {

            case 'questionnaire':
            case 'guide':
            case 'external':

              if (btn === 'getInfo' || btn === 'completed') {
                return true;
              } else {
                return false;
              }
            case 'help':
              return false;
            case 'test':

              if (btn === 'getInfo') {
                return true;
              } else {
                return false;
              }
            default:

              if (btn !== 'completed') {
                return true;
              } else {
                return false
              }

          }
        }

        function vmBack() {
     
          let backParameters = window.localStorage.getItem('historyBackShelve') ? JSON.parse(window.localStorage.getItem('historyBackShelve')) : [];
          
          let _page = backParameters.find((x) => x.params.id === $stateParams.id ); //&& x.params.level === $stateParams.level
      
          switch (_page.number) {
            case 0: {
            
              let mode = window.localStorage.getItem('mode');
              if (mode === 'library') {
            
                $state.go('app.library.shelve.curriculums');
              
              } else if (mode === 'teacher') {
                $state.go('app.teacher.library.curriculums');
              
              } else if (mode === 'schooladmin') {
                $state.go('app.admin.school.library.curriculums');               
              }
              window.localStorage.setItem('historyBackShelve', JSON.stringify([]));
              break;
            }
            case 1: {
              $state.go(backParameters[_page.number - 1].state, backParameters[_page.number - 1].params);
              window.localStorage.setItem('historyBackShelve', JSON.stringify([]));
              break;
            }
            default: {
              $state.go(backParameters[_page.number - 1].state, backParameters[_page.number - 1].params);
              window.localStorage.setItem('historyBackShelve', JSON.stringify([]));
              break;
            }
          }     
        }

        $scope.openCourse = vmOpenCourse;
        $scope.openAudioCourse = vmOpenAudioCourse;
        $scope.getInfo = vmGetInfo;
        $scope.closeInfo = vmCloseInfo;
        $scope.showDetails = vmShowDetails;
        $scope.prevInfoCourse = vmPrevInfoCourse;
        $scope.nextInfoCourse = vmNextInfoCourse;
        $scope.prevLevel = vmPrevLevel;
        $scope.nextLevel = vmNextLevel;
        $scope.order = vmOrderBy;
        $scope.getElementByCourse = vmGetElementByCourse;
        $scope.externalLink = vmExternalLink;
        $scope.openDialog = vmOpenDialog;
        $scope.showButton = vmShowButton;
        $scope.back = vmBack;
        $scope.vmAssignBookToQuest = vmAssignBookToQuest;
      };

      return {
        scope: {
          courses: '=ngModel',
          token: '=vmsToken',
          title: '=vmsTitle',
          removeSort: '=vmsRemoveSort',
          page: '=vmsPage',
          flagcloseinfobook: '=vmsFlag',
          user: '=vmsUser',
          isMoreUnread: '=vmsMoreUnread',
          levels: '=vmsLevels',
          level: '=vmsLevel',

          // set to true when showing favorite courses
          favoriteMode: '=vmsFavoriteMode'
        },
        controller: vmController,
        templateUrl: '/assets/ng1/course/list.html'
      }

    })

    .service('coursesService', function (LMAPI, LMAPICache, $state, $q, LMWordpress, $translate, commonService) {
      this.get = function (token, category, cohort) {
        var deferred = $q.defer();
        if (token) {
          deferred.resolve(token);
        } else {
          LMWordpress.getcredentials().then(function (cred) {
            deferred.resolve(cred.token);
          });
        }

        return deferred.promise.then(function (token) {
          // load courses
          // by current cohort / category
          return LMAPICache.courses(token, cohort, category)
            .then(function (courses) {

              // load course scores
              // by current cohort / category
              return LMAPI.coursesScores(token, category, cohort)
                .then(function (response) {

                  // parse and update course scores
                  response.data.forEach(function (score) {
                    var course = courses.find(x => x.id == score.courseidnumber);
                    if (!course) return;
                    course.scores = JSON.parse(score.params);                 
                  });
                  courses.forEach((x, i) => {
                    x.default = i + 1;     
                    x.$course_state = x.course_state ? JSON.parse(x.course_state) : {};
                    x.isAssign = false;                     
                  })
                  return courses;
                });
            });
        });
      }

      this.runTest = function (token, course, activity, back, notDirect, taskId) {
        var deferred = $q.defer();
        if (token) {
          deferred.resolve(token);
        } else {
          LMWordpress.getcredentials().then(function (cred) {
            deferred.resolve(cred.token);
          });
        }

        deferred.promise.then(function (token) {
          LMAPICache.courses(token, null, null, course)
            .then(function (response) {
              var deferred = $q.defer();
              deferred.promise.then(onRun);

              if (response.params) {
                var crsParams = JSON.parse(response.params);
                // if check course for worksation certification
                if (crsParams.p_certified_workstation_only == true) {
                  // check test center for workstation certification
                  LMWordpress.checkSchoolForCertification().then(function (response) {
                    if (!response.data.checkForCertification) {
                      deferred.resolve({ certified: true });
                      return;
                    }
                    // check workstation is certified
                    commonService.getClientFingerprint().then(function (fingerprintObj) {
                      if (!fingerprintObj.value) {
                        if (fingerprintObj.msg) alert(fingerprintObj.msg);
                        deferred.resolve({ certified: false });
                        return;
                      }
                      LMWordpress.workstations.isRegistered(fingerprintObj.value).then(function (response) {
                        var isRegistered = !!response.data.workstation;
                        var isCertified = isRegistered && response.data.workstation.is_certified == true;
                        deferred.resolve({ certified: isCertified });
                      });
                    }).catch(function () {
                      deferred.resolve({ certified: false });
                    });
                  });
                } else {
                  deferred.resolve({ certified: true })
                }
              } else deferred.resolve({ certified: true });

              function onRun(result) {
                if (!result.certified) {
                  var text = $translate.instant('common.msg.cantrun.coures.workstation.not.cert');
                  alert(text);
                  return;
                }

                var params = { course: course };
                if (activity)
                  params.activity = activity;
                if (back) {
                  params.back = back;
                }
                if (notDirect) {
                  params.notDirect = notDirect;
                }
                if (taskId) {
                  params.taskId = taskId;
                }
                $state.go('app.course.read', params);
              }
            });
        });
      }
    })

})();