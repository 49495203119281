(function () {
    'use strict';

    angular.module('languabooksApp')

    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
        .state('app.admin.school.users.email', {
            url: '/email',
            params: {
                recipients: null,
                people: null
            },
            controller: 'sendEmailCtrl',
            templateUrl: '/assets/ng1/admin/email/email.html',
        })
        .state('app.admin.school.cindex.email', {
            url: '/email',
            params: {
                recipients: null,
                people: null
            },
            controller: 'sendEmailCtrl',
            templateUrl: '/assets/ng1/admin/email/email.html',
        })
    }])
    .controller('sendEmailCtrl', function ($scope, $stateParams, LMWordpress, $window, $translate) {

        $scope.$emit('adminTabChange', 'teacher');
        $scope.$emit('adminClassesHighlighted', 'active');

        $scope.people = $stateParams.people;
        $scope.attachments = [];

        $scope.multipleDemo = {};
        $scope.multipleDemo.selectedPeople = $stateParams.recipients;
        $scope.resipientsSelectCollapsed = true;


        function vmSendEmail(recipients, subject, body, attachments) {

            LMWordpress.sendemail(recipients, subject, body, attachments).then(function (response) {

                alert($translate.instant('email.successfully'));

                $scope.multipleDemo.selectedPeople = [];
                $scope.body = null;
                $scope.attachments = [];
                $scope.subject = null;
            }).catch(function (reject) {

                alert("Error");
            });
        }

        $scope.onAttachmentLoaded = function (index, fileData) {
            $scope.attachments.push({
                data: fileData.data,
                name: fileData.name,
                type: fileData.type
            });
            $scope.$apply();
        }

        $scope.deleteAttachment = function (index) {
            $scope.attachments.splice(index, 1);
        }        

        function vmBack() {

            $window.history.back();
        };

        $scope.sendMail = vmSendEmail;
        $scope.back = vmBack;
    })
    .filter('propsFilter', function () {
        return function (items, props) {
            var out = [];

            if (angular.isArray(items)) {
                var keys = Object.keys(props);

                items.forEach(function (item) {
                    var itemMatches = false;

                    for (var i = 0; i < keys.length; i++) {
                        var prop = keys[i];
                        var text = props[prop].toLowerCase();
                        if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                            itemMatches = true;
                            break;
                        }
                    }

                    if (itemMatches) {
                        out.push(item);
                    }
                });
            } else {
                // Let the output be the input untouched
                out = items;
            }

            return out;
        };
    })
})();