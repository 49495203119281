(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(function ($stateProvider) {

            $stateProvider
                .state('app.library.curriculum.plans', {
                    url: '/lessonsplans',
                    controller: 'lessonsCtrl',
                    templateUrl: '/assets/ng1/library/curriculum/lessonsplans/lessonsplans.html'
                })
                .state('app.library.curriculum.plans.shelve', {
                    url: '/:lesson',
                    controller: 'lessonCtrl',
                    templateUrl: '/assets/ng1/library/curriculum/lessonsplans/lesson.html'
                })
                .state('app.teacher.curriculum.plans', {
                    url: '/lessonsplans',
                    controller: 'lessonsCtrl',
                    templateUrl: '/assets/ng1/library/curriculum/lessonsplans/lessonsplans.html'
                })
                .state('app.teacher.curriculum.plans.shelve', {
                    url: '/:lesson',
                    controller: 'lessonCtrl',
                    templateUrl: '/assets/ng1/library/curriculum/lessonsplans/lesson.html'
                })
                .state('app.admin.school.curriculum.plans', {
                    url: '/lessonsplans',
                    controller: 'lessonsCtrl',
                    templateUrl: '/assets/ng1/library/curriculum/lessonsplans/lessonsplans.html'
                })
                .state('app.admin.school.curriculum.plans.shelve', {
                    url: '/:lesson',
                    controller: 'lessonCtrl',
                    templateUrl: '/assets/ng1/library/curriculum/lessonsplans/lesson.html'
                })
        })

        .controller('lessonsCtrl', function ($scope, $state, $stateParams, LMAPI, course, user, credentials, school) {

            // throw event main tab change
            $scope.$emit('libraryTabChange', 'categories');

            // watch for categories tab changed
            $scope.$on('categoriesTabChange', function (event, data) {
                $scope.activeTab = data;
            });
            $scope.onOpenQuest = onOpenQuest;
            $scope.onCreateQuest = onCreateQuest;
            $scope.vmOpenPlans = vmOpenPlans;
            $scope.user = user;
            $scope.school = school;
            $scope.username = credentials.username;
            $scope.mode = window.localStorage.getItem('mode');

            $scope.lessons = [];
            let params = JSON.parse(course.params);

            for (let i = params.p_week_start; i <= params.p_week_end; i++) {

                $scope.lessons.push({
                    id: i,
                    title: i < 10 ? '0' + i : i
                });
            }

            vmOpenPlans($scope.lessons[0].title);

            function onOpenQuest(quest) {

                //let mode = window.localStorage.getItem('mode');
    
                if ($scope.mode === 'teacher') {
    
                    $state.go('app.teacher.tasks.tupdate12', { task: quest, page: 'fromLibrary', state: 'current' });
                  
                } else if ($scope.mode === 'schooladmin') {
    
                    $state.go('app.admin.school.tasks.tupdate12', { task: quest, page: 'fromLibrary', state: 'current' });
                
                }
            }
            function vmOpenPlans(item) {

                let mode = window.localStorage.getItem('mode');
    
                if (mode === 'teacher') {
    
                    $state.go('app.teacher.curriculum.plans.shelve',  { lesson: item });
                  
                } else if (mode === 'schooladmin') {
    
                    $state.go('app.admin.school.curriculum.plans.shelve', { lesson: item });
                
                } else if (mode === 'library') {
                    $state.go('app.library.curriculum.plans.shelve', { lesson: item });
                }
            }
            function onCreateQuest() {
                alert('create!');
            }

        })
        .controller('lessonCtrl', function ($scope, $state, $stateParams, LMAPI, course, user) {

            // throw event categories tab change
            $scope.$emit('categoriesTabChange', $stateParams.lesson);
            $scope.course = course;
            $scope.lesson = $stateParams.lesson;

            let params = JSON.parse(course.params);

            $scope.countBlocks = params.p_week_config;

            $scope.countBlocks.forEach((x) => {
                x.img = 'https://s3.amazonaws.com/' + params.p_folder + '/' + params.p_id + '/' + x.img;
            })

            function vmOpen(lesson) {

                let valueX = lesson.suffix;

                let url = 'https://s3.amazonaws.com/' + params.p_folder + '/' + params.p_id + '/' +
                    (params.p_prefix ? params.p_prefix : '') + '_' + params.p_id + '-' +
                    $stateParams.lesson + '-' + valueX + '.pdf';

                let secureUrl = vmGetSignedURL(url); // secure url from field 'External Content'

                window.open(secureUrl, '_blank');
            }

            function vmGetSignedURL(url) {

                // find Amazon S3 bucket from URL
                // check two possible positions for it
                var buckets = url.match('https://([a-z\-]*).?s3[\.a-z0-9\-]*.amazonaws.com/([a-z\-]*)');
                var bucket = buckets[1] || buckets[2];

                // find key portion in Amazon S3 URL
                // exclude root and bucket
                var key = url.match('amazonaws.com(/' + bucket + ')?/([A-Za-z0-9\-\/\._ ]*)')[2];

                // get signed URL
                var s3 = new AWS.S3({ signatureVersion: "v4" });
                return s3.getSignedUrl('getObject', { Bucket: bucket, Key: key });
            };


            $scope.open = vmOpen;
        })
})();